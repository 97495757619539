<!-- <p>loopsearch works!</p> -->

<div *ngFor="let items of tableData" ngcontent-yrg-c82="" class="loop--details pw-loop-details">
    <div ngcontent-yrg-c82="" class="loop-artwork"><img [src]="setimage(items)"><span ngcontent-yrg-c82=""
            class="play-btn hover">
            <span class="play-btn hover play_button" id="play_button">
                <i aria-hidden="true" *ngIf="!items.hidepausebutton" (click)="playAudio(items)" class="fa fa-play"></i>
                <i (click)="stopAudio(items)" *ngIf="items.hidepausebutton" class="fa fa-pause"
                    aria-hidden="true"></i></span>

        </span>


    </div>
    <div ngcontent-yrg-c82="" class="loop-info-header">
        <h4 ngcontent-yrg-c82=""><b ngcontent-yrg-c82="" class="hover" mat-raised-button #red="matTooltip"
                matTooltip="Go to Detail Page" matTooltipHideDelay="100" mattooltip="Comments" (click)="redirecttoloopdetailpage(items)">"{{items.title}}"</b>
            by<a (click)="redirecttoprofilepage(items)" matTooltip="Go to Profile Page" matTooltipHideDelay="100"
                mattooltip="Comments" ngcontent-yrg-c82="" class="link">
                @{{items.username}}</a></h4>

        <span ngcontent-yrg-c82="" [innerHtml]="timeget(items)" class="posted">
        </span>
        <div ngcontent-yrg-c82="" class="row mt-2">
            <div _ngcontent-yrg-c82="" class="col-md-6" style="display: none">
                <div _ngcontent-yrg-c82="" class="media-equlizer">

                    <ng-waveform #waveform class="waveform" [src]="srcforsound(items)" [height]="100"
                        [useRegion]="false" backgroundColor="#d3d3d3" regionBackgroundColor="rgba(200, 255, 255, 0.7)"
                        regionStartStickColor="#21f032" regionEndStickColor="grey" regionTextColor="#09417e"
                        [withRegionLabels]="true" waveColor="#909090" (paused)="onPaused()"
                        (regionChange)="onRegionChange($event)" (durationChange)="onDurationChange($event)"
                        (trackLoaded)="onTrackLoaded($event)" (rendered)="onTrackRendered($event)"
                        (timeUpdate)="onTimeUpdate($event)" (paused)="onPaused()">

                    </ng-waveform>
                </div>
            </div>
            <div ngcontent-yrg-c82="" class="col-md-6">
                <div ngcontent-yrg-c82="" class="loop-col-cat">
                    <h5 ngcontent-yrg-c82="" class="mt-0">{{items.terms_name}}</h5><span ngcontent-yrg-c82=""
                        class="badge loop-badge">{{items.loop_type==1?'Loop':'Loop Kit'}}</span><span
                        ngcontent-yrg-c82="" [innerHtml]="tagsget(items)" class="badge hover"
                        (click)="redirectto1(items)"></span><span ngcontent-yrg-c82="" class="badge hover"
                        [innerHtml]="tagsget2(items)" (click)="redirectto2(items)"></span><span ngcontent-yrg-c82=""
                        class="badge hover" [innerHtml]="tagsget3(items)" (click)="redirectto3(items)"></span>
                </div>
            </div>
        </div>
        <!-- <span class="media-equlizer">
		
		</span> -->
        <ul class="loop-stats">
            <li id="red" mat-raised-button #red="matTooltip" matTooltip="Likes" matTooltipHideDelay="100"
                mattooltip="Likes" class="mat-tooltip-trigger hover re {{addredcolor(items)}}"
                (click)="heartcolorred(items.loop_id,'loop',items)"><i ngcontent-yrg-c82="" class="fa fa-heart"
                    [innerHtml]="likesnumber(items)">{{items.number_of_likes}}</i></li>

            <li mat-raised-button #red="matTooltip" matTooltip="Reposts" matTooltipHideDelay="100" mattooltip="Reposts"
                id="green" class="mat-tooltip-trigger hover {{addgreencolor(items)}}"><i class="fa fa-retweet"
                    [innerHtml]="repostnumber(items)"
                    (click)="repost(items.loop_id,items)">{{items.number_of_reposts}}</i>
            </li>

            <li mat-raised-button #red="matTooltip" matTooltip="Comments" matTooltipHideDelay="100"
                mattooltip="Comments" class="mat-tooltip-trigger hover"><i class="fa fa-comment"
                    (click)="redirecttolooppagecomment(items.loop_id)"></i> {{items.number_of_comments}}
            </li>
            <li mat-raised-button #red="matTooltip" matTooltip="Download" matTooltipHideDelay="100"
                mattooltip="Download" class="mat-tooltip-trigger hover"><i class="fa fa-download"></i>
            </li>
            <li mat-raised-button #red="matTooltip" matTooltip="Price" matTooltipHideDelay="100"
                *ngIf="items.loop_price!==0" mat-raised-button #red="matTooltip" matTooltip="Price"
                matTooltipHideDelay="100" class="">
                <i class="fa fa-dollar" [innerHtml]="priceget(items)"></i>
            </li>
        </ul>
    </div>
</div>




<div class="text-center" *ngIf="nocomments">
    <ul class="paginationlist">
        <li class="text-info" *ngIf="!norecordfound">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
        <!-- <li class="text-info" *ngIf="!noresult">Showing {{record}} records of {{total}}</li> -->
        <!-- <li class="text-info"> {{total}}</li> -->
        <ul class="nextulpagination" *ngIf="!hidepagination">

            <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item === pageno}"
                *ngFor="let item of pagercount" id="liststyle{{item}}" (click)="paginationget(item)">
                {{item}}

            </li>
        </ul>

    </ul>


</div>

<div *ngIf="!nocomments" class="text-center" style="
    
    padding: 10px;
">No records to display</div>