






import { environment } from 'src/app/sevicesall/alldata';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-follow-listing',
  templateUrl: './follow-listing.component.html',
  styleUrls: ['./follow-listing.component.css']
})
  
export class FollowListingComponent implements OnInit {
  

  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = false;
  norecordfound:any;
  sorttype = "";
  sorton = "";
  imageofcomment: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  value: any;
  encryptedagainremoveslashes: string;
  hidesearchbox = false;
  pagenocount: any;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService) {  }

  ngOnInit(): void {
    // this.value = this.route.snapshot.queryParamMap.get('user_id');
    // this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    // this.decryptUsingAES256();
    this.getapicallforcouponcode();
    
  }

  async getapicallforcouponcode() {
    // ////console.log("getting");
    this.common.showLoader();
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

  //   if (this.pageno == 1) {
  //     this.router.navigate([`followers`], { queryParams: { user_id: this.value } } );
  //  } else {
  //    this.router.navigate([`followers`], { queryParams: {user_id: this.value,pageno: this.pageno } });
  //  }
    
    

    let sendingdata = {
      "page_no":this.pageno,
      "sort_on":"created_on",
      "row_size":"10",
      "sort_type":"DESC",
      
      
    }

    this.api.forclientgettokenpublic(`/user/get_follow_requests`, sendingdata).then((res: any) => {

      // ////console.log(res);
      this.pagercount = [];

      if (res.response.status == 1) {
        this.common.hideLoader();
        // ////console.log(res);

        if (res.response.data.total_records == 0) {
          this.norecordfound = false;
        } else {
          this.norecordfound = true;
        }

        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
        } else {
          this.hidepagination = false;
        }
        if (this.paginationempty == true) {
          this.pagercount = [];
        }

        this.tableData = res.response.data.pagging_list;
        this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;
        this.record = res.response.data.pagging_list.length;
        this.total = res.response.data.total_records;
        this.record = this.pageno - 1 + "1";
        if (this.record == "01") {
          this.record = 1;
        }
        this.pagenocount = 10*(this.pageno - 1) + res.response.data.pagging_list.length


        // this.tableData.map((data: any) => {
        //   if ( data.profile_image_path == null) {
        //     this.imageofcomment = this.profile_image_pathofcomments;
        //     // ////console.log("gaya");
        //   } else {
        //     // ////console.log("nahi gaya");
        //     this.imageofcomment = CONSTNAME.fILE_PATH + data.profile_image_path;
        //   }
        // })

        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }
        // ////console.log(res.data.total_records)
        this.total = res.response.data.total_records;
        // let ida: any = document.getElementById("liststyle"+1);
        // ////console.log(ida);
        // ida.style.backgroundColor = "orange";

      } else if (res.response.status == 4) {
        // ////console.log("status is 4");
        this.common.hideLoader();
        this.hidesearchbox = true;
        // this.noresult = false

      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  }





  onsearch() {

    // ////console.log("searching");
    this.searchApi();
  }
  searchClick() {

    this.pageno = 1;
    this.searchApi();
  }
  async searchApi() {
    this.common.showLoader();



    // this.router.navigate(['/admin/loops/list-uploads'], { queryParams: { search: this.validationforsearch, pageno: this.pageno } });
    
    // this.router.navigate(['followers'], { queryParams: { user_id: this.value, search: this.validationforsearch, pageno: this.pageno } });
    
    if (this.pageno == 1) {
      this.router.navigate(['followers'], { queryParams: { user_id: this.value, search: this.validationforsearch } });
   } else {
    this.router.navigate(['followers'], { queryParams: { user_id: this.value, search: this.validationforsearch, pageno: this.pageno } });
   }
    let search = this.validationforsearch
    // let tokkenget = localStorage.getItem("loggedintoken");
    // const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
  

    let sendingdata = {
      "page_no":this.pageno,
      // "sort_on":this.sorton,
      "row_size":this.rowsize,
      // "sort_type":this.sorttype,
      "get_followers":1,
      "search": search ,
      "profile_user_id": Number(this.decrypted)
    }

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    this.api.forclientgettokenpublic(`/user/get_followers_following`, sendingdata).then((res: any) => {

    // this.api.postApiHit(`/admin/get_all_uploads?page_no=${this.pageno}&row_size=${this.rowsize}&search=${search}`, {}, VARS.ADMIN_TYPE).then((res: any) => {
      this.validationforsearch = "";
      if (res.response.status == 1) {
        this.common.hideLoader();
        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
        } else {
          this.hidepagination = false;
        }



        if (res.response.data.total_records == 0) {
          this.pagercount = [];


          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.noresult = true;
          // ////console.log("aa");
          // this.toastr.success("No record found", res.response.errors, { positionClass: 'toast-bottom-right' });
          this.total = res.response.data.total_records;
          this.record = this.pageno - 1 + "1";
          if (this.record == "01") {
            this.record = 1;
          }
          this.pagenocount = 10*(this.pageno - 1) + res.response.data.pagging_list.length

        } else {
          this.common.hideLoader();

          this.noresult = false;
          this.pagercount = [];
          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.record = this.tableData.length;
          this.record = this.pageno - 1 + "1";
          if (this.record == "01") {
            this.record = 1;
          }
          this.pagenolist = res.response.data.total_pages + 1;
          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);
          }
          this.total = res.response.data.total_records;
          this.record = this.pageno - 1 + "1";
          if (this.record == "01") {
            this.record = 1;
          }
          this.pagenocount = 10*(this.pageno - 1) + res.response.data.pagging_list.length

        }

      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  tablevaluearray = [
    { name: 'Title', tablehead: 'title', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Loop type', tablehead: 'loop_type', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created By', tablehead: 'first_name', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created On', tablehead: 'created_on', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },

    { name: 'Action', tablehead: 'Action', sort: false, showicon: false, sortdesc: false, class: 'fa fa-sort' }

  ];
  sortOn(value: any) {
    this.getapicallforsort(value.tablehead);
    this.tablevaluearray.map(item => {
      item.sort = false;
      item.sortdesc = false;
      item.class = 'fa fa-sort';
    })
    value.sort = true;
    value.class = "fa fa-sort-asc";
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "title")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "loop_type")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "first_name")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "created_on")) {
      value.class = 'fa fa-sort-desc';
    }


  }

  getapicallforsort(got: any) {
    let sort_on = got;
    let arrange = "DESC";
    if (this.route.snapshot.queryParamMap.get('sort') == "ASC") {
      arrange = "DESC";
      this.sortsign = "sort-desc";
      this.sorton = sort_on;
      this.sorttype = arrange;
    } else {
      arrange = "ASC";
      this.sortsign = "sort-asc";
      this.sorton = sort_on;
      this.sorttype = arrange;
    }
    this.searchwordget = this.route.snapshot.queryParamMap.get('search')
    if (this.searchwordget) {
      this.common.showLoader();
      // this.router.navigate(['/profile/followers'], { queryParams: { search: this.validationforsearch, pageno: this.pageno } });
      this.router.navigate(['followers'], { queryParams: { search: this.validationforsearch, pageno: this.pageno, sort: arrange } });
      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

      let sendingdata = {
        "page_no":this.pageno,
        "sort_on":sort_on,
        "row_size":this.rowsize,
        "sort_type":arrange,
        "get_followers":1,
        "search": this.searchwordget ,
        "profile_user_id": Number(this.decrypted)
      }

      

    
  
      this.http.post(APIURL.BASE_URL +`/user/get_followers_following`, sendingdata, { headers }).subscribe(async (res: any) => {
  

      // this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search_input=${this.searchwordget}`, { headers }).subscribe(async (res: any) => {
      // this.api.postApiHit(`/admin/get_all_uploads?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search=${this.searchwordget}`, {}, VARS.ADMIN_TYPE).then((res: any) => {

        if (res.response.status == 1) {
          this.common.hideLoader();
          this.tableData = res.response.data.pagging_list;
        } else {
          this.toastr.error("Error", res.response.error, { positionClass: 'toast-bottom-right' });
          this.common.hideLoader();
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.getpageno = this.route.snapshot.queryParamMap.get('pageno');
      if (this.getpageno) {
        this.pageno = this.getpageno;
      } else {
        this.pageno = 1;
      }
      this.router.navigate(['followers'], { queryParams: { sort: arrange, pageno: this.pageno } });

      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      let sendingdata = {
        "page_no":this.pageno,
        "sort_on":sort_on,
        "row_size":this.rowsize,
        "sort_type":arrange,
        "get_followers":1,
        "profile_user_id": Number(this.decrypted)
        // "search": this.searchwordget 
      }
  
      this.http.post(APIURL.BASE_URL +`/user/get_followers_following`, sendingdata, { headers }).subscribe(async (res: any) => {
    
      // this.api.postApiHit(`/admin/get_all_uploads?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}`, {}, VARS.ADMIN_TYPE).then((res: any) => {
        if (res.response.status == 1) {
          this.common.hideLoader();

          this.tableData = res.response.data.pagging_list;

         

        } else {
          this.toastr.error("", res.response.error, { positionClass: 'toast-bottom-right' });
          this.common.hideLoader();
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

      });

    }

  }


  paginationget(pageinfo: any) {
    this.paginationempty = true;
    this.pageno = pageinfo;

    if (this.route.snapshot.queryParamMap.get('search')) {
      this.searchApi()
    } else {
      this.getapicallforcouponcode();
    }


  }

  // routerLink=""
  toreportedloops() {
    // ////console.log("yes");
    this.router.navigate(['/admin/loops/reported-loops']);
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(value);
    // ////console.log(this.encrypted);

    // ////console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }

  // [routerLink]="['/admin/loops/detail-uploads/'+item.loop_id]"

  addcolor(item: any) {
    if (item == this.pageno) {
      return 'activated';
    } else {
      return ''
    }
  }

  followredirect() {

    this.router.navigate(['followings/'], { queryParams: { user_id: this.value } })

    // ////console.log(this.userMenuforfollow[1].active);
    // this.router.navigate([`profile/followings`]);
  }


  setimage(data:any) {
    if (data.profile_image_path == null) {
      // ////console.log("i am in null");
    return this.profile_image_pathofcomments;
    } else {
      // ////console.log("i am not in null");
      return CONSTNAME.fILE_PATH + data.profile_image_path;
    }
  }

  decryptUsingAES256() {
    // ////console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    // ////console.log(this.decrypted);
    this.decrypted = this.decrypted.replace(/[""]/gi, '');
    // ////console.log(this.tokenFromUI);

  }

  redirecttoprofilepage(item:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.user_type_id), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    this.encrypted =encodeURIComponent(this.encrypted);
    // ////console.log(['user/'], { queryParams: { user_id: item.user_id } });
    this.router.navigate(['user/sounds'], { queryParams: { user_id: this.encrypted } });
    
  }

  async Followapi() {
    this.common.showLoader();
    let sendingData = {
      "profile_user_id": this.decrypted
    }
  



    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    
    let headers: any;
    if (tokkenget) {
      this.common.showLoader();
      this.api.postApiHit(`/user/follow_unfollow_user`, sendingData).then((res: any) => {
        if (res.response.status == 1) {

         

          
          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          
          // ////console.log("gaya");
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
          

        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.common.hideLoader();
      // this.opendialogueforverification();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }
  }
  functionforaccept(items: any) {

    // ////console.log(items)
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.user_type_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(item.user_id);





    this.callforaccept(items, this.encrypted);

  }
  callforaccept(items: any, second: any) {
    this.common.showLoader();
    let sendingdata = {
      "requester_user_id": items.user_type_id,
      "encrypted_requester_user_id": second
    }

    this.api.forclientgettokenpublic(`/user/accept_follow_request`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        this.getapicallforcouponcode()
        // ////console.log(res)
        // this.arrayfortable=res.response.data.pagging_list
      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }
  functionfordecline(items: any) {

    // ////console.log(items.notifier_user_id)
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.user_type_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(item.user_id);





    this.callfordestroy(items.user_type_id, this.encrypted);

  }


  callfordestroy(items: any, second: any) {


    this.common.showLoader();
    let sendingdata = {
      "requester_user_id": items,
      "encrypted_requester_user_id": second
    }

    this.api.forclientgettokenpublic(`/user/decline_follow_request`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        this.getapicallforcouponcode()
        // ////console.log(res)
        // this.arrayfortable=res.response.data.pagging_list
      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }

}

