import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { APIURL } from 'src/app/allServices/constant.service';
// import { APIURL } from '../allServices/constant.service';

@Component({
  selector: 'app-paymentreceipt',
  templateUrl: './paymentreceipt.component.html',
  styleUrls: ['./paymentreceipt.component.css']
})
export class PaymentreceiptComponent implements OnInit {
  Planname: any;
  planprice: any;
  createdon: any;
  loopkittoupload: any;
  loopstoupload: any;
  showpremimum:any;
  Username: any;
  displayname = "";
  afterload = false;
  freedate: any;
  

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute,private datePipe: DatePipe) {
    this.getapicallforprint();
  }

  ngOnInit(): void {


  }




  getapicallforprint() {
    let urlinfo = this.route.snapshot.queryParamMap.get('plantype');
    let tokkenget = localStorage.getItem("clientintoken");
    // ////console.log(urlinfo);
    if (urlinfo == "free") {
      // alert("free");
      this.showpremimum = false;
    } else if (urlinfo == "Premimum") {
      this.showpremimum = true;
    }

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.http.get(APIURL.BASE_URL+'/user/get_user_subscription_details', { headers }).subscribe(async (res: any) => {
      this.afterload = true;
      if (res.response.status == 1) {
        let name = localStorage.getItem("loopheadusername");
        // ////console.log(name);
        this.Username = name;
        this.displayname = "none";
        // ////console.log(res.response.data[0]);
        this.Planname = res.response.data[0].plan_name;
        this.planprice = res.response.data[0].plan_price;
        this.loopkittoupload = res.response.data[0].loopkits_to_upload;
        this.loopstoupload = res.response.data[0].loops_to_upload;
        // this.createdon = res.response.data[0].created_on;
        // this.createdon =res.response.data[0].payment_created_on;
        const date = new Date(res.response.data[0].payment_created_on);
        // this.createdon = this.datePipe.transform(new Date(), 'YY-MM-yyyy , hh:mm a');
        let createddate = moment(res.response.data[0].payment_created_on).format('DD-MM-YYYY h:mm A');
        this.createdon = createddate;
        // this.createdon= this.createdon;
        let freed = moment(res.response.data[0].created_on).format('DD-MM-YYYY h:mm A');
        this.freedate = freed;

      } else {
        this.displayname = "none";
  
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          // let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.displayname = "none";
      // ////console.log('Error', e);
      this.toastr.error("Error in Api", "Error", { positionClass: 'toast-bottom-right' });

    });
  }

}
