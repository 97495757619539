import { DatePipe, PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Location } from '@angular/common';
@Component({
  selector: 'app-couponcodes',
  templateUrl: './couponcodes.component.html',
  styleUrls: ['./couponcodes.component.css']
})
export class CouponcodesComponent implements OnInit {
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  sorttype = "";
  sorton = "";
  pagenocount: any;
  pageOfItems: Array<any>
  totalpagesinaccount: any;
  items: any = [];
  run: boolean = false;
  yesundefined: boolean = false;
  urlofrouter: string;
  config: any; 
  collection = [];
  firstcall: any = 0;
  temp_pno: any = 0;

  constructor(public router: Router, location: PlatformLocation, private http: HttpClient, private toastr: ToastrService, public route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService) {

    // this.route.queryParams.subscribe(params => {
    //   // let storedPageNo = localStorage.getItem('couponPageNo');

    //   // this.pageno = storedPageNo != null ? Number(storedPageNo) : 1;

    //   if (params && params.page_no) {
    //     ////console.log(params.page_no)
    //     ////console.log("in param")
    //     this.pageno = Number(params.page_no);
    //   }



    // });


  }

  ngOnDestroy() {
    // ////console.log("yup")
    localStorage.removeItem('couponPageNo');
  }

  ngOnInit(): void {

    // ////console.log(this.route.snapshot.queryParamMap.get('pageno'))
    this.pageno = this.route.snapshot.queryParamMap.get('pageno')
    if (this.pageno) {
      this.pageno = Number(this.pageno)
    }
    // let storedPageNo = localStorage.getItem('couponPageNo');

    // this.pageno = storedPageNo != null ? Number(storedPageNo) : 1;
    this.urlofrouter = this.router.url
    // ////console.log(this.router.url);
    this.getapicallforcouponcode();
    // this.route.paramMap
    // .map((params:any) => params.get('pageno'))
    // .subscribe((pageno:any) => this.pageno = page);

    // this.config = {
    //   currentPage: 1,
    //   itemsPerPage: 2
    // };

    // this.route.queryParamMap
    //   .map(params => params.get('page'))
    //   .subscribe(page => this.config.currentPage = page);

    // for (let i:any = 1; i <= 100; i++) {
    //   this.collection.push(`item ${i}`);
    // }
  }


  // @HostListener('window:popstate', ['$event'])
  // onBrowserBackBtnClose(event: Event) {
  //   // ////console.log('back button pressed');
  //   this.router.navigate([''], { replaceUrl: true });
  //   // event.preventDefault(); 
  // }


  getapicallforcouponcode() {
    this.common.showLoader();

    if (this.validationforsearch == "") {
      this.router.navigate(['/admin/list-coupon-codes'], { queryParams: { pageno: this.pageno } });
    } else {
      this.router.navigate(['/admin/list-coupon-codes'], { queryParams: { pageno: this.pageno, search: this.validationforsearch } });
      // ////console.log(this.validationforsearch)
    }
    if (this.pageno == undefined) {
      // ////console.log("undefined")
      this.yesundefined = true
      this.pageno = 1
    }


    let tokkenget = localStorage.getItem("loggedintoken");
    let search = this.validationforsearch
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?page_no=${this.pageno}&row_size=${this.rowsize}&sort_on=${this.sorton}&sort_type=${this.sorttype}&search_input=${search}`, { headers }).subscribe(async (res: any) => {

      if (res.response.status == 1) {
        this.run = true
        // this.tableData = res.response.data.pagging_list;
        // this.record = this.tableData.length;
        // this.pagenolist = res.response.data.total_pages + 1;

        // for (let i = 1; i < this.pagenolist; i++) {
        //   this.pagercount.push(i);

        // }

        // this.total =  res.response.data.total_records;
        this.common.hideLoader();
        if (this.paginationempty == true) {
          this.pagercount = [];
        }
        // ////console.log(res.response.data);
        this.pagenocount = + res.response.data.pagging_list.length
        this.record = this.pageno - 1 + "1";
        // ////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
        this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length

        this.tableData = res.response.data.pagging_list;
        if (this.totalpagesinaccount !== res.response.data.total_pages) {
          this.totalpagesinaccount = res.response.data.total_pages
          this.items = Array(this.totalpagesinaccount).fill(0).map((x, i) => (i + 1))
        }
        // this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;
        if (this.record == "01") {
          this.record = 1;
        }
        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);
        }
        // ////console.log(res.data.total_records)
        this.total = res.response.data.total_records;
      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  MyEncodeURI(path: any) {
    return encodeURI(path);
  }
  buttonapi(value: any) {
    if (value == false) {
      value = 0;
    } else if (value == true) {
      value = 1;
    }
    // ////console.log(value);
    let sendingdata = {
      "coupon_code_id": value
    }


    this.api.postApiHit(APIURL.ADMIN_ENABLE_DISABLE_COUPON, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.router.navigate(['/admin/list-coupon-codes']);
        // ////console.log(res);
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      } else {
        let errorthroughapi = res.response.errors;
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }





  onsearch() {
    // ////console.log("searching");
    this.getapicallforcouponcode();
  }

  searchClick() {
    this.pageno = 1;
    this.getapicallforcouponcode();
  }
  // searchApi() {
  //   this.common.showLoader();
  //   this.router.navigate(['/admin/list-coupon-codes'], { queryParams: { search: this.validationforsearch, pageno: this.pageno } });
  //   let search = this.validationforsearch
  //   let tokkenget = localStorage.getItem("loggedintoken");
  //   const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
  //   this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?page_no=${this.pageno}&row_size=${this.rowsize}&search_input=${search}`, { headers }).subscribe(async (res: any) => {
  //     if (res.response.status == 1) {
  //       this.run = true
  //       this.common.hideLoader();
  //       if (res.response.data.total_records == 0) {
  //         this.pagercount = [];
  //         this.tableData = res.response.data.pagging_list;
  //         this.record = this.tableData.length;
  //         this.noresult = true;
  //         // this.toastr.success("No record found", res.response.errors, { positionClass: 'toast-bottom-right' });
  //         this.total = res.response.data.total_records;
  //       } else {
  //         this.noresult = false;
  //         this.pagercount = [];
  //         this.tableData = res.response.data.pagging_list;
  //         this.record = this.tableData.length;
  //         this.record = this.pageno - 1 + "1";
  //         if (this.record == "01") {
  //           this.record = 1;
  //         }
  //         // ////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
  //         this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length
  //         this.pagenolist = res.response.data.total_pages + 1;
  //         for (let i = 1; i < this.pagenolist; i++) {
  //           this.pagercount.push(i);
  //         }
  //         this.total = res.response.data.total_records;
  //       }
  //       if (this.totalpagesinaccount !== res.response.data.total_pages) {
  //         this.totalpagesinaccount = res.response.data.total_pages
  //         this.items = Array(this.totalpagesinaccount).fill(0).map((x, i) => (i + 1))
  //       }
  //     } else {
  //       this.common.hideLoader()
  //       let errorthroughapi = res.response.errors;
  //       let error = [];
  //       for (var i = 0; i < errorthroughapi.length; i++) {
  //         let jk = error.push(errorthroughapi);
  //         this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
  //       }
  //     }
  //   }, async e => {
  //     this.common.hideLoader()
  //     this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
  //   });
  // }

  tablevaluearray = [
    { name: 'Coupon Name', tablehead: 'coupon_code', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Coupon Start Date', tablehead: 'valid_from', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Coupon End Date', tablehead: 'valid_to', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created On', tablehead: 'created_on', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Coupon Status', tablehead: 'is_enabled', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Action', tablehead: 'Action', sort: false, showicon: false, sortdesc: false, class: 'fa fa-sort' }

  ];
  sortOn(value: any) {
    this.getapicallforsort(value.tablehead);
    this.tablevaluearray.map(item => {
      item.sort = false;
      item.sortdesc = false;
      item.class = 'fa fa-sort';
    })
    value.sort = true;
    value.class = "fa fa-sort-asc";
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "coupon_code")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "valid_from")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "valid_to")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "created_on")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "is_enabled")) {
      value.class = 'fa fa-sort-desc';
    }

  }

  getapicallforsort(got: any) {
    let sort_on = got;
    let arrange = "DESC";
    if (this.route.snapshot.queryParamMap.get('sort') == "ASC") {
      arrange = "DESC";
      this.sortsign = "sort-desc";
      this.sorton = sort_on;
      this.sorttype = arrange;
    } else {
      arrange = "ASC";
      this.sortsign = "sort-asc";
      this.sorton = sort_on;
      this.sorttype = arrange;
    }
    this.searchwordget = this.route.snapshot.queryParamMap.get('search')
    if (this.searchwordget) {
      this.router.navigate(['admin/list-coupon-codes'], { queryParams: { search: this.validationforsearch, pageno: this.pageno, sort: arrange } });
      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search_input=${this.searchwordget}`, { headers }).subscribe(async (res: any) => {
        if (res.response.status == 1) {
          this.run = true
          this.tableData = res.response.data.pagging_list;
        } else {
          this.toastr.error("Error", res.response.error, { positionClass: 'toast-bottom-right' });
        }
      }, async e => {
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.getpageno = this.route.snapshot.queryParamMap.get('pageno');
      if (this.getpageno) {
        this.pageno = this.getpageno;
      } else {
        this.pageno = 1;
      }
      this.router.navigate(['admin/list-coupon-codes'], { queryParams: { sort: arrange, pageno: this.pageno } });
      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}`, { headers }).subscribe(async (res: any) => {
        if (res.response.status == 1) {
          this.run = true
          this.tableData = res.response.data.pagging_list;
        } else {
          this.toastr.error("", res.response.error, { positionClass: 'toast-bottom-right' });
        }
      }, async e => {
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    }
  }


  paginationget(pageinfo: any) {
    this.paginationempty = true;
    this.pageno = pageinfo;
    // let idname = "liststyle" + this.pageno;
    // let idaa: any = document.getElementById(idname);
    // ////console.log(idaa);
    // let x: any = document.querySelectorAll(".bluecolor");
    // for (let i = 0; i < x.length; i++) {
    //   x[i].style.backgroundColor = "";
    // }
    // idaa.style.backgroundColor = "orange";
    if (this.route.snapshot.queryParamMap.get('search')) {
      // this.searchApi()
      this.getapicallforcouponcode();
    } else {
      this.getapicallforcouponcode();
    }
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    return this.encrypted;
    // ////console.log(value);
    // ////console.log(this.encrypted);

    // ////console.log(this.pageno);
    // this.router.navigate(["page2"], navigationExtras);

    // ////console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    //this.router.navigate([`/admin/list-coupon-codes/detail-coupon-code/${this.encrypted}`], navigationExtras);
  }


  addcolor(item: any) {
    if (item == this.pageno) {
      return 'activated';
    } else {
      return ''
    }
  }
  onChangePage(pageOfItems: Array<any>) {
 
    if (pageOfItems[0] == undefined && this.firstcall == 0) {
      this.firstcall = 1;
      this.temp_pno = Number(this.route.snapshot.queryParamMap.get('pageno'));
     
    }
    else if (this.firstcall == 1 && this.temp_pno > 0 && (pageOfItems[0] != this.temp_pno)) {
      
      // ////console.log(2)
      
      this.pageno = this.temp_pno;
      
      this.firstcall = 0;
      this.temp_pno = 0;

      this.getapicallforcouponcode();
    }
    else if ((this.pageno !== pageOfItems[0]) && !(pageOfItems[0] == undefined)) {

      this.firstcall = 0;
      this.pageno = pageOfItems[0];
      
      this.getapicallforcouponcode();
    }
    else {
      this.firstcall = 0;
      this.temp_pno = 0;
    }

    
  }








}
