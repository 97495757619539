<div class="header">
    <div class="">
      <div class="row1">
        <div class="navbar-translate logged-out col-sm-9 col-7">
          <div class="header-logo">
            <img src="./assets/img/logo1.png" href="index.html" height="60" class="header-logo-img">
            <a class="navbar-brand" href="index.html" rel="tooltip" data-placement="bottom">
              Loophead
            </a>
          </div>
        </div>
        <div _ngcontent-mwb-c143="" class="col-sm-3 col-5 btn-div"><button _ngcontent-mwb-c143="" class="header-btn mr-2" (click)="redirecttologin()">Login</button>
          <button _ngcontent-mwb-c143="" class="header-btn" (click)="redirecttoregister()">Register
          </button></div>
      </div>
    </div>
  </div>