<!-- <h1 class="text-center">Welcome to Global setting Page. Coming soon !!!</h1> -->

<h3 class="dynamictext">Global Settings</h3>
<span class="loader d-none" id="divLoading" [ngStyle]="{'display': displayname}">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span>
</span>


<form action="" class="my-5">

    <div>
        <div class="form-group col-md-6">
            <label for="admin_notification_email">Admin Notification Email Address</label><span
                class="ml-1 text-danger">*</span>
            <input type="email"  [disabled]="disabledvalue" [(ngModel)]="validateemail.emailcheck" class="form-control"
                id="admin_notification_email" name="admin_notification_email" placeholder="Enter your email address">
            <small class="form-text "
                [ngClass]=" !validateemail.isemailvalid ? 'text-danger' : 'text-success'">{{emailerror}}</small>
        </div>
        <div class="form-group col-md-6">
            <label for="max_number_of_top_charts">Maximum number of Top Charts</label><span
                class="ml-1 text-danger">*</span>
            <input type="number"  [disabled]="disabledvalue" [(ngModel)]="validatetopchart.topchartcheck" min="0" class="form-control"
                id="max_number_of_top_charts" placeholder="Enter number of Top Charts " name="max_number_of_top_charts">
            <small class="form-text "
                [ngClass]=" !validatetopchart.istopchartvalid? 'text-danger' : 'text-success'">{{topcharterror}}</small>
        </div>
    </div>
    <div >
        <div class="form-group col-md-6">
            <label for="instagram_link">Instagram link</label><span class="ml-1 text-danger">*</span>
            <input type="text"  [disabled]="disabledvalue" [(ngModel)]="validationinstagram.instagramcheck" class="form-control" id="instagram_link"
                name="instagram_link" placeholder="Enter your Instagram link">
            <small class="form-text "
                [ngClass]=" !validationinstagram.isinstagramvalid ? 'text-danger' : 'text-success'">{{instagramerror}}</small>
        </div>
        <div class="form-group col-md-6">
            <label for="twitter_link">Twitter Link</label><span class="ml-1 text-danger">*</span>
            <input type="text"  [disabled]="disabledvalue" [(ngModel)]="validationtwitter.twittercheck" class="form-control" id="twitter_link"
                placeholder="Enter your Twitter link" name="twitter_link">
            <small class="form-text "
                [ngClass]=" !validationtwitter.istwittervalid? 'text-danger' : 'text-success'">{{twittererror}}</small>
        </div>
    </div>
    <div >
        <div class="form-group col-md-6">
            <label for="discord_link">Discord link </label><span class="ml-1 text-danger">*</span>
            <input type="text"  [disabled]="disabledvalue" [(ngModel)]="validationdiscord.discordcheck" class="form-control" id="discord_link"
                name="discord_link" placeholder="Enter your discord link">
            <small class="form-text "
                [ngClass]=" !validationdiscord.isdiscordvalid ? 'text-danger' : 'text-success'">{{discorderror}}</small>
        </div>
        <div class="form-group col-md-6">
            <label for="max_number_of_listings">Youtube link </label><span
                class="ml-1 text-danger">*</span>
            <input type="text"  [disabled]="disabledvalue" [(ngModel)]="validationyoutube.youtubecheck" class="form-control"
                name="max_number_of_listi" id="max_number_of_listi" placeholder="Enter your Youtube link">
            <small class="form-text "
                [ngClass]=" !validationyoutube.youtubevalid ? 'text-danger' : 'text-success'">{{youtubeerror}}</small>
        </div>
        <div class="form-group col-md-6">
            <label for="max_number_of_listings">Maximum number of Listings on Home Page </label><span
                class="ml-1 text-danger">*</span>
            <input type="number"  [disabled]="disabledvalue" [(ngModel)]="validaterecommendloop.loopcheck" class="form-control"
                name="max_number_of_listings" id="max_number_of_listings" placeholder="Enter no">
            <small class="form-text "
                [ngClass]=" !validaterecommendloop.isloopvalid ? 'text-danger' : 'text-success'">{{looperror}}</small>
        </div>
        <!-- <div class="form-group col-md-6">
            <label for="partnerfees">Partner Fees Percentage  </label><span
                class="ml-1 text-danger">*</span>
            <input type="number"  [disabled]="disabledvalue" [(ngModel)]="validationcommisoncut.commisioncheck" class="form-control"
                name="partnerfees" id="partnerfees" placeholder="Enter no">
            <small class="form-text "
                [ngClass]=" !validationcommisoncut.commisionvalid ? 'text-danger' : 'text-success'">{{commisionvalue}}</small>
        </div> -->
        <div class="form-group col-md-12  text-right">
        <button type="submit" [disabled]="disabledvalue" class="btn btn-theme text-end" (click)="onsave()"    >{{submitbutton.submitresponse}}</button>
    </div>
    </div>


    
</form>
