<!-- <h1 class="text-center">Welcome to Coupon Code Page. Coming soon !!!</h1> -->

<h3 class="dynamictext">Reported Loops</h3>
<div class="page-actions">
  <div class="row">
    <div class="col-md-4 col-sm-5">
      <form class="search-group">
        <input type="search" placeholder="Search" [(ngModel)]="validationforsearch" aria-label="Search"
          name="searchsubscription" id="searchsubscription" aria-describedby="search-addon"
          class="form-control rounded form-control-sm">
        <button type="submit" style="margin: 0px;"(click)=" searchClick()" class="btn btn-theme">
          <i class="fa fa-search"></i>
        </button>
      </form>
    </div>
    <div class="col-md-8 col-sm-12">
      <div class="btn-group-right">
        <button (click)="touploadloops()" class="btn btn-theme">Loops/Loop Kits</button>
        <button (click)="todeletedloops()" class="btn btn-theme">Deleted Loops/Loop Kits </button>
      </div>
    </div>
  </div>
</div>
<!-- <div>
  <div>
    <ul class="name">

      <li>
        <form class="displayinline">
          <input type="search" [(ngModel)]="validationforsearch" class="form-control rounded form-control-sm " placeholder="Search"
            aria-label="Search" name="searchsubscription" id="searchsubscription" aria-describedby="search-addon" />
          <button class="btn btn-theme " type="submit" (click)=" searchClick()"><i class="fa fa-search"></i></button>
        </form>
      </li>
      <li><button (click)="touploadloops()" class="btn btn-theme">Loops/Loop Kits</button></li>
      <li><button (click)="todeletedloops()" class="btn btn-theme">Deleted Loops/Loop Kits </button></li>
    </ul>
  </div>
</div> -->
<div class="table-responsive">
  <table class="table table-striped  table-bordered" *ngIf="!noresult">
    <thead>
      <tr>
        <th class="text-center marginfa" *ngFor="let items of tablevaluearray">{{items.name}} <i (click)="sortOn(items)"
            *ngIf="items.showicon" id="{{items.tablehead}}" class={{items.class}} style="cursor: pointer;"
            aria-hidden="true"></i></th>
        <!-- <th class="text-center">Coupon Name <i class="hover " aria-hidden="true"></i></th>
          <th class="text-center">Coupon Start Date <i class=" hover " aria-hidden="true"></i></th>
          <th class="text-center">Coupon End Date <i class="hover " aria-hidden="true"></i></th>
          <th class="text-center">Coupon Created Date <i class="hover " aria-hidden="true"></i></th>
          <th class="text-center">Coupon Status <i class="hover " aria-hidden="true"></i></th>
          <th class="text-center">Action</th> -->
      </tr>

    </thead>
    <tbody>


      <tr *ngFor="let item of tableData">
        <td class="text-center">{{item.title}}</td>
        <td class="text-center"> {{item.loop_type==1?'Loop':'Loop Kit'}}</td>
        <!-- <td class="text-center"  >{{item?.loops_to_upload}}</td>
            <td class="text-center" >{{item.can_sell_loops?'Yes':'No'}}</td>
            <td class="text-center">{{item.loopkits_to_upload}}</td>
            <td class="text-center">{{item.can_sell_loopkits?'Yes':'No'}}</td> -->
        <td class="text-center">{{item.first_name}} {{item.last_name}}</td>
        <td class="text-center">{{item.created_on| date: 'dd-MM-yyyy h:mm a'}} </td>

        <td class="text-center hover"><a class="text-center" (click)="encryptUsingAES256(item.loop_id)"><i
              class="fa fa-eye text-center" aria-hidden="true"></i></a></td>
      </tr>


    </tbody>

  </table>
  



</div>
<div *ngIf="noresult" class="noresu text-center">No records to display </div>

  <div *ngIf="norecordfound" class="text-center">No records to display </div>
<div class="text-center">
  <ul class="paginationlist" *ngIf="!noresult">
    <li class="text-info" *ngIf="!norecordfound">Showing {{record}} records of {{total}}</li>
    <!-- <li class="text-info"> {{total}}</li> -->
    <ul class="nextulpagination" *ngIf="!hidepagination">

      <!-- <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item === pageno}" *ngFor="let item of pagercount" id="liststyle{{item}}"
        (click)="paginationget(item)">
        {{item}}

      </li> -->
      <li><jw-pagination class="hover" [items]="items" (changePage)="onChangePage($event)"
    
        [pageSize]=1
        [maxPages]="7"
        
        ></jw-pagination></li>
    </ul>

  </ul>


</div>