<app-header  *ngIf="!isMobile||showMenu"></app-header>
<app-responsiveheader  *ngIf="isMobile&&!showMenu"></app-responsiveheader>
  <div class="page-header" id="page">
    <div class="page-header-image" style="background:black"></div>
    <div class="container my-auto">
      <div  class="explore-content" style="width: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 11px; display: inline-flex">
        <div style="justify-content: flex-start; align-items: center; gap: 20px; display: inline-flex">
          <div><span style="color: white; font-size: 40px; font-family: Inter; font-weight: 700; word-wrap: break-word">Find What’s </span><span style="color: #E39F37; font-size: 40px; font-family: Inter; font-weight: 700; word-wrap: break-word">Hot 🔥</span></div>
        </div>
        <div class="subheading-text"> Explore Our Open Marketplace of Loops</div>
        <div style="width: 100%; height: 100%; justify-content: center; align-items: flex-start; gap: 40px; display: inline-flex">
        <div class="row" style="">
          <div class="col-md-10" style="padding-right: 0px; padding-top: 0px; padding-bottom: 8px; background: white; border-radius: 10px; overflow: hidden; border: 0.50px #888888 solid; gap: 8px; text-align: left;">
            <label class="fa fa-search" style="color: #888888; padding-right: 8px; font-size: 20px; margin-top: 15px;"></label>
            <input type="search"   (keyup.enter)="performSearch()"
            [(ngModel)]="searchQuery" placeholder="Search for loops.." class="search">
          </div>
          <div class="col-md-2 search-div-btn pr-0" style="display: flex;justify-content: flex-end;cursor: pointer;"> 
            <div (click)="performSearch()"class="search-rw"  [ngClass]="searchQuery?'btn-1':''">
            <div style="text-align: center; color: white; font-size: 16px; font-family: Inter; font-weight: 700; word-wrap: break-word">
              Search
            </div>
          </div></div>
         
        </div>
      </div>
      <div class="hidden-mobile" style=" padding:41px;width: 100%;  background: white; border-radius: 20px; margin-top: 20px;display:flex;justify-content:start-around;flex-wrap: wrap;gap: 8px;">
            <span *ngFor="let item of arraytablefortags;last as last" [ngClass]="forfont(item)" 
            style=" cursor: pointer;color: black;border: 1px solid black;height: fit-content; border-radius: 10px;margin-bottom:5px;padding: 1px 10px;text-transform: capitalize;" (click)="redirectto(item.tag_name)"  > {{item.tag_name}}</span>
      </div>
    </div>
    <!--<footer class="footer">
      <div class=" container ">
        <div class="copyright" id="copyright">
          &copy; Loophead
          <script>
            document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))
          </script>
        </div>
      </div>
    </footer>-->
  </div>
  