import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { VARS } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-deleteaccount',
  templateUrl: './deleteaccount.component.html',
  styleUrls: ['./deleteaccount.component.css']
})
export class DeleteaccountComponent implements OnInit {
  

  constructor( public matDialog: MatDialog,public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService,public common: commonservice,private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  cancel(){
    this.matDialog.closeAll()
  }

  deleteaccount() {
    this.common.showLoader();
    this.api.postApiHit('/user/delete_account',{}, VARS.ClIENT_TYPE).then((res: any) => {
      
      if (res.response.status == 1) {
        // this.tableDataofsubgenre = res.response.data;
        // ////console.log(res.response.data);
        this.common.hideLoader();
        // ////console.log(res);
        this.common.clearAllLocalAndSession();
      // localStorage.removeItem("clientintoken");
        this.router.navigate(['/login']);
        this.matDialog.closeAll();

        // let keys = res.response.data.loop_id;

        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })

       
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("failed", "", { positionClass: 'toast-bottom-right' });
    });
  }

}
