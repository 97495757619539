

import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';

import * as CryptoJS from 'crypto-js';

import { CommonModule } from '@angular/common';
imports: [CommonModule];
@Component({
  selector: 'app-dashboar',
  templateUrl: './dashboar.component.html',
  styleUrls: ['./dashboar.component.css']
})

export class DashboarComponent implements OnInit {
  detail:any =[];
  loops_loopkits:any= [];
  users: any = [];
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string
  

  constructor( public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService) {
    this.getallinfoofdashboard();
    // ////console.log("gerat")
  }

  ngOnInit(): void {
  }

  getallinfoofdashboard() {



    let sendingdata = {

      

    }
    


 
    


      this.api.postApiHit(APIURL.ADMIN_GET_ALL_dashboard, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {
  this.common.showLoader();
        
        
        if (res.response.status) {
         this.common.hideLoader();
          
          this.againgetinfo(res);
        } else {
          this.common.hideLoader();
          let errorthroughapi = res.response.errors;
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }

        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });


  }
  

  againgetinfo(res: any) {
    // ////console.log(res);

    this.detail=res.response.data.details 
    this.loops_loopkits=res.response.data.loops_loopkits 
    this.users = res.response.data.users
    
    // ////console.log(this.detail)
    // ////console.log(this.loops_loopkits)
    // ////console.log(this.users)
    
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(value);
    // ////console.log(this.encrypted);

    // ////console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }



  }



