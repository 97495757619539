<!-- <h1 class="text-center">Welcome to Coupon Code Page. Coming soon !!!</h1> -->

<h3 class="dynamictext">Users</h3>
<div class="page-actions">
	<div class="row">
		<div class="col-md-4 col-sm-5">
      <form class="search-group search-inner d-flex">
        <input type="search" [(ngModel)]="validationforsearch" class="form-control rounded form-control-sm"
          placeholder="Search" aria-label="Search" name="searchsubscription" id="searchsubscription"
          aria-describedby="search-addon" />
        <button class="btn btn-theme " style="margin: 0px;" type="submit" (click)=" searchClick()"><i class="fa fa-search "></i></button>
      </form>
		</div>
		<div class="col-md-8 col-sm-7">
			<div class="btn-group-right admin-btns">
          <button *ngIf="!norecordfound" class="btn btn-danger" style="margin: 0px;" [disabled]="disabledeletebutton" (click)="sure()"> <i
              class="fa fa-trash text-right" aria-hidden="true"></i> Delete Selected</button>
          <button (click)="redirectto()" class="btn btn-theme"> <i class="fa fa-plus" aria-hidden="true"></i>
            Add user</button>
          <!-- <button routerLink="/admin/addbanned/new" class="btn btn-theme"> <i class="fa fa-plus" aria-hidden="true"></i>
                Add Banned words</button> -->
      
			</div>
		</div>
	</div>
</div>

<div class="table-responsive mt-5">
  <table class="table table-striped table-bordered table-loops v-middle" *ngIf="!norecordfound && !noresult">
    <thead>
      <tr>

        <th class=" marginfa" [ngClass]="items.tablehead != 'username'?'text-center':''" *ngFor="let items of tablevaluearray">
          <input type="checkbox" (change)="clickforallcheckbox(items)" name="" id="delete" class="delete"
            *ngIf="items.tablehead == 'username'" [(ngModel)]="items.isSelected"> {{items.name}} <i
            (click)="sortOn(items)" *ngIf="items.showicon" id="{{items.tablehead}}" class={{items.class}}
            style="cursor: pointer;" aria-hidden="true"></i>
        </th>

      </tr>

    </thead>
    <tbody>

      <!-- (change)="onChange(item.banned_word_id, $event.target.checked)" -->
      <tr *ngFor="let item of tableData">
        <!-- <td></td> -->
        <!-- (change)="yourfunc($event,item.banned_word_id)" -->
        <!-- [checked]="deletebanned(item.banned_word_id)" -->
        <td class=""><input type="checkbox" (ngModelChange)="singleclick(item)" [(ngModel)]="item.isSelected"
            name="" id="" class="checkbox mt-0 mr-2">{{item.username}}</td>

        <td class="text-center">{{item.first_name}} <span>{{item.last_name}}</span></td>
        <td class="text-center">{{item.email}} </td>
        <td class="text-center">{{item.created_on| date: 'dd-MM-yyyy h:mm a'}} </td>

        <td class="text-center hover"><a
            class="text-center" ><i (click)="sendtouserdetail(item.user_type_id)"
            class="fa fa-eye text-center mr-3" aria-hidden="true"></i><i 
              class=" text-center fa fa-pencil-square-o text-center" (click)="encryptUsingAES256(item.user_type_id)" aria-hidden="true"></i></a></td>
      </tr>
      <!-- [routerLink]="['/admin/addbanned/'+]" -->

    </tbody>
    <!-- <a class="view-user mr-2"><i aria-hidden="true"
      (click)="redirectaa(item.user_type_id)" class="text-center hover fa fa-eye text-center"></i></a> -->

  </table>
  <div *ngIf="noresult" class="noresult">No records to display</div>


</div>
<div *ngIf="norecordfound" class="noresult ">No records to display </div>

<!-- <div *ngIf="norecordfound" class="text-center ">No records to display </div> -->

<!-- <div *ngIf="norecordfound" class="text-center" style="
       color: #108a84;
      background-color: #DFF2BF;
      border-color: #f5c6cb;
      padding: 10px;
  ">No Record Found</div> -->

<div class="text-center">
  <ul class="paginationlist" *ngIf="!noresult">
    <!-- <li class="text-info" *ngIf="!norecordfound">Showing {{record}} records of {{total}}</li> -->
    <li class="text-info" *ngIf="!noresult">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
    <!-- <li class="text-info"> {{total}}</li> -->
    <ul class="nextulpagination" *ngIf="!hidepagination">

      <!-- <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item === pageno}"
        *ngFor="let item of pagercount" id="liststyle{{item}}" (click)="paginationget(item)">
        {{item}}

      </li> -->
      <li><jw-pagination class="hover" [items]="items" (changePage)="onChangePage($event)"
    
        [pageSize]=1
        [maxPages]="3"
        [initialPage]=pageno
        
        ></jw-pagination></li>
      <!-- <button (click)="paginationprev()"><</button>
     <span>{{pageno}}/{{totalpagesinaccount}}</span> 
      <button (click)="paginationnext()">></button> -->


    </ul>

  </ul>


</div>


<!-- <div class="list">
		<ul>
			<li *ngFor="let item of collection | paginate: { itemsPerPage: 10, currentPage: p }">{{ item }}</li>
		</ul>

		<pagination-controls (pageChange)="p = $event"></pagination-controls>
</div> -->


<!-- <ul  class="pagination">
  <li [ngClass]="{disabled:pager.currentPage === 1}">
      <a (click)="setPage(1)">First</a>
  </li>
  <li [ngClass]="{disabled:pager.currentPage === 1}">
      <a (click)="setPage(pager.currentPage - 1)">Previous</a>
  </li>
  <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
      <a (click)="setPage(page)">{{page}}</a>
  </li>
  <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
      <a (click)="setPage(pager.currentPage + 1)">Next</a>
  </li>
  <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
      <a (click)="setPage(pager.totalPages)">Last</a>
  </li>
</ul> -->