<div class="wrapper">
   
    <form >
        <div (click)="cancel()" style="background-color: transparent; color: gray; display: flex; column-gap: 20px;">
            <img src="../../../../assets/img/x-close.svg"/>
            <h6 class="filter-modal-heading">Download Filters</h6>
        </div>
        
            <div>
                <div class="main-box">
                    <div class="row mt-4">
                        <div class="col-11">
                            <p class="filter-p">Filter by Type</p>
                            <!-- <div  class="input-rw">
                                <input type="checkbox" class="check" [(ngModel)]="pack" [ngModelOptions]="{standalone: true}"/>
                                <label> Packs</label>
                            </div> -->
                            <div  class="input-rw">
                                <input type="checkbox" class="check" [(ngModel)]="loop" [ngModelOptions]="{standalone: true}"/>
                                <label> Loops</label>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-11" >
                            <p class="filter-p">Filter by Username</p>
                            <div class="input-rw filter-text">
                            <input type="checkbox"class="check" class="check"[(ngModel)]="username_checked" [ngModelOptions]="{standalone: true}"/>
                            <input class="filter-input"  type="text" placeholder="username" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" />
                        </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-11">
                            <p class="filter-p">Filter by Title</p>
                            <div  class="input-rw filter-text">
                                <input type="checkbox" class="check" [(ngModel)]="title_checked" [ngModelOptions]="{standalone: true}"/>
                                <input type="text" class="filter-input" placeholder="title" [(ngModel)]="title" [ngModelOptions]="{standalone: true}"/>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-11">
                            <p class="filter-p">Filter by Date</p>
                            <div  class="input-rw">
                                <input type="checkbox" class="check" [(ngModel)]="days" [ngModelOptions]="{standalone: true}"/>
                                <label> Last 7 days</label>
                            </div>
                            <div  class="input-rw">
                                <input type="checkbox" class="check" [(ngModel)]="month" [ngModelOptions]="{standalone: true}"/>
                                <label> Last 30 days</label>
                            </div>
                            <div  class="input-rw">
                                <input type="checkbox" class="check" [(ngModel)]="year" [ngModelOptions]="{standalone: true}"/>
                                <label> Last 1 year</label>
                            </div>
                        </div>
                    </div>
                    <button class="btn" (click)="apply()">Apply</button>
                </div>
            </div>

            <!-- <div class="form-group">
                <div class="tabs tabs-alt ng-star-inserted">
                    <input type="checkbox"
                    (click)="Rangeactive()">BPM Range</input><input type="checkbox" 
                    (click)="exactbpmctive()">Exact BPM </input>
      
                </div>
                <div *ngIf="hiderangeactive">
                  <ng5-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ng5-slider>
                </div>
      
                <div *ngIf="hideexactbpm">
                  <label for="Genre">BPM</label>
                  <input type="number" placeholder="Bpm" formControlName="exact_bpm" (keyup)="valueforBPM()">
                  <small class="text-danger">{{errorshowbpm}}</small>
                </div>
      
              </div> -->
     
    </form>
</div>