// zip.service.ts

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ZipService {
  private zip: JSZip;

  constructor(private http: HttpClient) {
    this.zip = new JSZip();
  }

  addSongToZip(name: string, base64Data: string): void {
    try {
      // Decode base64 to binary
      // const binaryData = atob(base64Data);
  
      // // Convert binary string to Uint8Array
      // const uint8Array = Uint8Array.from(binaryData, char => char.charCodeAt(0));
  
      // Add to the zip
      this.zip.file(name, base64Data, { binary: true });
    } catch (error) {
      //console.error('Base64 decoding error:', error);
    }
  }
  
  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }
  async downloadZip(wavFiles: any,name:string): Promise<void> {
    // ////console.log(wavFiles);
  
    if (wavFiles.length === 0) {
      //console.error('No files to include in the zip.');
      return;
    }
    // ////console.log(wavFiles.length);
    
    for(let i=0;i<wavFiles.length;i++){
      // //console./log(wavFiles[i].name, wavFiles[i].data);
      
      await this.addFileToZip(wavFiles[i].name, wavFiles[i].data);
    }
    // await this.addFileToZip('file1.wav', wavFiles[0]);
    // await this.addFileToZip('file2.wav', wavFiles[1]);
  
    this.zip.generateAsync({ type: 'blob' }).then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = name+'.zip';
  
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
    });
  }
  
  async addFileToZip(fileName: string, filePath: any ,extension: string = '.mp3'): Promise<void> {
    // ////console.log(filePath);
    
    const fileContent = await this.readFileContent(filePath);
    // ////console.log(fileName, fileContent);
    
    this.zip.file(fileName+ extension, fileContent,{ binary: true });
  }
  
  async readFileContent(filePath: any): Promise<Blob> {
    try {
      const response = await fetch(filePath);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      //console.error('Error reading file content:', error);
      throw error;
    }
  }
}  