import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CONSTNAME } from './../../../allServices/constant.service';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-usersearch',
  templateUrl: './usersearch.component.html',
  styleUrls: ['./usersearch.component.css']
})
export class UsersearchComponent implements OnInit {
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService) { }
  @Input() items: any;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
 
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = false;
  norecordfound: any;
  sorttype = "";
  sorton = "";
  imageofcomment: any;
  
  value: any;
  encryptedagainremoveslashes: string;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  time: string;
  addred: boolean;
  addgreen: boolean;
  durationofaudio: any;
  above15second = false;
  apihitforstream = true;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  id: any;
  nocomments: boolean;
  src: any;
  pagenocount: any;

  ngOnInit(): void {
    this.run(this.items);
  }


  run(res:any) {
    if (res.response.data.total_records == 0) {
      this.norecordfound = true;
    } else {
      this.norecordfound = false;
      // this.callforallinfo(res);
    }

    if (res.response.data.total_pages == 1) {
      this.hidepagination = true;
      // ////console.log("hide");
    } else {
      this.hidepagination = false;
      // ////console.log("not hide");
    }
    if (this.paginationempty == true) {
      this.pagercount = [];
    }
    this.total = res.response.data.total_records;

    this.tableData = res.response.data.pagging_list;

    this.record = this.tableData.length;
    if (this.record == 0) {
      this.nocomments = false;
    } else {
      this.nocomments = true;
    }
    this.pagenolist = res.response.data.total_pages + 1;

    this.record = this.pageno - 1 + "1";
    // ////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
    this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length

    this.tableData = res.response.data.pagging_list;
    // this.record = this.tableData.length;
    this.pagenolist = res.response.data.total_pages + 1;
    if (this.record == "01") {
      this.record = 1;
    }
    for (let i = 1; i < this.pagenolist; i++) {
      this.pagercount.push(i);
    }
    this.total = res.response.data.total_records;
  }

  setimage(data: any) {
   
      if (data.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        return CONSTNAME.fILE_PATH + data.profile_image_path;
      }

   
  }

  redirecttoprofilepage(item: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.user_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(['user/'], { queryParams: { user_id: item.user_id } });
    this.router.navigate(['user/'], { queryParams: { user_id: this.encrypted } });
  }

  redirecttoloopdetailpage(items: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.loop_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(value);
    // ////console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
  }

}
