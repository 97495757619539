// zip-upload.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateTabService {
  private UpdateloopTabServiceSubject = new BehaviorSubject<boolean>(false);
  looptabUploadInProgress$ = this.UpdateloopTabServiceSubject.asObservable();
  private UpdatepackTabServiceSubject = new BehaviorSubject<boolean>(false);
  packtabUploadInProgress$ = this.UpdatepackTabServiceSubject.asObservable();
  setUpdateloopTabServiceProgress(tab: boolean = true) {
    this.UpdateloopTabServiceSubject.next(tab);
  }
  setUpdatepackTabServiceProgress(tab: boolean) {
    this.UpdatepackTabServiceSubject.next(tab);
  }
}
