<!-- <button type="button" (click)="fileInput.click()">
   <span>Upload</span>
   <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
 </button> -->
<div class="upload-wrapper" *ngIf="showform">
   <div class="container">
      <div class="row text-center">
         <div class="col-12">
            <h1>{{Loopvalue}}</h1>
            <p class="text-muted mt-2 mb-2">
               <ng-container *ngIf="showloader  ">
                  <ngx-skeleton-loader count="1" [theme]="{ 
                  width: '30%'
                }"></ngx-skeleton-loader>
               </ng-container>
               <ng-container *ngIf="!showloader ">
                  <span *ngIf="hidedetailcontent">You have
                     <span class="circle-badge">

                        {{loopheadData?.loopkits_to_upload_allowed - loopheadData?.already_uploaded_loopkits}}
                     </span> Loop kits left to upload</span>

               </ng-container>
            </p>
            <h4 class="mb-2" *ngIf="hidedetailcontent">Want unlimited uploads? <a class="link" (click)="trypremium()">
                  Try Premium! </a> </h4>
         </div>
         <div class="col-md-6 col-md-offset-3">
            <ngx-file-drop class="dropSection" dropZoneClassName="test" (onFileDrop)="dropped($event)"
               (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">

            </ngx-file-drop>
            <div class="form-group" [ngClass]="dragClass">
               <div class="custom-upload">
                  <input [attr.disabled]="disable_input_as_itsloading? true : null" type="file"
                     accept=".rar,application/zip" class="form-control" (change)="selectFilemandatoryzip($event)"
                     id="uploadlooptit" placeholder="Title">
                  <p class="green"> UPLOAD YOUR KIT HERE </p><label
                     [matTooltip]="disable_input_as_itsloading ? 'You cannot perform this action while uploading is in progress' : ''"
                     for="uploadlooptit">Drop zip or rar file here or
                     <span class="link">Browse..</span></label>

               </div>
               <small id="fileerrorformandatory" class="text-danger confirmperror"></small>
            </div>
            <p class="text-center mt-1"><small class="text-muted">Support .zip and .rar only</small></p>
         </div>
      </div>
      <!-- Text Center Ends -->
      <div class="upload-loop-info"  [ngClass]="showleftform ? 'fade' : 'fadehide' ">
         <!-- <p *ngIf="show1bar" class="hover text-end" (click)="detailpageofloop()"><i class="fa fa-eye" aria-hidden="true"></i></p> -->
         <div *ngIf="progress" class="upload-progress">
            <span class="upload-name">{{nameoffile}}</span>

            <!-- <span class="upload-status"><span class="hover red"
               (change)="selectFilemandatoryzip($event)" (click)="cancelzipfile()" > -->
            <span class="upload-status"> {{valueofprogressbarofrequiredloop}}%</span>
            <!-- <mat-progress-bar *ngIf="shownowfirstloader" mode="determinate" [value]="progress" color="success"></mat-progress-bar> -->
            <mat-progress-bar mode="determinate" [value]="valueofprogressbarofrequiredloop" color="success"
               class="mt-5 "></mat-progress-bar>
         </div>
         <div class="loop-info">
            <div class="firstincontentform">
               <div class=" " id="preview">
                  <img class="my-3 shadow-none  bg-dark rounded rounded-circle z-depth-2 " style="object-fit: cover;"
                     [src]="url" id="thumb" />
               </div>
               <div class="imagebtn">

                  <label
                     [matTooltip]="disable_input_as_itsloading ? 'You cannot perform this action while uploading is in progress' : ''"
                     class="btn btn-theme btn-file">Upload Artwork
                     <input [attr.disabled]="disable_input_as_itsloading? true : null" type="file" class=""
                        name="fileToUpload" (change)="selectFileimage($event)" id="fileToUpload"
                        accept=".jpg,.jpeg,.png">
                  </label>
                  <label
                     [matTooltip]="disable_input_as_itsloading ? 'You cannot perform this action while uploading is in progress' : ''"
                     *ngIf="showartworkcancelbutton" class="btn btn-theme btn-file my-3" for=""
                     (click)="disable_input_as_itsloading ? false :cancelartwork()">Remove Artwork</label>
                  <small>Recommended 1000x1000 Pixels</small>
                  <!-- formControlName="imagevalid" -->
               </div>
               <div>
                  <small id="imageerror" class="text-danger confirmperror"></small>
               </div>
            </div>
            <!-- firstincontentform Ends -->
            <div class="secondmaincontentform">
               <div class="form-group">
                  <label>Upload Demo of the Loop Kit </label><span class="ml-1 text-danger">*(Required)</span>

                  <div class="custom-upload-inline">
                     <div class="rr">
                        <ngx-file-drop class="dropSection1" dropZoneClassName="droptag" (onFileDrop)="dropped1($event)"
                           (onFileOver)="fileOver1($event)" (onFileLeave)="fileLeave1($event)">

                        </ngx-file-drop>
                     </div>

                     <input [attr.disabled]="disable_input_as_itsloading? true : null" type="file" accept=".wav"
                        class="form-control" (change)="selectwavFilemp3($event)" id="uploadloopfile" placeholder="Title"
                        accept=".mp3">
                     <label
                        [matTooltip]="disable_input_as_itsloading ? 'You cannot perform this action while uploading is in progress' : ''"
                        for="uploadloopfile" [ngClass]="newbackgroundClass">Drag or Browse..</label>
                  </div>
                  <!-- <div *ngIf='progress1'>
                     <mat-progress-bar mode="determinate" [value]="progress1" color="success"></mat-progress-bar>
                  </div> -->

                  <!-- <div class="flex">
                     <li mat-raised-button #red="matTooltip" matTooltip="Click to cancel" matTooltipHideDelay="100"
                        (click)="cancelmp3file()" class="upload-nam hover redhover">
                        {{nameofTagfile?this.nameofTagfile:''}}</li>
 
                     <button (click)="cancelmp3file()">gkuiug</button>
                     <li (click)="cancelmp3file()" class="red hover">{{nameofTagfile?'X':''}}</li>
                  </div> -->



                  <p><small class="text-muted">.mp3 file only</small></p>
                  <small id="fileerror" class="text-danger confirmperror"></small>
               </div>
               <div>
                  <p *ngIf="show2bar" class="hover text-end" (click)="detailpageofloop()"><i class="fa fa-eye"
                        aria-hidden="true"></i></p>
                  <div *ngIf="progress1" class="upload-progress">
                     <span class="upload-name">{{nameofTagfile}}</span>
                     <!--          
                     <span class="upload-status"><span class="hover red"
                           (click)="cancelmp3file()"></span> </span> -->
                     <span *ngIf="!editloopapi" class="upload-status">{{valueofprogressbaroftaggedversionofloop}}%
                     </span>
                     <!-- <mat-progress-bar  *ngIf="shownowsecondloader" mode="determinate" [value]="progress1" color="success"></mat-progress-bar> -->
                     <mat-progress-bar mode="determinate" [value]="valueofprogressbaroftaggedversionofloop"
                        color="success" class="mt-5 "></mat-progress-bar>
                  </div>
               </div>
               <form [formGroup]="uploadloopkit">
                  <div class="form-group">
                     <label for="uploadlooptitle"> Loop Kit Title</label><span class="ml-1 text-danger">*</span>
                     <input type="text" (change)="trim()" (keyup)="valuefortitle()" class="form-control"
                        (keyup.enter)="onSubmit()" id="uploadlooptitle" formControlName="uploadlooptitle"
                        placeholder="Loop Kit Title">
                     <div class="flexing">
                        <small class="text-danger" [innerHTML]="vCheck('uploadlooptitle', '_loppkittitle')"></small>
                        <small class="text-danger">{{errorshowfortitle}}</small>
                        <small class="text-danger">{{showerror}}</small>
                     </div>

                  </div>



                  <!-- <p>
                     <small class="text-danger" [innerHTML]="vCheck('keynotes', '_INPUT')"></small>
                  </p> -->
                  <!-- <div class="form-row">
                     <div class="form-group col-md-6">
                        <label for="instrumentcategory">Instrument category</label><span
                           class="mx-4 text-danger">*</span>
                        <div>
                           <select class="form-control" id="instrumentcategory" (change)="valuepass()"
                              formControlName="instrumentcategory">
                              <option disabled=true value="">Select</option>
                              <option [value]="items.instrument_id" *ngFor="let items of tableDataofinstrumentcategory">
                                 {{items.instrument_name}}
                              </option>
                           </select>
                           <small class="text-danger" [innerHTML]="vCheck('instrumentcategory', '_INPUT')"></small>
                        </div>
                     </div>
                     <div class="form-group col-md-6">
                        <label for="instruments">Instruments</label><span class="mx-4 text-danger">*</span>
                        <div>
                           <select class="form-control" id="instruments" formControlName="instruments">
                              <option disabled=true value="" [innerHtml]="testhtml()"></option>
                              <option [value]="items.instrument_id" *ngFor="let items of tableDataofinstruments">
                                 {{items.instrument_name}}</option>
                           </select>
                           <small class="text-danger" [innerHTML]="vCheck('instruments', '_INPUT')"></small>
                        </div>
                     </div>
                  </div> -->

                  <div class="row">
                     <div class="col-md-6">
                        <div class="form-group">
                           <label for="Genre">Main Genre</label><span class="ml-1 text-danger">*</span>
                           <select class="form-control" id="Genre" (change)="valuepassgenre()" formControlName="genre">
                              <option value="">Select</option>
                              <option [value]="items.genre_id" *ngFor="let items of tableDataofgenre"> {{items.
                                 genre_name}}
                              </option>
                           </select>
                           <small class="text-danger" [innerHTML]="vCheck('genre', '_INPUT')"></small>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="form-group">
                           <label for="subgenre">Main Sub Genre (optional) </label><span
                              class="mx-4 text-danger"></span>
                           <select class="form-control" id="subgenre" formControlName="subgenre">
                              <option value="" [innerHtml]="testhtmlforsubgenre()"></option>
                              <option [value]="items.genre_id" *ngFor="let items of tableDataofsubgenre">
                                 {{items.genre_name}}</option>
                           </select>
                           <!-- <small class="text-danger" [innerHTML]="vCheck('subgenre', '_INPUT')"></small> -->
                        </div>
                     </div>
                  </div>
                  <div class="form-group">
                     <label for="uploadloopno"> How Many Loops Are In This Loop Kit?</label><span
                        class="ml-1 text-danger">*</span>
                     <input type="text" (keyup)="valueforloopkit()" class="form-control" (keyup.enter)="onSubmit()"
                        id="uploadloopno" formControlName="uploadloopno" placeholder="No of loops">
                     <div class="flexing">
                        <small class="text-danger" [innerHTML]="vCheck('uploadloopno', '_uploadloopkitloopno')"></small>
                        <!-- <small class="text-danger">{{errorshowfortitle}}</small> -->
                        <small *ngIf="errorforloopkitno" class="text-danger">{{errorshowloopkitno}}</small>
                     </div>

                  </div>

                  <div class="form-group">
                     <label for="uploadselling">Selling Preference</label><span class="ml-1 text-danger">*</span>
                     <div class="flex-row row">
                        <div class="col-sm-6 col-xs-12">
                           <div class="custom-radio-alt">

                              <input type="radio" formControlName="sellingpreference" name="sellingpreference"
                                 id="sellingprefernce1" value=1 (click)="hidesellingprefernce('free')">
                              <label for="sellingprefernce1" id="firstdiv" (click)="hidesellingprefernce('free')">
                                 List Kit For Free

                                 <small>Royalty Free </small>
                                 <small>-50/50 Master + Publishing Split</small></label>

                           </div>
                        </div>
                        <div class="col-sm-6 col-xs-12" *ngIf="checkdata == 'Premium'">
                           <div class="custom-radio-alt">

                              <input formControlName="sellingpreference" name="sellingpreference" type="radio"
                                 id="sellingprefernce2" value=2 (click)="hidesellingprefernce('premium')">
                              <label for="sellingprefernce2" id="seconddiv"
                                 (click)="hidesellingprefernce('premium')">List Kit For Sale
                                 <small>-Royalty Free </small>
                                 <small>-50/50 Master + Publishing Split</small><small>-Exclusive</small>
                              </label>

                           </div>
                        </div>
                        <div class="col-sm-6 col-xs-12" *ngIf="checkdata == 'Free'">
                           <div class="custom-radio-alt ">

                              <!-- <input formControlName="sellingpreference" name="sellingpreference" type="radio"
                                 id="rr" value=2 > -->
                              <label for="rr" class="dis" id="ee">List Kit For Sale
                                 <small>-Royalty Free </small>
                                 <small>-50/50 Master + Publishing Split</small><small>-Exclusive</small>
                              </label>

                           </div>
                        </div>
                     </div>
                     <div>
                        <small class="text-danger" [innerHTML]="vCheck('sellingpreference', '_INPUT')"></small>
                     </div>
                  </div>
               </form>

               <div class="row" *ngIf="showwhenpreferenceselected">
                  <!-- <div class="form-group col-md-6">
                     <label for="instrumentcategory">Selling Preference</label><span class="mx-4 text-danger">*</span>
                     <div>
                        <select class="form-control" formControlName="sellingpreference" id="instrumentcategory"
                           (change)="listchanged('value')">
                           <option selected={{item.selected}} *ngFor="let item of optionvalueArray"
                              value={{item.value}}>{{item.name}}</option>
                        </select>
                     </div>
                     <div>
                        <small class="text-danger" [innerHTML]="vCheck('sellingpreference', '_INPUT')"></small>
                     </div>
                   </div> -->

                  <div class="col-md-6">
                     <form [formGroup]="uploadloopkit">
                        <div class="form-group">

                           <label for="instruments">Terms</label><span class="ml-1 text-danger">*</span>
                           <select class="form-control" id="instruments" formControlName="selling_preference_type">
                              <option *ngFor="let item of optionvalueArray2box" value={{item.value}}>{{item.name}}
                              </option>
                           </select>
                           <small class="text-danger" [innerHTML]="vCheck('selling_preference_type', '_INPUT')"></small>
                        </div>
                     </form>
                  </div>
                  <div class="col-md-6">
                     <div class="form-group" *ngIf="premiumselectedpriceshow">
                        <label for="uploadlooptitle">Price</label><span class="ml-1 text-danger">*</span>
                        <input type="number" class="form-control" [(ngModel)]="pricetag" (keyup.enter)="onSubmit()"
                           id="premimumsellingprice" placeholder="Price $">
                        <small class="text-danger">{{priceerrorshow}}</small>
                        <!-- <ng-container *ngIf="showpriceerror">
                           <small class="text-danger" [innerHTML]="priceErrFn()"></small>
                        </ng-container> -->
                     </div>
                  </div>
               </div>
               <form [formGroup]="uploadloopkit">
                  <div class="form-group">
                     <div class="chkbox">
                        <input type="checkbox" class="form-check-input" (keyup.enter)="onSubmit()" id="tickbox"
                           formControlName="tickbox">
                        <label class="tickbox boxcss" class="form-check-label" for="tickbox"> Your upload is 100%
                           your work and there are no uncleared 3rd party samples</label><span
                           class="ml-1 text-danger">*</span>
                     </div>
                     <small class="text-danger"
                        *ngIf="uploadloopkit.get('tickbox')?.touched && uploadloopkit.get('tickbox')?.hasError('required')">
                        Please check this box to proceed further
                     </small>
                  </div>
                  <!-- <small class="text-danger" [innerHTML]="vCheck('tickbox', '_INPUT')"></small> -->

               </form>
               <!-- <div class="form-group">
                  <label for="uploadlooptitl">3 Tags</label>
                  <ng-select [items]="allFruits" bindLabel="tag_name" (keyup.enter)="onSubmit()"
                     placeholder="Select 3 tags" [(ngModel)]="selected1" appendTo="body" [multiple]="true"
                     [closeOnSelect]="false">
                  </ng-select>
 
               </div> -->
               <!-- <div>
                  <input type="text" id="myInput" (keyup)="check()" placeholder="Search for names.." title="Type in a name">
               </div> -->
               <!-- <ng-container *ngIf="showTagErr">
                  <small class="text-danger" [innerHTML]="tagErrFn()"></small>
               </ng-container> -->

               <!-- <div class="form-group">
                  <label for="TAgs">3 Tags</label><span class="mx-4 text-danger">*</span>
                  <input type="text" class="form-control" placeholder="for e.g music hiphop rap "
                     [(ngModel)]="searchsymbol" (focusout)="checktag()" name="" id="">
                  <small class="text-danger">{{errorshow}}</small>
 
               </div> -->
               <div class="form-group">
                  <label for="TAgs"></label>3 Tags<span class="ml-1 text-danger">*</span>
                  <mat-form-field class="example-chip-list ">
                     <mat-label></mat-label>
                     <mat-chip-list #chipList aria-label="Fruit selection">
                        <mat-chip *ngFor="let fruit of fruits" class="fi" [selectable]="selectable"
                           [removable]="removable" (removed)="remove(fruit)" matChipRemove>
                           {{fruit[0]=='#'?''+fruit:'#'+fruit}}
                           <!-- <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon> -->
                        </mat-chip>
                        <input placeholder="i.e. lil baby, bouncy, aggressive" #fruitInput [formControl]="fruitCtrl"
                           (focusout)="checktag()" [matChipInputFor]="chipList"
                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                     </mat-chip-list>

                  </mat-form-field>
                  <!-- <input type="text" class="form-control" (keyup.enter)="myFunction()"
                     placeholder="for e.g music hiphop rap " [(ngModel)]="searchsymbol" (focusout)="checktag()" name=""
                     id="tags"> -->

                  <small class="text-danger">{{errorshow}}</small>

               </div>


               <form [formGroup]="uploadloopkit">

                  <div class="form-group">
                     <label for="description">Description</label>
                     <textarea id="descriptiontext" (keyup)="keyupfunc()" cols="130" rows="5"
                        placeholder="Include number of loops and description of what your kit sounds like"
                        formControlName="descriptionbox" class="form-control"></textarea>
                  </div>
                  <div class="flex">
                     <small class="text-danger" [innerHTML]="vCheck('descriptionbox', '_descriptionbox')"></small>
                     <small class="text-muted" id="textarea_feedback"></small>
                  </div>
                  <div class="form-group text-right">
                     <span
                        [matTooltip]="disable_input_as_itsloading ? 'You cannot perform this action while uploading is in progress' : ''">
                        <button [attr.disabled]="disable_input_as_itsloading? true : null"
                           class="btn btn-theme text-end mt-2" (click)="onSubmit()">{{endstring}}</button>
                     </span>



                  </div>
                  <!-- <a >sss</a> -->

               </form>

            </div>
            <!-- secondmaincontentform Ends -->
         </div>
         <!--Loop Info Ends -->
      </div>
      <!-- Upload Loop Info Ends -->
   </div>
   <!-- container Wrapper Ends -->
</div>
<!-- Upload Wrapper Ends -->
<div class="upload-wrappernotshow" *ngIf="!showform">
   <div class="headerfornonwrapper">
      <h3>Upload Loop</h3>
      <div class="container" style="
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 10px;
 ">You have reached the maximimum limit to upload Loops. <a class="link" (click)="trypremium()">Try Premium</a> to
         upload more Loops.</div>
   </div>
</div>