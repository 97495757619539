
<div>
  <ul class="name">

      <li>
          <form class="displayinline">
              <input type="search" [(ngModel)]="validationforsearch" class="form-control rounded form-control-sm"
                  placeholder="Search" aria-label="Search" name="searchsubscription" id="searchsubscription"
                  aria-describedby="search-addon" />
              <button class="btn btn-theme" type="submit" (click)="searchClick()"><i
                      class="fa fa-search"></i></button>
          </form>
      </li>

  </ul>
</div>

<!-- <h3>Report Log</h3> -->
      <ul class="name" *ngIf="!norecordfound">

        <!-- <li>
          <form class="displayinline">
            <input type="search" [(ngModel)]="validationforsearch" class="form-control rounded form-control-sm" placeholder="Search"
              aria-label="Search" name="searchsubscription" id="searchsubscription" aria-describedby="search-addon" />
            <button class="btn btn-theme" type="submit" (click)=" searchClick()"><i class="fa fa-search"></i></button>
          </form>
        </li> -->

      </ul>

      <div class="table-responsive" *ngIf="!noresult">
        <table class="table table-striped  table-bordered" *ngIf="!norecordfound">
          <thead>
            <tr>
              <th class="text-center marginfa" *ngFor="let items of tablevaluearray">{{items.name}} <i
                  (click)="sortOn(items)" *ngIf="items.showicon" id="{{items.tablehead}}" class={{items.class}}
                  style="cursor: pointer;" aria-hidden="true"></i></th>
          
            </tr>

          </thead>
          <tbody>


            <tr *ngFor="let item of tableData">
              <td class="text-center">{{item.reason}}</td>
              
              <td class="text-center">{{item.first_name}} {{item.last_name}}</td>
              <td class="text-center">{{item.created_on| date: 'dd-MM-yyyy h:mm a'}} </td>

             
            </tr>


          </tbody>

        </table>

        <!-- <h>No records to display</h4> -->


      </div>

      <div *ngIf="noresult" class="noresul text-center">No records to display </div>

      <div *ngIf="norecordfound" class="text-center">No records to display </div>

      <div class="text-center" *ngIf="!noresult">
        <ul class="paginationlist">
          <!-- <li class="text-info" *ngIf="!norecordfound">Showing {{record}} records of {{total}}</li> -->
          <li class="text-info" *ngIf="!norecordfound">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
          <!-- <li class="text-info"> {{total}}</li> -->
          <ul class="nextulpagination" *ngIf="!hidepagination">

            <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item == pageno}" *ngFor="let item of pagercount" id="liststyle{{item}}"
              (click)="paginationget(item)">
              {{item}}

            </li>
          </ul>

        </ul>


      </div>
