import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { commonservice } from "src/app/allServices/commonservice";
import { APIURL } from "src/app/allServices/constant.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
@Component({
  selector: "app-delete-pack",
  templateUrl: "./delete-pack.component.html",
  styleUrls: ["./delete-pack.component.css"],
})
export class DeletePackComponent implements OnInit {
  whichpage: any;

  constructor(
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private clipboard: Clipboard,
    public common: commonservice,
    private http: HttpClient,
    private router: Router,
    private dialogRef: MatDialogRef<DeletePackComponent>

  ) {}

  ngOnInit(): void {
    this.whichpage = this.data.pageValue;
    // ////console.log(this.whichpage);
  }
  cancel() {
    this.dialogRef.close();
  }
  submit() {
    let tokkenget: any = localStorage.getItem("clientintoken");
    let headers: any = {
      Authorization: `Bearer ${tokkenget}`,
      "My-Custom-Header": "foobar",
    };

    this.http
      .post(
        `${APIURL.BASE_URL}/user/delete_pack`,
        { pack_id: this.data.data },
        {
          headers: headers,
        }
      )
      .pipe()
      .subscribe((event: any) => {
        // ////console.log("event:", event);
        if (event.response.status) {
        this.toastr.success("Pack Deleted Successfully. ", "", { positionClass: 'toast-bottom-right' });
}
        this.matDialog.closeAll();
      });
  }
}
