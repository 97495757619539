import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { AuthenticationGuard } from './authentication.guard';












// import { TermandconditionComponent } from './termandcondition/termandcondition.component';
// import { PaymentreceiptComponent } from './paymentreceipt/paymentreceipt.component';
// import { VerifyEmailComponent } from './verify-email/verify-email.component';
// import { RegisterComponent } from './+register/register.component';

// import { NotFoundComponent } from './not-found/not-found.component';

// import { ResetPasswordComponent } from './reset-password/reset-password.component';


import { UserauthGuard } from './auth/userauth.guard';

// import { UploadloopComponent } from './user/uploadloop/uploadloop.component';


// import { UsersComponent } from 'New folder/src/app/users/users.component';
import { AdminLoginComponent } from './ADMIN_PAGE/admin-login/admin-login.component';
import { AdminprofileComponent } from './ADMIN_PAGE/adminprofile/adminprofile.component';
import { GlobalsettingComponent } from './ADMIN_PAGE/globalsetting/globalsetting.component';
import { CouponcodesComponent } from './ADMIN_PAGE/COUPON_PAGE/couponcodes/couponcodes.component';
import { SubscriptiontiermainComponent } from './ADMIN_PAGE/SUBSCRIPTION_PAGE/subscriptiontiermain/subscriptiontiermain.component';
import { SubscriptionplanComponent } from './ADMIN_PAGE/SUBSCRIPTION_PAGE/subscriptionplan/subscriptionplan.component';
import { CouponcodeformComponent } from './ADMIN_PAGE/COUPON_PAGE/couponcodeform/couponcodeform.component';
import { UsersComponent } from './ADMIN_PAGE/users/users.component';
// import { LoopsComponent } from './ADMIN_PAGE/loops/loops.component';
import { HomepageComponent } from './CLIENT_PAGES/CLIENT_PAGE_HEADER_FOOTER/homepage/homepage.component';
import { RegistersComponent } from './CLIENT_PAGES/user/registers/registers.component';
import { Loopheadsignup2Component } from './CLIENT_PAGES/CLIENT_PAYMENT_PAGE_AFTER_LOGIN/loopheadsignup2/loopheadsignup2.component';
import { Plan2signupComponent } from './CLIENT_PAGES/CLIENT_PAYMENT_PAGE_AFTER_LOGIN/plan2signup/plan2signup.component';
import { Plan3signupComponent } from './CLIENT_PAGES/CLIENT_PAYMENT_PAGE_AFTER_LOGIN/plan3signup/plan3signup.component';
import { ContactusComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/contactus/contactus.component';
import { TermandconditionComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/termandcondition/termandcondition.component';
import { PaymentreceiptComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/paymentreceipt/paymentreceipt.component';
import { VerifyEmailComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/verify-email/verify-email.component';
import { ResetPasswordComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/reset-password/reset-password.component';
import { LoginLoopheadComponent } from './CLIENT_PAGES/client_LOGIN_PAGE/login-loophead/login-loophead.component';
import { EmailVerificationComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/email-verification/email-verification.component';
// import { DashboardComponent } from './CLIENT_PAGES/user/dashboard/dashboard.component';
import { DetailuploadloopComponent } from './CLIENT_PAGES/user/detailuploadloop/detailuploadloop.component';
// import { UploadloopComponent } from './CLIENT_PAGES/user/uploadloop/uploadloop.component';
import { NotFoundComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/not-found/not-found.component';
import { MYACCOUNTComponent } from './CLIENT_PAGES/my-account/my-account.component';
import { WebhookEventsComponent } from './CLIENT_PAGES/ROOT/webhook-events/webhook-events.component';
import { RedirectfrompaypalComponent } from './CLIENT_PAGES/EXTRA_CLIENT_FILES/redirectfrompaypal/redirectfrompaypal.component';
import { BannedComponent } from './ADMIN_PAGE/banned/banned.component';
import { AddBannedComponent } from './ADMIN_PAGE/add-banned/add-banned.component';
import { LoopsComponent } from './ADMIN_PAGE/loops/loops-list-uploads/loops.component';
import { LoopsReportedloopsComponent } from './ADMIN_PAGE/loops/loops-reportedloops/loops-reportedloops.component';
import { LoopsDetailUploadsComponent } from './ADMIN_PAGE/loops/loops-detail-uploads/loops-detail-uploads.component';
import { RoughComponent } from './rough/rough.component';
import { HomepagemainComponent } from './CLIENT_PAGES/homepagemain/homepagemain.component';
import { CandeactivateGuard } from './auth/candeactivate.guard';
import { DeacforloopkitGuard } from './auth/deacforloopkit.guard';
import { LoopkittrialComponent } from './loopkittrial/loopkittrial.component';
import { ThreeBounceModule } from 'angular-loading-page';
import { ProfileComponent } from './CLIENT_PAGES/profile/profile.component';
import { MyFeedComponent } from './CLIENT_PAGES/my-feed/my-feed.component';
import { DashboardComponent } from './ADMIN_PAGE/dasboard/dashboard/dashboard.component';
import { DashboarComponent } from './ADMIN_PAGE/dashboar/dashboar.component';
import { WaveformComponent } from './commonComponent/waveform/waveform.component';
import { RoughtrialforcheckComponent } from './roughtrialforcheck/roughtrialforcheck.component';
import { MaintagsearchforadvanceComponent } from './CLIENT_PAGES/Advancesearchfortags/maintagsearchforadvance/maintagsearchforadvance.component';
import { AllinvoicesComponent } from './ADMIN_PAGE/loops/allinvoices/allinvoices.component';
import { UserformComponent } from './ADMIN_PAGE/userform/userform.component';
import { ListinguserComponent } from './ADMIN_PAGE/Alluser/listinguser/listinguser.component';
import { S3bucketComponent } from './CLIENT_PAGES/s3bucket/s3bucket.component';
import { InvoiceredirectComponent } from './CLIENT_PAGES/invoiceredirect/invoiceredirect.component';
import { DetailComponent } from './ADMIN_PAGE/Alluser/Listforuser/detail/detail.component';
import { DeletedloopLoopkitsComponent } from './ADMIN_PAGE/loops/deletedloop-loopkits/deletedloop-loopkits.component';
import { RefreshroughComponent } from './ADMIN_PAGE/rough/refreshrough/refreshrough.component';
import { ForgotpasswordmodelComponent } from './CLIENT_PAGES/forgotpasswordmodel/forgotpasswordmodel.component';
import { ExploreComponent } from '../app/CLIENT_PAGES/explore/explore.component';
import { FilterComponent } from '../app/CLIENT_PAGES/MODAL/filter/filter.component';
import { EmailsentComponent } from './CLIENT_PAGES/MODAL/emailsent/emailsent.component';
import { ProfileSettingsComponent } from './CLIENT_PAGES/profile-settings/profile-settings.component';
import { VerifyotpComponent } from './CLIENT_PAGES/verifyotp/verifyotp.component';
import { MydownloadComponent } from './CLIENT_PAGES/mydownload/mydownload.component';
import { UploadLoopComponent } from './CLIENT_PAGES/upload-loop/upload-loop.component';
import { NotificationsComponent } from './CLIENT_PAGES/notifications/notifications.component';
import { CreatePackComponent } from './CLIENT_PAGES/create-pack/create-pack.component';
import { PackDetialsComponent } from './CLIENT_PAGES/pack-detials/pack-detials.component';
import { UpdatePackComponent } from './CLIENT_PAGES/update-pack/update-pack.component';
import { VerifyLoginTokenComponent } from './CLIENT_PAGES/verify-login-token/verify-login-token.component';
import { PrivacyPolicyComponent } from './CLIENT_PAGES/Footercontent/privacy-policy/privacy-policy.component';
import { EditLoopComponent } from './CLIENT_PAGES/MODAL/edit-loop/edit-loop.component';
import { UrlComponent } from './CLIENT_PAGES/url/url.component';
import { APIURL } from './allServices/constant.service';


const routes: Routes = [
  {

    path: '',
    data: {
      title: "Loophead - Explore Our Open Marketplace of Loops",
      customLayout: true
    },
    children: [
      {
        path: '',
        // canActivate: [UserauthGuard],
        component: HomepagemainComponent,
        // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
        data: {
          type: 404,
          
          customLayout: true,
          hideMenu: false,
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Upload', val: 'upload', hide: false },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
            { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
          ]

        }
      },
      {
        path: 'login',
        component: LoginLoopheadComponent,
        // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
        data: {
        // title:"Login",
        type: 404,
          customLayout: true,
          hideMenu: false,
          menuOption: [
            { name: 'Register', val: 'rg', hide: false },
            { name: 'Logout', val: 'logout', hide: true },
            { name: 'Login', val: 'ad', hide: true },
          ]
        }
      },
      {
        path: 'register',
    
        component: RegistersComponent,
    
        data: {
          customLayout: true,
          title: "Loophead - Explore Our Open Marketplace of Loops",
          signup: true,
          regType: true,
          hideMenu: false,
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Login', val: 'ad', hide: false },
            { name: 'Logout', val: 'logout', hide: true },
          ]
        }
      },
    ]
  },
  {

    path: '',
    data: {
      title: "Loophead - Explore Our Open Marketplace of Loops",
      customLayout: true
    },
    children: [
      {
        path: '',
        // canActivate: [UserauthGuard],
        component: HomepagemainComponent,
        // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
        data: {
          type: 404,
          customLayout: true,
          hideMenu: false,
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Upload', val: 'upload', hide: false },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
            { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
          ]

        }
      },
    ]
  },
  {

    path: 'admin',
    // canActivate :[AuthenticationGuard],
    data: {
      title: "",
      // customLayout: true
    },

    children: [
      {
        path: 'login',
        // component:  LoginComponent,
        component: AdminLoginComponent,

        data: {
          customLayout: true,
          signup: false,
          hideMenu: true,
          // xyz :'shubham',
          hideFooter: true,
        },
      },
      {
        path: '',
        canActivate: [AuthenticationGuard],
        component: AdminprofileComponent,
        data: {
          customLayout: false
        },
      },
      // {
      //   path: 'profileupdate',
      //   canActivate: [AuthenticationGuard],
      //   component: ProfileupdateComponent,
      //   data: {
      //     customLayout: false
      //   },
      // },
      

      {
        path: 'globalsetting',
        canActivate: [AuthenticationGuard],
        component: GlobalsettingComponent,
        data: {
          title: "",
          customLayout: false
        }
      },
      {
        path: 'bannedwords',
        canActivate: [AuthenticationGuard],
        component: BannedComponent,
        data: {
          title: "",
          customLayout: false
        }
      },
      {
        path: 'addbanned',
        canActivate: [AuthenticationGuard],
        component: AddBannedComponent,
        data: {
          title: "",
          customLayout: false
        }
      },
      {
        path: 'addbanned/:id',
        canActivate: [AuthenticationGuard],
        component: AddBannedComponent,
        data: {
          title: "",
          customLayout: false
        }
      },

      {
        path: 'users',
        canActivate: [AuthenticationGuard],
        component: UsersComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'filter-modal',
    
        component: FilterComponent,
    
        data: {
          customLayout: true,
          signup: true,
          regType: true,
          hideMenu: false,
        }
      },
      {
        path: 'email-sent',
    
        component: EmailsentComponent,
    
        data: {
          // customLayout: true,
          // // signup: true,
          // regType: true,
          // hideMenu: false,
        }
      },
         
      {
        path: 'user',
        canActivate: [AuthenticationGuard],
        component: ListinguserComponent,
        data: {
          title: "",
          customLayout: false 


        }
      },
      {
        path: 'dashboard',
        canActivate: [AuthenticationGuard],
        component: DashboardComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'loops/list-uploads',
        canActivate: [AuthenticationGuard],
        component: LoopsComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'loops/reported-loops',
        canActivate: [AuthenticationGuard],
        component: LoopsReportedloopsComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'loops/deleted-loops-loopkits',
        canActivate: [AuthenticationGuard],
        component: DeletedloopLoopkitsComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'loops/allinvoices',
        canActivate: [AuthenticationGuard],
        component: AllinvoicesComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'loops/allinvoices/:id',
        canActivate: [AuthenticationGuard],
        component: AllinvoicesComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'loops/detail-uploads',
        canActivate: [AuthenticationGuard],
        component: LoopsDetailUploadsComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'loops/detail-uploads/',
        canActivate: [AuthenticationGuard],
        component: LoopsDetailUploadsComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'loops/detail-uploads/:id',
        canActivate: [AuthenticationGuard],
        component: LoopsDetailUploadsComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'userdetails',
        canActivate: [AuthenticationGuard],
        component: DetailComponent,
        data: {
          title: "",
          customLayout: false


        }
      },
      {
        path: 'userdetails/:id',
        canActivate: [AuthenticationGuard],
        component: DetailComponent,
        data: {
          title: "",
          customLayout: false


        }
      },

      {
        path: 'list-coupon-codes',
        canActivate: [AuthenticationGuard],

        data: {
          title: "",
          customLayout: false


        },
        children: [
          {
            path: '',
            // component:  LoginComponent,
            component: CouponcodesComponent,

            data: {
              customLayout: false,

            },
          },
          {
            path: 'detail-coupon-code',
            canActivate: [AuthenticationGuard],
            component: CouponcodeformComponent,
            data: {
              title: "",
              customLayout: false
            }
          },
          {
            path: 'detail-coupon-code/:id',
            canActivate: [AuthenticationGuard],
            component: CouponcodeformComponent,
            data: {
              title: "",
              customLayout: false
            }
          },
        ]
      },
      {
        path: 'roughwork',
        canActivate: [AuthenticationGuard],
        component: RefreshroughComponent,
        data: {
          title: "",
          customLayout: false
        }
      },
      {
        path: 'subscriptiontiers',
        canActivate: [AuthenticationGuard],
        component: SubscriptiontiermainComponent,
        data: {
          title: "",
          customLayout: false

        }
      },
      {
        path: 'subscriptiontie/:',
        canActivate: [AuthenticationGuard],
        component: SubscriptiontiermainComponent,
        data: {
          title: "",
          customLayout: false

        }
      },
      {
        path: 'subscriptionplan/:id',
        canActivate: [AuthenticationGuard],
        component: SubscriptionplanComponent,
        data: {
          title: "",
          customLayout: false
        }
      },

      {
        path: 'user-detail/:id',
        canActivate: [AuthenticationGuard],
        component: UserformComponent,
        data: {
          title: "",
          customLayout: false
        }
      },

    ]
  },

  {
    path: 'plandetails',
    component: Loopheadsignup2Component,
    canActivate: [UserauthGuard],
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },

      ]


    }
  },
  {
    path: 'premimum',
    component: Plan2signupComponent,
    canActivate: [UserauthGuard],
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }
  },
  {
    path: 'payment',
    component: Plan3signupComponent,
    canActivate: [UserauthGuard],
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]
    }
  },
  {
    path: 'contactus',

    component: ContactusComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }

  },
  // {
  //   path: 'contactus',

  //   component: ContactusComponent,
  //   // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
  //   data: {
  //     type: 404,
  //     customLayout: true,
  //     hideMenu: false,
  //     menuOption: [
  //       { name: 'Register', val: 'rg', hide: true },
  //       { name: 'Logout', val: 'logout', hide: false },
  //       { name: 'Login', val: 'ad', hide: true },
  //       { name: 'Upload', val: 'upload', hide: false },
  //       { name: 'Top Charts', val: 'topcharts', hide: false },
  //       { name: 'My Feed', val: 'myfeed', hide: false },
  //       { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
  //       { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
  //     ]

  //   }

  // },

  {
    path: 'delete_s3_bucket_drafts',

    component: S3bucketComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }

  },
 
  {
    path: 'termsandcondition',
    component: TermandconditionComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }
  },
  {
    path: 'PrivacyPolicy',
    component: PrivacyPolicyComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }
  },
  {
    path: 'verify-email',
    // canActivate :[UserauthGuard],
    component: VerifyEmailComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: true },
        { name: 'Login', val: 'ad', hide: true },
      ]
    }
  },
  
  {
    path: 'detail-pack',
    // canActivate: [UserauthGuard],
    component: PackDetialsComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }
  },
  
  {
    path: 'detail-pack/:id',
    canActivate: [UserauthGuard],
    component: PackDetialsComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }
  },
  {
    path: 'detail-pack/:id/:id1',
    canActivate: [UserauthGuard],
    component: PackDetialsComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }
  },
  {
    path: 'webhook-events',
    // canActivate :[UserauthGuard],
    component: WebhookEventsComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: true },
        { name: 'Login', val: 'ad', hide: true },
      ]
    }
  },
  {
    path: 'profile/paypalredirect',
    // canActivate :[UserauthGuard],
    component: RedirectfrompaypalComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: true },
        { name: 'Login', val: 'ad', hide: true },
      ]
    }
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }
  },
  {
    path: 'waveformagain',
    component: WaveformComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: true },
        { name: 'Login', val: 'ad', hide: true },
      ]
    }
  },
  {
    path: 'explore',
    // canActivate: [UserauthGuard],
    component: ExploreComponent,

    data: {
      customLayout: true,
      title: "Loophead - Explore Our Open Marketplace of Loops",
      hideMenu: false,

    }
  },
  {
    path: 'notifications',
    canActivate: [UserauthGuard],
    component: NotificationsComponent,

    data: {
      customLayout: true,
      hideMenu: false
    }
  },
  {
    path: 'downloadhistory',
    canActivate: [UserauthGuard],
    component: MydownloadComponent,

    data: {
      // title:'My downloads',
      customLayout: true,
      hideMenu: false
    }
  },
  {
    path: 'forgot-password',

    component: ForgotpasswordmodelComponent,

    data: {
      // title:'Forgot Password',
      customLayout: true,
      regType: true,
      hideMenu: false,
    }
  },
   {
    path: 'authenticate',

    component: VerifyotpComponent,

    data: {
      // title:'Authenticate',
      customLayout: true,
      regType: true,
      hideMenu: false,
    }
  },
 
  {
    path: 'emailverify',
    // canActivate: [UserauthGuard],
    component: EmailVerificationComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      // title:'Email Verification',
      customLayout: true,
      hideMenu: false,
      // menuOption: [
      //   { name: 'Register', val: 'rg', hide: true },
      //   { name: 'Logout', val: 'logout', hide: false },
      //   { name: 'Login', val: 'ad', hide: true },
      //   { name: 'Upload', val: 'upload', hide: false },
      //   { name: 'Top Charts', val: 'topcharts', hide: false },
      //   { name: 'My Feed', val: 'myfeed', hide: false },
      //   { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
      //   { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      // ],

    }
  },
  {
    path: 'detail-loop',
    // canActivate: [UserauthGuard],
    component: DetailuploadloopComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      // title:'Loop Details',
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }
  },
  {
    path: 'detail-loop/:id',
    canActivate: [UserauthGuard],
    component: DetailuploadloopComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      // title:'Loop Details',
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }
  },
  {
    path: 'detail-loop/:id/:id1',
    canActivate: [UserauthGuard],
    component: DetailuploadloopComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      title: "Loophead - Explore Our Open Marketplace of Loops",
      // title:'Loop Details',
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    }
  },
  
  
  // {
  //   path: 'user',
  //   canActivate: [UserauthGuard],
  //   component: ProfileComponent,
  //   // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
  //   data: {
  //     type: 404,
  //     customLayout: true,
  //     title: "Loophead - Explore Our Open Marketplace of Loops",
      
  //     Likedlooploopkit: [
  //       { val: 'loopsloopkits', active: false },


  //     ],
  //     SOundprofile: [
  //       { val: 'sound', active: true },
  //     ]

  //   },
    
  // },
  // {
  //   path: 'user/:id',
  //   canActivate: [UserauthGuard],
  //   component: ProfileComponent,
  //   // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
  //   data: {
  //     type: 404,
  //     title: "Loophead - Explore Our Open Marketplace of Loops",
  //     customLayout: true,
  //   },
  //   children: [
  //     {
  //       path: 'sounds/:username',
  //       // component:  LoginComponent,
  //       component: ProfileComponent,
  //       data: {
  //         customLayout: true,
  //         signup: false,
  //     title: "Loophead - Explore Our Open Marketplace of Loops",
  //     hideMenu: false,
  //         // xyz :'shubham',
  //         hideFooter: true,
  //         // activeTab: 'accountdetail',
  //         Likedlooploopkit: [
  //           { val: 'loopsloopkits', active: false },
  //         ],
  //         SOundprofile: [
  //           { val: 'sound', active: true },
  //         ]

  //       },
  //     },
  //     {
  //       path: 'Likedloops-loopkits/:username',
  //       // component:  LoginComponent,
  //       component: ProfileComponent,
  //       data: {
  //         customLayout: true,
  //         signup: false,
  //         hideMenu: false,
  //         // xyz :'shubham',
  //         hideFooter: true,
  //         // activeTab: 'accountdetail',
  //         menuOption: [
  //           { name: 'Register', val: 'rg', hide: true },
  //           { name: 'Upload', val: 'upload', hide: true },
  //           { name: 'Logout', val: 'logout', hide: false },
  //           { name: 'Login', val: 'ad', hide: true },
  //           { name: 'Top Charts', val: 'topcharts', hide: false },
  //           { name: 'My Feed', val: 'myfeed', hide: false },
  //           { name: 'Upload', val: 'upload', hide: false },
  //           // { name: 'user', val: 'user', hide:false },
  //         ],



  //         Likedlooploopkit: [
  //           { val: 'loopsloopkits', active: true },


  //         ],
  //         SOundprofile: [
  //           { val: 'sound', active: false },
  //         ]

  //       },
  //     },


  //   ]
  // },

// { path: 'user/packs/:username', canActivate: [UserauthGuard],
// component: ProfileComponent,
// // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
// data: {
//   type: 404,
//   title: "Loophead - Explore Our Open Marketplace of Loops",
//   customLayout: true,
// },
// children: [
//   {
//     path: 'sounds/:username',
//     // component:  LoginComponent,
//     component: ProfileComponent,
//     data: {
//       customLayout: true,
//       signup: false,
//   title: "Loophead - Explore Our Open Marketplace of Loops",
//   hideMenu: false,
//       // xyz :'shubham',
//       hideFooter: true,
//       // activeTab: 'accountdetail',
//       menuOption: [
//         { name: 'Register', val: 'rg', hide: true },
//         { name: 'Upload', val: 'upload', hide: true },
//         { name: 'Logout', val: 'logout', hide: false },
//         { name: 'Login', val: 'ad', hide: true },
//         { name: 'Top Charts', val: 'topcharts', hide: false },
//         { name: 'My Feed', val: 'myfeed', hide: false },
//         { name: 'Upload', val: 'upload', hide: false },
//         // { name: 'user', val: 'user', hide:false },
//       ],



//       Likedlooploopkit: [
//         { val: 'loopsloopkits', active: false },


//       ],
//       SOundprofile: [
//         { val: 'sound', active: true },
//       ]

//     },
//   },
//   {
//     path: 'Likedloops-loopkits/:username',
//     // component:  LoginComponent,
//     component: ProfileComponent,
//     data: {
//       customLayout: true,
//       signup: false,
//       hideMenu: false,
//       // xyz :'shubham',
//       hideFooter: true,
//       // activeTab: 'accountdetail',
//       menuOption: [
//         { name: 'Register', val: 'rg', hide: true },
//         { name: 'Upload', val: 'upload', hide: true },
//         { name: 'Logout', val: 'logout', hide: false },
//         { name: 'Login', val: 'ad', hide: true },
//         { name: 'Top Charts', val: 'topcharts', hide: false },
//         { name: 'My Feed', val: 'myfeed', hide: false },
//         { name: 'Upload', val: 'upload', hide: false },
//         // { name: 'user', val: 'user', hide:false },
//       ],



//       Likedlooploopkit: [
//         { val: 'loopsloopkits', active: false },


//       ],
//       SOundprofile: [
//         { val: 'sound', active: true },
//       ]

//     },
//   },


// ] 
// },
  // {
  //   path: 'user/:id',
  //   canActivate: [UserauthGuard],
  //   component: ProfileComponent,
  //   // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
  //   data: {
  //     type: 404,
  //     customLayout: true,
  //   },
  // }, 
   {
    path: 'edit',
    canActivate: [UserauthGuard],
    component: MYACCOUNTComponent,

    data: {
      customLayout: true,
      hideMenu: false
    }
  },{
    path: 'profilesettings',
    canActivate: [UserauthGuard],
    component: ProfileSettingsComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      // title:'Profile Settings',
      title: "Loophead - Explore Our Open Marketplace of Loops",
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ]

    //   // Likedlooploopkit: [
    //   //   { val: 'loopsloopkits', active: false },


    //   // ],
    //   // SOundprofile: [
    //   //   { val: 'sound', active: true },
    //   // ]

    },
    // children: [

    //   {
    //     path: 'Likedloops-loopkits/:id',
    //     // component:  LoginComponent,
    //     component: ProfileComponent,
    //     data: {
    //       customLayout: true,
    //       signup: false,
    //       hideMenu: false,
    //       // xyz :'shubham',
    //       hideFooter: true,
    //       // activeTab: 'accountdetail',
    //       menuOption: [
    //         { name: 'Register', val: 'rg', hide: true },
    //         { name: 'Upload', val: 'upload', hide: true },
    //         { name: 'Logout', val: 'logout', hide: false },
    //         { name: 'Login', val: 'ad', hide: true },
    //         { name: 'Top Charts', val: 'topcharts', hide: false },
    //         { name: 'My Feed', val: 'myfeed', hide: false },
    //         { name: 'Upload', val: 'upload', hide: false },
    //         // { name: 'user', val: 'user', hide:false },
    //       ],



    //       Likedlooploopkit: [
    //         { val: 'loopsloopkits', active: true },


    //       ],
    //       SOundprofile: [
    //         { val: 'sound', active: false },
    //       ]

    //     },
    //   },


    // ]
  },
  
 
  {
    path: 'invoice',
    // component:  LoginComponent,
    component: InvoiceredirectComponent,
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Upload', val: 'upload', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { name: 'Upload', val: 'upload', hide: false },
        // { name: 'User', val: 'user', hide:false },
      ]

    }
  },
  {
    path: 'invoice/:id',
    // component:  LoginComponent,
    component: InvoiceredirectComponent,
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Upload', val: 'upload', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { name: 'Upload', val: 'upload', hide: false },
        // { name: 'User', val: 'user', hide:false },
      ]

    }
  },


  {
    path: 'create-pack',
    canActivate: [UserauthGuard],
    canDeactivate: [CandeactivateGuard],
    component:  CreatePackComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      // title:'Create Pack',
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Upload', val: 'upload', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { name: 'Upload', val: 'upload', hide: false },
        // { name: 'User', val: 'user', hide:false },
      ]

    }
  },
  {
    path: 'add-loops',
    canActivate: [UserauthGuard],
    canDeactivate: [CandeactivateGuard],
    component:  UpdatePackComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      // title:'Create Pack',
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Upload', val: 'upload', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { name: 'Upload', val: 'upload', hide: false },
        // { name: 'User', val: 'user', hide:false },
      ]

    }
  },
  {
    path: 'verify-login',
    // canActivate :[UserauthGuard],
    component: VerifyLoginTokenComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      // title:'Login Verification',
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: true },
        { name: 'Login', val: 'ad', hide: true },
      ]
    }
  },
  {
    path: 'upload-loop',
    canActivate: [UserauthGuard],
    canDeactivate: [CandeactivateGuard],
    component: UploadLoopComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Upload', val: 'upload', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { name: 'Upload', val: 'upload', hide: false },
        // { name: 'User', val: 'user', hide:false },
      ]

    }
  },


  {
    path: 'advancesearch',
    // canActivate: [UserauthGuard],
    component: MaintagsearchforadvanceComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ],

      Likedlooploopkit: [
        { val: 'loopsloopkits', active: false },


      ],
      SOundprofile: [
        { val: 'sound', active: true },
      ]

    }
  },
  {
    path: 'advancesearch/:id',
    // canActivate: [UserauthGuard],
    component: MaintagsearchforadvanceComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ],

      Likedlooploopkit: [
        { val: 'loopsloopkits', active: false },


      ],
      SOundprofile: [
        { val: 'sound', active: true },
      ]

    }
  },
  {
    path: 'advancesearch/:id/:id1',
    // canActivate: [UserauthGuard],
    component: MaintagsearchforadvanceComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Upload', val: 'upload', hide: false },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { type: 'showicon', icon: 'fa fa-bell', val: 'topcharts', hide: false },
        { type: 'showicon', icon: 'fa fa-search', val: 'topcharts', hide: false },
      ],

      Likedlooploopkit: [
        { val: 'loopsloopkits', active: false },


      ],
      SOundprofile: [
        { val: 'sound', active: true },
      ]

    }
  },

  {
    path: 'my-feed',
    canActivate: [UserauthGuard],
    component: MyFeedComponent,
    // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
    data: {
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Upload', val: 'upload', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { name: 'Upload', val: 'upload', hide: false },
        // { name: 'user', val: 'user', hide:false },
      ]

    }
  },
  {
    path: 'profile',
    canActivate: [UserauthGuard],
    data: {
      type: 404,
      customLayout: true,
      hideMenu: false,
      menuOption: [
        { name: 'Register', val: 'rg', hide: true },
        { name: 'Upload', val: 'upload', hide: true },
        { name: 'Logout', val: 'logout', hide: false },
        { name: 'Login', val: 'ad', hide: true },
        { name: 'Top Charts', val: 'topcharts', hide: false },
        { name: 'My Feed', val: 'myfeed', hide: false },
        { name: 'Upload', val: 'upload', hide: false },
        // { name: 'user', val: 'user', hide:false },
      ],
    },
    children: [
      {
        path: 'my-account',
        // component:  LoginComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          // hideFooter: true,
          activeTab: 'accountdetail',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ]
        },
      },
      {
        path: 'subscriptiondetail',
        // component:  LoginComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          hideFooter: false,
          activeTab: 'subscriptiondetail',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ]
        },
      },
      {
        path: 'uploadloopstatus',
        // component:  LoginComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          hideFooter: false,
          activeTab: 'uploadloopstatus',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ]
        },
      },
      {
        path: 'archive',
        // component:  LoginComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          hideFooter: false,
          activeTab: 'archivework',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ]
        },
      },
      {
        path: 'archive/:id',
        // component:  LoginComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          hideFooter: false,
          activeTab: 'archivework',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ]
        },
      },
      {
        path: 'payment-accounts',
        // component:  LoginComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          hideFooter: false,
          activeTab: 'paymentaccounts',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ]
        },
      },
      {
        path: 'followers',
        // component:  LoginComponent,
        // component: RightFollowingpageComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          hideFooter: false,
          activeTab: 'followingpage',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ],
          followerspage: [
            { val: 'followers', active: true },
            { val: 'following', active: false },

          ]

        },
      },
      {
        path: 'followings',
        // component:  LoginComponent,
        // component: RightFollowingpageComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          hideFooter: false,
          activeTab: 'followingpage',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ],
          followerspage: [
            { val: 'followers', active: false },
            { val: 'following', active: true },

          ]
        },
      },
      {
        path: 'notification',
        // component:  LoginComponent,
        // component: RightFollowingpageComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          hideFooter: false,
          activeTab: 'notification',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ],

        },
      },
      {
        path: 'Loops',
        // component:  LoginComponent,
        // component: RightFollowingpageComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          hideFooter: false,
          activeTab: 'Likedlooploopkit',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ],
          Likedlooploopkit: [
            { val: 'loops', active: true },
            { val: 'loopkits', active: false },

          ]
        },
      },
      {
        path: 'Loopkits',
        // component:  LoginComponent,
        // component: RightFollowingpageComponent,
        component: MYACCOUNTComponent,
        data: {
          customLayout: true,
          signup: false,
          hideMenu: false,
          // xyz :'shubham',
          hideFooter: false,
          activeTab: 'Likedlooploopkit',
          menuOption: [
            { name: 'Register', val: 'rg', hide: true },
            { name: 'Upload', val: 'upload', hide: true },
            { name: 'Logout', val: 'logout', hide: false },
            { name: 'Login', val: 'ad', hide: true },
            { name: 'Top Charts', val: 'topcharts', hide: false },
            { name: 'My Feed', val: 'myfeed', hide: false },
            { name: 'Upload', val: 'upload', hide: false },
            // { name: 'user', val: 'user', hide:false },
          ],
          Likedlooploopkit: [
            { val: 'loops', active: false },
            { val: 'loopkits', active: true },

          ]
        },
        // children: [
        //   {
        //     path: 'Loops',
        //     // component:  LoginComponent,
        //     component: LoopkitsComponent,
        //     data: {
        //       customLayout: true,
        //       signup: false,
        //       hideMenu: false,
        //       // xyz :'shubham',
        //       hideFooter: true,
        //       activeTab: 'Likedlooploopkit',
        //       menuOption: [
        //         { name: 'Register', val: 'rg', hide: true },
        //         { name: 'Upload', val: 'upload', hide: true },
        //         { name: 'Logout', val: 'logout', hide: false },
        //         { name: 'Login', val: 'ad', hide: true },
        //         { name: 'Top Charts', val: 'topcharts', hide: false },
        //         { name: 'My Feed', val: 'myfeed', hide: false },
        //         { name: 'Upload', val: 'upload', hide: false },
        //         // { name: 'user', val: 'user', hide:false },
        //       ]
        //     },
        //   },
        //   {
        //     path: 'Loopkits',
        //     // component:  LoginComponent,
        //     component: LoopkitsComponent,
        //     data: {
        //       customLayout: true,
        //       signup: false,
        //       hideMenu: false,
        //       // xyz :'shubham',
        //       hideFooter: true,
        //       activeTab: 'Likedlooploopkit',
        //       menuOption: [
        //         { name: 'Register', val: 'rg', hide: true },
        //         { name: 'Upload', val: 'upload', hide: true },
        //         { name: 'Logout', val: 'logout', hide: false },
        //         { name: 'Login', val: 'ad', hide: true },
        //         { name: 'Top Charts', val: 'topcharts', hide: false },
        //         { name: 'My Feed', val: 'myfeed', hide: false },
        //         { name: 'Upload', val: 'upload', hide: false },
        //         // { name: 'user', val: 'user', hide:false },
        //       ]
        //     },
        //   },
        // ]
      },
    ]
  },
  { path: ':username', 
    // canActivate: [UserauthGuard],
  component: ProfileComponent,
  // loadChildren: () => import('./loopheadsignup2/loopheadsignup2.module').then(m => m.Loopheadsignup2Module),
  data: {
    type: 404,
    title: "Loophead - Explore Our Open Marketplace of Loops",
    customLayout: true,
  },
  // children: [
  //   {
  //     path: 'sounds/:username',
  //     // component:  LoginComponent,
  //     component: ProfileComponent,
  //     data: {
  //       customLayout: true,
  //       signup: false,
  //   title: "Loophead - Explore Our Open Marketplace of Loops",
  //   hideMenu: false,
  //       // xyz :'shubham',
  //       hideFooter: true,
  //       // activeTab: 'accountdetail',
  //       menuOption: [
  //         { name: 'Register', val: 'rg', hide: true },
  //         { name: 'Upload', val: 'upload', hide: true },
  //         { name: 'Logout', val: 'logout', hide: false },
  //         { name: 'Login', val: 'ad', hide: true },
  //         { name: 'Top Charts', val: 'topcharts', hide: false },
  //         { name: 'My Feed', val: 'myfeed', hide: false },
  //         { name: 'Upload', val: 'upload', hide: false },
  //         // { name: 'user', val: 'user', hide:false },
  //       ],



  //       Likedlooploopkit: [
  //         { val: 'loopsloopkits', active: false },


  //       ],
  //       SOundprofile: [
  //         { val: 'sound', active: true },
  //       ]

  //     },
  //   },
  //   {
  //     path: 'packs/:username',
  //     // component:  LoginComponent,
  //     component: ProfileComponent,
  //     data: {
  //       customLayout: true,
  //       signup: false,
  //       hideMenu: false,
  //       // xyz :'shubham',
  //       hideFooter: true,
  //       // activeTab: 'accountdetail',
  //       menuOption: [
  //         { name: 'Register', val: 'rg', hide: true },
  //         { name: 'Upload', val: 'upload', hide: true },
  //         { name: 'Logout', val: 'logout', hide: false },
  //         { name: 'Login', val: 'ad', hide: true },
  //         { name: 'Top Charts', val: 'topcharts', hide: false },
  //         { name: 'My Feed', val: 'myfeed', hide: false },
  //         { name: 'Upload', val: 'upload', hide: false },
  //         // { name: 'user', val: 'user', hide:false },
  //       ],



  //       Likedlooploopkit: [
  //         { val: 'loopsloopkits', active: true },


  //       ],
  //       SOundprofile: [
  //         { val: 'sound', active: false },
  //       ]

  //     },
  //   },


  // ] 
},
  {
    path: '404', component: NotFoundComponent, data: {
      customLayout: true,
      hideMenu: false,
      type: 404,
      // menuOption: [
      //   { name: 'Register', val: 'rg',hide:true },
      //   { name: 'Logout', val: 'logout', hide: true },
      //   { name: 'Login', val: 'ad',hide:false},
      // ]
    }
  },
  {
    path: '**', redirectTo: '/404', data: {
      customLayout: true, hideMenu: false,
      // menuOption: [
      //   { name: 'Register', val: 'rg',hide:false },
      //   { name: 'Logout', val: 'logout', hide: true },
      //   { name: 'Login', val: 'ad',hide:false},
      // ]
    }
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
