







import { environment } from 'src/app/sevicesall/alldata';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
// import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.
import { NgWaveformModule } from 'ng-waveform';
import { ITimeUpdateEvent, NgWaveformComponent, IRegionPositions } from 'ng-waveform';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from '../CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-paramsirrough',
  templateUrl: './paramsirrough.component.html',
  styleUrls: ['./paramsirrough.component.css']
})
export class ParamsirroughComponent implements OnInit {
  @ViewChild('waveform1', { static: false }) waveform1: NgWaveformComponent;
  @ViewChild('waveform2', { static: false }) waveform2: NgWaveformComponent;
  @ViewChild('waveform3', { static: false }) waveform3: NgWaveformComponent;
  @ViewChild('waveform4', { static: false }) waveform4: NgWaveformComponent;
  @ViewChild('waveform5', { static: false }) waveform5: NgWaveformComponent;
  @ViewChild('waveform6', { static: false }) waveform6: NgWaveformComponent;
  @ViewChild('waveform7', { static: false }) waveform7: NgWaveformComponent;
  @ViewChild('waveform8', { static: false }) waveform8: NgWaveformComponent;
  @ViewChild('waveform9', { static: false }) waveform9: NgWaveformComponent;
  @ViewChild('waveform10', { static: false }) waveform10: NgWaveformComponent;

  userMenuforfollow = environment.followerspage;
  followers = environment.followerspage[0].active;
  following = environment.followerspage[1].active;

  userMenuforlike = environment.Likedlooploopkit;
  loop = environment.Likedlooploopkit[0].active;


  userMenuforSoundmenu = environment.SOundprofile;
  sound = environment.SOundprofile[0].active;
  

  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = false;
  norecordfound = false;
  sorttype = "";
  sorton = "";
  imageofcomment: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  value: any;
  encryptedagainremoveslashes: string;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  time: string;
  addred: boolean;
  addgreen: boolean;
  durationofaudio: any;
  above15second = false;
  apihitforstream = true;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  id: any;
  nocomments: boolean;
  src: any;
  pagenocount: any;
  
  hideplaybutton: any = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
  };
  hidepausebutton: any = {
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    7: true,
    8: true,
    9: true,
    10: true,
  };
  /*
  hideplaybutton2: boolean;
  hidepausebutton2: boolean;
  hideplaybutton3: boolean;
  hidepausebutton3: boolean;
  hideplaybutton4: boolean;
  hidepausebutton4: boolean;
  hideplaybutton5: boolean;
  hidepausebutton5: boolean;
  hideplaybutton6: boolean;
  hidepausebutton6: boolean;
  hideplaybutton7: boolean;
  hidepausebutton7: boolean;
  hideplaybutton8: boolean;
  hidepausebutton8: boolean;
  hideplaybutton9: boolean;
  hidepausebutton9: boolean;
  hideplaybutton10: boolean;
  hidepausebutton10: boolean;
  */



  current_waveform: any;
  current_waveform_id: any;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.value = this.route.snapshot.queryParamMap.get('user_id');
    this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    
  }

  audioLinks= [
    {file:'https://d1ptp6rux3p5im.cloudfront.net/demo_loopkits/1624344925911-589286d5cb3c1fb8865d.mp3'},
    {file:'https://d1ptp6rux3p5im.cloudfront.net/main_loops/1624002108706-file_example_WAV_1MG.wav'},
  ]



  onPlayButtonClick(item: any) {
    /*pause all other waveforms*/
    if (typeof this['waveform1'] != 'undefined') { this['waveform1'].pause(); }
    if (typeof this['waveform2'] != 'undefined') { this['waveform2'].pause(); }
    if (typeof this['waveform3'] != 'undefined') { this['waveform3'].pause(); }
    if (typeof this['waveform4'] != 'undefined') { this['waveform4'].pause(); }

    if (item == 1) { this.current_waveform = this['waveform1']; }
    if (item == 2) { this.current_waveform = this['waveform2']; }
    if (item == 3) { this.current_waveform = this['waveform3']; }
    
    this.current_waveform.play();
    
    this.hideplaybutton[item] = true;
    this.hidepausebutton[item] = false;
  }
  onPauseButtonClick(item: any) {
    // this.waveform.pause();
    this.hidepausebutton = true;
    this.hideplaybutton = false;
  }

  onRegionChange(value: any = null) {
    // ////console.log(value);
  }
  onTrackLoaded(v: any = null) {
    // ////console.log("track");
    // ////console.log(v);

  }
  onTrackRendered(v: any = null) {
    // ////console.log("trackren");
    // ////console.log(v);
  }
  onPaused(v: any = null) {
    // ////console.log("pause");
  }

}



