

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { UploadClickComponent } from 'src/app/CLIENT_PAGES/MODAL/upload-click/upload-click.component';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { InvoiceComponent } from 'src/app/CLIENT_PAGES/MODAL/invoice/invoice.component';
import { AdmininvoiceComponent } from 'src/app/CLIENT_PAGES/MODAL/admininvoice/admininvoice.component';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<string>();
  @Input() items: any;
  tableData: any;
  pagercount: any = [];
  pagenolist: any;
  pageno: any = 1;
  paginationempty = false;
  totallength: any;
  noresultshow: any;
  termsofpreference: any
  looptype: any;
  hidepagination: boolean = true;
  record: any;
  total: any;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenocount: any;
  arrayfortable: any = []
  durationofaudio: any;
  hidepausebutton: boolean = true;
  totalpagesall: any
  norecordfound: any;
  noresult = false;
  profile_image_pathofcomments = "assets/img/artwork.jpg";

  nocomments: boolean;
  constructor(private router: Router, public api: ApiServiceService, private toastr: ToastrService, public common: commonservice, public matDialog: MatDialog, private http: HttpClient,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.pageno = this.route.snapshot.queryParamMap.get('pageno')
    this.run(this.items);
    // ////console.log(this.items)

  }
  paginationget(item:any) {
    // ////console.log("red")
    this.messageEvent.emit(item)
  }

  run(res:any) {
    if (res.response.data.total_records == 0) {
      this.norecordfound = true;
      this.tableData = []
    } else {
      this.norecordfound = false;
      if (res.response.data.total_pages == 1) {
        this.hidepagination = true;
        // ////console.log("hide");
      } else {
        this.hidepagination = false;
        // ////console.log("not hide");
      }
      if (this.paginationempty == true) {
        this.pagercount = [];
      }
      this.total = res.response.data.total_records;
      
      this.tableData = res.response.data.pagging_list;
      // ////console.log(this.tableData)
  
      this.record = this.tableData.length;
      if (this.record == 0) {
        this.tableData =[]
        this.nocomments = false;
      } else {
        this.nocomments = true;
      }
      this.pagenolist = res.response.data.total_pages + 1;
  
      this.record = this.pageno - 1 + "1";
      // ////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
      this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length
  
      this.tableData = res.response.data.pagging_list;
      // this.record = this.tableData.length;
      this.pagenolist = res.response.data.total_pages + 1;
      if (this.record == "01") {
        this.record = 1;
      }
      for (let i = 1; i < this.pagenolist; i++) {
        this.pagercount.push(i);
      }
      this.total = res.response.data.total_records;
      // this.callforallinfo(res);
    }

    
  }

  srcforimage(item: any) {
    if (item.profile_image_path == null) {
      // ////console.log("i am in null");
      return this.profile_image_pathofcomments;
    } else {
      // ////console.log("i am not in null");
      return  item.profile_image_path;
    }
  }


  getallinfoofloops() {
    this.common.showLoader();
    let sendingdata = {
      
        "sort_on":"created_on",
        "sort_type":"DESC",
        "page_no":this.pageno,
        "row_size":10
    
    }

    // this.common.showLoader();
    this.api.postApiHit(`/user/get_all_downloads`,sendingdata, VARS.ClIENT_TYPE).then((res: any) => {

      // this.afterload = true;
      // ////console.log(res);
      this.arrayfortable = res.response.data.pagging_list
      // ////console.log(this.arrayfortable)
      if (res.response.status == 1) {
        if (this.paginationempty == true) {
          this.pagercount = [];
        }
        this.pagenolist = res.response.data.total_pages + 1
        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }
        // ////console.log(res);
        this.record = res.response.data.pagging_list.length;
        this.total = res.response.data.total_records;
        this.totalpagesall = res.response.data.total_pages
        this.record = this.pageno - 1 + "1";
        if (this.record == "01") {
          this.record = 1;
        }
        this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length


        if (res.response.data.total_pages == 1) {
          this.hidepagination = false;

        }

        this.totallength = this.pagercount;


        this.tableData = res.response.data.pagging_list;


        if (res.response.data.total_records == 0) {
          this.noresultshow = true;
        }

        this.common.hideLoader();





      } else if (res.response.status == 0) {

        this.common.hideLoader();

        this.noresultshow = true;
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];



        this.common.hideLoader();

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })

        }
      }
    }, async e => {

      this.common.hideLoader();

    });
  }

  // paginationget(pageinfo: any) {
  //   this.paginationempty = true;

  //   this.pageno = pageinfo;
  //   // this.tableData = false;

  //   this.getallinfoofloops();



  // }

  callfree(item: any) {
    // ////console.log(item);
    if (item == 1) {
      this.termsofpreference = "Royalty Free";

    } else {
      this.termsofpreference = "50/50 Master + Publishing Split"

    }
  }
  callpremium(item: any) {
    // ////console.log(item);
    if (item == 1) {
      this.termsofpreference = "Royalty Free";

    } else if (item == 2) {
      this.termsofpreference = "50/50 Master + Publishing Split"

    } else {
      this.termsofpreference = "Exclusive";
    }
  }
  loopinfo(item: any) {
    if (item == 1) {
      this.looptype = "Loop";

    } else if (item == 2) {
      this.looptype = "Loop Kit"

    }
  }


  getUserDetail() {
    this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }

  redirecttoloop() {
    this.openDialog();
  }


  detailpageofloop(value: any) {

    this.encryptUsingAES256(value);

  }

  openDialog(): void {
    let dialogue = this.matDialog.open(UploadClickComponent, { panelClass: 'forgot-password', disableClose: true });
    dialogue.afterClosed().subscribe(result => {

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);


    // this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
     window.open(`${APIURL.BASE_URLforredirect}/detail-loop?loop_id=${this.encrypted}`, `_blank`)


  }
  downloadloop(res: any) {
    window.location.href = `${APIURL.BASE_URL}/user/download_loop?loop_id=${res.loop_id}`;

  }
  redirecttoprofilepage(item: any) {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.user_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(item.user_id);
   
    this.router.navigate([`${item.username}`]);

    // this.router.navigate(['user/sounds'], { queryParams: { user_id: this.encrypted } });

  }

  redirecttoinvoice(item: any, items: any) {
    this.opendialogueforverificatio(item, items)
  }

  opendialogueforverificatio(item: any, items: any): void {
    let dialogue = this.matDialog.open(AdmininvoiceComponent, { panelClass: 'forgot-password', disableClose: true, data: { pagename: item, paymentid: items } });
    dialogue.afterClosed().subscribe(result => {





    });

    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });

  }

  file = '';
  audio = new Audio(this.file);
  id: any;
  above15second = false;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  apihitforstream = true;

  playAudio(item: any) {

    this.arrayfortable.map((r: any) => {
      r.hidepausebutton = false;
    })
    // this.arraytableforcommentsforloopkit.map((r: any) => {
    //   r.hidepausebutton = false;
    // })

    this.arrayfortable.map((r: any) => {
      if (r.audio) r.audio.pause()
    })
    // this.arraytableforcommentsforloopkit.map((r: any) => {
    //   if (r.audio) r.audio.pause()
    // })

    if (!item.audio) {
      // item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
      // this.durationofaudio = item.tagged_loop_path
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      item.audio.play();
      // ////console.log('item.duration', item.duration)
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true
        // ////console.log("above 15 sec");
      }
      else if (item.duration < 15 && item.duration >= 5) {
        // ////console.log("between 5 and 15");
        this.apihitforstream = true
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {
        // ////console.log("between 1 and 5");
        this.apihitforstream = true
        this.above1secondlessthan5 = true;
      }
    } else {
      item.audio.play();
    }



    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      const ended = item.audio.complete

      // ////console.log(item.audio.ended);
      // ////console.log(item.audio.onended);
      // ////console.log(item.audio);
      // ////console.log(ended);
      if (item.audio.onended) {
        // ////console.log(Math.round(r));
      }
      if (Math.round(r) + 1 >= item.duration) {

        // ////console.log("gaya");

        item.audio.pause();
        setTimeout(() => {
          // item.audio.pause();
          item.hidepausebutton = false;
        }, 1000);
      }
      // ////console.log(item.audio.currentTime);
      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
            // ////console.log("above 15 api hit");
          }

        }
      } else if (item.duration < 15 && item.duration >= 5) {
        // ////console.log("gaya")
        if (r > 5) {
          // ////console.log("idar tak gaya")
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
            // ////console.log("below 15 and above 5 api hit");
          }
        }


      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
            // ////console.log("below 5 and above 1 api hit");
          }

        }
      }
    }, 200);

  }

  stopAudio(item: any = null) {
    if (item) {
      if (item.audio) {
        item.audio.pause()
      }
      clearInterval(this.id);

      this.arrayfortable.map((r: any) => {
        r.hidepausebutton = false;
      })

    } else {
      this.arrayfortable.map((r: any) => {
        if (r.audio) {
          r.audio.pause()
        }
        r.hidepausebutton = false;
      })

    }


  }

  async apihitforstreaming(item: any, r: any, dura: any) {
    // this.common.showLoader();
    // ////console.log("apihit");
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "audio_duration": dura,
      "loop_id": item.loop_id,
      "play_duration": r
    }
    this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  paginationnext() {
    // ////console.log(this.totalpagesall)

    if (this.pageno == this.totalpagesall) {

    } else {
      this.pageno = Number(this.pageno) + 1
      
        this.getallinfoofloops();
      
    }

  }
  paginationprev() {
    if (this.pageno == 1 || this.pageno < 1) {

    } else {
      this.pageno = Number(this.pageno) - 1
    
        this.getallinfoofloops();
      
    }

  }






}


