import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
// import { BrowserModule } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { CONSTNAME } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';

// @NgModule({
//   imports: [BrowserModule, /* or CommonModule */],
// })
@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadComponent implements OnInit {
  search: any;
  pageno: any;
  sorton: any;
  rowsize: any;
  sorttype: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  tokenFromUI: string = "0123456789123456";
  encrypted: string;
  total: any;
  loop_id: any;
  pack_id: any;

  constructor(private router: Router,public common: commonservice, public api: ApiServiceService,private route: ActivatedRoute, public matDialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.loop_id = this.data.data
    if(this.data.pack_id)
    this.pack_id = this.data.pack_id
  this.performsearch()

      // ////console.log(res);


  }
  performsearch(){
    let sendingdata = {
      "page_no":this.pageno,
      "sort_on":this.sorton,
      "row_size":this.rowsize,
      "sort_type":this.sorttype,
      "search" : this.search,
      // "loop_id" : this.loop_id
      
    }
    if(this.pack_id){
      Object.assign(sendingdata,{pack_id:this.pack_id})
    }else{
      Object.assign(sendingdata,{loop_id:this.loop_id})
    }

    this.api.forclientgettokenpublic(`/user/download_user_list`, sendingdata).then((res: any) => {
      this.data = res.response.data.pagging_list
      this.total = res.response.data.total_count
      // ////console.log(this.total);
    })
  }
  cancel(){
    this.matDialog.closeAll()
  }
  getTime(t:any){
    const utcDate = new Date(t);

    const day = utcDate.getUTCDate().toString().padStart(2, '0');
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = utcDate.getUTCFullYear();
    
    return `${day}/${month}/${year}`
  }
  setimage(data: any) {
    // if (data.artwork_path == null) {
      if (data.profile_image_path == null) {
        return this.profile_image_pathofcomments;
  
      } else {
        return  data.profile_image_path;
      }
  
    // } else {
  
    //   return CONSTNAME.fILE_PATH + data.artwork_path;
    // }
  }
  redirecttouser(item: any){
    this.matDialog.closeAll()
    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let encrypted = CryptoJS.AES.encrypt(
    //   JSON.stringify(item), _key, {
    //   keySize: 16,
    //   iv: _iv,
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7
    // });
    // this.encrypted = encrypted.toString();
    // this.encrypted = encodeURIComponent(this.encrypted);
    this.router.navigate([`${item}`])
   
  }

}
