import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/app/sevicesall/alldata';
@Component({
  selector: 'app-couponcodeform',
  templateUrl: './couponcodeform.component.html',
  styleUrls: ['./couponcodeform.component.css']
})
export class CouponcodeformComponent implements OnInit {
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  name: any;
  couponcodeform = StaticService.couponcodeadd();
  sendingdata: any;
  noteditableform:any = true;
  encryptedagainremoveslashes: string;
  getpageno: any;
  page_referer: any;
  // userMenu = environment.sidebarLeftMenu[4].route;
  constructor(private formbuilder: FormBuilder, public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService) {
    this.route.queryParams.subscribe(params => {
      this.getpageno = params.page_no;
      if (params.referer) {
        this.page_referer = params.referer;
      }
      else {
        this.page_referer = '/admin/list-coupon-codes';
      }
      this.encrypted = params.coupon_id;
      this.encryptedagainremoveslashes = decodeURIComponent(this.encrypted);
      this.decryptUsingAES256();
      if (this.route.snapshot.params['id'] !== "new") {
        this.name = "Edit";
        this.getapicallforcouponcode();
        // ////console.log("old page ha");
      } else {
        this.name = "Add";
        // ////console.log("new page ha");
      
      }

      
      //////console.log(environment.sidebarLeftMenu[4].route);
      //////console.log(this.router.url)

      //if (this.router.url.indexOf(environment.sidebarLeftMenu[4].route) > -1) {
      // ////console.log("yes its a child")
      // } else {
      //   ////console.log("not")
      // }
    });
  
    


  }
  ngOnInit(): void {
    let left_sidebar:any = document.getElementsByClassName('sidebar-menu');
    if (left_sidebar.length > 0) {
      left_sidebar = left_sidebar[0];
      left_sidebar.querySelector('[href="/admin/list-coupon-codes"]').parentElement.classList.add("active", 'menu-open', 'custom-active');
    }
  }

  decryptUsingAES256() {
    // ////console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

  }
  


  vCheck(fieldName: any, type: INPUT_TYPE_NAME, options: any = {}) {
    options.isRequired = true;
    const cFrm = this.couponcodeform.controls;
    if (cFrm) {
      return StaticService.formError(cFrm, fieldName, type, options).msg;
    }
  }

  async couponcodepage() {
    // const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    // StaticService.markFormGroupTouched(this.couponcodeform.controls, invalidElements);
    this.couponcodeform.markAllAsTouched();
    // ////console.log(this);
    let validfrom = moment(this.couponcodeform.value.couponstartdate).format('DD-MM-YYYY');
    let validto = moment(this.couponcodeform.value.couponendenddate).format('DD-MM-YYYY');

    if (this.couponcodeform.value.couponenradio == true) {
      this.couponcodeform.value.couponenradio = 1;
    } else {
      this.couponcodeform.value.couponenradio = 0;
    }

    this.sendingdata = {

      "coupon_code": this.couponcodeform.value.couponcode,
      "valid_from": validfrom,
      "valid_to": validto,
      "discount_percentage": this.couponcodeform.value.couponenddiscountamount,
      "is_enabled": this.couponcodeform.value.couponenradio
    }
    // ////console.log(this.couponcodeform.value);

    if (this.route.snapshot.params['id'] !== "new") {
      this.sendingdata["coupon_code_id"] = this.decrypted;

    } else {

    }


    if (this.couponcodeform.valid) {

      this.common.showLoader();


      this.api.postApiHit(APIURL.ADMIN_ADD_COUPONDETAILS, this.sendingdata, VARS.ADMIN_TYPE).then((res: any) => {

        this.common.hideLoader();
        // this.common.showToast(res.response.msg?res.response.msg:res.response.errors[0], res.response.status);
        if (res.response.status) {
          // this.router.navigate(['/admin/list-coupon-codes']);
          this.cancelandredirect();
          // ////console.log(res);
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        } else {
          this.common.hideLoader();
          let errorthroughapi = res.response.errors;
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }

        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });


    }



  }


  cancelandredirect() {
    // ////console.log(this.page_referer);
    this.router.navigateByUrl(this.page_referer);
  }

  // 

  getapicallforcouponcode() {
    this.common.showLoader();
    this.noteditableform = false;
    let Idfromurl = this.decrypted;
    
    this.api.getApiHit(`/admin/get_coupon_code_detail?coupon_code_id=${Idfromurl}`, VARS.ADMIN_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        // ////console.log(res);
        let couponstartdate = moment(res.response.data[0].valid_from).format('YYYY-MM-DD');
        let couponenddate = moment(res.response.data[0].valid_to).format('YYYY-MM-DD');
        let createddate = moment(res.response.data[0].created_on).format('DD-MM-YYYY h:mm A');
        let modifieddate = moment(res.response.data[0].modified_on).format('DD-MM-YYYY h:mm A');
        // ////console.log(modifieddate);
        if (res.response.data[0].modified_on == null) {
          modifieddate = "";
        }
        let radiobtn = res.response.data[0].is_enabled;
        let btnvalue;
        if (radiobtn == 1) {
          btnvalue = true;
        } else {
          btnvalue = false;
        }
        // ////console.log(couponenddate);
        this.couponcodeform.patchValue({
          couponcode: res.response.data[0].coupon_code,
          couponenddiscountamount: res.response.data[0].discount_percentage,
          couponstartdate: couponstartdate,
          couponendenddate: couponenddate,
          couponenradio: btnvalue,
          createddate: createddate,
          modifieddate: modifieddate

       });

      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });

  }














}
