import { Component, OnInit } from '@angular/core';
import { commonservice } from 'src/app/allServices/commonservice';
import { VARS } from 'src/app/allServices/constant.service';
import { environment } from 'src/app/sevicesall/alldata';
// import { commonservice } from '../allServices/commonservice';
// import { VARS } from '../allServices/constant.service';
// import { environment } from '../sevicesall/alldata';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  menu = environment.menubar;
  constructor(public common: commonservice) {

  }

  ngOnInit(): void {
    // this.menu.menuItems.map(i => {
    //   i.hide = true;

    // });
    // this.menu.seconMenu.map(i => {
    //   i.hide = true;

    // });
    // this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY).then(data => {
    //   ////console.log('data=>', data);
    //   if (data) {
    //     ////console.log('logout')
    //     this.menu.menuItems.map(i => {
    //       if (i.val == 'logout') i.hide = false;
    //     })
    //   } else {

    //     this.menu.menuItems.map(i => {
    //       if (i.val == 'rg') i.hide = false;
    //       if (i.val == 'ad') i.hide = false;
    //     })

    //   }

    // })

  }



}
