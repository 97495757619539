import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AudioPlayerService } from 'src/app/allServices/audio-player.service';
import { CONSTNAME } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-music-player',
  templateUrl: './music-player.component.html',
  styleUrls: ['./music-player.component.css']
})
export class MusicPlayerComponent implements OnInit {
  @Input() items: any;
  @Input() index: any;
  @ViewChildren('audioElement') audioElements: QueryList<ElementRef>;
  durationofaudio: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  play:boolean = true
  mute:boolean = false
  piValue: number = 0;
  volumeValue: number = 1; // Initialize with 0
  audioElement: Input;
  sliderValue : any = 0.00
  ngAfterViewInit() {
    this.audioElements.toArray().forEach((audioElement, index) => {
      audioElement.nativeElement.setAttribute('src', this.items[index]);
    });
    
  }
  constructor(private audioPlayerService: AudioPlayerService) { }

  ngOnInit(): void {
// ////console.log('dkeoihfie');
// ////console.log(this.index);
  this.items[this.index].audio.addEventListener('timeupdate', () => {
    this.sliderValue = this.items[this.index].audio.currentTime;
  });
  this.PlayAudio()
}
PlayAudio() {
  if (! this.items[this.index].audio) {
    if ( this.items[this.index].loop_type == 1) {
      if ( this.items[this.index].tagged_loop_path !== null) {
        this.items[this.index].file = CONSTNAME.fILE_PATH +  this.items[this.index].tagged_loop_path;
        this.durationofaudio =  this.items[this.index].tagged_loop_duration;
      } else {
        this.items[this.index].file = CONSTNAME.fILE_PATH +  this.items[this.index].loop_path;
        this.durationofaudio =  this.items[this.index].loop_duration;
      }
    } else {
      this.items[this.index].file = CONSTNAME.fILE_PATH +  this.items[this.index].tagged_loop_path;
      this.durationofaudio =  this.items[this.index].tagged_loop_duration;
    }
    // if (!item.duration) {
    //   let sound: any;
    //   sound = await new Promise((resolve, reject) => {
    //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
    //   });
    // }
    this.items[this.index].duration = this.durationofaudio;
    this.items[this.index].audio = new Audio( this.items[this.index].file);
  }
  this.items[this.index].audio.addEventListener('timeupdate', () => {
    this.sliderValue = this.items[this.index].audio.currentTime;
  });
  this.play = true
  this.items[this.index].audio.play()
}
next(){
if(this.items.length==this.index){

}else{
 this.stopAudio()
 this.items[this.index].audio.currentTime = 0
 this.sliderValue =0
 this.index = this.index + 1
 this.PlayAudio()
}
}
previous(){
 this.sliderValue =0
 this.items[this.index].audio.currentTime = 0
 this.stopAudio()
  this.index = this.index - 1
  this.PlayAudio()
}
onInput(event: any): void {
  // ////console.log(event.target.value);
  this.items[this.index].audio.currentTime = parseFloat(event.target.value);
}
onVolumeInput(event: any): void {
  // ////console.log(event.target.value);
  this.items[this.index].audio.volume = parseFloat(event.target.value);
}
Mute(){
  this.mute = true
  this.items[this.index].audio.muted = true
}
Unmute(){
  this.mute = false
  this.items[this.index].audio.muted = false

}

stopAudio() {
  this.play = false
  this.items[this.index].audio.pause()
}
setimage(data: any) {
  if (data.artwork_path == null) {
    if (data.profile_image_path == null) {
      return this.profile_image_pathofcomments;

    } else {
      return CONSTNAME.fILE_PATH + data.profile_image_path;
    }

  } else {

    return CONSTNAME.fILE_PATH + data.artwork_path;
  }
}
}
