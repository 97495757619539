<div class="modal-header">
  <button type="button" class="close" (click)="cancel()"><span aria-hidden="true">×</span></button>
  <h4 class="mt-0 mb-0">Upload</h4>
 
</div>
<div class="modal-body">
  <div class="buttons-wrapper">
    <div class="mb-1"><button    (click)="redirecttoloopkit()" class="btn btn-outline btn-block" >Upload Loop Kit</button></div>
    <div class="mb-1"><button (click)="redirecttoloop()" class="btn btn-outline btn-block" >Upload Loop </button></div>
 </div>
</div>