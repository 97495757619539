import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';
import { REPORTTRACKMODELComponent } from '../../MODAL/report-track-model/report-track-model.component';
import { NgWaveformModule } from 'ng-waveform';
import { ITimeUpdateEvent, NgWaveformComponent, IRegionPositions } from 'ng-waveform';

import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import { ConfirmDeleteComponent } from '../../MODAL/confirm-delete/confirm-delete.component';
import { DownloadmodalComponent } from '../../MODAL/downloadmodal/downloadmodal.component';
import { ShareComponent } from '../../MODAL/share/share.component';
import { EditLoopComponent } from '../../MODAL/edit-loop/edit-loop.component';
import { event } from 'jquery';
import { Subscription } from 'rxjs';
import { ZipService } from 'src/app/allServices/zip.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
// import WaveSurfer from 'wavesurfer.js';
// import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';

@Component({
  selector: 'app-detailuploadloop',
  templateUrl: './detailuploadloop.component.html',
  styleUrls: ['./detailuploadloop.component.css'],
  providers: [DatePipe]
})
export class DetailuploadloopComponent implements OnInit {
  // WaveSurfer = require('wavesurfer.js');
  @ViewChild('waveform', { static: false }) waveform: NgWaveformComponent;

  norecordfound : boolean = false

  titlename: any;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  username: any;
  createdon: any;
  bpm: any;  volumeValue: number = 1;
  instrument_name: any;
  parent_instrument_name: any;
  genre_name: any;
  parent_genre_name: any;
  artwork_path: null;
  sub_key: null;
  color="#ffffff"
  imagehide: any = false;
  afterload = false;
  key: any;
  imagesrc: any;
  loop_path: any;
  audiosrc: any;
  loop_type: any;
  sellingpreference: any;
  termsofpreference: any;
  time: any;
  looporloopkitid: any;
  showbpm: any = true;
  hidereportbutton: boolean = true;
  commentofdetailedloopview = StaticService.commentformforuser();
  // arraytableforcomments: any;
  commentData: any;
  nocomments: any = true;
  pageno: any = 1;
  paginationempty = false;
  pagercount: any = [];
  pagenolist: any;
  totallength: any;
  hidepagination: any = false;
  rowsize: any = 2;
  src: any;
  commentno: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  artworkpathimage = "assets/img/artwork.jpg";
  hideplaybutton: boolean;
  hidepausebutton: boolean = true;
  number_of_likes: any;
  number_of_reposts: any;
  showupperpage: boolean;
  addred: boolean;
  addgreen: boolean;
  description: any;
  showdescription: any = false;
  loop_price: any;
  hidecommentbox: boolean;
  messageerror = "";
  fILE_PATH = "https://d1ptp6rux3p5im.cloudfront.net"
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  arraytableforallvalues: any = [];
  render: boolean = false;
  d :any

  validationmessage = {
    messagecheck: '',
    ismessagecheck: true
  }
  imageofcomment: any;
  encryptedagainremoveslashes: string;
  value: any;
  disblecommentreposts: boolean;
  stream: any;
  hideloopprice: boolean;
  showloopno: any;
  currenttime: any = "0.00";
  totaltime: any;
  durationofaudio: any;
  loopidwhenstatus4: any;
  looptypewhenstatus4: any;
  namewhenstatus4: any;
  showstatus4 = false;
  user_idget: any;
  user_idcomment: any;
  arraytableforcomments: any = []

  last_index = 400;

  counter = 400;

  showTxt = "Show More"
  firstCount = 400
  hidedownloadoption: boolean;
  fileService: any;
  checkingforid: any;
  idfromwebsite: any;
  sameshowdownload: boolean;
  encrypted2: string;
  completerender: boolean = false;
  urlpass: string;
  urlstatusmessage: string;
  valuefromurl:any;
  tableData: any=[];
  id: any;
  showAudioPlayer: boolean;
  player: any = 0;
  sliderValue: any;
  show_usage: any;
  selectedImageIndex: any;
  showFlag: boolean;
  currentIndex: number;
  url = "assets/img/artwork.jpg";
  imageObject: Array<object> = [{
    image: this.url
  }];

  imagenullforleft: boolean;
  mute: boolean;
  steps: any = 0.01;
  loop: any;
  duration: any;
  disable: boolean;
  showAudioPlayer1: boolean;
  private navigationSubscription: Subscription;
  isMobile: boolean;
  user_id: any;
  usage: any;
  showMenu: boolean;

  constructor(public common: commonservice,
    private zipService: ZipService,
    private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService, private matDialog: MatDialog, private _sanitizer: DomSanitizer,    private elementRef: ElementRef,
    private breakpointObserver: BreakpointObserver

  ) { 
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Navigation is about to start, handle cleanup logic
        this.handleCleanup();
      } else if (event instanceof NavigationEnd) {
        // Navigation has ended, perform any necessary actions
        // (e.g., trigger the click event)
        this.handleNavigationEnd();
      }
    });
  }
  private handleCleanup() {
    // Perform cleanup logic before navigation
    // (e.g., pausing audio or video playback)
    const element = this.elementRef.nativeElement.querySelector("#player1-row-" +  this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
    const element1 = this.elementRef.nativeElement.querySelector("#player-row-" +  0 + " .play_button.pause");
   
    if (element1) {
      element1.click();
    }
    // ////console.log('Performing cleanup before navigation');
  }
  fontSize(item:any){
    if (item.title.length <= 50 && item.title.length >= 30) {
      return 'long'
    } else if (item.title.length <= 30 && item.title.length >= 10) {
      return 'medium'
    } else {
      return 'small'
    }
  }
  // redirecttouser(){

  // }
  private handleNavigationEnd() {
    // Navigation has ended, perform necessary actions
    // (e.g., trigger the click event)
    // ////console.log('Navigation has ended, triggering click event');

    const element = this.elementRef.nativeElement.querySelector("#player-row-" +  0 + " .play_button.pause");
    if (element) {
      element.click();
    }
    const element1 = this.elementRef.nativeElement.querySelector("#player1-row-" +  this.player + " .play_button.pause");
    if (element1) {
      element1.click();
    }
  }
  ngOnInit(): void {
    // ////console.log(btoa("937"))
    let b = localStorage.getItem("clientintoken");
    if (b) {
      this.showMenu = true
    }else{
      this.showMenu = false
    }
    // console.log(this.showMenu)
    window.scrollTo(0,0)
    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
    this.valuefromurl = this.route.snapshot.queryParamMap.get('msg');

    this.urlstatusmessage= atob(this.valuefromurl)

    this.user_id = localStorage.getItem("client_data");
    this.usage = JSON.parse(this.user_id)?.usage_rights;  
    this.urlpass = APIURL.BASE_URLforredirect+this.router.url
   
    
    // this.route.paramMap
    // .switchMap((params:any) => this.itemsSrv.getItems(params.get('loop_id')))
    // .subscribe((data:any) => {
    //     this.items=data;
    // });
    this.route.queryParams.subscribe(params => {
      // ////console.log(params['loop_id'])
      this.value = params['loop_id']
      this.encryptedagainremoveslashes = decodeURIComponent(this.value);
      this.decryptUsingAES256();
      // ////console.log(this.decrypted);
      
      this.getapihit();
      // ////console.log(this.encryptedagainremoveslashes);
      // ////console.log(this.value);
  
      this.common.getDataInCookie(VARS.Login_Client_data).then(async (res: any) => {
        if (!res) {
          // this.getUserDetail();
        }
        let resa = JSON.parse(res)
        if (resa) {
          this.idfromwebsite = resa.user_type_id
          // ////console.log(this.idfromwebsite)
          // if (idfromloop === idfromwebsite) {
          //   // ////console.log("gaya")
          //   this.downloadloop(item)
          //   // this.apihitelsepart(item);
          // } else {
          //   this.apihitelsepart(item);
  
          // }
        
        };
      })
    });

    

    

    // this.route.params.subscribe(params => {
    //   this.value = this.route.snapshot.params['loop_id'];
    //   ////console.log(this.value)
      
  
    // });
  



   





  }
  ngOnDestroy() {
    this.stopAudio(this.loop, this.player);
    this.stopAudio1(this.loop, this.player);
  }
  timeupdate(){
    const du =  this.tableData[this.player].duration || this.tableData[this.player].loop_duration || this.tableData[this.player].tagged_loop_duration
    return du * 1000
   }
   timeupdate1(){
    // ////console.log(this.arraytableforallvalues);
    
    const du =  this.arraytableforallvalues[0].duration || this.arraytableforallvalues[0].loop_duration || this.arraytableforallvalues[0].tagged_loop_duration
    // ////console.log(du);
    
    return du * 1000
   }
   onSliderValueChanged(value: number) {
    // ////console.log("value",value);
    
    this.sliderValue = value; // Update sliderValue in the parent component
   
  }
  getImage(data:any){
    // ////console.log(data);
    
      if (data == null) {
        // ////console.log("null")
        this.imagenullforleft = true;
      } else {
        // ////console.log("not null")
        this.imagenullforleft = false;
      }
      if(data){
        if (data.includes("/profile_photos/")||data.includes("/loop_artworks/")) {
          this.url =CONSTNAME.fILE_PATH + data;
  
          }else
        this.url = data
        this.imageObject = [];
        let img = {
          image: this.url
        }
        this.imageObject[0]= img
      } else{
        this.url= 'assets/img/artwork.jpg';
        let img = {
          image: this.url
        }
        this.imageObject[0]= img
      }
  }
  // public myMethodChangingQueryParams() {
  //   const queryParams = { loop_id: this.value };
  //   ////console.log("in")
  
  //   this.router.navigate(
  //     [], 
  //     {
  //       relativeTo: this.route,
  //       queryParams: queryParams, 
  //       queryParamsHandling: 'merge', // remove to replace all query params by provided
  //     });
  // }
  showLightbox(index: any) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }
  
  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
  vCheck(fieldName: any, type: INPUT_TYPE_NAME, options: any = {}) {
    options.isRequired = true;
    const cFrm = this.commentofdetailedloopview.controls;
    if (cFrm) {
      return StaticService.formError(cFrm, fieldName, type, options).msg;
    }
  }
  waveformLoaded: boolean[] = [];

  onWaveformLoaded(index: number) {
    this.waveformLoaded[index] = true;
  }
  srcforsound(items: any) {
    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
        // ////console.log("checking");
      } else {
        this.durationofaudio = items.loop_duration;
        return CONSTNAME.fILE_PATH + items.loop_path;
      }

      // ////console.log(this.src);
    } else {
      this.durationofaudio = items.tagged_loop_duration;
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      // ////console.log(this.src);
    }
  }
  openshare(item: any):void{
    // ////console.log(item);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.loop_id ), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    let dialogue = this.matDialog.open(ShareComponent, { panelClass: 'share', disableClose: true, data: { link:this.encrypted}});
    dialogue.afterClosed().subscribe(result => {

    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });

  }
  getFileExtension(filename: string): string {
    // Extract the extension by splitting the filename at the last dot
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1].toLowerCase();
    } else {
      // If there's no dot in the filename, consider it as having no extension
      return '';
    }
  }
  
  downloadFile(filePath: any) {
    // Create a hidden anchor element
    const a = document.createElement("a");
    // ////console.log("jid");
    const fileExtension = this.getFileExtension(CONSTNAME.fILE_PATH + filePath.loop_path ||
      CONSTNAME.fILE_PATH + filePath.tagged_loop_path);
    document.body.appendChild(a);
    a.style.display = "none";
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      // ////console.log(res);
      if(res===null){
        // fetch(
        //   CONSTNAME.fILE_PATH + filePath.loop_path ||
        //     CONSTNAME.fILE_PATH + filePath.tagged_loop_path
        // )
        //   .then((response) => response.blob())
        //   .then((blob) => {
        //     // Create a blob URL for the file
        //     const blobUrl = window.URL.createObjectURL(blob);
            
        //     // Set the anchor's href and download attributes
        //     a.href = blobUrl;

        //     a.download =
        //       filePath.title +
        //       "_" +
        //       filePath.bpm +
        //       "_" +
        //       filePath.sub_key +
        //       "_@" +
        //       filePath.username +
        //       `.${fileExtension}`; // Set the desired filename
        //     this.common.hideLoader();

        //     // Simulate a click on the anchor to trigger the download
        //     a.click();

        //     // Clean up by revoking the blob URL
        //     window.URL.revokeObjectURL(blobUrl);
        //   })
        //   .catch((response) => {
        //     this.toastr.error(response.response.errors, "", {
        //       positionClass: "toast-bottom-right",
        //     });
        //   })
        this.toastr.error("Please Login First", "", {
          positionClass: "toast-bottom-right",
        });
        setTimeout(() => {
          this.router.navigate(["/login"])
        }, 1000);
      }else{
      let resa = JSON.parse(res);
      this.idfromwebsite = resa.user_type_id;
      if (filePath.user_id !== this.idfromwebsite) {
        this.api
          .postApiHit(`/user/save_free_loop`, {
            loop_id: filePath.loop_id,
            user_type_id: this.idfromwebsite,
          })
          .then((res: any) => {
            if (res.response.status) {
              fetch(
                CONSTNAME.fILE_PATH + filePath.loop_path ||
                  CONSTNAME.fILE_PATH + filePath.tagged_loop_path
              )
                .then((response) => response.blob())
                .then((blob) => {
                  // Create a blob URL for the file
                  const blobUrl = window.URL.createObjectURL(blob);
                  
                  // Set the anchor's href and download attributes
                  a.href = blobUrl;

                  a.download =
                    filePath.title +
                    "_" +
                    filePath.bpm +
                    "_" +
                    filePath.sub_key +
                    "_@" +
                    filePath.username +
                    `.${fileExtension}`; // Set the desired filename
                  this.common.hideLoader();

                  // Simulate a click on the anchor to trigger the download
                  a.click();

                  // Clean up by revoking the blob URL
                  window.URL.revokeObjectURL(blobUrl);
                })
                .catch((response) => {
                  this.toastr.error(response.response.errors, "", {
                    positionClass: "toast-bottom-right",
                  });
                });

              // Remove the anchor element from the DOM
              document.body.removeChild(a);
            } else {
              this.toastr.error(res.response.errors, "", {
                positionClass: "toast-bottom-right",
              });
            }
          });
      } else {
        fetch(
          CONSTNAME.fILE_PATH + filePath.loop_path ||
            CONSTNAME.fILE_PATH + filePath.tagged_loop_path
        )
          .then((response) => response.blob())
          .then((blob) => {
            // Create a blob URL for the file
            const blobUrl = window.URL.createObjectURL(blob);

            // Set the anchor's href and download attributes
            a.href = blobUrl;

            a.download =
              filePath.title +
              "_" +
              filePath.bpm +
              "_" +
              filePath.sub_key +
              "_@" +
              filePath.username +
              `.${fileExtension}`; // Set the desired filename
            this.common.hideLoader();

            // Simulate a click on the anchor to trigger the download
            a.click();

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(blobUrl);
          })
          .catch((response) => {
            this.toastr.error(response.response.errors, "", {
              positionClass: "toast-bottom-right",
            });
          });

        // Remove the anchor element from the DOM
        document.body.removeChild(a);
      }
      if(filePath.stems){
        this.zipService.downloadFile(CONSTNAME.fILE_PATH  + filePath.stems).subscribe((data) => {
          const blob = new Blob([data], { type: 'application/zip' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${filePath.title}.zip`;
          link.click();
      })
    }
}

});
   
    // Create a blob for the file data
  }








  async getapihit() {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }
    // ////console.log(this.decrypted);

    let sendingdata = {
      "loop_id": this.decrypted
    }
    // this.http.post(APIURL.BASE_URL + `/user/get_upload_detail`, sendingdata, { headers }).subscribe(async (res: any) => {
    this.api.forclientgettokenpublic(`/user/get_upload_detail`, sendingdata).then(async (res: any) => {
      // this.api.postApiHit(, sendingdata).then((res: any) => {

      this.arraytableforallvalues = res.response.data.loop_details
      
      this.afterload = true;
      if (res.response.status == 1) {
        this.common.hideLoader();
        this.showupperpage = true

        if ((res.response.data.loop_details[0].paypal_merchant_account_linked == 1) || (res.response.data.loop_details[0].stripe_merchant_account_linked == 1)) {
          this.hidedownloadoption = false;
        } else {
          this.hidedownloadoption = true;
        }

        this.checkingforid = res.response.data.loop_details[0].user_id
        if (this.checkingforid == this.idfromwebsite) {

          this.sameshowdownload = true
          // ////console.log( res)
        } else {
          // ////console.log( res)
          this.sameshowdownload = false
        }
        if(res.response.data.loop_details[0].artwork_path)
           this.getImage(res.response.data.loop_details[0].artwork_path)
        else
           this.getImage(res.response.data.loop_details[0].profile_image_path)
           // ////console.log(res.response.data.loop_details[0].artwork_path);
        // if (res.response.data.loop_details[0].artwork_path !== null) {
        //   this.artworkpathimage = CONSTNAME.fILE_PATH + res.response.data.loop_details[0].artwork_path;
        //   // ////console.log("dfdf");
        // } else if (res.response.data.loop_details[0].profile_image_path !== null) {
        //   this.artworkpathimage = CONSTNAME.fILE_PATH + res.response.data.loop_details[0].profile_image_path;
        // }
        // this.imageofartwork = 
        // ////console.log(res);
        if (res.response.data.loop_details[0].loop_type == 1) {
          if (res.response.data.loop_details[0].tagged_loop_path !== null) {
            this.src = CONSTNAME.fILE_PATH + res.response.data.loop_details[0].tagged_loop_path;
            this.durationofaudio = res.response.data.loop_details[0].tagged_loop_duration;
            // ////console.log( this.src);
          } else {
            this.src = CONSTNAME.fILE_PATH + res.response.data.loop_details[0].loop_path;
            this.durationofaudio = res.response.data.loop_details[0].loop_duration;
          }
          // ////console.log(this.src);

        } else {
          this.src = CONSTNAME.fILE_PATH + res.response.data.loop_details[0].tagged_loop_path;
          // ////console.log(this.src);
          // ////console.log(this.src);
          this.durationofaudio = res.response.data.loop_details[0].tagged_loop_duration;
        }

        if (res.response.data.loop_details[0].loop_type == 2) {
          this.description = res.response.data.loop_details[0].description;
          this.showdescription = true;
          this.showloopno = res.response.data.loop_details[0].loops_count;

          if (this.description !== null) {
            this.last_index = (this.description.substring(0, 400)).lastIndexOf(' ');
            // ////console.log(this.last_index)
            if (this.last_index > 400)
              this.last_index = 400;
            this.counter = this.last_index;
            // ////console.log(this.description.length)
          } else {
            this.showdescription = false;
          }
        } else {
          this.showdescription = false;
        }
        // ////console.log(res.response.data.loop_details[0].delete_flag);
        if (res.response.data.loop_details[0].delete_flag === 1) {
          this.disblecommentreposts = true;
        } else {
          this.disblecommentreposts = false;
        }

        if (res.response.data.loop_details[0].is_liked == 1) {
          // ////console.log(res.response.data.loop_details[0].is_liked);
          this.addred = true;
          //   let a: any = document.getElementById("red");
          // a.classList.add("red");
        } else {
          this.addred = false;
          // ////console.log(res.response.data.loop_details[0].is_liked);
        }
        if (res.response.data.loop_details[0].is_reposted == 1) {
          this.addgreen = true;
          // let a: any = document.getElementById("green");
          // a.classList.add("green");
        } else {
          this.addgreen = false;
          // ////console.log(res.response.data.loop_details[0].is_reposted);
        }
        // ////console.log(res.response.data.loop_details[0].set_usage);
        
        // if(res.response.data.loop_details[0].set_usage){
        //   //  let usage be same
        //   }else{
        //  this.d = localStorage.getItem("client_data");
        //  let data = JSON.parse(this.d)
        //  ////console.log(data.usage_rights);
         
        //     this.arraytableforallvalues[0].usage_rights = data.usage_rights
        //   }


        // ////console.log(res.response.data.loop_details[0].tagged_loop_path);
        let a = res.response.data.loop_details[0].tags
        if(a){
        let strtag: any = a.split('\n ');
        let strtag1: any = strtag[0];
        let strtag2: any = strtag[1];
        let strtag3: any = strtag[2];
        this.tablefortagsdisplay1 = strtag1;
        this.tablefortagsdisplay2 = strtag2;
        this.tablefortagsdisplay3 = strtag3;
        }
        
        this.artwork_path = res.response.data.loop_details[0].artwork_path;
        if (this.artwork_path == null) {
          this.imagehide = true;
        } else {
          this.imagesrc = this.artwork_path;
          // ////console.log(this.imagesrc);
          this.imagehide = false;
        }
        this.key = res.response.data.loop_details[0].main_key;
        if (this.key == 1) {
          this.key = "Major";
        } else {
          this.key = "Minor";
        }
        this.loop_path = res.response.data.loop_details[0].loop_path;

        if (this.loop_path) {
          this.audiosrc = CONSTNAME.fILE_PATH + this.loop_path;
          // ////console.log(this.audiosrc);
        }

        if (res.response.data.loop_details[0].bpm == null || res.response.data.loop_details[0].bpm == "") {
          this.showbpm = false;
        }
        if (res.response.data.loop_details[0].loop_id) {
          this.looporloopkitid = res.response.data.loop_details[0].loop_id
          // ////console.log(this.looporloopkitid);
        }
        if (res.response.data.loop_details[0].show_report_button == 0) {
          this.hidereportbutton = false;
          // ////console.log(res.response.data.loop_details[0].show_report_button);

        } else {
          this.hidereportbutton = true;
        }

        this.number_of_likes = res.response.data.loop_details[0].number_of_likes;
        this.number_of_reposts = res.response.data.loop_details[0].number_of_reposts;

        this.getallinfoofcomments();
        // ////console.log(res);
        this.sub_key = res.response.data.loop_details[0].sub_key;
        this.titlename = res.response.data.loop_details[0].title;
        if (res.response.data.loop_details[0].instrument_name !== null) {
          this.instrument_name = "- " + res.response.data.loop_details[0].instrument_name;
        } else {
          this.instrument_name = "";
        }

        this.user_idget = res.response.data.loop_details[0].user_id
        let sendingdata1 = {
          "page_no": this.pageno,
          "row_size": this.rowsize,
          "profile_user_id": this.checkingforid,
          "get_liked_loops": 1,
          "more":true
    
        }
    
        let tokkenget1 = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    
        let headers1: any;
        if (tokkenget1) {
          headers1 = { 'Authorization': `Bearer ` + tokkenget1, 'My-Custom-Header': 'foobar', };
    
        }
    
    
    
        // this.http.post(APIURL.BASE_URL + `/user/get_all_user_sounds`, sendingdata, { headers }).subscribe(async (res: any) => {
        this.api.forclientgettokenpublic(`/user/get_all_user_sounds`, sendingdata1).then((res: any) => {
    
    
          // ////console.log(res)
          if (res.response.status == 1) {
            this.tableData = res.response.data.pagging_list
            res.response.data.pagging_list.map((item:any,index: any) =>{
              if(item.tags){
              res.response.data.pagging_list[index].tags_array= item.tags.split('\n');
              }
            })
          }
          })
        let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(this.user_idget), _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
        this.encrypted = encrypted.toString();
        this.encrypted = encodeURIComponent(this.encrypted);


        this.parent_instrument_name = res.response.data.loop_details[0].parent_instrument_name;
        this.parent_genre_name = res.response.data.loop_details[0].parent_genre_name;
        if (res.response.data.loop_details[0].genre_name !== null) {
          this.genre_name = "- " + res.response.data.loop_details[0].genre_name;
        } else {
          this.genre_name = "";
        }

        this.bpm = res.response.data.loop_details[0].bpm;
        if (res.response.data.loop_details[0].number_of_streams == null) {
          this.stream = 0;
        } else {
          this.stream = res.response.data.loop_details[0].number_of_streams;
          this.nFormatter(this.stream);
        }

        this.username = res.response.data.loop_details[0].username;
        if (res.response.data.loop_details[0].loop_price == 0) {
          this.hideloopprice = true;
        } else {
          this.loop_price = (Math.round(res.response.data.loop_details[0].loop_price * 100) / 100).toFixed(2);
          this.hideloopprice = false;
        }

       
        this.createdon = moment(res.response.data.loop_details[0].modified_on).format(' MMMM DD,YYYY ');
        this.loop_type = res.response.data.loop_details[0].loop_type;
        this.sellingpreference = res.response.data.loop_details[0].selling_preference;
        this.termsofpreference = res.response.data.loop_details[0].terms;



        var date_future: any = new Date();
        var date_now: any = new Date(res.response.data.loop_details[0].modified_on);
        //  ////console.log( moment(res.response.data.loop_details[0].created_on).format(' MMMM DD,YYYY h:m'))
        var time = date_future - date_now;
        // ////console.log(time);
        var seconds = moment.duration(time).seconds();
        var minutes = moment.duration(time).minutes();
        var hours = moment.duration(time).hours();
        var days = moment.duration(time).days();
        // ////console.log(hours, days, minutes);


        if (tokkenget) {
          this.hidecommentbox = false;
        } else {
          this.hidecommentbox = true;
        }
        if (seconds < 60 && minutes === 0) {
          this.time = seconds + " sec"
        }
        else if (minutes < 60 && hours === 0) {
          this.time = minutes + " min"
        } else if (hours == 1 && days === 0) {
          this.time = hours + " hr"
        } else if (hours <= 24 && days === 0) {
          this.time = hours + " hrs"
        }
        else if (days = 1) {
          this.time = days + " day"

        } else if (days > 1) {
          this.time = days + " days"
        }




        if (this.sellingpreference == 1) {
          if (this.termsofpreference == 1) {
            this.termsofpreference = "Royalty Free";
          } else if (this.termsofpreference == 2) {
            this.termsofpreference = "50/50 Master + Publishing Split";
          }
        } else if (this.sellingpreference == 2) {
          if (this.termsofpreference == 1) {
            this.termsofpreference = "Royalty Free";
          } else if (this.termsofpreference == 2) {
            this.termsofpreference = "50/50 Master + Publishing Split";
          } else {
            this.termsofpreference = "Exclusive";
          }
        }
        // ////console.log(this.loop_type);
        if (this.loop_type == 1) {
          this.loop_type = "Loop";
        } else {
          this.loop_type = "Loop Kit";
        }



      } else if (res.response.status == 4) {
        this.common.hideLoader();
        this.showstatus4 = true;
        // ////console.log(res);
        this.namewhenstatus4 = res.response.data.username;
        this.looptypewhenstatus4 = res.response.data.loop_type;
        if (this.looptypewhenstatus4 = 1) {
          this.looptypewhenstatus4 = "loop"
        } else {
          this.looptypewhenstatus4 = "loop kit"
        }
        this.loopidwhenstatus4 = res.response.data.user_id;
        // this.showupperpage = true
        this.user_idget = res.response.data.user_id

        let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(this.user_idget), _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
        this.encrypted = encrypted.toString();
        this.encrypted = encodeURIComponent(this.encrypted);
        // ////console.log( this.encrypted)

      } else {
        // this.render = true
        
        // this.showupperpage = true;
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });

// ////console.log( this.value);



  }
 next(i:any) {
    if (this.loop.length - 1 == i) {
      this.disable = false;
      this.loop.hidepausebutton = false;
      this.loop.audio.currentTime = 0;
      this.sliderValue = 0;
      //  this.player = this.player + 1
      // this.playAudio(this.tableData[0], 0, event);
    } else {
      this.disable = false;
      this.loop.audio.currentTime = 0;
      this.sliderValue = 0;
      this.stopAudio(this.tableData[i], i);
      i = i + 1;
      var seek_btn = document.querySelectorAll(
        "#player1-row-" + i + " .play_button.vol"
      )[0];
      seek_btn.setAttribute("data-seek-vol", String(this.volumeValue));
      (seek_btn as HTMLElement).click();
      setTimeout(() => {
      this.playAudio(this.tableData[i], i,event);
        
      }, 1000);
      if(this.mute===true){
        var seek_btn = document.querySelectorAll(
          "#player1-row-" + i + " .play_button.mute"
        )[0];
        // seek_btn.setAttribute("data-seek-time", String());
        (seek_btn as HTMLElement).click();
      }
    }
  }
  previous() {
    ////////console.log(this.tableData.length - 1, this.player);

    if (this.player == 0) {
      this.disable = true;
    } else {
      this.disable = false;
      this.sliderValue = 0;
      this.loop.audio.currentTime = 0;
      this.stopAudio(this.tableData[this.player], this.player);
      this.player = this.player - 1;
      this.playAudio(this.tableData[this.player], this.player,event);
    }
  }
  onInput(event: any): void {
    // ////console.log('event',event.target.value);
//     // this.stopAudio(this.tableData[this.player],this.player)
    var seek_time = parseFloat(event.target.value).toFixed(2);
//     this.tableData[this.player].audio.currentTime = seek_time;
//     ////console.log("sli",this.tableData[this.player].audio.currentTime);
//     // this.playAudio(this.tableData[this.player],this.player,event)
    
this.sliderValue = seek_time
// ////console.log("sli",this.sliderValue);

    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.seek"
     )[0];
    seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }
  onInput1(event: any): void {
    // ////console.log('event',event.target.value);
//     // this.stopAudio(this.tableData[this.player],this.player)
    var seek_time = parseFloat(event.target.value).toFixed(2);
//     this.tableData[this.player].audio.currentTime = seek_time;
//     ////console.log("sli",this.tableData[this.player].audio.currentTime);
//     // this.playAudio(this.tableData[this.player],this.player,event)
    
this.sliderValue = seek_time
// ////console.log("sli",this.sliderValue);

    var seek_btn = document.querySelectorAll(
    "#player1-row-" + this.player + " .play_button.seek")[0];
    seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }

  onVolumeInput(event: any): void {
    // //////console.log(event.target.value);
    var seek_time = parseFloat(event.target.value).toFixed(2);
    this.tableData[this.player].audio.volume = parseFloat(event.target.value);
    this.volumeValue =  this.tableData[this.player].audio.volume
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.vol"
    )[0];
    seek_btn.setAttribute("data-seek-vol", String(seek_time));
    (seek_btn as HTMLElement).click();
    if(this.volumeValue>0){
      this.Unmute()
    }else if(this.volumeValue==0){
      this.Mute()
    }
  }
  Mute() {
    this.volumeValue = 0
    this.mute = true;
    this.loop.audio.muted = true;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.mute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }
  Unmute() {
    this.volumeValue = this.loop.audio.volume
    this.mute = false;
    this.loop.audio.muted = false;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.unmute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }

  onVolumeInput1(event: any): void {
    // //////console.log(event.target.value);
    var seek_time = parseFloat(event.target.value).toFixed(2);
    this.tableData[this.player].audio.volume = parseFloat(event.target.value);
    this.volumeValue =  this.tableData[this.player].audio.volume
    var seek_btn = document.querySelectorAll(
      "#player1-row-" + this.player + " .play_button.vol"
    )[0];
    seek_btn.setAttribute("data-seek-vol", String(seek_time));
    (seek_btn as HTMLElement).click();
    if(this.volumeValue>0){
      this.Unmute()
    }else if(this.volumeValue==0){
      this.Mute()
    }
  }
  Mute1() {
    this.volumeValue = 0
    this.mute = true;
    this.loop.audio.muted = true;
    var seek_btn = document.querySelectorAll(
      "#player1-row-" + this.player + " .play_button.mute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }
  Unmute1() {
    this.volumeValue = this.loop.audio.volume
    this.mute = false;
    this.loop.audio.muted = false;
    var seek_btn = document.querySelectorAll(
      "#player1-row-" + this.player + " .play_button.unmute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }

  forward() {
    // this.steps = this.steps + 0.10;
    this.loop.audio.currentTime += 2;
    this.sliderValue =  this.loop.audio?.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.forward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
    ////////console.log(this.steps);
  }
  backward() {
    // this.steps = this.steps - 0.01;
    this.loop.audio.currentTime -= 2;
    this.sliderValue =  this.loop?.audio?.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.backward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }
  forward1() {
    // this.steps = this.steps + 0.10;
    this.loop.audio.currentTime += 2;
    this.sliderValue =  this.loop.audio?.currentTime
    var seek_btn = document.querySelectorAll(
      "#player1-row-" + this.player + " .play_button.forward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
    ////////console.log(this.steps);
  }
  backward1() {
    // this.steps = this.steps - 0.01;
    this.loop.audio.currentTime -= 2;
    this.sliderValue =  this.loop?.audio?.currentTime
    var seek_btn = document.querySelectorAll(
      "#player1-row-" + this.player + " .play_button.backward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }
  playAudio(item: any, index: any, event: any) {
    // ////console.log(item,index);
    this.stopAudio(this.loop,this.player)
    this.loop = item
    const divsWithClass = document.querySelectorAll('div.list');

    divsWithClass.forEach(div=>{
      div.classList.add('ply');
    })
    // this.common.showLoader();
   
    this.showAudioPlayer = true;
    this.showAudioPlayer1 = false;
  
    // list.forEach((menu: Element) => {
    //   // //////////console.log(menu);
    //   menu.classList.remove('ply');
    // })

    // this.waveform = item
    this.player = index;
    var play_time: any =0.00;
    // document.querySelectorAll("#player-row-"+index+ " .play_button.play")[0].click();
   

    this.tableData.map((r: any) => {
      r.hidepausebutton = false;
    });
    this.tableData.map((r: any,i:any) => {
      if (r.audio) {
        ////////console.log('hii');
        
        r.audio.pause();
        const element = document.querySelectorAll(
          "#player-row-" + i + " .play_button.pause"
        )[0];
    
        if (element && "click" in element) {
          (element as HTMLElement).click();
        }
      }
    });
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.play"
    )[0];

    // ////console.log(element);
    
    play_time = document
      .querySelectorAll("#player-row-" + index + " .play_button.seek")[0]
      ?.getAttribute("data-seek-time");
    ////////console.log(play_time);

    play_time = parseFloat(play_time).toFixed(2);
    // this.tableData[index].audio.addEventListener('timeupdate', () => {
    //   this.sliderValue = this.tableData[index].audio.currentTime;
    // });

    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      // this.childComponent.onPlayButtonClick(event)
      // ////////console.log(this.waveform);
      // item.waveform = new NgWaveformComponent(item.file);
      // this.waveform = item.waveform
      // ////////console.log(this.waveform);
      // this.waveform.play();
      // var target = event.target || event.srcElement || event.currentTarget;
      // var currently_playing_btn:any = document.querySelectorAll(".is-playing");
      // if (currently_playing_btn.length > 0) {
      //   if (target != currently_playing_btn[0]) {
      //     currently_playing_btn[0].nextSibling.click();
      //   }
      // }
      // item.audio.currentTime = play_time;
    
      // item.audio.addEventListener('loadedmetadata', () => {
        if(!this.mute){
          item.audio.muted = false
            }
      item.audio.volume = this.volumeValue
      
    //  const i =  item.audio.play();
    //  ////console.log(i);
     
      // if(!item.audio.paused){
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
    // }
      // });
      // item.audio.play();

      this.common.hideLoader();
      // item.audio.playbackRate = 1.3
      // ////console.log(item.audio.currentTime);
    //   item.audio.addEventListener("timeupdate", (test:any, test2:any) => {
    //     // //////console.log("fff",test, test2);
    // ////console.log("timeupdate",item.audio.currentTime);
        
    //   this.sliderValue = item.audio.currentTime;
    //     // this?.currentwaveform?.setRegionStart(this.sliderValue);
    //   //////////console.log(item.audio.currentTime);
    //   //////////console.log(this.sliderValue);
    //     var seek_btn = document.querySelectorAll(
    //       "#player-row-" + index + " .play_button.seek"
    //     )[0];
    //     // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
    //     // (seek_btn as HTMLElement).click();
    //     // this.currentwaveform.setRegionStart(this.sliderValue);
    //   });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        this.next(index);
      });
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true;
      } else if (item.duration < 15 && item.duration >= 5) {
        this.apihitforstream = true;
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {
        this.apihitforstream = true;
        this.above1secondlessthan5 = true;
      }
    } else {
      if(!this.mute){
        item.audio.muted = false
          }
      item.audio.volume = this.volumeValue
      // item.audio.play();
      // item.audio.currentTime = play_time;
      // ////console.log("currentTime",item.audio.currentTime);
      // item.audio.addEventListener("timeupdate", () => {
      //   this.sliderValue = item.audio.currentTime;
      //   // var seek_btn = document.querySelectorAll(
      //   //   "#player-row-" + index + " .play_button.seek"
      //   // )[0];
      //   // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
      //   // (seek_btn as HTMLElement).click();
      //   ////////console.log( this.currentwaveform);
      //   // this?.currentwaveform?.setRegionStart(item.audio.currentTime);
        
      //   // this.currentwaveform.setRegionStart(this.sliderValue);
      // });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        // this?.currentwaveform?.setRegionStart(item.duration);
        this.next(index);
      });
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
      this.common.hideLoader();
    }

    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {
        setTimeout(() => {}, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration < 15 && item.duration >= 5) {
        if (r > 5) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      }
    }, 200);
  }
  playAudio1(item: any, index: any, event: any) {
    // ////console.log(item,index);
    this.stopAudio(this.loop,this.player)
    this.loop = item
    const divsWithClass = document.querySelectorAll('div.list');

    divsWithClass.forEach(div=>{
      div.classList.add('ply');
    })
    // this.common.showLoader();
   
    this.showAudioPlayer1 = true;
    this.showAudioPlayer = false;
  
    // list.forEach((menu: Element) => {
    //   // //////////console.log(menu);
    //   menu.classList.remove('ply');
    // })

    // this.waveform = item
    this.player = index;
    var play_time: any =0.00;
    // document.querySelectorAll("#player-row-"+index+ " .play_button.play")[0].click();
   

    this.tableData.map((r: any) => {
      r.hidepausebutton = false;
    });
    this.tableData.map((r: any,i:any) => {
      if (r.audio) {
        ////////console.log('hii');
        
        r.audio.pause();
        const element = document.querySelectorAll(
          "#player1-row-" + i + " .play_button.pause"
        )[0];
    
        if (element && "click" in element) {
          (element as HTMLElement).click();
        }
      }
    });
    const element = document.querySelectorAll(
      "#player1-row-" + index + " .play_button.play"
    )[0];

    // ////console.log(element);
    
    play_time = document
      .querySelectorAll("#player1-row-" + index + " .play_button.seek")[0]
      ?.getAttribute("data-seek-time");
    ////////console.log(play_time);

    play_time = parseFloat(play_time).toFixed(2);
    // this.tableData[index].audio.addEventListener('timeupdate', () => {
    //   this.sliderValue = this.tableData[index].audio.currentTime;
    // });

    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      // this.childComponent.onPlayButtonClick(event)
      // ////////console.log(this.waveform);
      // item.waveform = new NgWaveformComponent(item.file);
      // this.waveform = item.waveform
      // ////////console.log(this.waveform);
      // this.waveform.play();
      // var target = event.target || event.srcElement || event.currentTarget;
      // var currently_playing_btn:any = document.querySelectorAll(".is-playing");
      // if (currently_playing_btn.length > 0) {
      //   if (target != currently_playing_btn[0]) {
      //     currently_playing_btn[0].nextSibling.click();
      //   }
      // }
      // item.audio.currentTime = play_time;
    
      // item.audio.addEventListener('loadedmetadata', () => {
        if(!this.mute){
          item.audio.muted = false
            }
      item.audio.volume = this.volumeValue
      
    //  const i =  item.audio.play();
    //  ////console.log(i);
     
      // if(!item.audio.paused){
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
    // }
      // });
      // item.audio.play();

      this.common.hideLoader();
      // item.audio.playbackRate = 1.3
      // ////console.log(item.audio.currentTime);
    //   item.audio.addEventListener("timeupdate", (test:any, test2:any) => {
    //     // //////console.log("fff",test, test2);
    // ////console.log("timeupdate",item.audio.currentTime);
        
    //   this.sliderValue = item.audio.currentTime;
    //     // this?.currentwaveform?.setRegionStart(this.sliderValue);
    //   //////////console.log(item.audio.currentTime);
    //   //////////console.log(this.sliderValue);
    //     var seek_btn = document.querySelectorAll(
    //       "#player-row-" + index + " .play_button.seek"
    //     )[0];
    //     // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
    //     // (seek_btn as HTMLElement).click();
    //     // this.currentwaveform.setRegionStart(this.sliderValue);
    //   });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        this.next(index);
      });
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true;
      } else if (item.duration < 15 && item.duration >= 5) {
        this.apihitforstream = true;
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {
        this.apihitforstream = true;
        this.above1secondlessthan5 = true;
      }
    } else {
      if(!this.mute){
        item.audio.muted = false
          }
      item.audio.volume = this.volumeValue
      // item.audio.play();
      // item.audio.currentTime = play_time;
      // ////console.log("currentTime",item.audio.currentTime);
      // item.audio.addEventListener("timeupdate", () => {
      //   this.sliderValue = item.audio.currentTime;
      //   // var seek_btn = document.querySelectorAll(
      //   //   "#player-row-" + index + " .play_button.seek"
      //   // )[0];
      //   // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
      //   // (seek_btn as HTMLElement).click();
      //   ////////console.log( this.currentwaveform);
      //   // this?.currentwaveform?.setRegionStart(item.audio.currentTime);
        
      //   // this.currentwaveform.setRegionStart(this.sliderValue);
      // });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        // this?.currentwaveform?.setRegionStart(item.duration);
        this.next(index);
      });
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
      this.common.hideLoader();
    }

    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {
        setTimeout(() => {}, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration < 15 && item.duration >= 5) {
        if (r > 5) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      }
    }, 200);
  }
  stopAudio(item: any, index: any) {
    //////////console.log(item);
    if (item) {
      if (item.audio) {
        item.audio.pause();
        // this.showAudioPlayer = false;s
      }
      clearInterval(this.id);

      this.tableData.map((r: any) => {
        r.hidepausebutton = false;
      });
      this.loop.hidepausebutton = false
    } else {
      this.tableData.map((r: any) => {
        if (r.audio) {
          r.audio.pause();
        }
        r.hidepausebutton = false;
      });
    }
    // item.waveform.pause();

    // document.querySelectorAll("#player-row-"+index+ " .play_button.pause")[0].click();
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.pause"
    )[0];

    if (element && "click" in element) {
      (element as HTMLElement).click();
    }
  }
  stopAudio1(item: any, index: any) {
    //////////console.log(item);
    if (item) {
      if (item.audio) {
        item.audio.pause();
        // this.showAudioPlayer = false;s
      }
      clearInterval(this.id);

      this.tableData.map((r: any) => {
        r.hidepausebutton = false;
      });
      this.loop.hidepausebutton = false
    } else {
      this.tableData.map((r: any) => {
        if (r.audio) {
          r.audio.pause();
        }
        r.hidepausebutton = false;
      });
    }
    // item.waveform.pause();

    // document.querySelectorAll("#player-row-"+index+ " .play_button.pause")[0].click();
    const element = document.querySelectorAll(
      "#player1-row-" + index + " .play_button.pause"
    )[0];

    if (element && "click" in element) {
      (element as HTMLElement).click();
    }
  }
  redirecttoupload(){
    this.router.navigate(['upload-loop/',{ queryParams: { loop_id: this.value } }])
  }
  openEdit(item:any){
    // ////console.log("item",item);
    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let encrypted = CryptoJS.AES.encrypt(
    //   JSON.stringify(item.loop_id), _key, {
    //   keySize: 16,
    //   iv: _iv,
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7
    // });
    // this.encrypted = encrypted.toString();
    // this.encrypted = encodeURIComponent(this.encrypted);
    // this.router.navigate(['upload-loop'], { queryParams: { loop_id: this.encrypted } });
    this.openDialog(item.loop_id)
  }
  
  openDialog(item:any): void {
    // ////console.log(item);
    
    let dialogue = this.matDialog.open(EditLoopComponent, { panelClass: 'share', disableClose: true, data: { data: item }});
    dialogue.afterClosed().subscribe(result => {
     
      this.ngOnInit()
     
    })
    dialogue.afterOpened().subscribe(() => {
      const element = this.elementRef.nativeElement.querySelector("#player1-row-" +  this.player + " .play_button.pause");
      if (element) {
        element.click();
      }
      const element1 = this.elementRef.nativeElement.querySelector("#player-row-" +  0 + " .play_button.pause");
     
      if (element1) {
        element1.click();
      }
      this.showAudioPlayer=false
      this.showAudioPlayer1=false
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  toggleSkil() {




    if (this.counter < 401) {

      this.counter = this.description.length;

      this.showTxt = "Show less";

    }

    else {
      this.counter = this.last_index;

      this.showTxt = "Show More"
    }


  }

  timeget(items: any) {
    // ////console.log("great ", items)


    let newa: any = new Date();
    let next: any = new Date(items?.created_on)

    var seconds: any = Math.floor((newa - next) / 1000);

    var interval: any = seconds / 31536000;

    if (interval > 1) {
      return "Posted " + Math.floor(interval) + " year ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {

      return "Posted " + Math.floor(interval) + " month ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "Posted " + Math.floor(interval) + " day ago";
      } else {
        return "Posted " + Math.floor(interval) + " days ago";
      }

    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "Posted " + Math.floor(interval) + " hour ago";
      } else {
        return "Posted " + Math.floor(interval) + " hours ago";
      }
    }
    interval = seconds / 60;
    if (interval > 1) {
      return "Posted " + Math.floor(interval) + " minutes ago";
    }if(interval===1){
      return "Posted " + Math.floor(interval) + " minute ago";
    }
    if(seconds==1){

    return "Posted " + Math.floor(seconds) + " second ago";
  }else{
    return "Posted " + Math.floor(seconds) + " seconds ago";

  }
  }









  async reportmodal() {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      this.opendialogueforreport();
    } else {
      this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }
  }



  opendialogueforreport(): void {
    let dialogue = this.matDialog.open(REPORTTRACKMODELComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: this.looporloopkitid } });
    dialogue.afterClosed().subscribe(result => {
    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }






  async onSubmit() {

    if (this.validationmessage.messagecheck.length !== 0) {

      this.messageerror = "";
      this.validationmessage.ismessagecheck = true;

    } else {
      this.messageerror = "This field is required ";
      this.validationmessage.ismessagecheck = false;

    }

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    if (tokkenget) {


      const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
      StaticService.markFormGroupTouched(this.commentofdetailedloopview, invalidElements);




      let sendingData = {
        "loop_id": this.looporloopkitid,
        "comment": this.validationmessage.messagecheck

      }
      // ////console.log(sendingData);
      if (this.validationmessage.ismessagecheck == true) {
        this.validationmessage.messagecheck = "";
        this.api.postApiHit(`/user/post_comment`, sendingData).then((res: any) => {
          if (res.response.status == 1) {
            this.commentofdetailedloopview.reset();
            this.common.hideLoader();
            this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
            this.getallinfoofcomments();
          } else if (res.response.status == 2) {
            let errorthroughapi = res.response.errors;
            let error = [];
            // this.cancel();
            this.common.hideLoader();
            for (var i = 0; i < errorthroughapi.length; i++) {
              this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
            }
            this.router.navigate(['']);

          }
          else {
            let errorthroughapi = res.response.errors;
            let error = [];
            // this.cancel();
            this.common.hideLoader();
            for (var i = 0; i < errorthroughapi.length; i++) {
              this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
            }
          }
        }, async e => {

          this.common.hideLoader();
          this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
        });
      }
    } else {
      this.opendialogueforverification();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' });

    }

  }




  async getallinfoofcomments() {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }
    let value = this.route.snapshot.queryParamMap.get('loop_id');

    this.http.get(APIURL.BASE_URL + `/user/get_all_comments?page_no=${this.pageno}&row_size=${this.rowsize}&sort_on=created_on&sort_type=DESC&loop_id=${this.looporloopkitid}`, { headers }).subscribe(async (res: any) => {


      // this.api.getApiForClient(`/user/get_all_comments?page_no=${this.pageno}&row_size=${this.rowsize}&sort_on=created_on&sort_type=DESC&loop_id=${this.looporloopkitid}`, VARS.ClIENT_TYPE).then((res: any) => {
      // ////console.log(this.looporloopkitid);
      // this.afterload = true;
      if (res.response.status == 1) {

        this.commentno = res.response.data.total_records;


        // ////console.log(res);
        this.callfunctionforcomment(res);

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
        this.commentData = true;
      }
    }, async e => {
      this.commentData = true;
    });
  }



  callfunctionforcomment(value: any) {
    // ////console.log(value);
    // ////console.log(value.response.data.total_records);
    // ////console.log(value.response.data.total_pages);
    // for (let i = 0; i < 1; i++) {
    //   if (value.response.data.pagging_list[i].profile_image_path !== null) {
    //     this.profile_image_pathofcomments = "https://d9x9vnpflfo8y.cloudfront.net" + value.response.data.pagging_list[i].profile_image_path;
    //   }
    // }


    this.commentData = true;
    if (value.response.data.total_pages == 1) {
      this.hidepagination = true;

    } else {
      this.hidepagination = false;
    }

    if (this.paginationempty == true) {
      this.pagercount = [];
    }
    this.pagenolist = value.response.data.total_pages + 1
    for (let i = 1; i < this.pagenolist; i++) {
      this.pagercount.push(i);

    }
    this.totallength = this.pagercount;

    if (value.response.data.total_records == 0) {
      this.nocomments = false;

    } else {
      this.nocomments = true;
    }
    this.arraytableforcomments = value.response.data.pagging_list;


    this.arraytableforcomments.map((data: any) => {
      if (data.profile_image_path == null) {
        // ////console.log("i am in null");
        this.imageofcomment = this.profile_image_pathofcomments;
      } else {
        // ////console.log("i am not in null");
        this.imageofcomment =  data.profile_image_path;
      }
    })

    this.user_idcomment = value.response.data.user_id;
  }

  setimage(data: any) {
    if(data.artwork_path==null){
    if (data.profile_image_path == null) {
      // ////console.log("i am in null");
      return this.profile_image_pathofcomments;
    } else {
      if (data.profile_image_path.includes("/profile_photos/")) {
        return  CONSTNAME.fILE_PATH + data.profile_image_path;

        }else
      // ////console.log("i am not in null");
      return data.profile_image_path;
    }
  }else{
    return CONSTNAME.fILE_PATH + data.artwork_path;

  }
  }

  setimage1(data: any) {
    if (data.profile_image_path == null) {
      // ////console.log("i am in null");
      return this.profile_image_pathofcomments;
    } else {
      // ////console.log("i am not in null");
      if (data.profile_image_path.includes("/profile_photos/")) {
        return  CONSTNAME.fILE_PATH + data.profile_image_path;

        }else
      return  data.profile_image_path;
    }
  }

  paginationget() {
    this.paginationempty = true;

    // this.pageno = pageinfo;
    this.rowsize += this.rowsize
    // ////console.log(this.rowsize);


    this.getallinfoofcomments();



  }

  onPlayButtonClick() {
    this.waveform.play();
    this.hideplaybutton = true
    // ////console.log('kefpe');
    this.hidepausebutton = false;
  }
  onPauseButtonClick() {
    this.waveform.pause();
    this.hidepausebutton = true;
    this.hideplaybutton = false;
  }




  async heartcolorred() {
    // ////console.log("red");
    let sendingData = {
      "loop_id": this.looporloopkitid,
    }
    // ////console.log(sendingData);



    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      this.api.postApiHit(`/user/like_dislike_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          let v: any = document.getElementById("red");
          v.classList.add("red");

          this.getapihit();
          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();

          // ////console.log("gaya");
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }


        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }

  }
  async focus() {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    if (tokkenget) {
      this.redirecttolooppagecomment()
    } else {
      this.opendialogueforverification();
    }

  }
  redirecttolooppage(value: any) {


    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);


    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
    window.scrollTo(0,0)


  }
  // stopAudio(item: any) {
  //   ////console.log(item);
  //   if (item) {
  //     if (item.audio) {
  //       item.audio.pause()
  //       item.hidepausebutton=false
  //       // this.showAudioPlayer = false;s
  //     }
  //     clearInterval(this.id);

  //     this.tableData.map((r: any) => {
  //       r.hidepausebutton = false;
  //     })

  //   } else {
  //     this.tableData.map((r: any) => {
  //       if (r.audio) {
  //         r.audio.pause()
  //       }
  //       r.hidepausebutton = false;
  //     })

  //   }


  // }
  redirecttouser(id: any){
    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let encrypted = CryptoJS.AES.encrypt(
    //   JSON.stringify(id), _key, {
    //   keySize: 16,
    //   iv: _iv,
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7
    // });
    // this.encrypted = encrypted.toString();
    // this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate([`${id}`])
  }
  redirecttolooppagecomment() {
    let a: any = document.getElementById("commentinput");
    a.focus();
  }

  async repost() {

    let sendingData = {
      "loop_id": this.looporloopkitid,
    }
    // ////console.log(sendingData);
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {


      this.api.postApiHit(`/user/repost_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          let v: any = document.getElementById("green");
          v.classList.add("green");

          this.common.hideLoader();
          this.getapihit();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();

          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
          this.router.navigate(['/users/uploadloopstatus']);

        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();


    }
  }

  callfordummyimage() {
    this.imageofcomment = this.profile_image_pathofcomments;
  }
  callforimage(value: any) {
    this.imageofcomment = this.profile_image_pathofcomments;
    // ////console.log(value);
    // this.imageofcomment = CONSTNAME.fILE_PATH +this._sanitizer.bypassSecurityTrustUrl( value);
  }
  decryptUsingAES256() {
    // ////console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    // ////console.log(this.decrypted);
    this.decrypted = this.decrypted.replace(/[""]/gi, '');
    // ////console.log(this.tokenFromUI);


    if (this.urlstatusmessage == "success") {
      // ////console.log(this.urlstatusmessage,"yes api hit")
      // this.apihitforpaymentapi();
   
    } else {
      // ////console.log("no api hit")
    }

  }



  onRegionChange(value: any = null) {
    // ////console.log(value);
    this.render = true
  }
  onTrackLoaded(v: any = null) {
    // ////console.log("track");
    // ////console.log(v);

  }
  onTrackRendered(v: any = null) {
    // ////console.log("trackren");
    // ////console.log("gaya")
    this.common.hideLoader();
    this.completerender = true
    // ////console.log(v);
  }
  above15second = false;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  apihitforstream = true;
  audio_duration: any;
  play_duration: any;
  onDurationChange(value: any = null) {
    const sec = parseInt(value, 10);
    let hours: any = Math.floor(sec / 3600);
    let minutes: any = Math.floor((sec - (hours * 3600)) / 60);
    let seconds: any = sec - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    this.totaltime = minutes + ':' + seconds;


    if (value == 0) {

    } else {
      // ////console.log(this.totaltime)
      this.audio_duration = value;
      // ////console.log(this.audio_duration)
      // this.totaltime = (Math.round(value * 100) / 100).toFixed(2);
      // ////console.log(this.totaltime)
      // if (this.totaltime > 60) {
      //   this.totaltime = (this.totaltime / 60).toFixed(2)
      //   // ////console.log(this.totaltime)
      // }
      // 
    }
    // ////console.log(this.audio_duration);

    if (this.durationofaudio >= 15) {
      this.above15second = true;
      // ////console.log("above 15 sec");
    }
    else if (this.durationofaudio < 15 && value >= 5) {
      // ////console.log("between 5 and 15");
      this.above5secondlessthan15 = true;
    } else if (this.durationofaudio > 1 && value <= 5) {
      // ////console.log("between 1 and 5");
      this.above1secondlessthan5 = true;
    }
    // ////console.log(this.durationofaudio);
    // if (value >= 15) {
    //   this.above15second = true;
    //   // ////console.log("above 15 sec");
    // }
    // else if (value < 15 && value >= 5) {
    //   // ////console.log("between 5 and 15");
    //   this.above5secondlessthan15 = true;
    // } else if (value > 1 && value <= 5) {
    //   // ////console.log("between 1 and 5");
    //   this.above1secondlessthan5 = true;
    // }
  }



 
  onPaused(v: any = null) {
    // ////console.log("pause");
  }


  async apihitforstreaming(item: any, r: any, dura: any) {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = {
        Authorization: `Bearer ` + tokkenget,
        "My-Custom-Header": "foobar",
      };
    }

    let sendingdata = {
      audio_duration: dura,
      loop_id: item.loop_id,
      play_duration: r,
    };
    this.http
      .post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers })
      .subscribe(
        async (res: any) => {
          if (res.response.status == 1) {
            // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          } else {
            let errorthroughapi = res.response.errors;
            let error = [];
            // this.cancel();
            this.common.hideLoader();
            for (var i = 0; i < errorthroughapi.length; i++) {
              this.toastr.error(res.response.errors[i], "", {
                positionClass: "toast-bottom-right",
              });
            }
          }
        },
        async () => {
          this.common.hideLoader();
          this.toastr.error("Error in Api", "", {
            positionClass: "toast-bottom-right",
          });
        }
      );
  }



  nFormatter(num: any) {
    if (num >= 1000000000) {
      this.stream = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';

    }
    else if (num >= 1000000) {
      this.stream = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';

    }
    else if (num >= 1000) {
      this.stream = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';

    } else {
      this.stream = num;
    }


  }



  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "detailpage" } });
    dialogue.afterClosed().subscribe(result => {

      // return new Promise(resolve => {
      //   if (num) {

      //     resolve((Math.round(num * 100) / 100).toFixed(2))
      //   }
      // })
      // ////console.log('The dialog was closed');
      // ////console.log(result);

      if (result.data == true) {
        this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
        this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
        // this.router.navigate(['/users/uploadloopstatus']);
      } else {

      }

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }

  redirecttoprofilepage() {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.user_idget), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(item.user_id);

    this.router.navigate(['user/sounds'], { queryParams: { user_id: this.encrypted } });

  }

  redirecttoprofilepagefromcomment(item: any) {
    // ////console.log()
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(item.user_id);

    this.router.navigate(['user/sounds'], { queryParams: { user_id: this.encrypted } });
  }


  sendingstrtag1: any
  sendingstrtag2: any
  sendingstrtag3: any

  redirectto1() {

    this.sendingstrtag1 = this.tablefortagsdisplay1;
    // ////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag1 } });
    // ////console.log(this.sendingstrtag1)

  }
  redirectto(item: any) {
    // ////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: item } });
    // ////console.log(item)
    
  }
  redirectto2() {

    this.sendingstrtag2 = this.tablefortagsdisplay2;
    // ////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag2 } });
    // ////console.log(item)
    // ////console.log(this.sendingstrtag2)

  }
  redirectto3() {

    this.sendingstrtag3 = this.tablefortagsdisplay3;
    // ////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: this.sendingstrtag3 } });
    // ////console.log(item)
    // ////console.log(this.sendingstrtag3)

  }

  openmodal(item: any) {
    // ////console.log(item)
    let idfromloop: any = item.user_id
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
      }
      let resa = JSON.parse(res)
      if (resa) {
        let idfromwebsite: any = resa.user_type_id
        // ////console.log(idfromwebsite, idfromloop)

        if (idfromloop === idfromwebsite) {
          // ////console.log("gaya")
          this.downloadloop(item)
          // this.apihitelsepart(item);
        } else {
          this.apihitelsepart(item);

        }
      };
    })
  }




  getUserDetail() {
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptplandetail(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }


  apihitelsepart(item: any) {
    // ////console.log(item)
    this.common.showLoader();
    let sendingdata = {
      "loop_id": item.loop_id
    }
    this.api.postApiHit(`/user/get_upload_detail`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {

        if (res.response.data.loop_details[0].is_already_downloaded == 0 && res.response.data.loop_details[0].is_already_paid == 0) {

          if (res.response.data.loop_details[0].selling_preference == 1) {
            this.download("free", item);
            this.common.hideLoader();
            // ////console.log("free")
          } else {
            if (res.response.data.loop_details[0].loop_price == 0) {
              this.download("free", item);
              this.common.hideLoader();
              // ////console.log("free")
            } else {
              this.download("premium", item);
              this.common.hideLoader();
              // ////console.log("premium")
            }


          }
        } else if (res.response.data.loop_details[0].is_already_downloaded == 0 && res.response.data.loop_details[0].is_already_paid == 1) {
          this.common.hideLoader();
          // ////console.log("yes");
        } else if (res.response.data.loop_details[0].is_already_downloaded == 1 && res.response.data.loop_details[0].is_already_paid == 1) {

          this.common.hideLoader();
          this.downloadloop(item)
        } else if (res.response.data.loop_details[0].is_already_downloaded == 1 && res.response.data.loop_details[0].is_already_paid == 0) {
          this.downloadloop(item)
        }

      } else if (res.response.status == 2) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }


  downloadlooppaid(res: any, item: any) {
    // ////console.log(res,item)
    if (res) {
      // this.encrypted = btoa(item.loop_id)
      this.encrypted = btoa(item)
      this.encrypted2 = btoa(res)
      this.router.navigate(['/invoice'], { queryParams: { loop_id: this.encrypted, payment_id: this.encrypted2, type: 2 } });
    } else {
      // ////console.log("else part")
    }
    this.common.hideLoader();
    window.location.href = `${APIURL.BASE_URL}/user/download_loop?loop_id=${item}`;

  }
  downloadloop(res: any) {





    this.common.hideLoader();
    window.location.href = `${APIURL.BASE_URL}/user/download_loop?loop_id=${res.loop_id}`;

  }


  download(value: any, item: any ): void {
    // ////console.log(item,"yes")
    let dialogue = this.matDialog.open(DownloadmodalComponent, { panelClass: 'forgot-password', disableClose: true, data: { pagevalue: value, allitems: item , url: this.urlpass} });
    dialogue.afterClosed().subscribe(result => {

      if (result.data == "downloadpre") {
        // this.downloadlooppaid(result.data2, item);
        // ////console.log("download")
      } else if (result.data == "downloadfree") {
        // ////console.log("gaya")
        this.downloadloop(item);
      } else {

      }

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }

  apihitforpaymentapi() {
    // ////console.log(this.decrypted)
    this.common.showLoader();
    // this.btndis = true;
    let sendingdata = {
      "loop_id": this.decrypted,
      // "success_referrer": this.urlfromback,
      // "cancel_referrer": this.cancelurl
    }


    this.api.postApiHit(`/user/verify_stripe_payment`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        
        // ////console.log(res.response.data)

        this.downloadlooppaid(res.response.data, this.decrypted);
        
        // ////console.log(res.response.msg)
        // this.matDialog.closeAll()
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        // this.dialogRef.close({ data: "downloadpre", data2: res.response.data });


        this.common.hideLoader();

      }
      else if (res.response.status == 2) {
        let errorthroughapi = res.response.errors;
        let error = [];

        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        }


      }
      else if (res.response.status == 3) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        // ////console.log("in going")
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          // this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        this.common.clearAllLocalAndSession();
        // localStorage.removeItem("clientintoken");
        this.router.navigate(['/login']);


      }
      else if (res.response.status == 4) {
        let errorthroughapi = res.response.errors;
        let error = [];

        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        }


      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }






}
