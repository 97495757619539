import { Injectable } from "@angular/core";
// import {INPUT_TYPE_NAME, USER_DETAILS, USER_ROLE, VALIDATION_MSG, VARS} from './constants.service';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { INPUT_TYPE_NAME, VALIDATION_MSG, VARS } from "../allServices/constant.service";

// // import * as moment from 'moment';
// // import * as momentTz from 'moment-timezone';
// // import {EncrDecrService} from './encr-decr.service';

@Injectable({
  providedIn: "root",
})
export class StaticService {
  constructor() {}
  // static getRegisterForm() {
  //   return new FormGroup({
  //       // imagevalid: ["", [Validators.required]],
  //       tickbox: ('', [Validators.requiredTrue]),
  //       Firstname: ["", [Validators.required]],
  //       Lastname: ["", [Validators.required]],
  //       username: ["", Validators.required],
  //       emailerr: ["", [Validators.required, Validators.email]],
  //       password: ["", [Validators.minLength(6), Validators.required]],
  //       confirmpassword: ["", [Validators.minLength(6), Validators.required]],
  //       country: ["", [Validators.required]],
  //       state: ["", [Validators.required]],
  //       city: ["", Validators.required],
  //       zip: ["", [Validators.required, Validators.pattern(no)]],
  //       instagram: ["", [Validators.pattern(reg)]],
  //       twitter: ["", [Validators.pattern(reg)]],
  //       youtube: ["", [Validators.pattern(reg)]],
  //       beatstars: ["", [Validators.pattern(reg)]],
  //       tiktok: ["", [Validators.pattern(reg)]],
  //       twitch: ["", [Validators.pattern(reg)]],
  //     }),
  // }

  static getRegisterForm() {
    const reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
    const no = "^[0-9]{1,555}$";
    const password = "^(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$";
    return new FormGroup({
      first_name: new FormControl("", [Validators.required]),
      last_name: new FormControl("", [Validators.required]),
      username: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.pattern(VARS.EMAIL_PATTERN)]),
      password: new FormControl("", [Validators.minLength(6), Validators.required, Validators.pattern(password)]),
      confirm_new_password: new FormControl("", [Validators.minLength(6), Validators.required]),
      country: new FormControl("", [Validators.required]),
      state: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      zip: new FormControl("", [Validators.required, Validators.pattern(no)]),
      instagram_link: new FormControl(null, [Validators.pattern(reg)]),
      twitter_link: new FormControl(null, [Validators.pattern(reg)]),
      youtube_link: new FormControl(null, [Validators.pattern(reg)]),
      beatstars_link: new FormControl(null, [Validators.pattern(reg)]),
      tiktok_link: new FormControl(null, [Validators.pattern(reg)]),
      twitch_link: new FormControl(null, [Validators.pattern(reg)]),
      terms_policy_checkbox: new FormControl("", [Validators.requiredTrue]),
    });
  }

  static getLoginForm() {
    const password = "^(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$";
    return new FormGroup({
      username_email: new FormControl("", [ Validators.required]),
      password: new FormControl("", [Validators.minLength(6), Validators.required, Validators.pattern(password)]),
      remember: new FormControl(false, []),
    });
  }
  static contactForm() {
    return new FormGroup({
      contact_name: new FormControl("", [Validators.required]),
      contact_email: new FormControl("", [Validators.required, Validators.pattern(VARS.EMAIL_PATTERN)]),
      contact_message: new FormControl("", [Validators.required]),
    });
  }

  static getLoginFormAdmin() {
    return new FormGroup({
      email: new FormControl("", [Validators.required, Validators.pattern(VARS.EMAIL_PATTERN)]),
      password: new FormControl("", [Validators.required, Validators.minLength(6)]),
    });
  }

  static forgotpasswordClient() {
    return new FormGroup({
      email: new FormControl("", [Validators.required, Validators.pattern(VARS.EMAIL_PATTERN)]),
    });
  }

  static couponcodeadd() {
    const couponvalidation = "^[1-9][0-9]?$|^100$";
    return new FormGroup({
      couponcode: new FormControl("", [Validators.required]),
      couponstartdate: new FormControl("", [Validators.required]),
      couponendenddate: new FormControl("", [Validators.required]),
      couponenddiscountamount: new FormControl("", [Validators.required, Validators.pattern(couponvalidation)]),
      couponenradio: new FormControl(true, [Validators.required]),
      createddate: new FormControl({ value: "", disabled: true }, []),
      modifieddate: new FormControl({ value: "", disabled: true }, []),
    });
  }

  static uploadloopinuser() {
    // const bpmvalidation = '^(0|1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20|21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|36|37|38|39|40|41|42|43|44|45|46|47|48|49|50|51|52|53|54|55|56|57|58|59|60|61|62|63|64|65|66|67|68|69|70|71|72|73|74|75|76|77|78|79|80|81|82|83|84|85|86|87|88|89|90|91|92|93|94|95|96|97|98|99|100|101|102|103|104|105|106|107|108|109|110|111|112|113|114|115|116|117|118|119|120|121|122|123|124|125|126|127|128|129|130|131|132|133|134|135|136|137|138|139|140|141|142|143|144|145|146|147|148|149|150|151|152|153|154|155|156|157|158|159|160|161|162|163|164|165|166|167|168|169|170|171|172|173|174|175|176|177|178|179|180|181|182|183|184|185|186|187|188|189|190|191|192|193|194|195|196|197|198|199|200)$'
    // const bpmvalidation = '^([1-9]|[01][0-9][0-9]|20[0-0])$';
    // const bpmvalidation = '(100)|[1-9]\d?';
    // const bpmvalidation = '^(0?[1-9]|[1-9][0-9]|[1][1-9][1-9]|200)$';
    // const bpmvalidation = '^[1-9][0-9]?$|^200$';
    const patternfortitle = "^[0-9a-zA-Z\b ]+$";
    return new FormGroup({
      // uploadtagversion: new FormControl("", []),
      uploadlooptitle: new FormControl("", [Validators.required, Validators.maxLength(50), Validators.pattern(patternfortitle)]),
      uploadloopbpm: new FormControl("", [Validators.required]),
      instrumentcategory: new FormControl("", [Validators.required]),
      instruments: new FormControl("", []),
      genre: new FormControl("", [Validators.required]),
      subgenre: new FormControl("", []),
      tickbox: new FormControl("", [Validators.requiredTrue]),
      key: new FormControl("1", [Validators.required]),
      keynotes: new FormControl("A", [Validators.required]),
      sellingpreference: new FormControl("", [Validators.required]),
      // tag: new FormControl("", [Validators.required]),
      selling_preference_type: new FormControl("", [Validators.required]),
    });
  }
  static advancesearch() {
    const patternfortitle = "^[0-9a-zA-Z\b ]+$";
    return new FormGroup({
      keyword: new FormControl("", []),
      // uploadlooptitle: new FormControl("",  [Validators.required,Validators.maxLength(50),Validators.pattern(patternfortitle) ]),
      uploadloopbpm: new FormControl("", []),
      instrumentcategory: new FormControl("", []),
      instruments: new FormControl("", []),
      genre: new FormControl("", []),
      subgenre: new FormControl("", []),
      sortof: new FormControl("", []),
      sub_key: new FormControl("", []),
      main_key: new FormControl("", []),
      keynotes: new FormControl("", []),
      sellingpreference: new FormControl("", []),
      exact_bpm: new FormControl("", []),
      bpm: new FormControl("", []),
      max_bpm: new FormControl("", []),
      min_bpm: new FormControl("", []),
      // selling_preference_type: new FormControl("",[]),
    });
  }
  static uploadloopkituser() {
    // const patternfortitle = "^[a-zA-Z0-9-]+$";
    const patternfortitle = "^[0-9a-zA-Z\b ]+$";
    // const no = /^\+?(0|[1-9]\d*)$/;
    // const no = /^[+]?([0-9]|[1-9][0-9]|100)*\.?[0-9]+$/;
    // const no = /^[0-9A-Za-z!@.,;:'"?-]{1,100}\z/;
    // const patternfor = "^[0-9a-zA-Z\b ]+$";
    return new FormGroup({
      descriptionbox: new FormControl("", [Validators.maxLength(1000)]),
      uploadlooptitle: new FormControl("", [Validators.required, Validators.maxLength(50), Validators.pattern(patternfortitle)]),
      genre: new FormControl("", [Validators.required]),
      subgenre: new FormControl("", []),
      tickbox: new FormControl("", [Validators.requiredTrue]),
      uploadloopno: new FormControl("", [Validators.required]),
      // keynotes: new FormControl("", []),
      sellingpreference: new FormControl("", [Validators.required]),
      selling_preference_type: new FormControl("", [Validators.required]),
    });
  }
  static reportformforuser() {
    return new FormGroup({
      checkboxreport: new FormControl("", [Validators.required]),
    });
  }
  static bannnedwordsadmin() {
    return new FormGroup({
      loop_name: new FormControl("", [Validators.required]),
      makePackPrivate: new FormControl("", [Validators.required]),
    });
  }
  static uploadLoop() {
    return new FormGroup({
      bannedwords: new FormControl("", [Validators.required]),
      createddate: new FormControl({ value: "", disabled: true }, []),
      modifieddate: new FormControl({ value: "", disabled: true }, []),
    });
  }
  static commentformforuser() {
    return new FormGroup({
      comment: new FormControl("", [Validators.required]),
    });
  }

  static formError(formControl: any, fieldName: any, type: INPUT_TYPE_NAME = "OTHER", opts: any = null) {
    const options: any = {};
    options.isRequired = !!(opts && opts.isRequired);
    options.isNumberOnly = !!(opts && opts.isNumberOnly);
    options.equal = opts && opts.equal ? opts.equal : null;
    options.min = opts && opts.min ? opts.min : 0;
    options.max = opts && opts.max ? opts.max : 1;
    options.minLength = opts && opts.minLength ? opts.minLength : 0;
    options.maxLength = opts && opts.maxLength ? opts.maxLength : 1;
    options.passwordEqualField = opts && opts.passwordEqualField ? opts.passwordEqualField : "password";
    const validationResponse: any = { msg: null, data: [] };
    if (formControl[fieldName]?.touched) {
      // ////console.log(formControl[fieldName]);
      
      switch (type) {
        case "_EMAIL": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_EMAIL_REQUIRED;
          }
          if (formControl[fieldName].hasError("email")) {
            validationResponse.msg = VALIDATION_MSG.ERR_EMAIL_PATTERN;
          }
          break;
        }
        case "_uploadloopkitloopno": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_LOOPKIT_NO_LOOP;
          }
          if (formControl[fieldName].hasError("pattern")) {
            validationResponse.msg = VALIDATION_MSG.ERR_LOOPKIT_NO_LOOP_pattern;
          }
          break;
        }
        case "_PASSWORD": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_PASSWORD_REQUIRED;
          }
          if (formControl[fieldName].hasError('minlength')) {
            validationResponse.msg = VALIDATION_MSG.ERR_PASS_MIN_LENGTH;
          }
          if (formControl[fieldName].hasError('pattern')) {
            validationResponse.msg = VALIDATION_MSG.ERR_PASS_PATTERN;
          }
          break;
        }
        case "_INPUT": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
          }
          break;
        }
        case "_COUPON": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_COUPON_CODE_NAME;
          }
          break;
        }
        case "_uploadtitle": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_UPLOAD_TITLE;
          }
          break;
        }
        case "_STARTDATECOUPON": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_COUPON_START_DATE;
          }
          break;
        }
        case "_ENDDATECOUPON": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_COUPON_END_DATE;
          }
          break;
        }
        case "_SELECT": {
          if (formControl[fieldName].hasError("required")) {
            validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_SELECT;
          }
          if (formControl[fieldName].hasError("cannotSelectMultiple")) {
            validationResponse.msg = VALIDATION_MSG.ERR_MULTIPLE_SELECT;
          }
          break;
        }
        case "_EQUAL": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
          }
          if (
            formControl[fieldName].hasError("equalTo") ||
            formControl[options.passwordEqualField].value !== formControl[fieldName].value
          ) {
            validationResponse.msg = VALIDATION_MSG.ERR_FIELD_NOT_MATCH;
          }
          break;
        }
        case "_MINLENGTH_MAXLENGTH_SAME": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
          }
          if (formControl[fieldName].hasError("minlength") || formControl[fieldName].hasError("maxlength")) {
            validationResponse.msg =
              options && options.minLength && options.maxLength
                ? "*Enter " + options.minLength + " digit only."
                : VALIDATION_MSG.ERR_LENGTH_NOT_MATCH;
          }
          break;
        }
        case "_PATTERN_NUM_MIN_MAX": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
          }
          if (formControl[fieldName].hasError("pattern")) {
            validationResponse.msg = VALIDATION_MSG.ERR_NUMERIC_ONLY;
          }
          if (formControl[fieldName].hasError("min")) {
            validationResponse.msg = VALIDATION_MSG.ERR_GREATER_OR_EQUAL + options.min;
          }
          if (formControl[fieldName].hasError("max")) {
            validationResponse.msg = VALIDATION_MSG.ERR_LESS_OR_EQUAL + options.max;
          }
          break;
        }
        case "_MAX_CHAR": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
          }
          if (formControl[fieldName].hasError("maxlength")) {
            validationResponse.msg = " Max " + options.maxLength + " Character allowed!";
          }
          break;
        }
        case "_MIN_CHAR": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
          }
          if (formControl[fieldName].hasError("minlength")) {
            validationResponse.msg = "Min " + options.minLength + " Character required!";
          }
          break;
        }
        case "PASSWORD": {
          // not required
          if (formControl[fieldName].hasError("minlength")) {
            validationResponse.msg = VALIDATION_MSG.ERR_PASS_MIN_LENGTH;
          }
          break;
        }
        case "MINLENGTH_MAXLENGTH_SAME": {
          // not required
          if (formControl[fieldName].hasError("minlength") || formControl[fieldName].hasError("maxlength")) {
            validationResponse.msg =
              options && options.minLength && options.maxLength
                ? "*Enter " + options.minLength + "digit only."
                : VALIDATION_MSG.ERR_LENGTH_NOT_MATCH;
          }
          break;
        }
        case "WEBLINK": {
          // not required
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
          }
          if (formControl[fieldName].hasError("pattern")) {
            validationResponse.msg = VALIDATION_MSG.ERR_WEB_PATTERN;
          }
          break;
        }
        case "AGRREE_POLICY": {
          // not required
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = `Before creating your account, you must
            agree with our <b style="text-decoration: underline;"><a href="termsandcondition" > Terms
                and Policy</a></b>`;
          }
          break;
        }
        case "_COUPONPATTERN": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_COUPON_DISCOUNT_PERCENTAGE;
          }
          if (formControl[fieldName].hasError("pattern")) {
            validationResponse.msg = VALIDATION_MSG.ERR_COUPON_DISCOUNT_PERCENTAGE_PATTERN;
          }
          break;
        }
        case "_uploadloopbpm": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_UPLOAD_BPM;
          }
          if (formControl[fieldName].hasError("pattern")) {
            validationResponse.msg = VALIDATION_MSG.ERR_UPLOAD_BPM_PATTERN;
          }
          break;
        }
        case "_descriptionbox": {
          if (formControl[fieldName].hasError("pattern")) {
            validationResponse.msg = VALIDATION_MSG.ERR_DESCRIPTION_PATTERN;
          }
          break;
        }
        case "_loppkittitleforloop": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_UPLOAD_TITLE;
          }

          if (formControl[fieldName].hasError("maxlength")) {
            validationResponse.msg = VALIDATION_MSG.ERR_DESCRIPTION_Max_LoopKit;
          }
          if (formControl[fieldName].hasError("pattern")) {
            validationResponse.msg = VALIDATION_MSG.ERR_DESCRIPTION_Pattern_LoopKit;
          }
          break;
        }
        case "_loppkittitle": {
          if (formControl[fieldName].hasError("required") || options.isRequired ? !String(formControl[fieldName].value).trim() : false) {
            validationResponse.msg = VALIDATION_MSG.ERR_UPLOAD_TITLE;
          }
          if (formControl[fieldName].hasError("minlength")) {
            validationResponse.msg = VALIDATION_MSG.ERR_DESCRIPTION_Min_LoopKit;
          }

          if (formControl[fieldName].hasError("maxlength")) {
            validationResponse.msg = VALIDATION_MSG.ERR_DESCRIPTION_Max_LoopKit;
          }
          if (formControl[fieldName].hasError("pattern")) {
            validationResponse.msg = VALIDATION_MSG.ERR_DESCRIPTION_Pattern_LoopKit;
          }
          break;
        }
        case "OTHER": {
          break;
        }
        default: {
          break;
        }
      }
      if (options.isRequired && options.isNumberOnly && formControl[fieldName].value && isNaN(parseInt(formControl[fieldName].value))) {
        validationResponse.msg = VALIDATION_MSG.ERR_DIGIT_ONLY;
      }
    }
    return validationResponse;
  }

  static markFormGroupTouched(form: any, invalidElements: any = [], focusIndex = 1) {
    try {
      form.markAllAsTouched();
    } catch (e) {}
    if (invalidElements && invalidElements.length > focusIndex) {
      try {
        invalidElements[focusIndex].setFocus();
      } catch (e) {}
    }
  }
}

//   clicked = false;
//   // static getCDT(myFormat = null, myDate = null, tzz: any = VARS.APP_TIMEZONE) {
//   //   if (myFormat) {
//   //     if (myDate) {
//   //       return momentTz.tz(myDate, tzz).format(myFormat);
//   //     }
//   //     return momentTz.tz(tzz).format(myFormat);
//   //   } else {
//   //     if (myDate) {
//   //       return momentTz.tz(myDate, tzz);
//   //     }
//   //     return momentTz.tz(tzz);
//   //   }
//   //   // return format ? momentTz.tz(date ? date : moment().toDate(), tzz).format(format) : momentTz.tz(date ? date : moment().toDate(), tzz);
//   // }
//   // static snapshotToArray = snapshot => {
//   //   const returnArr = [];
//   //   const item = snapshot.val();
//   //   if (item) {
//   //     _.forEach(item, childSnapshot => {
//   //       returnArr.push(childSnapshot);
//   //     });
//   //   }
//   //   return returnArr;
//   // }

//    formError(formControl = {}, fieldName = '', type = 'OTHER', opts: any = null) {
//     const options: any = {};
//     options.isRequired = !!(opts && opts.isRequired);
//     options.isNumberOnly = !!(opts && opts.isNumberOnly);
//     options.equal = (opts && opts.equal) ? opts.equal : null;
//     options.min = (opts && opts.min) ? opts.min : 0;
//     options.max = (opts && opts.max) ? opts.max : 1;
//     options.minLength = (opts && opts.minLength) ? opts.minLength : 0;
//     options.maxLength = (opts && opts.maxLength) ? opts.maxLength : 1;
//     options.passwordEqualField = (opts && opts.passwordEqualField) ? opts.passwordEqualField : 'password';
//     const validationResponse = {msg: '', data: []};
//     if (formControl[fieldName].touched) {
//       switch (type) {
//         // case '_EMAIL': {
//         //   if (formControl[fieldName].hasError('required') || (options.isRequired) ? formControl[fieldName].value.trim() : false) {
//         //     validationResponse.msg = 'VALIDATION_MSG.ERR_REQUIRED_FIELD';
//         //   }
//         //   if (formControl[fieldName].hasError('pattern')) {
//         //     validationResponse.msg = 'VALIDATION_MSG.ERR_EMAIL_PATTERN';
//         //   }
//         //   break;
//         // }
//         // case '_PASSWORD': {
//         //   if (formControl[fieldName].hasError('required') || (options.isRequired) ? !String(formControl[fieldName].value).trim() : false) {
//         //     validationResponse.msg = 'VALIDATION_MSG.ERR_REQUIRED_FIELD';
//         //   }
//         //   if (formControl[fieldName].hasError('minlength')) {
//         //     validationResponse.msg = 'VALIDATION_MSG.ERR_PASS_MIN_LENGTH';
//         //   }
//         //   break;
//         // }
//         // case '_INPUT': {
//         //   if (formControl[fieldName].hasError('required') || (options.isRequired) ? !String(formControl[fieldName].value).trim() : false) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
//         //   }
//         //   break;
//         // }
//         // case '_SELECT': {
//         //   if (formControl[fieldName].hasError('required')) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_SELECT;
//         //   }
//         //   if (formControl[fieldName].hasError('cannotSelectMultiple')) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_MULTIPLE_SELECT;
//         //   }
//         //   break;
//         // }
//         case '_EQUAL': {
//           if (formControl[fieldName].hasError('required') || (options.isRequired) ? !String(formControl[fieldName].value).trim() : false) {
//             validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
//           }
//           if (formControl[fieldName].hasError('equalTo') || formControl[options.passwordEqualField].value !== formControl[fieldName].value) {
//             validationResponse.msg = VALIDATION_MSG.ERR_FIELD_NOT_MATCH;
//           }
//           break;
//         }
//         // case '_MINLENGTH_MAXLENGTH_SAME': {
//         //   if (formControl[fieldName].hasError('required') || (options.isRequired) ? !String(formControl[fieldName].value).trim() : false) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
//         //   }
//         //   if (formControl[fieldName].hasError('minlength') || formControl[fieldName].hasError('maxlength')) {
//         //     validationResponse.msg = (options && options.minLength && options.maxLength) ? '*Enter ' + options.minLength + ' digit only.' : VALIDATION_MSG.ERR_LENGTH_NOT_MATCH;
//         //   }
//         //   break;
//         // }
//         // case '_PATTERN_NUM_MIN_MAX': {
//         //   if (formControl[fieldName].hasError('required') || (options.isRequired) ? !String(formControl[fieldName].value).trim() : false) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
//         //   }
//         //   if (formControl[fieldName].hasError('pattern')) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_NUMERIC_ONLY;
//         //   }
//         //   if (formControl[fieldName].hasError('min')) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_GREATER_OR_EQUAL + options.min;
//         //   }
//         //   if (formControl[fieldName].hasError('max')) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_LESS_OR_EQUAL + options.max;
//         //   }
//         //   break;
//         // }
//         // case '_MAX_CHAR': {
//         //   if (formControl[fieldName].hasError('required') || (options.isRequired) ? !String(formControl[fieldName].value).trim() : false) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
//         //   }
//         //   if (formControl[fieldName].hasError('maxlength')) {
//         //     validationResponse.msg = 'Max ' + options.maxLength + 'Character allowed!';
//         //   }
//         //   break;
//         // }
//         // case '_MIN_CHAR': {
//         //   if (formControl[fieldName].hasError('required') || (options.isRequired) ? !String(formControl[fieldName].value).trim() : false) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_REQUIRED_FIELD;
//         //   }
//         //   if (formControl[fieldName].hasError('minlength')) {
//         //     validationResponse.msg = 'Min ' + options.minLength + 'Character required!';
//         //   }
//         //   break;
//         // }
//         // case 'PASSWORD': { // not required
//         //   if (formControl[fieldName].hasError('minlength')) {
//         //     validationResponse.msg = VALIDATION_MSG.ERR_PASS_MIN_LENGTH;
//         //   }
//         //   break;
//         // }
//         // case 'MINLENGTH_MAXLENGTH_SAME': { // not required
//         //   if (formControl[fieldName].hasError('minlength') || formControl[fieldName].hasError('maxlength')) {
//         //     validationResponse.msg = (options && options.minLength && options.maxLength) ? '*Enter ' + options.minLength + 'digit only.' : VALIDATION_MSG.ERR_LENGTH_NOT_MATCH;
//         //   }
//         //   break;
//         // }
//         case 'OTHER': {
//           break;
//         }
//         default: {
//           break;
//         }
//       }
//       if (options.isRequired && options.isNumberOnly && formControl[fieldName].value && formControl[fieldName].value) {
//         validationResponse.msg =' VALIDATION_MSG.ERR_DIGIT_ONLY';
//       }
//     }
//     return validationResponse;
//   }

//   // static formErrorMultipleControls(formControlList, fieldNameList: string[], errorString: string) {
//   //   //  Checks multiple controls and if any ONE has a valid status then it DOES NOT return a...
//   //   let validationResponse = {msg: null, data: []};
//   //   // Will return true using find() on a list, if any of the fieldnames is true within that list
//   //   const checkTouched = (fieldName) =>  formControlList[fieldName].touched == true;
//   //   //  Will check validity of formcontrol using find() on a list, if any of the fieldnames has error within that list, it will return true
//   //   const checkStatus = (fieldName) =>  formControlList[fieldName].status == "VALID" ? true : false;
//   //   if (fieldNameList.find(checkTouched)) !fieldNameList.find(checkStatus)?validationResponse.msg = errorString:'';
//   //   return validationResponse;
//   // }

//   // static markFormGroupTouched(form, invalidElements = [], focusIndex = 1) {
//   //   try {
//   //     form.markAllAsTouched();
//   //   } catch (e) {
//   //   }
//   //   if (invalidElements && invalidElements.length > focusIndex) {
//   //     try {
//   //       invalidElements[focusIndex].setFocus();
//   //     } catch (e) {
//   //     }
//   //   }
//   // }

//   // static myCustomValidator(fieldNameOrVal, valType = 'equalTo'): ValidatorFn {
//   //   return (control: AbstractControl): { [key: string]: any } => {
//   //     const input = control.value;
//   //     const isValid = (valType === 'equalTo') ? (control.root.value[fieldNameOrVal] === input) : (valType === 'greaterThan') ? (input > fieldNameOrVal) : (valType === 'lessThan') ? (input < fieldNameOrVal) : false;
//   //     if (!isValid) {
//   //       return {[valType]: {isValid}};
//   //     } else {
//   //       return null;
//   //     }
//   //   };
//   // }

//   // static getLoginForm() {
//   //   return new FormGroup({
//   //     email: new FormControl('', [Validators.required, Validators.pattern(VARS.EMAIL_PATTERN)]),
//   //     password: new FormControl('', [Validators.required])
//   //   });
//   // }

//   // static getForgotPasswordForm() {
//   //   return new FormGroup({
//   //     email: new FormControl('', [Validators.required, Validators.pattern(VARS.EMAIL_PATTERN)]),
//   //     user_type: new FormControl('', Validators.required)
//   //   });
//   // }

//   // static resetPasswordForm() {
//   //   return new FormGroup({
//   //     vCode: new FormControl('', [Validators.required, Validators.minLength(VARS.V_CODE_LENGTH), Validators.maxLength(VARS.V_CODE_LENGTH)]),
//   //     email: new FormControl('', [Validators.required, Validators.pattern(VARS.EMAIL_PATTERN)]),
//   //     password: new FormControl('', [Validators.required, Validators.minLength(VARS.MIN_PASS_LENGTH)]),
//   //     confirm_password: new FormControl('', [Validators.required, StaticService.myCustomValidator('password')]),
//   //   });
//   // }

//   // static getThisInputForm() {
//   //   return new FormGroup({
//   //     this_input: new FormControl('', [Validators.required]),
//   //   });
//   // }

//   // static getThisInputExtraForm() {
//   //   return new FormGroup({
//   //     this_input: new FormControl('', [Validators.required]),
//   //     input_extra: new FormControl(''),
//   //   });
//   // }

//   // static addNewFormControl(form, key, value) {
//   //   form.addControl(key, new FormControl(value));
//   // }

//   // static getEveTrigger(e, type) {
//   //   const response: any = {};
//   //   if (e) {
//   //     if (type === 'sort') {
//   //       response.sortColumn = e.cCurrentSort.sort_column;
//   //       response.sortBy = e.cCurrentSort.sort_by;
//   //     } else if (type === 'filter') {
//   //       response.filterByStatus = e.cCurrentFilter;
//   //     } else if (type === 'search') {
//   //       response.searchText = e.searchText;
//   //     }
//   //   }
//   //   return response;
//   // }

//   // static setFormVal(formC, key, value) {
//   //   try {
//   //     formC[key].setValue(value);
//   //     formC[key].markAsTouched();
//   //   } catch (e) {
//   //   }
//   // }

//   // static getFilterList(list, terms, searchTerm) {
//   //   if (String(searchTerm).length) {
//   //     return list.filter(item => {
//   //       const tempStr = [];
//   //       _.forEach(terms, v => {
//   //         tempStr.push(item[v]);
//   //       });
//   //       const searchedText = _.join(tempStr, ' ');
//   //       return searchedText.toLowerCase().includes(searchTerm.toLowerCase());
//   //     });
//   //   } else {
//   //     return list;
//   //   }
//   // }

//   // static makeFilterArr(list, toNumber = false) {
//   //   const tempArr = [];
//   //   _.forEach(list, (val, key) => {
//   //     val = (toNumber) ? _.toNumber(val) : val;
//   //     tempArr.push({title: key, val});
//   //   });
//   //   return tempArr;
//   // }

//   // static toDateTime(inputDate) {
//   //   if (inputDate) {
//   //     try {
//   //       const sD = inputDate.split('/')[0];
//   //       const sM = inputDate.split('/')[1];
//   //       const sY = inputDate.split('/')[2];
//   //       return sY + '/' + sM + '/' + sD;
//   //     } catch (e) {
//   //     }
//   //   } else {
//   //     return undefined;
//   //   }
//   // }

//   // static setUserDetails(userDetails) {
//   //   USER_DETAILS.DATA = userDetails;
//   // }

//   // static registerRentee() {
//   //   return new FormGroup({
//   //     first_name: new FormControl('', [Validators.required]),
//   //     last_name: new FormControl('', [Validators.required]),
//   //     email: new FormControl('', [Validators.required, Validators.pattern(VARS.EMAIL_PATTERN)]),
//   //     password: new FormControl('', [Validators.required, Validators.minLength(8)]),
//   //     confirm_password: new FormControl('', [Validators.required]),
//   //     is_enabled: new FormControl(''),
//   //     profile_image: new FormControl(''),
//   //     address: new FormControl('', [Validators.required]),
//   //     city: new FormControl('', [Validators.required]),
//   //     state: new FormControl('', [Validators.required]),
//   //     pincode: new FormControl('', [Validators.required, Validators.minLength(VARS.V_CODE_LENGTH), Validators.maxLength(VARS.V_CODE_LENGTH), Validators.pattern('^[0-9]*$')]),
//   //     phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]),
//   //     file_check: new FormControl(''),
//   //   });
//   // }

//   // static renterUpdateDetail() {
//   //   return new FormGroup({
//   //     first_name: new FormControl('', [Validators.required]),
//   //     last_name: new FormControl('', [Validators.required]),
//   //     email: new FormControl('', [Validators.required, Validators.pattern(VARS.EMAIL_PATTERN)]),
//   //     password: new FormControl(''),
//   //     is_enabled: new FormControl(''),
//   //     profile_image: new FormControl(''),
//   //     address: new FormControl('', [Validators.required]),
//   //     city: new FormControl('', [Validators.required]),
//   //     state: new FormControl('', [Validators.required]),
//   //     pincode: new FormControl('', [Validators.required, Validators.minLength(VARS.V_CODE_LENGTH), Validators.maxLength(VARS.V_CODE_LENGTH), Validators.pattern('^[0-9]*$')]),
//   //     phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]),
//   //     file_check: new FormControl(''),
//   //   });
//   // }

//   // static AddClothesRenter() {
//   //   return new FormGroup({
//   //     // address: new FormControl('', [Validators.required]),
//   //     item_name: new FormControl('', [Validators.required]),
//   //     security_fee: new FormControl(''),
//   //     rent_price: new FormControl('', [Validators.required, Validators.pattern('[0-9]+(\.[0-9][0-9]?)?')]),
//   //     description: new FormControl(''),
//   //     location: new FormControl('', [Validators.required]),
//   //     gender: new FormControl('', [Validators.required]),
//   //     item_purchase_price: new FormControl('', [Validators.required]),
//   //     condition: new FormControl('', [Validators.required]),
//   //     item_summary: new FormControl('', [Validators.required]),
//   //     item_purchase_date: new FormControl('', [Validators.required]),
//   //     brand_name: new FormControl('', [Validators.required]),
//   //     is_published: new FormControl('1'),
//   //     is_enabled: new FormControl('1'),
//   //     renter_id: new FormControl('', [Validators.required]),
//   //     item_id: new FormControl(''),
//   //     cloth_size: new FormControl('',[Validators.required]),
//   //     sale_price: new FormControl('', [Validators.required, Validators.pattern('[0-9]+(\.[0-9][0-9]?)?')]),

//   //   });
//   // }

//   // static paymentForm() {
//   //   return new FormGroup({
//   //     item_id: new FormControl(''),
//   //     available_from: new FormControl(''),
//   //     available_to: new FormControl(''),
//   //     rentee_id: new FormControl(''),
//   //     card_name: new FormControl('', [Validators.required]),
//   //     card_number: new FormControl('', [Validators.required]),
//   //     expiry_month: new FormControl('', [Validators.required]),
//   //     expiry_year: new FormControl('', [Validators.required,Validators.maxLength(4)]),
//   //     cvv: new FormControl('', [Validators.required]),
//   //     rent_price: new FormControl(''),
//   //     security_fee: new FormControl(''),
//   //   });
//   // }
//   // static packageForm() {
//   //   return new FormGroup({
//   //     card_name: new FormControl('', [Validators.required]),
//   //     card_number: new FormControl('', [Validators.required]),
//   //     expiry_date: new FormControl('', [Validators.required]),
//   //     cvv: new FormControl('', [Validators.required])
//   //   });
//   // }

//   // static AddAddressForBooking() {
//   //   return new FormGroup({
//   //     first_name: new FormControl('', [Validators.required]),
//   //     last_name: new FormControl('', [Validators.required]),
//   //     email: new FormControl('', [Validators.required, Validators.pattern(VARS.EMAIL_PATTERN)]),
//   //     address: new FormControl('', [Validators.required]),
//   //     city: new FormControl('', [Validators.required]),
//   //     state: new FormControl('', [Validators.required]),
//   //     pincode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]),
//   //     phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]),
//   //   });
//   // }
//   // static getUserType() {
//   //   return (USER_DETAILS && USER_DETAILS.DATA && USER_DETAILS.DATA.user_type) ? _.toNumber(USER_DETAILS.DATA.user_type) : null;
//   // }
//   // static getUserId() {
//   //   return (USER_DETAILS && USER_DETAILS.DATA && USER_DETAILS.DATA.user_type_id) ? _.toNumber(USER_DETAILS.DATA.user_type_id) : 0;
//   // }
//   // static getUserTypeAndId(uid = null, uType = null) {
//   //   if (USER_DETAILS.DATA && USER_DETAILS.DATA.user_type && USER_DETAILS.DATA.user_type_id) {
//   //     if (!uid) { uid = USER_DETAILS.DATA.user_type_id; }
//   //     if (!uType) { uType = USER_DETAILS.DATA.user_type; }
//   //     return uType + 'uTypeId' + uid;
//   //   }
//   //   return null;
//   // }
//   // static getBuddyEbid(buddy) {
//   //   const uid = StaticService.getUserTypeAndId();
//   //   if (uid) {
//   //     const euid = EncrDecrService.encrypt(uid);
//   //     return (buddy.sentBy === euid) ? buddy.sentTo : buddy.sentBy;
//   //   }
//   //   return null;
//   // }
// }
