
<div >
<footer class="footer">
   <div class="container">
     <div class="footer-div">
       <div class="footer-logo" (click)="redirecttohome()">
           <img class="footer-img" src="./assets/img/logo.png" />
           <div class="loophead-text">Loophead</div>
       </div>
       <div style="align-self: stretch; flex-direction: column; justify-content: center; align-items: flex-start; gap: 20px; display: inline-flex">
           <div style="color: white; font-size: 14px; font-family: Inter; font-weight: 500;cursor: pointer;" (click)="open()" class="contact-us">Contact Support</div>
           <!-- <div style="color: black; font-size: 14px; font-family: Inter; font-weight: 500">Email</div>
           <div style="color: black; font-size: 14px; font-family: Inter; font-weight: 500">Terms & Conditions</div> -->
       </div>
     </div>
     <!-- <div class="copyright" id="copyright" style="color: black">
       &copy; Loophead
       <script>
         document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))
       </script>
     </div> -->
   </div>
 </footer>
</div>