
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';
import * as CryptoJS from 'crypto-js';
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from '@angular/common';
imports: [CommonModule];
@Component({
  selector: 'app-loopkittrial',
  templateUrl: './loopkittrial.component.html',
  styleUrls: ['./loopkittrial.component.css']
})

export class LoopkittrialComponent implements OnInit {
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }

  audioLinks = [
    {
      file: "https://d1ptp6rux3p5im.cloudfront.net/demo_loopkits/1624344925911-589286d5cb3c1fb8865d.mp3"
    },
    {
      file: "https://d1ptp6rux3p5im.cloudfront.net/demo_loopkits/1624344925911-589286d5cb3c1fb8865d.mp3"
    }
  ];

 



}
  





