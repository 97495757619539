
<!-- <head>
  <meta charset="utf-8" />
  <link rel="apple-touch-icon" sizes="76x76" href="./assets/img/apple-icon.png">
  <link rel="icon" type="image/png" href="./assets/img/logo.png">
  <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  <title>
    Loophead
  </title>
  <meta content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no' name='viewport' />
  <!--     Fonts and icons    
  <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700,200" rel="stylesheet" />
  <link href="https://fonts.googleapis.com/css?family=Inter:400,700,200" rel='stylesheet'>
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.1/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous">
  <!-- CSS Files
  <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
  <link href="./assets/css/now-ui-kit.css?v=1.3.0" rel="stylesheet" />
</head> -->

<!-- <body class="index-page sidebar-collapse"> -->
  <!-- Navbar -->
  <!-- <nav class="navbar navbar-expand-lg bg-primary fixed-top navbar-transparent " color-on-scroll="150">
    <div class="container">
      <div class="navbar-translate">
        <img src="./assets/img/logo.png" href="index.html" height="60" width="60">
        <a class="navbar-brand" href="index.html" rel="tooltip" data-placement="bottom">
          Loophead
        </a>
        
        <button class="navbar-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-bar top-bar"></span>
          <span class="navbar-toggler-bar middle-bar"></span>
          <span class="navbar-toggler-bar bottom-bar"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse justify-content-end" id="navigation" data-nav-image="./assets/img/blurred-image-1.jpg">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link btn btn-neutral" href="login.html" style="border-radius: 10px; width: 125px">
              <p style="font-weight: bold; font-size: medium; color:black">Log In</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav> -->
  <!-- End Navbar -->
  <div class="main-page d-none">
  <div class="wrapper">
    <div class="page-header clear-filter" filter-color="darkBlue">
      <div class="page-header-image" data-parallax="true" style="background-image:url('./assets/img/header.gif');">
      </div>
      <div class="container main-div">
        <div class="banner-text">
          <div class="row">
            <div class="col-md-6 text-left my-auto">
              <div><span style="color: white; font-size: 36px; font-family: Inter; font-weight: 700">Find Instant </span><span style="color: #E39F37; font-size: 36px; font-family: Inter; font-weight: 700">Inspiration</span></div>
              <div style="color: white; font-size: 24px; font-family: Inter; font-weight: 400; line-height: 40.80px; word-wrap: break-word; margin-top: 10px">Upload, organize, and discover <br/>loops for collaborations.</div>
              <br>
              <a class="signup-btn" *ngIf="!showMenu" (click)="redirecttosignup()">
                <p style="font-weight: bold; font-size: medium; color:black; margin: 0;">Sign Up</p>
              </a>
            </div>
            <div class="col-md-6">
              <img src="./assets/img/stock-landing.jpeg" width="100%">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <!-- Features Header -->
      <div style="width: 100%; height: 100%; flex-direction: column; justify-content: flex-start; align-items: center; gap: 20px; display: inline-flex">
        <div style="text-align: center"><span style="color: black; font-size: 40px; font-family: Inter; font-weight: 700; word-wrap: break-word">Our </span><span style="color: #E39F37; font-size: 40px; font-family: Inter; font-weight: 700; word-wrap: break-word">Features</span></div>
        <div style="text-align: center"><span style="color: black; font-size: 24px; font-family: Inter; font-weight: 400; word-wrap: break-word">Join </span><span style="color: black; font-size: 24px; font-family: Inter; font-weight: 700; word-wrap: break-word">today</span><span style="color: black; font-size: 24px; font-family: Inter; font-weight: 400; word-wrap: break-word"> and get access to these unique features</span></div>
      </div>
      <!-- Tiles -->
      <div class="container">
        <div class="row" style="margin-top: 0px; margin-bottom: 30px;">
          <div class="col rw-crd">
            <div class="justify-content-center d-flex" style="padding-top: 50px; position: relative;">
              <div style="width: 294px; height: 213px; padding-left: 20px; padding-right: 20px; padding-top: 40px; padding-bottom: 40px; margin-top: 30px; background: white; box-shadow: 0px 8px 32px rgba(44, 47, 116, 0.30); border-radius: 10px; overflow: hidden; border: 0.12px #444445 solid; flex-direction: column; justify-content: flex-start; align-items: center; gap: 10px; display: inline-flex">
                  <div style="text-align: center"><span style="color: #E39F37; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word">Upload</span><span style="color: black; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word"> <br/>Loops</span></div>
                  <div style="width: 254px; height: 75px; text-align: center; color: black; font-size: 16px; font-family: Inter; font-weight: 400; line-height: 25px; word-wrap: break-word">Seamlessly manage your loops and stay in control of your creative assets with ease.<br/></div>
              </div>
              <div style="width: 60px; height: 60px; top: 50px; position: absolute">
                  <div style="width: 60px; height: 60px; left: 0px; top: 0px; position: absolute; background: black; border-radius: 9999px"></div>
                  <div style="width: 28px; height: 28px; left: 16px; top: 16px; position: absolute">
                      <div style="width: 23.33px; height: 21px; left: 2.33px; top: 3.50px; position: absolute"><img src="./assets/img/icons/folder-check.svg"></div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col rw-crd">
            <div class="justify-content-center d-flex" style="padding-top: 50px; position: relative;">
              <div style="width: 294px; height: 213px; padding-left: 20px; padding-right: 20px; padding-top: 40px; padding-bottom: 40px; margin-top: 30px; background: white; box-shadow: 0px 8px 32px rgba(44, 47, 116, 0.30); border-radius: 10px; overflow: hidden; border: 0.12px #444445 solid; flex-direction: column; justify-content: flex-start; align-items: center; gap: 10px; display: inline-flex">
                <div style="text-align: center"><span style="color: #E39F37; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word">Absolutely<br/></span><span style="color: black; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word">Free</span></div>
                <div style="width: 254px; text-align: center; color: black; font-size: 16px; font-family: Inter; font-weight: 400; line-height: 25px; word-wrap: break-word">No strings attached. Loophead is 100% free of cost.<br/></div>
              </div>
              <div style="width: 60px; height: 60px; top: 50px; position: absolute">
                  <div style="width: 60px; height: 60px; left: 0px; top: 0px; position: absolute; background: black; border-radius: 9999px"></div>
                  <div style="width: 28px; height: 28px; left: 16px; top: 16px; position: absolute">
                      <div style="width: 23.33px; height: 21px; left: 2.33px; top: 3.50px; position: absolute"><img src="./assets/img/icons/tag-01.svg"></div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col rw-crd">
            <div class="justify-content-center d-flex" style="padding-top: 50px; position: relative;">
              <div style="height: 213px; padding-left: 20px; padding-right: 20px; padding-top: 40px; padding-bottom: 40px; margin-top: 30px; background: white; box-shadow: 0px 8px 32px rgba(44, 47, 116, 0.30); border-radius: 10px; overflow: hidden; border: 0.12px #444445 solid; flex-direction: column; justify-content: flex-start; align-items: center; gap: 10px; display: inline-flex">
                <div style="text-align: center"><span style="color: #E39F37; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word">Track</span><span style="color: black; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word"> Your<br/>Success</span></div>
                <div style="width: 254px; text-align: center; color: black; font-size: 16px; font-family: Inter; font-weight: 400; line-height: 25px; word-wrap: break-word">Stay in the loop with your loops! See which users are downloading your content.</div>
              </div>
              <div style="width: 60px; height: 60px; top: 50px; position: absolute">
                  <div style="width: 60px; height: 60px; left: 0px; top: 0px; position: absolute; background: black; border-radius: 9999px"></div>
                  <div style="width: 28px; height: 28px; left: 16px; top: 16px; position: absolute">
                      <div style="width: 23.33px; height: 21px; left: 2.33px; top: 3.50px; position: absolute"><img src="./assets/img/icons/eye.svg"></div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col rw-crd">
            <div class="justify-content-center d-flex" style="padding-top: 50px; position: relative;">
              <div style="width: 294px; height: 213px; padding-left: 20px; padding-right: 20px; padding-top: 40px; padding-bottom: 40px; margin-top: 30px; background: white; box-shadow: 0px 8px 32px rgba(44, 47, 116, 0.30); border-radius: 10px; overflow: hidden; border: 0.12px #444445 solid; flex-direction: column; justify-content: flex-start; align-items: center; gap: 10px; display: inline-flex">
                <div style="text-align: center"><span style="color: #E39F37; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word">Master</span><span style="color: black; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word"> Your<br/>Collection</span></div>
                <div style="width: 254px; text-align: center; color: black; font-size: 16px; font-family: Inter; font-weight: 400; line-height: 25px; word-wrap: break-word">Loophead allows you to effortlessly keep track of all of your downloaded loops.</div>
              </div>
              <div style="width: 60px; height: 60px; top: 50px; position: absolute">
                  <div style="width: 60px; height: 60px; left: 0px; top: 0px; position: absolute; background: black; border-radius: 9999px"></div>
                  <div style="width: 28px; height: 28px; left: 16px; top: 16px; position: absolute">
                    <div style="width: 23.33px; height: 21px; left: 2.33px; top: 3.50px; position: absolute"><img src="./assets/img/icons/download-01.svg"></div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col  rw-crd">
            <div class="justify-content-center d-flex" style="padding-top: 50px; position: relative;">
              <div style="width: 294px; height: 213px; padding-left: 20px; padding-right: 20px; padding-top: 40px; padding-bottom: 40px; margin-top: 30px; background: white; box-shadow: 0px 8px 32px rgba(44, 47, 116, 0.30); border-radius: 10px; overflow: hidden; border: 0.12px #444445 solid; flex-direction: column; justify-content: flex-start; align-items: center; gap: 10px; display: inline-flex">
                <div style="text-align: center"><span style="color: #E39F37; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word">Maximum<br/></span><span style="color: black; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word">Exposure</span></div>
                <div style="width: 254px; text-align: center; color: black; font-size: 16px; font-family: Inter; font-weight: 400; line-height: 25px; word-wrap: break-word">Upload your loops to our open marketplace, becoming instantly searchable by our users!</div>
              </div>
              <div style="width: 60px; height: 60px; top: 50px; position: absolute">
                  <div style="width: 60px; height: 60px; left: 0px; top: 0px; position: absolute; background: black; border-radius: 9999px"></div>
                  <div style="width: 28px; height: 28px; left: 16px; top: 16px; position: absolute">
                      <div style="width: 23.33px; height: 21px; left: 2.33px; top: 3.50px; position: absolute"><img src="./assets/img/icons/chart-breakout-square.svg"></div>
                  </div>
              </div>
            </div>
          </div>
          <div class="col rw-crd">
            <div class="justify-content-center d-flex" style="padding-top: 50px; position: relative">
              <div style="width: 294px; height: 213px; padding-left: 20px; padding-right: 20px; padding-top: 40px; padding-bottom: 40px; margin-top: 30px; background: white; box-shadow: 0px 8px 32px rgba(44, 47, 116, 0.30); border-radius: 10px; overflow: hidden; border: 0.12px #444445 solid; flex-direction: column; justify-content: flex-start; align-items: center; gap: 10px; display: inline-flex">
                <div style="text-align: center"><span style="color: #E39F37; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word">Thrive </span><span style="color: black; font-size: 20px; font-family: Inter; font-weight: 700; word-wrap: break-word">in a<br/>Vibrant Community</span></div>
                <div style="width: 254px; text-align: center; color: black; font-size: 16px; font-family: Inter; font-weight: 400; line-height: 25px; word-wrap: break-word">Join the force of a rapidly growing community with new loops uploaded every day.</div>
              </div>
              <div style="width: 60px; height: 60px; top: 50px; position: absolute;">
                  <div style="width: 60px; height: 60px; left: 0px; top: 0px; position: absolute; background: black; border-radius: 9999px"></div>
                  <div style="width: 28px; height: 28px; left: 16px; top: 16px; position: absolute">
                      <div style="width: 23.33px; height: 21px; left: 2.33px; top: 3.50px; position: absolute"><img src="./assets/img/icons/star-07.svg"></div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>


  <div class="landing-page">
      <div class="banner-section">
        <div class="">
          <div class="header">
            <div class="container">
              <div class="row">
                <div class="navbar-translate logged-out col-sm-9 col-7">
                  <div class="header-logo">
                    <img src="./assets/img/logo1.png" href="index.html" height="60" class="header-logo-img">
                    <a class="navbar-brand" href="index.html" rel="tooltip" data-placement="bottom">
                      Loophead
                    </a>
                  </div>
                </div>
                <div class="col-sm-3 col-5 btn-div">
                  <button class="header-btn" (click)="redirecttologin()">Login</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="banner-section-cntnt">
          <!-- <img src="../../../assets/img/banner_image.png"> -->
          <h2>FIND WHAT'S <span>HOT</span> </h2>
          <p>Making it easy for loopmakers to collab and create</p>
          <button class="banner-btn" (click)="redirecttoexplore()">Find Loops</button>
        </div>
      </div>

      <div class="content-section">
        <div class="container">
          <div class="row mb-text">
            <div class="col-md-4 col-12">
              <div class="heading-text">
                <h2>Why <span>Loophead?</span></h2>
              </div>
            </div>
            <div class="col-md-8 col-12 my-auto">
              <div class="side-text">
                <p>We want to revolutionize the loop-making culture by making the loopmaker to producer relationship easy <a href="#about">Learn how we got started</a></p>
              </div>
            </div>
          </div>

          <div class="row mt">
            <div class="col-md-4 col-12 my-auto">
              <div class="heading-text ">
                <h3>Share your <span>loop</span> catalog</h3>
                <p>Showcase your uploaded loops onto your public profile</p>
              </div>
            </div>
            <div class="col-md-8 col-12 my-auto">
              <div class="side-img">
                <img src="../../../assets/img/Profile 1 (1).png">
              </div>
            </div>
          </div>

          
          <div class="row mt">
            <div class="col-md-4 col-12 my-auto">
              <div class="heading-text stay">
                <h3>Stay <span>connected</span></h3>
                <p>Get notified anytime another user downloads your loop, follows you, or adds a new pack</p>
              </div>
            </div>
            <div class="col-md-8 col-12 my-auto">
              <div class="side-img stay_connected">
                <img src="../../../assets/img/stay_connect.png">
              </div>
            </div>
          </div>


          <div class="row mt">
            <div class="col-md-4 col-12 my-auto">
              <div class="heading-text explore-heading">
                <h3>Explore <span>new ideas</span> </h3>
                <p>If you’re looking for instant inspiration, start on our explore page, where you can browse by popular tags</p>
              </div>
            </div>
            <div class="col-md-8 col-12 my-auto">
              <div class="side-img explore_img">
                <img src="../../../assets/img/explore.png">
              </div>
            </div>
          </div>


          <div class="row mt">
            <div class="col-md-4 col-12 my-auto">
              <div class="heading-text">
                <h3>Manage your <span>downloads</span></h3>
                <p>Every loop you download can be found under your “My Downloads” tab</p>
              </div>
            </div>
            <div class="col-md-8 col-12 my-auto">
              <div class="side-img">
                <img src="../../../assets/img/downloads_screen.png">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="about-section" id="about">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-12 head-cntnt my-auto">
              <div class="heading-text ">
                <div class="about-head">
                  <h3>About</h3><span>The Founder</span>
                </div>
                <div class="subtext">
                  <a href="https://www.instagram.com/wonderlustbeats/">@wonderlustbeats</a>
                  <p>(Producer/Founder of Loophead)</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-12 text-center my-auto">
                <img src="../../../assets/img/about-pic.png">
            </div>
            <div class="col-lg-5 col-12 side-contnt">
              <img src="../../../assets/img/quotes.png"/>  <p>I started Loophead because the process of sending your loops through
                 email has become extremely difficult. Most loops get sent to spam, ignored, 
                 or simply forgotten about. So, I figured, why not create a simple, 
                 centralized platform for loop sharing? After making music for 10+ years and 
                 witnessing the evolution of internet producers, it only makes sense that an
                  open marketplace exists just for loops. Our mission is to help producers get
                   to their favorite loopmakers quickly and efficiently.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="signup-banner">
        <h2>Start uploading <span> today. </span></h2>
        <button class="sign-btn" (click)="redirecttosignup()">Sign Up for Free</button>
      </div>

      <div class="faq-section">
        <h2>Frequently Asked <span>Questions</span></h2>

      <div class="container">
        <div class="mb-4 example-normal">
            <div class="accordion" id="accordionExample1">
              <div class="card">
                <div class="card-header" id="headingSix1">
                  <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix1" aria-expanded="false" aria-controls="collapseSix1">
                      Does Loophead take a cut of my loop?<i class="fas fa-chevron-up"></i>
                    </button>
                  </h2>
                </div>

                <div id="collapseSix1" class="collapse" aria-labelledby="headingSix1" data-parent="#accordionExample1">
                  <div class="card-body">
                    Nope, whatever you upload is 100% yours.</div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo1">
                  <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1">
                      How can I let other users know what my splits are?<i class="fas fa-chevron-up"></i>
                    </button>
                  </h2>
                </div>
                <div id="collapseTwo1" class="collapse" aria-labelledby="headingTwo1" data-parent="#accordionExample1">
                  <div class="card-body">
                    With every upload, you can state your splits in the usage rights box (e.g., 50/50 splits for beat sales and publishing, advances, producer royalty, sync-licencing etc.)
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree1">
                  <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree1" aria-expanded="false" aria-controls="collapseThree1">
                      How many loops am I allowed to upload?<i class="fas fa-chevron-up"></i>
                    </button>
                  </h2>
                </div>
                <div id="collapseThree1" class="collapse" aria-labelledby="headingThree1" data-parent="#accordionExample1">
                  <div class="card-body">
                    Unlimited. Go crazy!  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFour1">
                  <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour1" aria-expanded="false" aria-controls="collapseFour1">
                      How are my loops discovered by other users?<i class="fas fa-chevron-up"></i>
                    </button>
                  </h2>
                </div>
                <div id="collapseFour1" class="collapse" aria-labelledby="headingFour1" data-parent="#accordionExample1">
                  <div class="card-body">
                    Other users can primarily find you through the explore page and search results page. As we gather more of your valuable feedback, we'll build a more in-depth explore page based on your needs.  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingFive1">
                  <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive1" aria-expanded="false" aria-controls="collapseFive1">
                      Can I sell my loops directly on Loophead?<i class="fas fa-chevron-up"></i>
                    </button>
                  </h2>
                </div>
                <div id="collapseFive1" class="collapse" aria-labelledby="headingFive1" data-parent="#accordionExample1">
                  <div class="card-body">
                    As of right now, you can only list your loops for free with usage rights. In the coming updates, you'll be able to directly sell your loops on the platform. </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingsix1">
                  <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsesix1" aria-expanded="false" aria-controls="collapseFive1">
                      Is it possible to upload my loops as private?
                      <i class="fas fa-chevron-up"></i>
                    </button>
                  </h2>
                </div>
                <div id="collapsesix1" class="collapse" aria-labelledby="headingsix1" data-parent="#accordionExample1">
                  <div class="card-body">
                    Yes, with every upload, you have the option to make the loop public or private.
                     If the loop is private, it is only shareable by a User’s unique link.
                    </div>
              </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingseven1">
                  <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseseven1" aria-expanded="false" aria-controls="collapseFive1">
                      Can I create a loop pack? <i class="fas fa-chevron-up"></i>
                    </button>
                  </h2>
                </div>
                <div id="collapseseven1" class="collapse" aria-labelledby="headingseven1" data-parent="#accordionExample1">
                  <div class="card-body">
                    Yes! You can create a pack with your uploaded loops. </div>
              </div>
            </div>
        </div>
      </div>
      </div>
  </div>
  <app-footer></app-footer>
