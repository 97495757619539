<!-- <div class="home-search" >
    <input type="text" [(ngModel)]="validationforsearch"  class="form-control" placeholder="try “piano loop” or “guitar loop"
        style="border-radius: 50px;height: 55px;padding-left: 20px;padding-right: 60px;"><button
        style="background: transparent;border: none;position: absolute;right: 15px;top: 15px;font-size: 17px;"><i
            class="fa fa-search" (click)=" searchClick()"></i></button>
            <small *ngIf="errorforsearch" class="text-danger">{{searchvalue}}</small>
    
</div>

<div class="feeds-wrapper">
	<div class="container">
		<div class="minheight">
			
			<div *ngFor="let items of tableData" ngcontent-yrg-c82="" class="loop--details pw-loop-details ">
				<div class="repostedBy" *ngIf="items.repost_username"> <i class="fa fa-retweet"></i> Reposted by @{{items.repost_username}}
			</div>
				<div ngcontent-yrg-c82="" class="loop-artwork"><img [src]="setimage(items)"><span ngcontent-yrg-c82=""
						class="play-btn hover">
						<span class="play-btn hover play_button" id="play_button">
							<i aria-hidden="true" *ngIf="!items.hidepausebutton" (click)="playAudio(items)"
								class="fa fa-play"></i>
							<i (click)="stopAudio(items)" *ngIf="items.hidepausebutton" class="fa fa-pause"
								aria-hidden="true"></i></span>

					</span>


				</div>

				<div ngcontent-yrg-c82="" class="loop-info-header">
					<h4 ngcontent-yrg-c82=""><b ngcontent-yrg-c82="" class="hover" mat-raised-button #red="matTooltip"
							matTooltip="Go to Detail Page" matTooltipHideDelay="100" mattooltip="Comments"
							(click)="redirecttoloopdetailpage(items)">"{{items.title}}"</b> by<a
							(click)="redirecttoprofilepage(items)" ngcontent-yrg-c82="" matTooltip="Go to Profile Page"
							matTooltipHideDelay="100" mattooltip="Comments" class="link">
							@{{items.username}}</a></h4>
					<span ngcontent-yrg-c82="" [innerHtml]="timeget(items)" class="posted"></span>
					<div ngcontent-yrg-c82="" class="row mt-2">
						<div _ngcontent-yrg-c82="" class="col-md-6">
							<div _ngcontent-yrg-c82="" class="media-equlizer">
								<ng-waveform #waveform class="waveform" [src]="srcforsound(items)" [height]="100"
								[useRegion]="false" backgroundColor="#d3d3d3"
								regionBackgroundColor="rgba(200, 255, 255, 0.7)" regionStartStickColor="#21f032"
								regionEndStickColor="grey" regionTextColor="#09417e" [withRegionLabels]="true"
								waveColor="#909090" (paused)="onPaused()" (regionChange)="onRegionChange($event)"
								(durationChange)="onDurationChange($event)" (trackLoaded)="onTrackLoaded($event)"
								(rendered)="onTrackRendered($event)" (timeUpdate)="onTimeUpdate($event)"
								(paused)="onPaused()">
	
							</ng-waveform>
							</div>
						</div>
						<div ngcontent-yrg-c82="" class="col-md-6">
							<div ngcontent-yrg-c82="" class="loop-col-cat">
								<h5 ngcontent-yrg-c82="" class="mt-0">{{items.terms_name}}</h5><span
									ngcontent-yrg-c82="" class="badge loop-badge">{{items.loop_type==1?'Loop':'Loop
									Kit'}}</span>
								<span ngcontent-yrg-c82="" [innerHtml]="tagsget(items)" class="badge hover" (click)="redirectto1(items)"></span><span
									ngcontent-yrg-c82="" class="badge hover" [innerHtml]="tagsget2(items)" (click)="redirectto2(items)"></span><span
									ngcontent-yrg-c82="" class="badge hover" [innerHtml]="tagsget3(items)" (click)="redirectto3(items)"></span>
							</div>

						</div>
					</div>
					<!-- <span class="media-equlizer">
						
					</span> 
					<ul class="loop-stats">
						<li id="red" mat-raised-button #red="matTooltip" matTooltip="Likes" matTooltipHideDelay="100"
							mattooltip="Likes" class="mat-tooltip-trigger hover re {{addredcolor(items)}}"
							(click)="heartcolorred(items.loop_id,'loop',items)"><i ngcontent-yrg-c82=""
								class="fa fa-heart" [innerHtml]="likesnumber(items)"></i></li>

						<li mat-raised-button #red="matTooltip" matTooltip="Reposts" matTooltipHideDelay="100"
							mattooltip="Reposts" id="green" class="mat-tooltip-trigger hover {{addgreencolor(items)}}">
							<i class="fa fa-retweet" (click)="repost(items.loop_id,items)" [innerHtml]="repostnumber(items)"></i>
							 </li>

						<li mat-raised-button #red="matTooltip" matTooltip="Comments" matTooltipHideDelay="100"
							mattooltip="Comments" class="mat-tooltip-trigger hover"><i class="fa fa-comment"
								(click)="redirecttolooppagecomment(items.loop_id)"></i> {{items.number_of_comments}}
						</li>
						<li mat-raised-button #red="matTooltip" matTooltip="Download" matTooltipHideDelay="100"
							mattooltip="Download" class="mat-tooltip-trigger hover"><i class="fa fa-download"></i>
						</li>
						<li mat-raised-button #red="matTooltip" matTooltip="Price" matTooltipHideDelay="100"
							*ngIf="items.loop_price!==0" mat-raised-button #red="matTooltip" matTooltip="Price"
							matTooltipHideDelay="100" class="">
							<i class="fa fa-dollar" [innerHtml]="priceget(items)"></i>
						</li>
					</ul>
				</div>
			</div>

			<div class="text-center">
				<ul class="paginationlist">
					<li class="text-info" *ngIf="norecordfound">Showing {{record}} to {{pagenocount}} records of
						{{total}}</li>
					<!-- <li class="text-info" *ngIf="!noresult">Showing {{record}} records of {{total}}</li> -->
					<!-- <li class="text-info"> {{total}}</li> 
					<ul class="nextulpagination" *ngIf="!hidepagination">

						<li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item === pageno}"
							*ngFor="let item of pagercount" id="liststyle{{item}}" (click)="paginationget(item)">
							{{item}}

						</li>
					</ul>

				</ul>


			</div>


			<div *ngIf="!nocomments || !norecordfound" class="text-center" style="
    
    padding: 10px;
">No records to display</div>

		</div>
	</div>
</div>
 -->
<!-- 
<div _ngcontent-htr-c51=""  class="page-wrapper">
 
 
    <div ngcontent-htr-c51="" class="container-fluid row">
		<div ngcontent-htr-c51="" class="text-center">
            <div class="home-search" >
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchQuery"
                    style="padding-left: 20px;padding-right: 60px;">
                    <button (click)="performSearch()"
                    class="btn">Search</button>
            </div>
        </div>
        <div>
        <a> <div class="fas fa-search" ></div>View Filter</a>
        <div> {{totalRecords}} search results for "{{validationforsearch}}"</div>
      </div>
		<table>
    <thead>
    <th>Loop + Artist</th>
    <th>BPM</th>
    <th>Key</th>
    <th>Tags</th>
    <th>Download</th>
</thead>
<tbody>
    <tr *ngFor="let items of tableData" ngcontent-yrg-c82="" >
        <td  class="pw-loop-details">
            <div ngcontent-yrg-c82="" class="loop-artwork">
            <img [src]="setimage(items)"/><span ngcontent-yrg-c82=""
            class="play-btn hover">
            <span class="play-btn hover play_button" id="play_button">
                <i aria-hidden="true" *ngIf="!items.hidepausebutton" (click)="playAudio(items)" class="fa fa-play"></i>
                <i (click)="stopAudio(items)" *ngIf="items.hidepausebutton" class="fa fa-pause"
                    aria-hidden="true"></i></span>

        </span>
    </div>
        <div ngcontent-yrg-c82="" class="loop-info-header">
            <h4 ngcontent-yrg-c82="">{{items.title}}</h4>
                    
                    <a (click)="redirecttoprofilepage(items)" >
                    @{{items.username}}</a>
        </div>
		<!-- <div ngcontent-yrg-c82="" class="row mt-2"> 
			<div _ngcontent-yrg-c82="" class="col-md-6">
				<div _ngcontent-yrg-c82="" class="media-equlizer">
					<ng-waveform #waveform class="waveform"
    [src]="srcforsound(items)"
    [useRegion]="false"
    regionBackgroundColor="rgba(200, 255, 255, 0.7"
    regionStartStickColor="#21f032"
    regionEndStickColor="grey"
    regionTextColor="#09417e"
    [withRegionLabels]="true"
    waveColor="#909090"
    (paused)="onPaused()"
    (regionChange)="onRegionChange($event)"
    (durationChange)="onDurationChange($event)"
    (trackLoaded)="onTrackLoaded($event)"
    (rendered)="onTrackRendered($event)"
    (timeUpdate)="onTimeUpdate($event)"
    (paused)="onPaused()">
</ng-waveform>

				</div>
			</div>
        </td>
        <td>
            {{items.bpm}}
        </td>
        <td>
            {{items.sub_key}}
        </td>
        <td >
            <div class="tags" *ngFor="let i of items.tags_array" >
                {{i}}

            </div>
        </td>
        <td>
            <div></div>
        </td>
    </tr>
</tbody>
</table>
	</div>
</div>

<div *ngIf="!nocomments" class="text-center" style="
    
    padding: 10px;
">No records to display</div> -->
<app-header></app-header>

<div class="container Search-page" >
<div _ngcontent-htr-c51=""  class="page-wrapper">
    <!-- <div ngcontent-htr-c51="" class="container-fluid "> -->
        <div ngcontent-htr-c51="" class="row">
            <div class="col-md-6" >
                <!-- <form [formGroup]="advancesearch">
                    <div class="searchbox">
                        
                            <input type="text" class="form-control" value="{{validationforsearch}}" (keyup.enter)="apply('frommain')"  formControlName="keyword"  style="">
                                <button class="btn" (click)="apply('frommain')">Search</button>
                           </div>
                  </form> -->
                  <!-- <mat-form-field class="example-chip-list"> -->
                    <mat-label></mat-label>
                  
                    <mat-chip-list #chipList aria-label="Fruit selection">
                      
                      <div class="searchbox">
                        <div class="icon-srch">
                        <i class="srch-icon fa fa-search" aria-hidden="true"></i>
                        </div>
                        <mat-chip *ngFor="let fruit of fruits" class="fi" [selectable]="selectable" [removable]="removable"
                        (removed)="remove(fruit)" matChipRemove>
                        {{ fruit[0] == '#' ? '' + fruit : '#' + fruit }}
                      </mat-chip>
                      <input class="form-control" placeholder="i.e. lil baby, bouncy, aggressive" #fruitInput [formControl]="fruitCtrl"
                        (focusout)="checktag()" [matChipInputFor]="chipList" style="color: black;"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
                        
                      </div>
                      <button class="btn" (click)="searchClick()">Search</button>
                    </mat-chip-list>
                  <!-- </mat-form-field> -->
              
            </div>
            <div class="col-md-6">
                <div class="filter-btn">
                  <!-- <a class="filter" (click)="openDialog()"> <img src="../../../../assets/img/icons/filter-lines.svg"/>View Filter</a> -->
                </div>
              </div>
      </div>
        <!-- <div> -->
        <div class="search-value"> {{totalRecords}} search results for "{{value}}"</div>

        <div class="table-responsive">
            <table *ngIf="norecordfound" >
                <thead>
                <th class="pl">Loop + User</th>
                <th></th>
                <th></th>
                <th class="t-align">BPM</th>
                <th class="t-align">Key</th>
                <th>Tags</th>
                <th class="dnload-align dnload-head" >Download</th>
            </thead>
            <tbody>
                <tr *ngFor="let items of tableData; let i = index"  id="player-row-{{i}}" ngcontent-yrg-c82="" >
                    <td class="loop-artwork pl">
                        <img [src]="setimage(items)"/><span ngcontent-yrg-c82=""
                        class="play-btn hover">
                        <span class="play-btn hover play_button" id="play_button" >
                            <i aria-hidden="true" *ngIf="!items.hidepausebutton" (click)="playAudio(items,i)" class="fa fa-play"></i>
                            <i (click)="stopAudio(items,i)" *ngIf="items.hidepausebutton" class="fa fa-pause"
                                aria-hidden="true"></i></span>
            
                    </span>
                    </td>
                    <td class="loop-details pl">
                        <!-- <div ngcontent-yrg-c82=""> -->
                            <h4 ngcontent-yrg-c82="" style="cursor: pointer;" (click)="redirecttolooppage(items.loop_id)">{{items.title}}</h4>
                                    
                                    <a (click)="redirecttoprofilepage(items)"  style="cursor: pointer;" >
                                    @{{items.username}}</a>
                        <!-- </div> -->
            </td>
            <td class="col-md-4">
                <div _ngcontent-yrg-c82="" class="wave">
                    <div _ngcontent-yrg-c82="" class="media-equlizer">
                        <app-waveform [arr]="srcforsound(items)" [loop_id]="items.loop_id" ></app-waveform>

                    </div>
                </div>
            </td>
                    <td class="t-align">
                        {{items.bpm}}
                    </td>
                    <td class="t-align">
                        {{items.sub_key}}
                    </td>
                    <td class="t-align">
                        <div class="tags" *ngFor="let i of items.tags_array" (click)="redirectto(i)"  >
                            {{i}}
            
                        </div>
                    </td>
                    <td class="dnload-align">
                        <button class="download dnload-align" (click)="downloadFile(items)" ><img src="../../../../assets/img/download.svg"/></button>
                    </td>
                </tr>
            </tbody>
            </table>
        </div>
        
        
        
        <button class="load-btn btn btn-theme center mb-4" *ngIf="!hidepagination" (click)="paginationgetmore()">Load
            More
            Results</button></div>
<!-- <div *ngIf="!norecordfound" class="text-center" style="
            padding: 10px;
            padding-top: 20px;
            
        ">
        <img src="../../../../assets/img/hand and electronic musical instrument.png" class="mb-5"/>
        <div class="msg mb-5 mt-5">Search Results Stuck in a   <div class="yellow"> Loop</div></div>
        <p>We can’t seem to find what you’re looking for. Use the search bar at the top of the page to conduct another search.</p>
        </div> -->
        <div *ngIf="!norecordfound" class="text-center" style=" padding: 10px; padding-top: 20px;">
            <img src="../../../../assets/img/hand and electronic musical instrument.png" class="mb-5"/>
            <div class="msg mb-5 mt-5">
                Search Results Stuck in a  
                <div class="yellow">
                    Loop
                </div>
            </div>
            <p>We can’t seem to find what you’re looking for.<br> Use the search bar at the top of the page to conduct another search.</p>
            </div>
    </div>
<!-- <app-music-player[items]="tableData" [index]="player"></app-music-player> -->
<!-- <p>music-player works!</p> -->
<td class="music-player"  *ngIf="showAudioPlayer" >
        
        
    <div class="aWrap" data-src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3">
        <div>
            <input class="range" style="width: 100%;" id="pi_input" type="range" [min]="0" [max]="tableData[player].duration || tableData[player].loop_duration || tableData[player].tagged_loop_duration" [step]="steps" [(ngModel)]="sliderValue" (input)="onInput($event)" />
        </div>
        <div class="col-sm-4 player-img">
            <div class="img-info-div">
            <img class="img" [src]="setimage(tableData[player])"/>
            <div class="name">
            <h5>{{tableData[player].title}} </h5>
            <span style="cursor: pointer;">@{{tableData[player].username}}</span>
            </div>
            </div>
        </div>
        <div class="col-md-5 col-sm-6 media-player">
            <div class="btns d-flex media-player-btns">
                <div>
                    <div (click)="backward()"><img src="../../../assets/img/fast-backward.svg"/></div>
                </div>
                <div class="buttons" *ngIf="!tableData[player].hidepausebutton"  (click)="playAudio(tableData[player],player)">
                    <button class="aPlay" ><span class="aPlayIco"><i class="fa fa-play" style="color: white;"></i></span></button>
                </div>
                
                <div class="buttons" *ngIf="tableData[player].hidepausebutton" (click)="stopAudio(tableData[player],player)" >
                    <button class="aPlay"><span class="aPlayIco"><i class="fa fa-pause" style="color: white;"></i></span></button>
                </div>
                <div>
                    <div (click)="forward()" ><img src="../../../assets/img/fast-forward.svg"/></div>
                </div>
            </div>
        </div>
        <div class="col-3 volume-btns">
            <div class="volume-div d-flex">
                <div *ngIf="!mute" class="volume-container" (click) ="Mute()">
                <span class="aVolIco"><i class="fa fa-volume-up icon"></i></span>
                </div>
                <div *ngIf="mute" class="volume-container"  (click) ="Unmute()">
                    <span class="aVolIco" >
                        <i class="fa fa-volume-off" style="color: black"></i>
                    </span> 
                    <!-- <span class="aVolIco" > <img src="../../../../assets/img/volume-xmark-solid.svg"/> </span> -->
                </div>
                <div  class="range-volume">
                    <input class="aVolume" id="volume" type="range" min="0" max="1" step="0.01" [(ngModel)]="volumeValue" (input)="onVolumeInput($event)" style="height: 5px;">
                </div>
            </div>
        </div>
    </div>
</td>
<!-- <div *ngIf="filter" class="filter-div">
<div class="inner-filter">
    <form [formGroup]="filterForm">
        <div (click)="close()" style="background-color: transparent; color: gray;">
            <img src="../../../../assets/img/x-close.svg"/>
        </div>
        <h6>Filter</h6>
            <div class="row">
                <div class=" col-xs-12">
                  <div class="keygroup-wrap">
                    <p>Key</p>
                    <div class="keygroup col-md-2" *ngFor="let item of arrayforradio">
                      <!-- <div class="key"  formControlName="key"> -->
                        <!-- <input type="radio" formControlName="keynotes" id={{item}} value={{item}}> -- -->
                        <!-- <button class="key" [ngStyle]="{'background-color': item === selectedKey ? 'gray' : 'initial'}" (click)="onButtonClick(item)" formControlName="sub_key"> {{item}} </button> -->
                    <!-- </div> --
                    </div>
                    <div class="keygroup col-md-2" *ngFor="let item of arrayformainkey">
                          <!-- <input type="radio" formControlName="keynotes" id={{item}} value={{item}}> --
                          <button class="key" [ngStyle]="{'background-color': item.key === selectedKey1 ? 'gray' : 'initial'}" (click)="onButtonClick1(item.key)" formControlName="main_key" value="{{item.key}}"> {{item.name}} </button>
                      </div>
                  </div>
                </div>
            </div>
            <div>
                <h6>BPM</h6>
                <div>
                    <div class="row">
                        <div class="col-md-2">                    
                            <input  type="checkbox" value="exact" formControlName="bpm"/>
                        </div>

                        <div class="col-md-10" >
                            <p>Exact</p>
                            <input type="text" placeholder="BPM" formControlName="exact_bpm"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">                    
                            <input  type="checkbox" value="range" formControlName="bpm" />
                        </div>
                        <div class="col-md-10">
                            <p>Range</p>
                            <div style="display: flex;"> <input type="text" placeholder="BPM" formControlName="start_bpm"/>
                                <p>to</p>
                                <input type="text" placeholder="BPM" formControlName="end_bpm" /></div>
                            
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="form-group">
                <div class="tabs tabs-alt ng-star-inserted">
                    <input type="checkbox"
                    (click)="Rangeactive()">BPM Range</input><input type="checkbox" 
                    (click)="exactbpmctive()">Exact BPM </input>
      
                </div>
                <div *ngIf="hiderangeactive">
                  <ng5-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ng5-slider>
                </div>
      
                <div *ngIf="hideexactbpm">
                  <label for="Genre">BPM</label>
                  <input type="number" placeholder="Bpm" formControlName="exact_bpm" (keyup)="valueforBPM()">
                  <small class="text-danger">{{errorshowbpm}}</small>
                </div>
      
              </div> --
      <button (click)="performSearch()"pply</button>
    </form>
</div>

</div>  -->
<!-- <app-loopsearch *ngIf="loopsearch" [items]="check"></app-loopsearch> -->
<app-footer></app-footer>
