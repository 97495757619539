<div class="title-header">
    <div class="container">
        <h3>Follow Requests</h3>
    </div>
</div>



<!-- <div class="mb-3">
    <button class="active btn btn-theme" >Followers</button>
    <button class="btn btn-theme" (click)="followredirect()">Following</button>
</div> -->
<div class="followers-wrapper minheight">
    <div class="container">
        <!-- <div ngcontent-wil-c120="" class="tabs "><button ngcontent-wil-c120="" class="active">Followers</button><button
                (click)="followredirect()">Following</button></div> -->


        <!-- <form class="search-group" *ngIf="!hidesearchbox">
            <input type="search" [(ngModel)]="validationforsearch" class="form-control" placeholder="Search"
                aria-label="Search" name="searchsubscription" id="searchsubscription" aria-describedby="search-addon" />
            <button type="submit" (click)=" searchClick()"><i class="fa fa-search"></i></button>
        </form> -->
        <div *ngFor="let items of tableData" class="follows mt-5">
            <img [src]="setimage(items)" class="follow-img" />
            <div class="follower-name">
                <span class="follower-uname"><a class="link" (click)="redirecttoprofilepage(items)">
                        @{{items.username}}</a> </span>
                <span class="text-muted small d-block">
                    {{items.first_name}} <span *ngIf="items.last_name">{{items.last_name}} </span>
                </span>
                <div class="ad-btns">
                    <span class="btn-accept hover" (click)="functionforaccept(items)">
                        Accept</span>
                    <span class="btn-decline ml-1 hover" (click)="functionfordecline(items)">Decline</span>
                </div>
            </div>
            <!-- <span class="follower-action">Remove</span> -->
            <!-- <span class="downloads-count"><i class="fa fa-download"></i> {{items.number_of_downloads}},</span>  <span *ngIf="items.delete_flag == 1"
            class="fa fa-arrow-circle-down upload-date"> {{items.delete_flag?'deleted':'notdeleted'}}</span> -->
            <!-- <span *ngIf="items.selling_preference === 1?call(items.terms):call()"></span> -->
        </div>

        <div *ngIf="noresult" class="noresult text-center .no_records_div mt-3">No records to display </div>

        <div *ngIf="!norecordfound" class="text-center .no_records_div mt-3">No records to display </div>

        <div class="text-left mt-3" *ngIf="!hidesearchbox">
            <ul class="paginationlist" *ngIf="!noresult">
                <li *ngIf="norecordfound">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
                <!-- <li class="text-info"> {{total}}</li> -->
                <ul class="nextulpagination" *ngIf="!hidepagination">

                    <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item === pageno}"
                        *ngFor="let item of pagercount" id="liststyle{{item}}" (click)="paginationget(item)">
                        {{item}}

                    </li>
                </ul>

            </ul>


        </div>
        <div class="headerfornonwrapper text-center" *ngIf="hidesearchbox">
            <!-- <h3>Upload Loop</h3> -->
            <div class="container" style="
  color: #108a84;
background-color: #DFF2BF;
border-color: #f5c6cb;
padding: 10px;
"> This account is private. <a class="link" (click)="Followapi()">Follow</a> to see their sounds </div>
        </div>

    </div>
</div>