import { HttpClient, HttpEventType } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from "ngx-file-drop";
import { ApiServiceService } from "src/app/allServices/api-service.service";
import { APIURL, CONSTNAME, INPUT_TYPE_NAME, VARS } from "src/app/allServices/constant.service";
import { PendingComponent } from "../MODAL/pending/pending.component";
import { MatDialog } from "@angular/material/dialog";
import { StaticService } from "src/app/sevicesall/static.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { FormControl } from "@angular/forms";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as CryptoJS from 'crypto-js';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { commonservice } from "src/app/allServices/commonservice";
import { ToastrService } from "ngx-toastr";
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
// import ffmpeg from 'fluent-ffmpeg';
interface DroppedFile {
  file: File;
  fileEntry: FileSystemFileEntry;
}
declare interface Window {
  AudioContext: AudioContext;
}
var thiscomp: any;
declare var p5: {
  SoundFile: new (arg0: string, arg1: (s: any) => void, arg2: (err: any) => void) => any;
};
@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: "app-uploadloop",
  templateUrl: "./uploadloop.component.html",
  styleUrls: ["./uploadloop.component.css"],
})
export class UploadloopComponent {
  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  private cancelUpload = new Subject<void>();
  private cancelUpload1 = new Subject<void>();
  private cancelUpload2 = new Subject<void>();
  private cancelUpload3 = new Subject<void>();
  uploadLoop = StaticService.uploadLoop();
  visiblity:any = 0
  usage_visiblity:any
  value: any;
  tableData: any;
  encrypted: string;
  image: any;
  zip: any;
  selectedImage: string | ArrayBuffer | null | undefined;
  main_key: any;
  bpmerror: any;
  keyerror: string;
  mainkeyerror: string;
  tagserror: string;
  loop_name_error: boolean;
  percentage: number = 0;
  imgpercentage: number;
  stempercentage: number;
  loop: any;
  tagged_loop: any;
  stems: any;
  loopprogress: boolean;
  imageuploaded: any;
  constructor(public router: Router,public api: ApiServiceService,  public common: commonservice, private toastr: ToastrService,private http: HttpClient, private route: ActivatedRoute, private matDialog: MatDialog) {}
  formData = new FormData();
  fruits: string[] = [];
  disable_input_as_itsloading = false;
  disable_input_as_itsloading1 = false;
  disable_input_as_itsloading2 = false;
  disable_image_field = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  dragClass: any;
  removable = true;
  fruitCtrl = new FormControl();
  selectable = true;
  canDeactivate: any;
  showsecondpage: boolean = false;
  errorshow: string;
  fileName: any;
  ImagefileName: any;
  ZipfileName: any;
  durationofaudio: any;
  user_id: any;
  loop_id: any;
  file: any;
  usage:any;
  id: any;
  loop_name: string = "";
  tags: any;
  bpm: any;
  key: any;
  fileerrorformandatory = false;
  progress: any = false;
  above15second = false;
  apihitforstream = true;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  imageProgress: boolean = false;
  zipProgress: boolean = false;
  wavfilevalidation: boolean = true;
  public files: NgxFileDropEntry[] = [];
  droppedFiles: DroppedFile[] = [];
  tag1: any;
  tag2: any;
  tag3: any;
  tagvalid = false;
  arr: any = [];
  arrforsubgenre: any = [];
  instrument_name: any[] = [];
  arrforinstrument: any[] = [];
  decrypted: any;
  encryptedagainremoveslashes: string;
  tokenFromUI: string = "0123456789123456";
  set_usage:any =false
  get_usage :any
  keys:any = ['A','B','C','D','E','F','G','A♭','B♭','C♭','D♭','E♭','F♭','G♭','A#','B#','C#','D#','E#','F#','G#']
  main_keys:any =  [{key:'0',name:"Major"},{key:'1',name:"Minor"}];
  
  ngOnInit() {
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      let resa = JSON.parse(res);
      // ////console.log(localStorage.getItem('usage_button'));
      if (resa) {
        if(Number(localStorage.getItem('usage_button'))){
          this.get_usage = localStorage.getItem("usage")
          this.usage = JSON.parse(this.get_usage)
          this.set_usage = 1
        }else{
        this.usage = resa.usage_rights

        }
        // ////console.log(resa);
      }
    })
  
  }
  zipfile: any;
  zipfilename: any;
  nameoffile: any;
  uploadcompletestatus: any;

  profile_image_pathofcomments = "assets/img/";
  reader = new FormData();

  setimage(data: any) {
    if (data?.artwork_path == null) {
      if (data?.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        return CONSTNAME.fILE_PATH + data.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }
  loadImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.selectedImage = e.target?.result;
    };
    reader.readAsDataURL(file);
  }
  srcforsound(items: any) {
    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      } else {
        this.durationofaudio = items.loop_duration;
        return CONSTNAME.fILE_PATH + items.loop_path;
      }
    } else {
      this.durationofaudio = items.tagged_loop_duration;
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
    }
  }
  onFileDropZip(files: NgxFileDropEntry[]) {
    // ////console.log("files", files);
    let id: any = document.getElementById("fileerrorformandatory");
    this.files = files;
    files[0].fileEntry.isFile;
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file(async (file: File) => {
      let extensiontype = file.name.split(".").pop();
      // ////console.log("extensiontype", file);
      if(extensiontype=='.zip'){

      this.uploadZipFileViaDrop(file);
    }else{
      this.toastr.error("Only .zip files are allowed", "", { positionClass: 'toast-bottom-right' })

    }
    });
  }
  uploadZipFileViaDrop(file: any) {
    this.zipProgress=true
    this.disable_input_as_itsloading2=true
    this.zipfilename = file.name;
    this.reader.append("stems", file);

    if (this.loop_id !== "") {
      this.reader.append("loop_id", this.loop_id);
    }
    this.user_id = localStorage.getItem("client_data");
    let tokkenget: any = localStorage.getItem("clientintoken");
    const user = JSON.parse(this.user_id).user_type_id;

    let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_stems`, this.reader, {
        headers: headers,
        reportProgress: true,
          observe: "events"
        }).pipe(takeUntil(this.cancelUpload2))
      .subscribe((e: any) => {
        if (e.type === HttpEventType.UploadProgress && e.loaded && e.total) {
          const newPercentage = Math.round((e.loaded / e.total) * 100);

          if (this.stempercentage !== newPercentage) {
            this.stempercentage = newPercentage;
          }

          if (this.stempercentage === 100) {
            this.zipProgress = false;
          }
        } 
        // ////console.log('e.type',e.type );
        
        if (e.type === HttpEventType.Response) {
        // ////console.log(e.body?.response?.status);
        // Handle the final response here if needed
          if (e.body?.response?.status) {
            this.zipProgress = false;
            this.stempercentage = 0
            this.stems = e.body.response.data

          } 
        }
      });
  }
  stopAudio(item: any) {
    // ////console.log(item);
    if (item) {
      if (item.audio) {
        item.audio.pause()
        item.hidepausebutton=false
        // this.showAudioPlayer = false;s
      }
      clearInterval(this.id);

      this.tableData.map((r: any) => {
        r.hidepausebutton = false;
      })

    } else {
      this.tableData.map((r: any) => {
        if (r.audio) {
          r.audio.pause()
        }
        r.hidepausebutton = false;
      })

    }


  }
  playAudio(item: any,index: any) {
    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      item.audio.play();
      item.audio.addEventListener('timeupdate', () => {
      });
      item.audio.addEventListener('ended', () => {
      });
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true

      }
      else if (item.duration < 15 && item.duration >= 5) {

        this.apihitforstream = true
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {

        this.apihitforstream = true
        this.above1secondlessthan5 = true;
      }
    } else {
      item.audio.addEventListener('timeupdate', () => {
        // this.sliderValue = item.audio.currentTime;
      });
      item.audio.addEventListener('ended', () => {
        // this.next();
      });
      item.audio.play();
    }
    




    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {

        setTimeout(() => {

        }, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            // this.apihitforstreaming();
            clearInterval(this.id);
            this.apihitforstream = false;

          }

        }
      } else if (item.duration < 15 && item.duration >= 5) {

        if (r > 5) {

          if (this.apihitforstream == true) {
            // this.apihitforstreaming();
            clearInterval(this.id);
            this.apihitforstream = false;

          }
        }


      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            // this.apihitforstreaming();
            clearInterval(this.id);
            this.apihitforstream = false;

          }

        }
      }
    }, 200);

  }

  selectFileZip(event: any) {
    // ////console.log(event.target.files[0].type);
  this.reader = new FormData();
  this.zipProgress = true;
    this.disable_input_as_itsloading2 = true;
    let id: any = document.getElementById("fileinput");
    // ////console.log("gaya")
    if (event.target.files[0] == undefined) {
      // this.url = "assets/img/images.png";
      // ////console.log("cancel");
      this.fileerrorformandatory = false;
      this.zipProgress = false;
      id.innerHTML = "";
      // this.canDeactivate = true;
      
    } else {
      let extensiontype = event.target.files[0].name.split('.').pop();;
      // (extensiontype);

      if (extensiontype == 'rar' || extensiontype == 'zip') {


        if (event.target.files[0].type == '' || event.target.files[0].type == 'application/x-zip-compressed' || event.target.files[0].type == 'application/zip') {
          // this.canDeactivate = true;
          // (event.target.files[0].size );
          
          if (event.target.files[0].size == 0 || event.target.files[0].size > 3221225472 ) {
            this.fileerrorformandatory = false;
            id.innerHTML = "File size cannot be 0 mb or above 3 Gb";
          this.toastr.error("File size cannot be 0 mb or above 3 Gb", "", { positionClass: 'toast-bottom-right' })
          this.zipProgress = false;
          this.disable_input_as_itsloading2 = false;

            
          } else {
            if (event.target.files) {
              // ("i am in");
              this.progress = 0;
              this.changeProgress();
              this.nameoffile = event.target.files[0].name;
              setTimeout(() => {
                this.uploadcompletestatus = "Upload Complete";
              }, 1000);
              this.zipfile = event.target.files[0];
              this.zipfilename = event.target.files[0].name;
              // (this.zipfilename);


              this.fileerrorformandatory = true;
              id.innerHTML = "";

              this.reader.append('stems', this.zipfile, this.zipfilename);

              if (this.loop_id!== "") {
                this.reader.append("loop_id", this.loop_id);
              }

           
              this.user_id = localStorage.getItem("client_data");
              let tokkenget: any = localStorage.getItem("clientintoken");
              const user = JSON.parse(this.user_id).user_type_id;


              let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
              this.http
                .post(`${APIURL.BASE_URL}/user/upload_loop_stems`, this.reader, {
                  headers: headers,
                  reportProgress: true,
                  observe: "events"
                }).pipe(takeUntil(this.cancelUpload2))
                .subscribe((e: any) => {
                  if (e.type === HttpEventType.UploadProgress && e.loaded && e.total) {
                    const newPercentage = Math.round((e.loaded / e.total) * 100);
        
                    if (this.stempercentage !== newPercentage) {
                      this.stempercentage = newPercentage;
                    }
        
                    if (this.stempercentage === 100) {
                      this.zipProgress = false;
                    }
                  } 
                  // ////console.log('e.type',e.type );
                   if (e.type === HttpEventType.Response) {
                    // ////console.log('e.type',e.body?.response?.status );
                    // Handle the final response here if needed
                    if (e.body?.response?.status) {
                    // ////console.log('e.type',e.body?.response?.data );
                    this.zipProgress = false;
                      this.stempercentage = 0
                      this.stems = e.body.response.data
                    } 
                  }
                  // ("event:", event);
                });

            }
          }
        } else {
          this.fileerrorformandatory = false;
          id.innerHTML = "Only .zip or .rar media is supported ";
        }
      } else {
        this.fileerrorformandatory = false;
        id.innerHTML = "Only .zip or .rar media is supported ";
      this.zipProgress = false;
    this.disable_input_as_itsloading2 = false;
    this.toastr.error("Only .zip or .rar media is supported", "", { positionClass: 'toast-bottom-right' })

      }
    }
  }
  changeProgress() {
    setTimeout(() => {
      if (this.progress < 100) {
        this.uploadcompletestatus = "Uploading"
        this.progress = this.progress + 10;
        this.changeProgress()
      } else if (this.progress == 100) {
        this.uploadcompletestatus = "Upload Complete";
        this.dragClass = '';
      }
    }, 100);
  }
  async apihitforstreaming(item: any, r: any, dura: any) {

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "audio_duration": dura,
      "loop_id": item.loop_id,
      "play_duration": r
    }
    this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }
 
  openpending(): void {
    let dialogue = this.matDialog.open(PendingComponent, { panelClass: "pending", disableClose: true });
    dialogue.afterClosed().subscribe((result) => {});
  }
   public onFileDrop(files: NgxFileDropEntry[]) {
    // ("files", files);
    let id: any = document.getElementById("fileerrorformandatory");
    this.files = files;
    // ('tdsf', files)
    files[0].fileEntry.isFile;
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file(async (file: File) => {
      let extensiontype = file.name.split(".").pop();
      // (extensiontype, file.name);

      if (extensiontype == "wav" || extensiontype == "x-wav") {
        if (file["type"] == "audio/wav" || file["type"] == "audio/x-wav") {
          if (file.size > 75000000) {
            this.fileerrorformandatory = false;
            id.innerHTML = "File size can be max 75 mb";
          } else {
            let sound: any;

            const path = URL.createObjectURL(file);
            const fileURL = URL.createObjectURL(file);

            // Create a new audio element
            const audio = new Audio(fileURL);

            // When the audio is loaded, get the duration
            audio.addEventListener("loadedmetadata", () => {
              var duration = audio.duration;
              // ("Duration:", duration); // in seconds
              this.durationofaudio = duration;
              // ("audio>>>>>>>>>>", this.durationofaudio);
            this.opendialogueforverificatio("Loop", file, this.durationofaudio);
          });
          }
        } else {
          this.fileerrorformandatory = false;
    this.disable_input_as_itsloading = false;
    id.innerHTML = "Only .WAV media is supported ";
        }
      } else {
    this.disable_input_as_itsloading = false;
    this.fileerrorformandatory = false;
        id.innerHTML = "Only .WAV media is supported ";
      }
    });
  }

  async opendialogueforverificatio(Loop_type: string, file: any, duration: any) {
    this.progress = true;
    this.fileName = file.name.split(".").slice(0, -1).join(".");
    // ("file", file);
    // //console.warn("fileopendialogueforverificatio", duration);

    let tokkenget: any = localStorage.getItem("clientintoken");
    this.user_id = localStorage.getItem("client_data");
    const user = JSON.parse(this.user_id).user_type_id;
    this.formData.append("main_loop", file);
    this.formData.append("user_id", user);
    this.formData.append("loop_duration", duration);
    let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_draft`, this.formData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
        this.progress = false;
        if(event.response.status){
          // ("event:", event);
    this.disable_input_as_itsloading = true;
    this.loop_id = event.response.data.loop_id;
        }else{
          this.toastr.error(event.response.errors,"", { positionClass: 'toast-bottom-right' })
          
        }
      
      });
  }

  next() {
    if(this.loop_name.length>50){
      this.loop_name_error =true
    }else{
    if(!this.loop_name){
      this.toastr.error("Please Create a Title First", "", { positionClass: 'toast-bottom-right' })
    }else if(!this.loop_id){
      this.toastr.error("Please Upload a Loop First", "", { positionClass: 'toast-bottom-right' })

    }else{
    this.showsecondpage = !this.showsecondpage;
    }
  }
}
  new() {
  }
  users :any
  submit() {
    if(this.loopprogress){
      this.toastr.error("Loop uploading is in progress","", { positionClass: 'toast-bottom-right' })

    }
    else if(this.zipProgress){
      this.toastr.error("Stem uploading is in progress","", { positionClass: 'toast-bottom-right' })

    }else if(this.imageProgress){
      this.toastr.error("Image uploading is in progress","", { positionClass: 'toast-bottom-right' })
    }else{  
      if(this.loop_name.length>50){
        this.loop_name_error =true
      }
    var values = this.fruits;
    let tolower = function (x: any) {
      return x.toLowerCase();
    };


    values = values.map(tolower);
    const unwantedCharsRegex =  /^(?!\s)[^a-zA-Z0-9_\-.,\s]/;
    const unwantedCharsRegex1 = /[^0-9]/;
    const hasUnwantedChars = values.some(tag => unwantedCharsRegex.test(tag));
    this.tag1 = values[0]
    this.tag2 = values[1]
    this.tag3 = values[2]
    if (values.length > 3) {
      this.tagserror = "Only 3 tags can be entered";
    }else if(hasUnwantedChars){
      this.tagserror = "Tags must only include standard characters.";

    } else if ((values.length === 3 || values.length===2 )&&values[1]!==undefined&&values[2]!==undefined&&(values[0] === values[1] || values[1] === values[2] || values[2] === values[3])) {
    
      this.tagserror = "Tags cannot be same";
    } else if (values[0]?.length > 25 || values[1]?.length > 25 || values[2]?.length > 25) {
      this.tagserror = "Only 25 characters max per tag";
    }
  else{
    if(this.bpm<10 || this.bpm>300){
        this.keyerror = ""
        this.mainkeyerror = ""
      this.tagserror = ""
      this.bpmerror = "Enter BPM between 10 - 300"
      }else{
       
        if(this.bpm){
        const hasUnwantedChars1 = unwantedCharsRegex1.test(this.bpm);
        if (hasUnwantedChars1) {
          // ("error4");
          this.bpmerror = "bpm can only contain numbers";
          return;  // Exit the function if unwanted characters are found
        }
      }
  if (hasUnwantedChars) {
    // ("error4");
    this.tagserror = "Tags must only include standard characters.";
    return;  // Exit the function if unwanted characters are found
  }
        this.bpmerror = ""
        this.keyerror = ""
      this.tagserror = ""
      this.mainkeyerror = ""
      
    this.user_id = localStorage.getItem("client_data");
    let tokkenget: any = localStorage.getItem("clientintoken");
    const user = JSON.parse(this.user_id).user_type_id;
    // const formData = new FormData();
    let sendData = {
      "title": this.loop_name,
      "bpm": this.bpm,
      "sub_key": this.key,
      "main_key":this.main_key,
      "tags": [this.tag1, this.tag2, this.tag3],
      "loop_path": this.loop.loop_path,
      "loop_duration": this.loop.loop_duration,
      "tagged_loop_path": this.tagged_loop.tagged_loop_path,
      "tagged_loop_duration": this.tagged_loop.tagged_loop_duration,
      "stems":this.stems?.stems,
      "usage_right":this.usage || null,
      "set_usage":this.set_usage?1:0,
      "visiblity":this.visiblity?1:0,
      "user_id" :user,
      "artwork_path" :this.imageuploaded?.artwork_path,
    }
   
    if(this.set_usage){
      let sendingdata={
      usage_rights:this.usage
      }
      this.api.forclientgettokenpublic(`/user/updateprofile`, sendingdata).then(async (res: any) => {
        // (res);
      })
    }
    let headers: any = {
      Authorization: `Bearer ${tokkenget}`,
      "My-Custom-Header": "foobar",
    };

    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_test`, sendData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
        // ("event:", event); 
        if(event.response.status){
        this.user_id = localStorage.getItem("client_data");
        const user = JSON.parse(this.user_id).user_type_id;
        // ("hey_ram", user);
        let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
        let encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(user), _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });
        this.encrypted = encrypted.toString();
        this.encrypted = encodeURIComponent(this.encrypted);
        this.users = localStorage.getItem("client_data") 
        const name = JSON.parse(this.users).username
        this.router.navigate([`/user/sounds/@${name}`])
      }
        else{
          this.toastr.error(event.response.errors,"", { positionClass: 'toast-bottom-right' })
        }
      });
    // }
  }
}
    }
}
async toggle(){
    this.set_usage=!this.set_usage
  }

  async selectFilemandatory(event: any) {
    let id: any = document.getElementById("fileerror");

    const file = event.target.files[0];

    if (file) {
      // Create a URL for the file

      const fileURL = URL.createObjectURL(file);

      // Create a new audio element
      const audio = new Audio(fileURL);
      
      // When the audio is loaded, get the duration
      audio.addEventListener("loadedmetadata", () => {
        var duration = audio.duration;
        this.durationofaudio = duration;
        this.processFileUpload(event);
      });
    }
  }
  processFileUpload(event: any) {
    // ("hey_ram", event.target.files[0]);
    this.showsecondpage = true
    const fileToUpload = event.target.files[0];
    let outputFile:any=fileToUpload
    let fileName = fileToUpload.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();
      
      // Check if the file extension is wav
      if (fileExtension === 'wav') {
        fileName = fileName.replace(/\.wav$/, '.mp3');
    
    // Create a new File object with the updated name
        outputFile = new File([fileToUpload], fileName, { type: fileToUpload.type });
    }
    this.fileName = fileToUpload.name;
    this.loopprogress = true;
    this.disable_input_as_itsloading = true;
    this.user_id = localStorage.getItem("client_data");
    const user = JSON.parse(this.user_id).user_type_id;
    let tokkenget: any = localStorage.getItem("clientintoken");

    const formData: FormData = new FormData();
    formData.append("main_loop", fileToUpload);
    formData.append("user_id", user);
   
    formData.append("loop_duration", this.durationofaudio);

    let headers: any = {
      Authorization: `Bearer ${tokkenget}`,
      "My-Custom-Header": "foobar",
    };
 this.http.post(`${APIURL.BASE_URL}/user/upload_loop_draft`, formData, { headers, reportProgress: true,
      observe: "events" })
    .pipe(takeUntil(this.cancelUpload))
    .subscribe((event: any) => {
        if (event.type === HttpEventType.UploadProgress && event.loaded && event.total) {
          const newPercentage = Math.round(((event.loaded / event.total)*100)/2);

          if (this.percentage !== newPercentage) {
            this.percentage = newPercentage;
          }
      } 
          // ////console.log("event",  this.percentage );
          if (event.type === HttpEventType.Response){
          // ////console.log("event",  event.type );
          if(event.body.response.status){
          // ////console.log("event",event.body.response.status);
          
          this.loop = event.body.response.data;
          this.callstreaming(outputFile,user)
        }
        // 
        }
    }, (error) => {
      this.handleError(error.message);
    });
  }
  callstreaming(outputFile:any,user:any){
    let tokkenget: any = localStorage.getItem("clientintoken");
    const formData1: FormData = new FormData();
            // formData1.append("loop_id", this.loop);
            let headers: any = {
      Authorization: `Bearer ${tokkenget}`,
      "My-Custom-Header": "foobar",
    };
    formData1.append("tagged_loop", outputFile);
    formData1.append("user_id", user);
    formData1.append("tagged_loop_duration", this.durationofaudio);
    // if (this.loop) {
    
      // ('Upload Complete. Loop ID:', this.loop_id);
    this.http.post(`${APIURL.BASE_URL}/user/upload_streaming_loop`, formData1, {
        headers,
        reportProgress: true,
        observe: "events"
      }).pipe(takeUntil(this.cancelUpload1)).subscribe((e: any) => {
        if (e.type === HttpEventType.UploadProgress && e.loaded && e.total) {
          const newPercentage = 50 + Math.round((e.loaded / e.total) * 50);

          if (this.percentage !== newPercentage) {
            this.percentage = newPercentage;
            // ('Upload Progress:', this.percentage);
          }

         
         
      }
      if (this.percentage === 100) {
        this.loopprogress = false;
        // this.loop_id = this.loop;
        // ('Upload Complete. Loop ID:', this.loop_id);
      }
      if (e.type === HttpEventType.Response){
        // ////console.log("event",  e.type );
        if(e.body.response.status){
        // ////console.log("event",e.body.response.status);
        
        this.tagged_loop = e.body.response.data;
      }
    }
      })
    // }
  }
  
  cancelUploadRequest() {
    this.percentage=0
    this.cancelUpload.next();
    this.cancelUpload.complete();
    this.cancelUpload1.next();
    this.cancelUpload1.complete();
    ('Upload cancelled');
    this.progress = false;
    this.disable_input_as_itsloading = false
    this.toastr.error("Upload cancelled", "", { positionClass: 'toast-bottom-right' });
  }
  cancelStemUploadRequest() {
    this.stempercentage=0
    this.cancelUpload2.next();
    this.cancelUpload2.complete();
    this.zipProgress = false;
    this.disable_input_as_itsloading2= false
    this.toastr.error("Stems upload cancelled", "", { positionClass: 'toast-bottom-right' });
  }
  cancelImageUploadRequest() {
    this.imgpercentage=0
    this.cancelUpload3.next();
    this.cancelUpload3.complete();
    this.imageProgress = false;
    this.disable_input_as_itsloading1= false
    this.toastr.error("Image upload cancelled", "", { positionClass: 'toast-bottom-right' });
  }
  handleError(error: string) {
    this.progress = false;
    this.disable_input_as_itsloading = false;
    this.toastr.error(error, "", { positionClass: 'toast-bottom-right' });
  }
  previous(){
    this.showsecondpage = !this.showsecondpage
  }
  selectFileImage(event: any) {
   
    this.disable_input_as_itsloading1 = true;
    const file = event.target.files[0];
    this.image = event.target.files[0].name
    //console.warn(this.profile_image_pathofcomments+this.image);
    this.ImagefileName=file.name
    this.user_id = localStorage.getItem("client_data");
    const user = JSON.parse(this.user_id).user_type_id;
    // ("hey_ram", user);
    if(file)
    this.loadImage(file);
    let extensiontype = file.name.split(".").pop();
    // if(extensiontype=='.png'||extensiontype=='.jpeg'||extensiontype=='.jpg'){
      this.imageProgress = true;
    // }
    let tokkenget: any = localStorage.getItem("clientintoken");
    const formData = new FormData();
    formData.append("loop_artwork", file);
    // formData.append("loop_id", this.loop_id);

    let headers: any = {
      Authorization: `Bearer ${tokkenget}`,
      "My-Custom-Header": "foobar",
    };

    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_draft`, formData, {
        headers: headers,
        reportProgress: true,
        observe: "events"
      }).pipe(takeUntil(this.cancelUpload3))
      .subscribe((e: any) => {
        if (e.type === HttpEventType.UploadProgress && e.loaded && e.total) {
          const newPercentage = Math.round((e.loaded / e.total) * 100);

          if (this.imgpercentage !== newPercentage) {
            this.imgpercentage = newPercentage;
          }

          if (this.imgpercentage === 100) {
            this.imageProgress = false;
          }
        } else if (e.type === HttpEventType.Response) {
          // Handle the final response here if needed
          if (e.body?.response?.status) {
            this.imageProgress = false;
            this.imgpercentage = 0
            this.imageuploaded = e.body.response.data
          } else {
            this.handleError(e.body?.response?.errors);
          }
        }
      });
  }
  onFileDropArtwork(files: NgxFileDropEntry[]) {
    let id: any = document.getElementById("fileerrorformandatory");
    this.files = files;
    files[0].fileEntry.isFile;
    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    fileEntry.file(async (file: File) => {
      let extensiontype = file.name.split(".").pop();
    if(extensiontype=='.png'||extensiontype=='.jpeg'){
    this.uploadArtworkFileViaDrop(file)
    }else{
      this.toastr.error("Error: only jpg, jpeg, and png files are allowed")
    }
    });
  }
  uploadArtworkFileViaDrop(file:any){
    this.imageProgress = false;
    this.disable_input_as_itsloading1 = false;
    this.fileName = file.name;
    // ////console.log("file", file);

    let tokkenget: any = localStorage.getItem("clientintoken");
    this.user_id = localStorage.getItem("client_data");
    const user = JSON.parse(this.user_id).user_type_id;
    this.formData.append("loop_artwork", file);
    this.formData.append("loop_id",this.loop_id)
    let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_draft`, this.formData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
        this.imageProgress = false;
    this.disable_input_as_itsloading1 = true;

        // ////console.log("event:", event);
        this.loop_id = event.response.data.loop_id;
      });
  }
  deleteFile() {
    // ////console.log("closeeee");
    this.disable_input_as_itsloading = false;
    // this.disable_input_as_itsloading1 = false;
    // this.disable_input_as_itsloading2 = false;
    this.loop_id=""
    
    this.progress = false;
  }
  deleteImageFile() {
    // ////console.log("closeeee");
    // this.disable_input_as_itsloading = false;
    this.disable_input_as_itsloading1 = false;
    // this.disable_input_as_itsloading2 = false;

    this.imageProgress = false;
  }
  deleteZipFile() {
    // ////console.log("closeeee");
    // this.disable_input_as_itsloading = false;
    this.disable_input_as_itsloading2 = false;
    // this.disable_input_as_itsloading2 = false;

    this.zipProgress = false;
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.fruits.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    // ////console.log("Ss");
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
    // //console/.log(this.fruits);
    
  }
  checktag(value: any = null) {

    var nameofuser = value;
    // ////console.log(value)
    // var nameofuser = this.searchsymbol;
    // ////console.log(this.searchsymbol);
    // var afterdeletingextraspace = nameofuser.replace(/\s\s+/g, ' ');

    // var trim = value.trim()
    // var valuesforsending = trim.split(" ");
    // var values = trim.split(" ");
    var values = this.fruits;
    // ////console.log(values)

    let tolower = function (x: any) {
      return x.toLowerCase();
    };
    // ////console.log(values);
    values = values.map(tolower);
    this.arr = this.arr.map(tolower);
    this.arrforsubgenre = this.arrforsubgenre.map(tolower);
    this.instrument_name = this.instrument_name.map(tolower);
    this.arrforinstrument = this.arrforinstrument.map(tolower);
    let allFounded = this.arr.some((ai: any) => values.includes(ai));
    let allFoundedfor = this.arrforsubgenre.some((ai: any) => values.includes(ai));
    let allFoundedforinstrumentcategory = this.instrument_name.some((ai: any) => values.includes(ai));
    let allFoundedforinstrument = this.arrforinstrument.some((ai: any) => values.includes(ai));
    if (values.length > 3) {
      // ////console.log("error");
      // this.errorshow = "Only 3 tags can be entered";
    }  else {
      this.tagvalid = true;
      this.tag1 = values[0]
      this.tag2 = values[1]
      this.tag3 = values[2]
      // this.errorshow = "";
    }



    // ////console.log(this.arr);
    // ////console.log(values);
    // ////console.log(this.arrforsubgenre);

    // // ////console.log(allFounded);
    // if (allFounded == true) {
    //   this.errorshow = "Genre and Instrument metadata should not be included in tags, try mood words or Soundalikes!";
    // } else {
    //   this.errorshow = "";
    // }
    // if (this.uploadloopkit.value.subgenre !="") {
    //   ////console.log("gaya");
    //   if (allFoundedfor == true) {
    //     this.errorshow = "Genre and Instrument metadata should not be included in tags, try mood words or Soundalikes!";
    //   } else {
    //     this.errorshow = "";
    //     }
    // }

    

  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.fruits.push(value);
    }

    // Clear the input value
    // event.chipInput!.clear();

    this.fruitCtrl.setValue(null);
    this.checktag(value);
  }
}
