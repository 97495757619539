import { Component, AfterViewInit, NgZone, ChangeDetectorRef, ViewRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { isNgTemplate } from '@angular/compiler';
import { APIURL } from 'src/app/allServices/constant.service';
import { commonservice } from 'src/app/allServices/commonservice';
import * as CryptoJS from 'crypto-js';
// import { OnInit } from '@angular/core';

@Component({
  selector: 'app-subscriptiontiermain',
  templateUrl: './subscriptiontiermain.component.html',
  styleUrls: ['./subscriptiontiermain.component.css'],
  providers: [DatePipe]
})
export class SubscriptiontiermainComponent implements AfterViewInit {

  public activeIndex: any = 0;

  pagenolist: any;
  pagercount: any = [];
  tableData: any[] = [];
  time: string | null;
  validationforsearch: any = '';
  pageno: any = 1;
  paginationempty = false;
  getpageno: any;
  myDiv: any;
  total: any;
  noresult = false;
  record: any;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;

  sortsign: any = "sort";
  pagenocount: any;
  // common: any;

  constructor(
    private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe,public common: commonservice
  ) {
    this.getapicallforglobalsetting();
  }




  getapicallforglobalsetting() {
    this.common.showLoader();


    this.router.navigate(['/admin/subscriptiontiers'], { queryParams: { pageno: this.pageno } });
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    // 
    this.http.get(APIURL.BASE_URL+`/admin/get_all_subscription_plans?row_size=10&page_no=${this.pageno}`, { headers }).subscribe(async (res: any) => {
      // ////console.log(res);
      if (res.response.status == 1) {
        if (this.paginationempty == true) {
          this.pagercount = [];
        }

        // ////console.log(res);
        this.tableData = res.response.data.pagging_list;
        this.record = this.tableData.length;
        // ////console.log(this.tableData);
        this.pagenolist = res.response.data.total_pages + 1;


        // ////console.log(this.pagenolist);
        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }
        // ////console.log(res.data.total_records)
        this.total =   res.response.data.total_records;

        // ////console.log(`liststyle${this.pageno}`);
        // this.myDiv = document.getElementById(`liststyle2`);
        // ////console.log(document.getElementById("liststyle2"));
        // this.myDiv.style.color = 'orange';
        // this.myDiv.disabled = true;
        // ////console.log(this.myDiv);
        this.common.hideLoader();
        this.record = this.pageno - 1 + "1";
        // ////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
        this.pagenocount = 10*(this.pageno - 1) + res.response.data.pagging_list.length

        this.tableData = res.response.data.pagging_list;
        // this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;
        if (this.record == "01") {
          this.record = 1;
        }

      } else {

        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          // let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        }
        // ////console.log(res);
        // this.toastr.error("Error", res.response.errors, { positionClass: 'toast-bottom-right' });
        this.common.hideLoader();
      }
    }, async e => {
      this.common.hideLoader();
      // ////console.log('Error', e);
      this.toastr.error("Error in Api", "Error", { positionClass: 'toast-bottom-right' });

    });
  }

  searchwordget: any;





  // loop for sorting 

  tablevaluearray = [
    { name: 'Plan name', tablehead: 'Plan_name', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Plan price', tablehead: 'Plan_price', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Status', tablehead: 'is_enabled', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created Date', tablehead: 'created_on', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Action', tablehead: 'Action', sort: false, showicon: false, sortdesc: false, class: 'fa fa-sort' }

  ];

  // dimag: any;
  sortOn(value: any) {
    this.getapicallforsort(value.tablehead);
    this.tablevaluearray.map(item => {
      item.sort = false;
      item.sortdesc = false;
      item.class = 'fa fa-sort';
    })
    value.sort = true;
    // alert(value.tablehead + "asc");
    value.class = "fa fa-sort-asc";
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "Plan_name")) {
      // let id: any = document.getElementById("Plan_name");
      // value.sortdesc = true;
      value.class = 'fa fa-sort-desc';
      // id.classList.add("fa fa-address-book");
      // this.id.add.classList.add("fa fa sort");
      // alert("planname sign desc");
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "Plan_price")) {

      // value.sortdesc = true;
      value.class = 'fa fa-sort-desc';
      // alert("price desc");
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "is_enabled")) {
      // value.sortdesc = true;
      // alert("status desc");
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "created_on")) {
      // value.sortdesc = true;
      // alert(" created disable");
      value.class = 'fa fa-sort-desc';
    }



    // if (value.tablehead == "Plan_name") {
    //   this.dimag = true
    // } else {
    //   this.dimag = false;
    // }
    // if ((this.dimag == true) && (value.tablehead == "Plan_name")) {

    //   value.sortdesc = true;

    // }






    // if (value.sort == true) {
    //   this.dimag = true;
    //   // alert("dimag hua true")

    // }
  }








  getapicallforsort(got: any) {
    let sort_on = got;
    let arrange = "DESC";
    if (this.route.snapshot.queryParamMap.get('sort') == "ASC") {
      arrange = "DESC";
      this.sortsign = "sort-desc";
    } else {
      arrange = "ASC";
      this.sortsign = "sort-asc";
    }

    this.searchwordget = this.route.snapshot.queryParamMap.get('search')
    if (this.searchwordget) {
      this.common.showLoader();
      // ////console.log(this.searchwordget);
      this.router.navigate(['/admin/subscriptiontiers'], { queryParams: { search: this.validationforsearch, pageno: this.pageno, sort: arrange } });
      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

      this.http.get(APIURL.BASE_URL+`/admin/get_all_subscription_plans?sort_on=${sort_on}&sort_type=${arrange}&row_size=10&page_no=${this.pageno}&search_input=${this.searchwordget}`, { headers }).subscribe(async (res: any) => {
        // ////console.log(res);
        if (res.response.status == 1) {
          this.common.hideLoader();

          this.tableData = res.response.data.pagging_list;

        } else {
          this.common.hideLoader();
          this.toastr.error("Error", res.response.error, { positionClass: 'toast-bottom-right' });

        }
      }, async e => {
        this.common.hideLoader();
        // ////console.log('Error', e);
        this.toastr.error("Error in Api", "Error", { positionClass: 'toast-bottom-right' });

      });



    } else {
      this.getpageno = this.route.snapshot.queryParamMap.get('pageno');
      if (this.getpageno) {
        this.pageno = this.getpageno;
      } else {
        this.pageno = 1;

      }
      this.router.navigate(['/admin/subscriptiontiers'], { queryParams: { sort: arrange, pageno: this.pageno } });
      // alert(got);
      // ////console.log(this.route.snapshot.queryParamMap.get('sort'));


      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

      this.http.get(APIURL.BASE_URL+`/admin/get_all_subscription_plans?sort_on=${sort_on}&sort_type=${arrange}&row_size=10&page_no=${this.pageno}`, { headers }).subscribe(async (res: any) => {
        // ////console.log(res);
        if (res.response.status == 1) {

          this.tableData = res.response.data.pagging_list;

        } else {
          this.toastr.error("Error", res.response.error, { positionClass: 'toast-bottom-right' });

        }
      }, async e => {
        // ////console.log('Error', e);
        this.toastr.error("Error in Api", "Error", { positionClass: 'toast-bottom-right' });

      });

    }




  }


  searchApi() {
    this.common.showLoader();



    this.router.navigate(['/admin/subscriptiontiers'], { queryParams: { search: this.validationforsearch, pageno: this.pageno } });
    let search = this.validationforsearch
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    // 
    this.http.get(APIURL.BASE_URL+`/admin/get_all_subscription_plans?row_size=10&search_input=${search}&page_no=${this.pageno}`, { headers }).subscribe(async (res: any) => {
      // ////console.log(res);
      if (res.response.status == 1) {
        this.common.hideLoader();

        // ////console.log(res.response.data);
        if (res.response.data.total_records == 0) {
          this.pagercount = [];
          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.noresult = true;
          // this.toastr.success("No record found", res.response.errors, { positionClass: 'toast-bottom-right' });
          this.total =   res.response.data.total_records;
        } else {

          this.noresult = false;
          // ////console.log(res);
          this.pagercount = [];
          this.tableData = res.response.data.pagging_list;
          this.pagenolist = res.response.data.total_pages + 1;
          this.record = this.tableData.length;
          this.record = this.pageno - 1 + "1";
          if (this.record == "01") {
            this.record = 1;
          }
        // ////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
        this.pagenocount = 10*(this.pageno - 1) + res.response.data.pagging_list.length


          // ////console.log(this.pagenolist);
          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);

          }
          this.total =  res.response.data.total_records;
        }


      } else {
        this.common.hideLoader();
        // ////console.log(res);
        // this.toastr.error("Error", res.response.errors, { positionClass: 'toast-bottom-right' });

        let errorthroughapi = res.response.errors;
        let error = [];
        this.record = this.tableData.length;

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      // ////console.log('Error', e);
      this.toastr.error("Error in Api", "Error", { positionClass: 'toast-bottom-right' });

    });
  }


















  ngAfterViewInit(): void {

  }

  onsearch() {
    // ////console.log(this.validationforsearch);
    // this.myDiv = document.getElementById(`liststyle${this.pageno}`);
    //     this.myDiv.style.color = 'blue';
    this.searchApi();
  }




  colo: any;

  paginationget(pageinfo: any) {

    // ////console.log(pageinfo);
    this.paginationempty = true;
    this.pageno = pageinfo;

    if (this.route.snapshot.queryParamMap.get('search')) {
      this.searchApi()
    } else {
      this.getapicallforglobalsetting();
    }

    // this.colo = document.getElementsByClassName("bluecolor");
    // ////console.log(this.colo);

    //  this.colo[0].style.color = 'blue';
    //  this.colo[1].style.color = 'blue';
    //  this.colo[2].style.color = 'blue';
    //  this.colo[3].style.color = 'blue';
    //  this.colo[4].style.color = 'blue';
    //  this.colo[5].style.color = 'blue';
    //  this.colo[6].style.color = 'blue';
    //  this.colo[7].style.color = 'blue';
  }


  encryptUsingAES256(value:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    this.encrypted =encodeURIComponent(this.encrypted);
    // ////console.log(value);
    // ////console.log(this.encrypted);

    // ////console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    this.router.navigate([`/admin/subscriptionplan/${this.encrypted}`]);
  }
}
