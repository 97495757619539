


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { UploadClickComponent } from 'src/app/CLIENT_PAGES/MODAL/upload-click/upload-click.component';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/app/sevicesall/alldata';
@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.css']
})
export class UploadsComponent implements OnInit {
  @Input() items: any;
  @Output() messageEvent = new EventEmitter<string>();
  tableData: any = [];
  pagercount: any = [];
  pagenolist: any;
  pageno: any;
  paginationempty = false;
  totallength: any;
  noresult = false;
  noresultshow: any;
  norecordfound: any;
  termsofpreference: any
  looptype: any;
  hidepagination: boolean = true;
  record: any;
  total: any;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenocount: any;
  totalpagesall: any
  nocomments: boolean;
  totalpagesinaccount: any;
  // items: any = [];
  // pageOfItems: Array<any>

  constructor(private router: Router, public api: ApiServiceService, private toastr: ToastrService, public common: commonservice,public matDialog: MatDialog,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.pageno = this.route.snapshot.queryParamMap.get('pageno')
    // ////console.log(typeof this.pageno)
    // this.getallinfoofloops();
    this.run(this.items);
    // ////console.log(this.items)
    let left_sidebar:any = document.getElementsByClassName('sidebar-menu');
    if (left_sidebar.length > 0) {
      // ////console.log("in")
      left_sidebar = left_sidebar[0];
      left_sidebar.querySelector('[href="/admin/user"]').parentElement.classList.add("active", 'menu-open', 'custom-active');
    }
    
  }
  paginationget(item:any) {
    // ////console.log("red")
    this.messageEvent.emit(item)
  }
  // onChangePage(pageOfItems: Array<any>) {
  //   // this.tableData = []
  //   let no:any = pageOfItems[0]
  //   // ////console.log(pageOfItems,noif
  //   // if (no !== 1) {
  //     this.messageEvent.emit(no)
  //   // }
    
  // }
  // (item:any) {
    
   



  // }

  run(res: any) {
    // ////console.log(res)
   
    if (res.response.data.total_records == 0) {
      this.tableData = []
      // ////console.log("gaya")
      this.norecordfound = true;
    } else {
      this.norecordfound = false;
      
      if (res.response.data.total_pages == 1) {
        this.hidepagination = true;
        // ////console.log("hide");
      } else {
        this.hidepagination = false;
        // ////console.log("not hide");
      }
      if (this.paginationempty == true) {
        this.pagercount = [];
      }
      this.total = res.response.data.total_records;
      
      this.tableData = res.response.data.pagging_list;
      
      // ////console.log(this.tableData)
  
      this.record = this.tableData.length;
      if (this.record == 0) {
        this.tableData =[]
        this.nocomments = false;
      } else {
        this.nocomments = true;
      }
      this.pagenolist = res.response.data.total_pages + 1;
     
  
      this.record = this.pageno - 1 + "1";
      // ////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
      this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length
  
      this.tableData = res.response.data.pagging_list;
      // this.record = this.tableData.length;
      this.pagenolist = res.response.data.total_pages + 1;
      if (this.record == "01") {
        this.record = 1;
      }
      // if (this.totalpagesinaccount !== res.response.data.total_pages) {
      //   this.totalpagesinaccount = res.response.data.total_pages
      //   this.items = Array(this.totalpagesinaccount).fill(0).map((x, i) => (i + 1))
      //   }
      for (let i = 1; i < this.pagenolist; i++) {
        this.pagercount.push(i);
      }
      this.total = res.response.data.total_records;
    }

 
  }


  getallinfoofloops() {

    // this.common.showLoader();
    this.api.getApiForClient(`/user/get_all_uploads?page_no=${this.pageno}&row_size=10&sort_on=created_on&sort_type=DESC`, VARS.ClIENT_TYPE).then((res: any) => {

      // this.afterload = true;
      this.pagercount = [];
      if (res.response.status == 1) {
        if (this.paginationempty == true) {
          this.pagercount = [];
        }
        this.pagenolist = res.response.data.total_pages + 1
        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }
        // ////console.log(res);
        this.record = res.response.data.pagging_list.length;
        this.total = res.response.data.total_records;
        this.totalpagesall = res.response.data.total_pages
        this.record = this.pageno - 1 + "1";
        if (this.record == "01") {
          this.record = 1;
        }
        this.pagenocount = 10*(this.pageno - 1) + res.response.data.pagging_list.length
        // ////console.log(res.response.data.total_pages);

        if (res.response.data.total_pages == 1) {
          this.hidepagination = false;
          // ////console.log("gaya");
        }
        // for (i = 0; i < res.response.datalength; i++) {
        //   let k = res.response.data.pagging_list[i].selling_preference;
        //   let y = res.response.data.pagging_list[i].terms;
        //   if (k == 1) {
        //     if (y == 1) {
        //       this.termsofpreference = "Royalty Free";
        //     } else {
        //       this.termsofpreference = "50/50 Master + Publishing Split"
        //      }

        //   } else {
        //     if (y == 2) {
        //       this.termsofpreference = "Royalty Free";

        //     }  else if(y == 2)  {
        //       this.termsofpreference = "50/50 Master + Publishing Split"

        //     } else {
        //           this.termsofpreference = "Exclusive";
        //         }


        //   }
        // }
        this.totallength = this.pagercount;


        this.tableData = res.response.data.pagging_list;

        // this.sellingpreference = res.response.data.loop_details[0].selling_preference;
        // this.termsofpreference = res.response.data.loop_details[0].terms;

        // if (this.sellingpreference == 1) {
        //   if (this.termsofpreference == 1) {
        //     this.termsofpreference = "Royalty Free";
        //   } else if (this.termsofpreference == 2) {
        //     this.termsofpreference = "50/50 Master + Publishing Split";
        //   }
        // } else if (this.sellingpreference == 2) {
        //   if (this.termsofpreference == 1) {
        //     this.termsofpreference = "Royalty Free";
        //   } else if (this.termsofpreference == 2) {
        //     this.termsofpreference = "50/50 Master + Publishing Split";
        //   } else {
        //     this.termsofpreference = "Exclusive";
        //   }

        // }
        // ////console.log(this.tableData);
        // ////console.log();
        if (res.response.data.total_records == 0) {
          this.noresultshow = true;
        }

        this.common.hideLoader();





      } else if (res.response.status == 0) {
        // ////console.log(res.response.data.total_records);
        // this.common.hideLoader();

        this.common.hideLoader();

        this.noresultshow = true;
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];

        // this.common.hideLoader();

        this.common.hideLoader();

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })

        }
      }
    }, async e => {

      this.common.hideLoader();

    });
  }

  

  // paginationget(pageinfo: any) {
  //   this.paginationempty = true;

  //   this.pageno = pageinfo;
  //   // this.tableData = false;

  //   this.getallinfoofloops();



  // }

  callfree(item: any) {
    // ////console.log(item);
    if (item == 1) {
      this.termsofpreference = "Royalty Free";

    } else {
      this.termsofpreference = "50/50 Master + Publishing Split"

    }
  }
  callpremium(item: any) {
    // ////console.log(item);
    if (item == 1) {
      this.termsofpreference = "Royalty Free";

    } else if (item == 2) {
      this.termsofpreference = "50/50 Master + Publishing Split"

    } else {
      this.termsofpreference = "Exclusive";
    }
  }
  loopinfo(item: any) {
    if (item == 1) {
      this.looptype = "Loop";

    } else if (item == 2) {
      this.looptype = "Loop Kit"

    }
  }


  getUserDetail() {
    this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }

  redirecttoloop() {
    // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_ACCOUNT_uploadloop]);
    this.openDialog();

  }


  detailpageofloop(value: any) {
    // ////console.log(value);
    // ////console.log(`/detail-loop?loop_id=${value}`);
    this.encryptUsingAES256(value);
    
  }

  openDialog(): void {
    let dialogue = this.matDialog.open(UploadClickComponent, { panelClass: 'forgot-password', disableClose: true });
    dialogue.afterClosed().subscribe(result => {

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }

  encryptUsingAES256(value:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    this.encrypted =encodeURIComponent(this.encrypted);
    // ////console.log(value);
    // ////console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    // this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
    
    // this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });
    window.open(`${APIURL.BASE_URLforredirect}/detail-loop?loop_id=${this.encrypted}`, `_blank`)

    // ////console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }
  userMenu = environment.account_menu;
  archivework = environment.account_menu[8].active;


  redirecttopagearchive(item: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    window.open(`${APIURL.BASE_URLforredirect}/profile/archive?loop_id=${this.encrypted}`, `_blank`)
    
    // this.router.navigate(['/profile/archive'], { queryParams: { loop_id: this.encrypted } });
  }

  paginationnext() {
    // ////console.log(this.totalpagesall)

    if (this.pageno == this.totalpagesall) {

    } else {
      this.pageno = Number(this.pageno) + 1
      
        this.getallinfoofloops();
      
    }

  }
  paginationprev() {
    if (this.pageno == 1 || this.pageno < 1) {

    } else {
      this.pageno = Number(this.pageno) - 1
    
        this.getallinfoofloops();
      
    }

  }


  

}




