<div class="hold-transition login-page">
  <div class="login-box">
    <div class="login-logo">
      <a class="testing"><b>Admin Panel</b></a>
    </div>
    <form [formGroup]="loginForm">
      <div class="form-group col-lg-12 ">
        <label for="email">Email </label><span class="ml-1 text-danger">*</span>
        <input type="email" autocomplete="off" (keyup.enter)="login()" formControlName="email" class="form-control " aria-describedby="emailHelp"
          placeholder="Email">
        <small class="text-danger" [innerHTML]="vCheck('email', '_EMAIL')"></small>
      </div>
      <div class="form-group col-lg-12 ">
        <label for="password">Password</label><span class="ml-1 text-danger">*</span>
        <input type="password" formControlName="password" (keyup.enter)="login()" autocomplete="new-password" class="form-control " aria-describedby="emailHelp"
          placeholder="Password">
        <span style="display: flex; justify-content: space-between; flex-direction: column;">
          <small class="text-danger" [innerHTML]="vCheck('password', '_PASSWORD',{minLength: 6})"></small>
        </span>
      </div>
      <div class="form-group col-lg-12 col-md-6">
        <button type="button" class="btn btn-theme" [disabled]="apiHit" (click)="login()">login</button>
      </div>
    </form>
  </div>
</div>