<div class="modal-header">
    <button type="button" (click)="leave()" class="close"><span aria-hidden="true">×</span></button>
    <h4 class="mt-0 mb-0"></h4>
  </div>
  <mat-divider></mat-divider>
  <div class="modal-body text-center">
  <p class="h4 mt-3 mb-3" *ngIf=" showmodeletebannedpage" >Are you sure you want to delete?</p>
  <p class="h4 mt-3 mb-3" *ngIf=" clearhistory" >Are you sure you want to clear the history?</p>
  <p class="h4 mt-3 mb-3" *ngIf=" showmounfollow" >Are you sure you want to unfollow @{{username}}?</p>
  <p class="h4 mt-3 mb-3" *ngIf="removeimage" >Are you sure you want to remove {{pagename}}?</p>
  <p class="h4 mt-3 mb-3" *ngIf=" showwithdraw" >Are you sure you want to withdraw your follow request?</p>
  <p class="h4 mt-3 mb-3" *ngIf="showmodalforpageredirect" >You must make an account to interact with uploaded media</p>
  <p class="h4 mt-3 mb-3" *ngIf="followerror" >You must make an account to follow the user</p>
   <button class="btn btn-secondary mb-3" *ngIf="showmodeletebannedpage"  (click)="leave()" >Cancel</button>
   <button class="btn btn-secondary mb-3" *ngIf="clearhistory"  (click)="leave()" >Cancel</button>
   <button class="btn btn-secondary mb-3" *ngIf="showwithdraw"  (click)="leave()" >Cancel</button>
   <button class="btn btn-secondary mb-3" *ngIf="removeimage"  (click)="leave()" >Cancel</button>
   <button class="btn btn-secondary mb-3" *ngIf="showmounfollow"  (click)="leave()" >Cancel</button>
   <button class="btn btn-theme mb-3 ml-3" *ngIf="showmodeletebannedpage"  (click)="delete()" >Yes, delete</button>
   <button class="btn btn-theme mb-3 ml-3" *ngIf="clearhistory"  (click)="delete()" >Yes, clear</button>
   <button class="btn btn-theme mb-3 ml-3" *ngIf="removeimage"  (click)="delete()" >Yes, remove</button>
   <button class="btn btn-theme mb-3 ml-3" *ngIf="showwithdraw"  (click)="delete()" >Yes, withdraw</button>
   <button class="btn btn-confirm mb-3 ml-3" *ngIf="showmounfollow"  (click)="delete()" >Yes, unfollow</button>
   <div class="flex">
     <button class="btn btn-theme mb-3 ml-3" *ngIf="showmodalforpageredirect" (click)="delete()">Register Now</button>
     <button class="btn btn-theme mb-3 ml-3" *ngIf="followerror" (click)="delete()">Register Now</button>
   <small *ngIf="showmodalforpageredirect">In case you have already an account , <a class="link" (click)="loginpage()">Click here to Log in</a> </small>
   <small *ngIf="followerror">In case you have already an account , <a class="link" (click)="loginpage()">Click here to Log in</a> </small>
   </div>
   
  </div>

<!-- <div class="delete">
  <button type="button" (click)="cancel()" class="close"><span aria-hidden="true">×</span></button>
  <h4 class="mt-0 mb-0">Delete Account</h4>
</div>
<!-- <mat-divider></mat-divider>
<div class="modal-body text-center delete-modal">
  <div _ngcontent-ysd-c142="" class="text-start" style="width: 100%; height: 100%; justify-content:center; align-items: flex-start; gap: 4px; display: inline-flex;">
    <img _ngcontent-ysd-c142="" src="../../../../assets/img/logo.png" style="width: 50px;">
    <img _ngcontent-ysd-c142="" src="../../../../assets/img/logo-secondary.png" style="width: 50px;">
  </div>
  <h4 class="mt-0 mb-0">Unfollow @{{username}}?</h4>
<p class="h4 mt-3 mb-3">Are you sure you want to unfollow @{{username}}?</p>
  <div class="delete-btns">
    <button class="btn red-btn" (click)="delete()">Yes, Unfollow</button> 
    <button class="btn cncl mb-3" (click)="leave()" >Cancel</button>
  </div>
</div> -->