

<span class="loader d-none" id="divLoading" [ngStyle]="{'display': displayname}">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span>
</span>



<main>
   
    <div class="container" >


        <div class="reciept">
        
            <h1 class="text-center">Subscribed Plan Detail</h1>
        
            <div class="height" >
                <table class="reciept-table table" *ngIf = "afterload">
                    <tbody>
                        <tr>
                            <td class="reciept-title">Plan name: </td>
                            <td class="pagedetails w100">{{Planname}}</td>
                        </tr>
                        <tr >
                            <td class="reciept-title">Plan price: </td>
                            <td class="pagedetails w100">{{planprice |currency}}</td>
                        </tr>
                        <tr *ngIf="!showpremimum">
                            <td class="reciept-title">Date: </td>
                            <td class="pagedetails w100">{{freedate}}</td>
                        </tr>
                        <tr >
                            <td class="reciept-title">Loopkits to upload: </td>
                            <td *ngIf="!showpremimum" class="pagedetails w100">{{loopkittoupload}} Loopkits</td>
                            <td *ngIf="showpremimum" class="pagedetails w100">Unlimited </td>
                        </tr>
                        <tr>
                            <td class="reciept-title">Loops to upload: </td>
                            <td *ngIf="!showpremimum" class="pagedetails w100">{{loopstoupload}} Loops</td>
                            <td *ngIf="showpremimum" class=" pagedetails w100">Unlimited </td>
                        </tr>
                        <tr  *ngIf="!showpremimum">
                            <td class="reciept-title">Can Sell Loops: </td>
                            <td class="pagedetails w100">No</td>
                        </tr>
                        <tr  *ngIf="!showpremimum">
                            <td class="reciept-title">Can Sell Loopkits: </td>
                            <td class="pagedetails w100">Yes</td>
                        </tr>
                        <tr  *ngIf="showpremimum">
                            <td class="reciept-title">Can Sell Loops: </td>
                            <td class="pagedetails w100">Yes</td>
                        </tr>
                        <tr  *ngIf="showpremimum">
                            <td class="reciept-title">Can Sell Loopkits: </td>
                            <td class="pagedetails w100">Yes</td>
                        </tr>
                        <tr  *ngIf="showpremimum">
                            <td class="reciept-title">Payment date: </td>
                            <td class="pagedetails w100">{{createdon}}</td>
                        </tr>
                    </tbody>
               </table>
               <!-- <h3>Planprice</h3>
               <h3>Planamount</h3> -->
        
        
            </div>
        </div>
        
            </div>


</main>





















