import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AudioPlayerConfig } from './audioPlayer.config';

@Component({
	selector: 'app-audio-player',
	templateUrl: './audio-player.component.html',
	styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {
	@Input() audioSrc: string;
	@Input() audioData: string;
	// audioSrc = "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3";
	@Input() config: AudioPlayerConfig;
	@ViewChild('audioPlayer') audioPlayer: ElementRef;
	@ViewChild('playhead') playhead: ElementRef;
	@ViewChild('timeline') timeline: ElementRef;

	public $trackLength: Observable<number>;
	public trackLength: number;
	public isPlaying: boolean = false;
	public isReadyForPlayback = true;


	constructor() { }

	ngOnInit() {
		this.config = {
			timelineConfig: {
				timelineColor: '#333333',
				timelineHeight: 4,
				playheadHeight: 12,
				playheadColor: 'lightblue',
				opacity: '0.6',
			},
			playPauseConfig: {
				iconClassPrefix: 'fa',
				iconPixelSize: 18,
				pauseIconClass: 'fa-pause',
				pauseIconColor: '#333333',
				playIconClass: 'fa-play',
				playIconColor: '#333333',
			},
		};

		// ////console.log(this.audioPlayer.nativeElement);


	}


	ngAfterViewInit() {
		// this.initEvents();
	}

	play() {
		// ////console.log(this.audioData);
		// ////console.log('play call');
		// ////console.log(this.audioSrc);
		// this.isPlaying = false;
		// this.audioPlayer.nativeElement.pause();
		// this.isPlaying = false;
		if (!this.isPlaying) {

			this.audioPlayer.nativeElement.pause();
			// ////console.log(this.audioPlayer.nativeElement.duration);
			// ////console.log(this.audioPlayer.nativeElement.duration);
			this.audioPlayer.nativeElement.play();
			this.isPlaying = true;
			if (this.audioPlayer.nativeElement.duration > 15) {
				setTimeout(() => {
        
			}, 15000);
			} else if (this.audioPlayer.nativeElement.duration  >= 5) {
				setTimeout(() => {
        
				}, 15000);
			} else if (this.audioPlayer.nativeElement.duration  >= 1) {
				setTimeout(() => {
        
				}, 15000);
			}
			

		} else {
			this.pause();
		}
	}

	pause() {
		// ////console.log('pause call')

		if (this.isPlaying) {
			this.audioPlayer.nativeElement.pause();
			this.isPlaying = false;
		}
	}


}
