

import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { NgWaveformModule } from 'ng-waveform';
import { ITimeUpdateEvent, NgWaveformComponent, IRegionPositions } from 'ng-waveform';
import * as CryptoJS from 'crypto-js';
// import { ConfirmDeleteComponent } from '../MODAL/confirm-delete/confirm-delete.component';
import { AudioPlayerComponent } from 'src/app/commonComponent/audio-player/audio-player.component';
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { OwlOptions } from 'ngx-owl-carousel-o';
SwiperCore.use([Pagination, Navigation]);
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { ConfirmDeleteComponent } from '../CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
import { DownloadmodalComponent } from '../CLIENT_PAGES/MODAL/downloadmodal/downloadmodal.component';
// import { ConfirmDeleteComponent } from 'src  siredit/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';

declare var p5: { SoundFile: new (arg0: string, arg1: (s: any) => void, arg2: (err: any) => void) => any; };
@Component({
  selector: 'app-rough',
  templateUrl: './rough.component.html',
  styleUrls: ['./rough.component.css']
})
  
  
export class RoughComponent implements OnInit {
  @ViewChild(AudioPlayerComponent) player: AudioPlayerComponent;
  @ViewChild('waveform', { static: false }) waveform: NgWaveformComponent;
  arraytableforcomments: any = [];
  hideloopprice: boolean;
  loop_price: string;
  artworkpathimage = "assets/img/artwork.jpg";
  pageno: any = 1;
  totalpages: any;
  hideplaybutton: boolean;
  hidepausebutton: boolean = true;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  array: any = [];
  first = true;
  addred: boolean;
  addgreen: boolean;
  // class: HTMLCollectionOf<Element>;
  class: any;
  lefthide = false;
  pagenoforloopkit: any = 1;
  pagenoforuser: any = 1;
  arraytableforcommentsforloopkit: any = [];
  totalpagesforloopkit: any;
  totalpageforuser: any;
  firstforloopkit = true;
  arrayforllopkit: any = [];
  lefthideforloopkit = false;
  hidepagination = true;
  hidepaginationforloop = true
  stoploader1: boolean;
  stoploader2: boolean;
  durationofaudio: any;
  stoploader3: boolean;
  arraytablefortags: any = [];
  arraytableforrecommendeduser: any = [];
  lefthideforuser = false;
  hidepaginationforuser = true;
  firstforuser = true;
  arrayforuser: any = [];
  stoploader4: boolean;
  righthide = false;
  righthideforloopkit = false;
  righthideforuser = false;
  maximumnooflisting: any;
  rowsizeforloop = 5;
  rowsizeforloopkit = 5;
  rowsizeforcheckthemout = 6;
  stoploader5: boolean
  validationforsearch: any = '';
  slideper: any;
  slideperforcheck: any;
  arrayforsearch: any = [];
  hiderecentsearches: boolean;

  constructor(public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService, private matDialog: MatDialog) { }
  public innerWidth: any;
  public innerheight: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    // this.innerheight = window.innerHeight;
    // ////console.log('test', this.innerWidth)
    // ////console.log('test', this.innerheight)
    if (window.innerWidth < 1199 && window.innerWidth > 991 ) {
      this.slideper = 4;
      this.slideperforcheck = 4;
      // ////console.log("1");
    } else if (window.innerWidth < 991 && window.innerWidth > 667) {
      this.slideper = 3;
      this.slideperforcheck = 3;
      // ////console.log("2");
    } else if (window.innerWidth < 667 && window.innerWidth > 480) {
      this.slideper = 2;
      this.slideperforcheck = 2;
      // ////console.log("3");
    } else if (window.innerWidth < 480 && window.innerWidth > 0) {
      this.slideper = 1;
      this.slideperforcheck = 1;
      // ////console.log("4");
    } else {
      this.slideper = 5;
      this.slideperforcheck = 6;
    }
  }
  ngOnInit(): void {
    this.gethomepagevalue();
    if (window.innerWidth < 1199 && window.innerWidth > 991 ) {
      this.slideper = 4;
      this.slideperforcheck = 4;
      // ////console.log("1");
    } else if (window.innerWidth < 991 && window.innerWidth > 667) {
      this.slideper = 3;
      this.slideperforcheck = 3;
      // ////console.log("2");
    } else if (window.innerWidth < 667 && window.innerWidth > 480) {
      this.slideper = 2;
      this.slideperforcheck = 2;
      // ////console.log("3");
    } else if (window.innerWidth < 480 && window.innerWidth > 0) {
      this.slideper = 1;
      this.slideperforcheck = 1;
      // ////console.log("4");
    } else {
      this.slideper = 5;
      this.slideperforcheck = 6;
    }
  }


  ngOnDestroy() {
    this.stopAudio();
  }


  async getapiforrecommendedloops() {
    this.common.showLoader();
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }
    if (this.maximumnooflisting < this.rowsizeforloop) {
      this.rowsizeforloop = this.maximumnooflisting
      // ////console.log("bigger");
    } else {
      if (this.maximumnooflisting -= this.rowsizeforloop) {
        
      }
      // ////console.log(this.maximumnooflisting);
    }

    let sendingdata = {
      "page_no": this.pageno,
      "sort_on": "created_on",
      "row_size": 10,
      "sort_type": "DESC"
    }
    // this.http.post(APIURL.BASE_URL + `/user/get_recommended_loops`, sendingdata, { headers }).subscribe(async (res: any) => {
      this.api.forclientgettokenpublic(`/user/get_recommended_loops`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        // this.common.hideLoader();
        this.stoploader1 = true;
        // ////console.log(res);

        this.callforloader();
        this.arraytableforcomments = res.response.data.pagging_list;
        this.firstruna();
        // this.firstrun();
        // ////console.log(res.response.data)
        // // ////console.log(res.response.data.total_pages)
        // ////console.log(this.maximumnooflisting);
        // ////console.log(res.response.data.total_records);
        

        this.totalpages = res.response.data.total_pages;
        // ////console.log(this.totalpages);
        // if (this.pageno == 1) {
        //   this.lefthide = true;
        // } else {
        //   this.lefthide = false;
        // }

        // if (this.pageno == this.totalpages) {
        //   // this.righthide = true;

        // } else {
        //   this.righthide = false;
        // }




        // var size = 5;
        // var items = res.response.data.pagging_list.slice(0, size).map((i: any) => {
        //   return 
        // })


        // ////console.log(this.arraytableforcomments)





      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }


  async getapiforrecommendedloopkit() {
    this.common.showLoader();
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }
    if (this.maximumnooflisting < this.rowsizeforloopkit) {
      this.rowsizeforloopkit = this.maximumnooflisting
      // ////console.log("bigger");
    } else {
      if (this.maximumnooflisting -= this.rowsizeforloopkit) {
        
      }
      // ////console.log(this.rowsizeforloop);
    }

    let sendingdata = {
      "page_no": this.pagenoforloopkit,
      "sort_on": "created_on",
      "row_size": 10,
      "sort_type": "DESC"
    }
    // this.http.post(APIURL.BASE_URL + `/user/get_recommended_loopkits`, sendingdata, { headers }).subscribe(async (res: any) => {
      this.api.forclientgettokenpublic(`/user/get_recommended_loopkits`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        // this.common.hideLoader();


        this.stoploader2 = true;
        this.callforloader();
        this.arraytableforcommentsforloopkit = res.response.data.pagging_list;
        this.firstrunforloopkit();

        this.totalpagesforloopkit = res.response.data.total_pages;

        // ////console.log(this.arraytableforcommentsforloopkit);

        // this.firstrunforloopkit();

        // this.totalpagesforloopkit = res.response.data.total_pages;
        // if (this.pagenoforloopkit == 1) {
        //   this.lefthideforloopkit = true;
        // } else {
        //   this.lefthideforloopkit = false;
        // }

        // if (this.pagenoforloopkit == this.totalpagesforloopkit) {
        //   this.righthideforloopkit = true;
        // } else {
        //   this.righthideforloopkit = false;
        // }


      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  async gethomepagevalue() {
    this.common.showLoader();

    let sendingdata = {
     
    }
   
      this.api.forclientgettokenpublic(`/user/get_global_settings`, sendingdata).then((res: any) => {
        if (res.response.status == 1) {
          this.stoploader5 = true;
        // ////console.log(res.response.data[4].meta_value);
        this.maximumnooflisting = res.response.data[4].meta_value;
        this.getapiforrecommendedloops();
    this.getapiforrecommendedloopkit();
    this.getapifortag();
    this.getapiforrecommendeduser();
       
      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }



  async getapiforrecommendeduser() {
    this.common.showLoader();
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }
    if (this.maximumnooflisting < this.rowsizeforcheckthemout) {
      this.rowsizeforcheckthemout = this.maximumnooflisting
      // ////console.log("bigger");
    } else {
      if (this.maximumnooflisting -= this.rowsizeforcheckthemout) {
        
      }
      // ////console.log(this.rowsizeforloop);
    }


    let sendingdata = {
      "page_no": this.pagenoforuser,
      "sort_on": "created_on",
      "row_size": 12,
      "sort_type": "DESC"
    }
    // this.http.post(APIURL.BASE_URL + `/user/get_recommended_users`, sendingdata, { headers }).subscribe(async (res: any) => {
      this.api.forclientgettokenpublic(`/user/get_recommended_users`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        // this.common.hideLoader();
        this.stoploader4 = true;
        // ////console.log(res);

        this.callforloader();



        this.arraytableforrecommendeduser = res.response.data.pagging_list;
        this.firstrunforuser();
        // this.firstrunforuser();

        this.totalpageforuser = res.response.data.total_pages;
        // ////console.log(this.totalpages);

        // ////console.log(this.arraytableforcomments)
        // if (this.pagenoforuser == 1) {
        //   this.lefthideforuser = true;
        // } else {
        //   this.lefthideforuser = false;
        // }

        // if (this.pagenoforuser == this.totalpageforuser) {
        //   this.righthideforuser = true;
        // } else {
        //   this.righthideforuser = false;
        // }





      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }





  callforloader() {
    if (this.stoploader2 == true && this.stoploader1 && this.stoploader3 && this.stoploader4 && this.stoploader5) {
      this.common.hideLoader();
      // ////console.log("problem");
    } else {

    }
  }




  // firstruna() {
  //   this.first = !this.first
  //   this.array = [];
  //   if (!this.first) {
  //     if (this.arraytableforcomments.length > 5) {
  //       for (let i = 0; i < 5; i++) {
  //         this.array.push(this.arraytableforcomments[i])
  //         ////console.log(this.arraytableforcomments[i]);
  //       }
  //       // ////console.log("1");
  //       this.lefthide = true;
  //       this.hidepaginationforloop = true;
  //     } else {
  //       for (let i = 0; i < this.arraytableforcomments.length; i++) {
  //         this.array.push(this.arraytableforcomments[i])
  //       }
  //       // ////console.log("2");
  //       // this.hidepagination = false;
  //       this.lefthide = true;
  //       this.hidepaginationforloop = false;
  //     }



  //   } else {
  //     if (this.arraytableforcomments.length > 5) {
  //       for (let i = 5; i < this.arraytableforcomments.length; i++) {
  //         this.array.push(this.arraytableforcomments[i]);
  //       }
  //       // ////console.log("3");
  //       this.hidepaginationforloop = true;


  //       this.lefthide = false;
  //     } else {
  //       for (let i = 5; i < this.arraytableforcomments.length; i++) {
  //         this.array.push(this.arraytableforcomments[i])
  //       }
  //       this.lefthide = false;
  //       this.hidepaginationforloop = false;
  //       // ////console.log("4");

  //     }


  //   }
  // }

  // firstrunnext() {

  //   // if (this.pageno == 1) {
  //   //   this.lefthide = true;
  //   //   ////console.log("yes 1");
  //   // }

  //   this.pageno = this.pageno + 1;

  //   if (this.pageno > this.totalpages) {
  //     this.pageno = 1;
  //   }
  //   this.getapiforrecommendedloops();
  //   // ////console.log(this.pageno);
    

  // }

  // firstrunprev() {
  //   this.pageno = this.pageno - 1;
  //   this.getapiforrecommendedloops();

  // }


  // firstrunforloopkitprev() {
  //   this.pagenoforloopkit = this.pagenoforloopkit - 1;
  //   this.getapiforrecommendedloopkit();
  // }

  // firstrunforloopkitnext() {
  //   this.pagenoforloopkit = this.pagenoforloopkit + 1;
  //   this.getapiforrecommendedloopkit();
  // }

  // firstrunforuserprev() {
  //   this.pagenoforuser = this.pagenoforuser - 1;
  //   this.getapiforrecommendeduser();
  // }

  // firstrunforusernext() {
  //   this.pagenoforuser = this.pagenoforuser + 1;
  //   this.getapiforrecommendeduser();
  // }




  // firstrunforloopkit() {
  //   this.firstforloopkit = !this.firstforloopkit
  //   this.arrayforllopkit = [];
  //   if (!this.firstforloopkit) {
  //     if (this.arraytableforcommentsforloopkit.length > 5) {
  //       for (let i = 0; i < 5; i++) {
  //         this.arrayforllopkit.push(this.arraytableforcommentsforloopkit[i])
  //       }
  //       this.lefthideforloopkit = true;
  //       this.hidepagination = true;
  //     } else {
  //       for (let i = 0; i < this.arraytableforcommentsforloopkit.length; i++) {
  //         this.arrayforllopkit.push(this.arraytableforcommentsforloopkit[i]);
  //       }
  //       this.hidepagination = false;
  //       this.lefthideforloopkit = true;
  //     }



  //   } else {
  //     if (this.arraytableforcommentsforloopkit.length > 5) {
  //       for (let i = 5; i < this.arraytableforcommentsforloopkit.length; i++) {
  //         this.arrayforllopkit.push(this.arraytableforcommentsforloopkit[i]);

  //       }
  //       this.hidepagination = true;

  //       this.lefthideforloopkit = false;
  //     } else {
  //       for (let i = 5; i < this.arraytableforcommentsforloopkit.length; i++) {
  //         this.arrayforllopkit.push(this.arraytableforcommentsforloopkit[i])
  //       }
  //       this.lefthideforloopkit = false;
  //       this.hidepagination = false;

  //     }
  //   }

  // }


  // firstrunforuser() {
  //   this.firstforuser = !this.firstforuser
  //   this.arrayforuser = [];
  //   if (!this.firstforuser) {
  //     ////console.log(this.arraytableforrecommendeduser.length);
  //     if (this.arraytableforrecommendeduser.length > 6) {
  //       for (let i = 0; i < 6; i++) {
  //         this.arrayforuser.push(this.arraytableforrecommendeduser[i]);
  //         ////console.log(this.arraytableforrecommendeduser[i]);
  //       }
  //       this.lefthideforuser = true;
  //       this.hidepaginationforuser = true;
  //     } else {
  //       for (let i = 0; i < this.arraytableforrecommendeduser.length; i++) {
  //         this.arrayforuser.push(this.arraytableforrecommendeduser[i]);
  //       }
  //       this.hidepaginationforuser = false;
  //       this.lefthideforuser = true;
  //     }

  //   } else {
  //     if (this.arraytableforrecommendeduser.length > 6) {
  //       for (let i = 6; i < this.arraytableforrecommendeduser.length; i++) {
  //         this.arrayforuser.push(this.arraytableforrecommendeduser[i]);

  //       }
  //       this.hidepaginationforuser = true;

  //       this.lefthideforuser = false;
  //     } else {
  //       for (let i = 6; i < this.arraytableforrecommendeduser.length; i++) {
  //         this.arrayforuser.push(this.arraytableforrecommendeduser[i])
  //       }
  //       this.lefthideforuser = false;
  //       this.hidepaginationforuser = false;

  //     }
  //   }

  // }

  firstruna() {
    this.first = !this.first
    this.array = [];
    if (!this.first) {
      if (this.arraytableforcomments.length > 5) {
        for (let i = 0; i < 5; i++) {
          this.array.push(this.arraytableforcomments[i])
          // ////console.log(this.arraytableforcomments[i]);
          // ////console.log("gaya")
        }
        // ////console.log("1");
        this.lefthide = true;
        this.hidepaginationforloop = true;
      } else {
        for (let i = 0; i < this.arraytableforcomments.length; i++) {
          this.array.push(this.arraytableforcomments[i])
        }
        // ////console.log("2");
        // this.hidepagination = false;
        this.lefthide = true;
        this.hidepaginationforloop = false;
      }



    } else {
      if (this.arraytableforcomments.length > 5) {
        for (let i = 5; i < this.arraytableforcomments.length; i++) {
          this.array.push(this.arraytableforcomments[i]);
        }
        // ////console.log("3");
        this.hidepaginationforloop = true;


        this.lefthide = false;
      } else {
        for (let i = 5; i < this.arraytableforcomments.length; i++) {
          this.array.push(this.arraytableforcomments[i])
        }
        this.lefthide = false;
        this.hidepaginationforloop = false;
        // ////console.log("4");

      }


    }
  }

  

  firstrunforloopkit() {
    // ////console.log("gaya")
    this.firstforloopkit = !this.firstforloopkit
    this.arrayforllopkit = [];
    if (!this.firstforloopkit) {
      if (this.arraytableforcommentsforloopkit.length > 5) {

        // ////console.log("in")
        for (let i = 0; i < 5; i++) {
          this.arrayforllopkit.push(this.arraytableforcommentsforloopkit[i])
        }
        this.lefthideforloopkit = true;
        this.hidepagination = true;
      } else {
        for (let i = 0; i < this.arraytableforcommentsforloopkit.length; i++) {
          this.arrayforllopkit.push(this.arraytableforcommentsforloopkit[i]);
        }
        this.hidepagination = false;
        this.lefthideforloopkit = true;
      }



    } else {
      if (this.arraytableforcommentsforloopkit.length > 5) {
        for (let i = 5; i < this.arraytableforcommentsforloopkit.length; i++) {
          this.arrayforllopkit.push(this.arraytableforcommentsforloopkit[i]);

        }
        this.hidepagination = true;

        this.lefthideforloopkit = false;
      } else {
        for (let i = 5; i < this.arraytableforcommentsforloopkit.length; i++) {
          this.arrayforllopkit.push(this.arraytableforcommentsforloopkit[i])
        }
        this.lefthideforloopkit = false;
        this.hidepagination = false;

      }
    }

  }


  firstrunforuser() {
    this.firstforuser = !this.firstforuser
    this.arrayforuser = [];
    if (!this.firstforuser) {
      // ////console.log(this.arraytableforrecommendeduser.length);
      if (this.arraytableforrecommendeduser.length > 6) {
        for (let i = 0; i < 6; i++) {
          this.arrayforuser.push(this.arraytableforrecommendeduser[i]);
          // ////console.log(this.arraytableforrecommendeduser[i]);
        }
        this.lefthideforuser = true;
        this.hidepaginationforuser = true;
      } else {
        for (let i = 0; i < this.arraytableforrecommendeduser.length; i++) {
          this.arrayforuser.push(this.arraytableforrecommendeduser[i]);
        }
        this.hidepaginationforuser = false;
        this.lefthideforuser = true;
      }

    } else {
      if (this.arraytableforrecommendeduser.length > 6) {
        for (let i = 6; i < this.arraytableforrecommendeduser.length; i++) {
          this.arrayforuser.push(this.arraytableforrecommendeduser[i]);

        }
        this.hidepaginationforuser = true;

        this.lefthideforuser = false;
      } else {
        for (let i = 6; i < this.arraytableforrecommendeduser.length; i++) {
          this.arrayforuser.push(this.arraytableforrecommendeduser[i])
        }
        this.lefthideforuser = false;
        this.hidepaginationforuser = false;

      }
    }

  }






  setImage(item: any) {
    if (item.artwork_path !== null) {
      try {
        return CONSTNAME.fILE_PATH + item.artwork_path;

      } catch (err) {
        // ////console.log("no")
        return this.artworkpathimage;
      }

    } else if (item.profile_image_path !== null) {
      try {
        return CONSTNAME.fILE_PATH + item.profile_image_path;
      }
      catch (err) {
        // ////console.log("no")
        return this.artworkpathimage;

      }

    } else {
      try {
        return this.artworkpathimage;
      }
      catch (err) {
        // ////console.log("no")
        return this.artworkpathimage;

      }

    }

  }

  setImageforuser(item: any) {
    if (item.profile_image_path !== null) {
      try {
        return CONSTNAME.fILE_PATH + item.profile_image_path;
      }
      catch (err) {
        // ////console.log("no")
        return this.artworkpathimage;

      }

    } else {
      try {
        return this.artworkpathimage;
      }
      catch (err) {
        // ////console.log("no")
        return this.artworkpathimage;

      }

    }
  }

  showpricetag(item: any) {
    if (item.loop_price == 0) {
      // return this.hideloopprice = true;
      return '<i  class="fa fa-download"></i>'
    } else {
      this.loop_price = (Math.round(item.loop_price * 100) / 100).toFixed(2);
      return `<i class="fa fa-dollar">${this.loop_price}</i>`;
    }
  }
  priceget(item: any) {
    if (item.loop_price == 0) {

    } else {
      return (Math.round(item.loop_price * 100) / 100).toFixed(2);

    }
  }


  // showaccordingto() {
  //   // ////console.log("check");
  //   this.first = true;
  //   this.common.showLoader();
  //   if (this.totalpages === this.pageno) {
  //     this.pageno = 1;
  //     this.getapiforrecommendedloops();
  //   } else {
  //     this.common.showLoader();
  //     this.pageno = 1;
  //     this.getapiforrecommendedloops();
  //     // ////console.log("go in");
  //   }

  // }

  // showaccordingtoloopkit() {
  //   this.firstforloopkit = true;
  //   this.common.showLoader();

  //   if (this.totalpagesforloopkit === this.pagenoforloopkit) {
  //     this.pagenoforloopkit = 1;
  //     this.getapiforrecommendedloopkit();
  //   } else {
  //     this.common.showLoader();
  //     this.pagenoforloopkit = 1;
  //     this.getapiforrecommendedloopkit();
  //   }

  // }
  // showaccordingtouserrecommend() {
  //   this.firstforuser = true;
  //   this.common.showLoader();

  //   if (this.totalpageforuser === this.pagenoforuser) {
  //     this.pagenoforuser = 1;
  //     this.getapiforrecommendeduser();
  //   } else {
  //     this.common.showLoader();
  //     this.pagenoforuser = 1;
  //     this.getapiforrecommendeduser();
  //   }
  // }


  showaccordingto() {
    // ////console.log("check");
    this.first = true;
    this.common.showLoader();
    if (this.totalpages === this.pageno) {
      this.pageno = 1;
      this.getapiforrecommendedloops();
    } else {
      this.common.showLoader();
      this.pageno = this.pageno + 1;
      this.getapiforrecommendedloops();
      // ////console.log("go in");
    }

  }

  showaccordingtoloopkit() {
    this.firstforloopkit = true;
    this.common.showLoader();

    if (this.totalpagesforloopkit === this.pagenoforloopkit) {
      this.pagenoforloopkit = 1;
      this.getapiforrecommendedloopkit();
    } else {
      this.common.showLoader();
      this.pagenoforloopkit = this.pagenoforloopkit + 1;
      this.getapiforrecommendedloopkit();
    }

  }
  showaccordingtouserrecommend() {
    this.firstforuser = true;
    this.common.showLoader();

    if (this.totalpageforuser === this.pagenoforuser) {
      this.pagenoforuser = 1;
      this.getapiforrecommendeduser();
    } else {
      this.common.showLoader();
      this.pagenoforuser = this.pagenoforuser + 1;
      this.getapiforrecommendeduser();
    }
  }


  onPlayButtonClick(item: any) {
    //  ////console.log(item);

    // ////console.log(item.loop_id)
    let loop_audio = item.tagged_loop_path
    // if(item.loop_id==this.arraytableforcomments)
    // ////console.log(this.waveform);
    // this.waveform.play();
    item.hidepausebutton = true;
    this.player.play();
    // item.hidepausebutton = false;
    // ////console.log(this)
  }
  onPauseButtonClick(item: any) {
    // ////console.log('testPause');
    this.player.pause();
    // this.waveform.pause();
    // item.hidepausebutton = true;
    item.hidepausebutton = false;
  }


  srcfileforimage(item: any) {
    if (item.loop_type == 1) {
      if (item.tagged_loop_path !== null) {
        return CONSTNAME.fILE_PATH + item.tagged_loop_path;
        // ////console.log("checking");
      } else {
        return CONSTNAME.fILE_PATH + item.loop_path;
      }

      // ////console.log(this.src);
    } else {
      return CONSTNAME.fILE_PATH + item.tagged_loop_path;
      // ////console.log(this.src);
    }
  }


  async redirecttolooppagecomment(value: any) {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    if (tokkenget) {
      this.redirecttolooppage(value)
    } else {
      this.opendialogueforverification();
    }

  }

  redirecttolooppage(value: any) {
    // ////console.log(value)

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(value);
    // ////console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });

    // ////console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    // this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);

  }


  callforcolour(item: any) {
    if (item.is_reposted == 1) {
      return true;
    } else {
      return false;
    }
  }

  addredcolor(item: any) {
    // ////console.log("yes add")
    if (item.is_liked == 1) {
      return true;
    } else {
      return false;
    }
  }

  async heartcolorred(value: any, detail: any, item: any) {
    // ////console.log(item)
    if (item.is_liked == 1) {
      item.is_liked = 0;
      // ////console.log("here")
    } else if (item.is_liked == 0) {
      item.is_liked = 1;
      // ////console.log("not here")
    }
    // ////console.log("red");
    let sendingData = {
      "loop_id": value,
    }
    // ////console.log(sendingData);

    



    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      this.common.showLoader();
      this.api.postApiHit(`/user/like_dislike_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          // let v: any = document.getElementById("red");
          // v.classList.add("red");
          if (detail == "loopkit") {
            // this.getapiforrecommendedloopkit();
          } else {
            // this.getapiforrecommendedloops();
          }



          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();

          // ////console.log("gaya");
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }


        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }

  }


  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "detailpage" } });
    dialogue.afterClosed().subscribe(result => {



      if (result.data == true) {
        this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
        this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
        // this.router.navigate(['/users/uploadloopstatus']);
      } else {

      }

    });
  }


  async repost(value: any,item:any) {

    let sendingData = {
      "loop_id": value,
    }


    if (item.is_reposted == 1) {
      item.is_reposted = 0;
    } else if (item.is_reposted == 0) {
      item.is_reposted = 1;
    }
    // ////console.log(sendingData);
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {


      this.common.showLoader();
      this.api.postApiHit(`/user/repost_upload`, sendingData).then((res: any) => {
        if (res.response.status == 1) {
          // let v: any = document.getElementById("green");
          // v.classList.add("green");
          // this.getapiforrecommendedloops();

          this.common.hideLoader();
          // ////console.log(value);


          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();

          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
          this.router.navigate(['/users/uploadloopstatus']);

        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.opendialogueforverification();


    }
  }



  async apihitforstreaming(item: any, r: any, dura: any) {
    // this.common.showLoader();
    // ////console.log("apihit");
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "audio_duration": dura,
      "loop_id": item.loop_id,
      "play_duration": r
    }
    this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      }

      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.cancel();
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }


    }, async e => {

      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }


  srcforloopid(value: any) {

    return value.loop_id;
  }
    call3() {
    // this.firstrunnext();
    // this.firstrunforusernext();
    // ////console.log("fre")
    // ////console.log("last");
  }
  call2() {
    // this.firstrunnext();
    // this.firstrunforusernext();
    // ////console.log("fre")
    // this.firstrunforloopkitnext();
    // ////console.log("last");
  }
  call1() {
    // this.firstrunnext();
    // this.getapiforrecommendedloops()();
    // ////console.log("fre")
    // ////console.log("last");
  }

  file = '';
  audio = new Audio(this.file);
  id: any;
  above15second = false;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  apihitforstream = true;
  playAudio(item: any) {

    this.arraytableforcomments.map((r: any) => {
      r.hidepausebutton = false;
    })
    this.arraytableforcommentsforloopkit.map((r: any) => {
      r.hidepausebutton = false;
    })

    this.arraytableforcomments.map((r: any) => {
      if (r.audio) r.audio.pause()
    })
    this.arraytableforcommentsforloopkit.map((r: any) => {
      if (r.audio) r.audio.pause()
    })

    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      item.audio.play();
      // ////console.log('item.duration', item.duration)
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true
        // ////console.log("above 15 sec");
      }
      else if (item.duration < 15 && item.duration >= 5) {
        // ////console.log("between 5 and 15");
        this.apihitforstream = true
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {
        // ////console.log("between 1 and 5");
        this.apihitforstream = true
        this.above1secondlessthan5 = true;
      }
    } else {
      item.audio.play();
    }



    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      const ended = item.audio.complete

      // ////console.log(item.audio.ended);
      // ////console.log(item.audio.onended);
      // ////console.log(item.audio);
      // ////console.log(ended);
      if (item.audio.onended) {
        // ////console.log(Math.round(r));
      }
      if (Math.round(r) + 1 >= item.duration) {

        // ////console.log("gaya");

        item.audio.pause();
        setTimeout(() => {
          // item.audio.pause();
          item.hidepausebutton = false;
        }, 1000);
      }
      // ////console.log(item.audio.currentTime);
      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
            // ////console.log("above 15 api hit");
          }

        }
      } else if (item.duration < 15 && item.duration >= 5) {
        // ////console.log("gaya")
        if (r > 5) {
          // ////console.log("idar tak gaya")
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
            // ////console.log("below 15 and above 5 api hit");
          }
        }


      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
            // ////console.log("below 5 and above 1 api hit");
          }

        }
      }
    }, 200);

  }


  stopAudio(item: any = null) {
    if (item) {
      if (item.audio) {
        item.audio.pause()
      }
      clearInterval(this.id);

      this.arraytableforcomments.map((r: any) => {
        r.hidepausebutton = false;
      })
      this.arraytableforcommentsforloopkit.map((r: any) => {
        r.hidepausebutton = false;
      })
    } else {
      this.arraytableforcomments.map((r: any) => {
        if (r.audio) {
          r.audio.pause()
        }
        r.hidepausebutton = false;
      })
      this.arraytableforcommentsforloopkit.map((r: any) => {
        if (r.audio) {
          r.audio.pause()
        }
        r.hidepausebutton = false;
      })
    }


  }

  hitApiafter15sec(item: any) {
    // ////console.log('itmeId', item.loop_id)

  }


 
  

  async getapifortag() {
    this.common.showLoader();
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    if (tokkenget) {
      headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    }

    let sendingdata = {
      "page_no": 1,
      "sort_on": "created_on",
      "row_size": "50",
      "sort_type": "DESC"
    }

    // this.http.post(APIURL.BASE_URL + `/user/get_recommended_tags`, sendingdata, { headers }).subscribe(async (res: any) => {
      this.api.forclientgettokenpublic(`/user/get_recommended_tags`, sendingdata).then((res: any) => {
    
      if (res.response.status == 1) {
        // this.common.hideLoader();
        this.stoploader3 = true;
        // ////console.log(res);

        this.callforloader();






        // var size = 5;
        // var items = res.response.data.pagging_list.slice(0, size).map((i: any) => {
        //   return 
        // })
        let sortarray = res.response.data.pagging_list;

        sortarray = sortarray.sort(() => Math.random() - 0.5)
        // ////console.log(sortarray);

        this.arraytablefortags = sortarray;
        // this.firstrun();

        // this.totalpages = res.response.data.total_pages;
        // ////console.log(this.totalpages);

        // ////console.log(this.arraytableforcomments)


        var i;
        for (let k: any = 0; k < res.response.data.pagging_list.length; k++) {
          let text: any = res.response.data.pagging_list[k].total_tag_count + "<br>";
          // ////console.log(text);
        }




      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i: any = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  forfont(item: any) {
    if (item.total_tag_count <= 40 && item.total_tag_count >= 30) {
      return 'eighty'
    } else if (item.total_tag_count <= 30 && item.total_tag_count >= 20) {
      return 'sixty'
    } else if (item.total_tag_count <= 20 && item.total_tag_count >= 10) {
      return 'forty'
    } else {
      return 'twenty'
    }
  }


  redirecttoprofilepage(item: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item.user_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(item.user_id);

    this.router.navigate(['user/sounds'], { queryParams: { user_id: this.encrypted } });

  }

  redirectto(item: any) {
    // ////console.log(item)
    this.router.navigate(['search-by-tag/'], { queryParams: { search_by: item } });
    // ////console.log(item)
    
  }

  redireacttoadvancesearch() {
    this.router.navigate(['/advancesearch'], { queryParams: { search: '' } })
  }

  searchClick() {

    if (this.validationforsearch == "") {
      // this.arrayforsearch = [];
      let khali:any = ""

      // var stringArray = this.arrayforsearch;
      this.router.navigate(['search-by-tag/'], { queryParams: { search_by: null } })
    } else {
      this.router.navigate(['/advancesearch'], { queryParams: { search: this.validationforsearch } })
    }
  }

  openmodal(item: any) {
    // ////console.log(item)
    let idfromloop: any = item.user_id
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
      }
      let resa = JSON.parse(res)
      if (resa) {
        let idfromwebsite: any = resa.user_type_id
        // ////console.log(idfromwebsite, idfromloop)

        if (idfromloop === idfromwebsite) {
          // ////console.log("gaya")
          this.downloadloop(item)
          // this.apihitelsepart(item);
        } else {
          // ////console.log("getting")
          this.apihitelsepart(item);

        }
      };
    })
  }

  
  downloadloop(res: any) {

    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let encrypted = CryptoJS.AES.encrypt(
    //   JSON.stringify(res.loop_id), _key, {
    //     keySize: 16,
    //     iv: _iv,
    //     mode: CryptoJS.mode.ECB,
    //     padding: CryptoJS.pad.Pkcs7
    //   });
    // this.encrypted = encrypted.toString();
    // this.encrypted =encodeURIComponent(this.encrypted);
    // this.router.navigate(['/profile/archive'], { queryParams: { loop_id: this.encrypted } });
    this.router.navigate(['/profile/downloadhistory']);
    // ////console.log("going in")
    this.common.hideLoader();
    window.location.href = `${APIURL.BASE_URL}/user/download_loop?loop_id=${res.loop_id}`;

  }

  apihitelsepart(item: any) {
    // ////console.log(item.loop_id)
    this.common.showLoader();
    let sendingdata = {
      "loop_id": item.loop_id
    }
    this.api.postApiHit(`/user/get_upload_detail`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        // ////console.log(item)
        if (res.response.data.loop_details[0].is_already_downloaded == 0 && res.response.data.loop_details[0].is_already_paid == 0) {

          if (res.response.data.loop_details[0].selling_preference == 1) {
            this.download("free", item);
            this.common.hideLoader();
            // ////console.log("free")
          } else {
            if (res.response.data.loop_details[0].loop_price == 0) {
              this.download("free", item);
              this.common.hideLoader();
              // ////console.log("free")
            } else {
              this.download("premium", item);
              this.common.hideLoader();
              // ////console.log("premium")
            }


          }
        } else if (res.response.data.loop_details[0].is_already_downloaded == 0 && res.response.data.loop_details[0].is_already_paid == 1) {
          this.common.hideLoader();
        } else if (res.response.data.loop_details[0].is_already_downloaded == 1 && res.response.data.loop_details[0].is_already_paid == 1) {
          // ////console.log("yes");
          this.common.hideLoader();
          this.downloadloop(item)
        } else if (res.response.data.loop_details[0].is_already_downloaded == 1 && res.response.data.loop_details[0].is_already_paid == 0) {
          this.downloadloop(item)
        }
        this.common.hideLoader();
      } else if (res.response.status == 2) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
      else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }


  download(value: any, item: any): void {
    // ////console.log(item)
    let dialogue = this.matDialog.open(DownloadmodalComponent, { panelClass: 'forgot-password', disableClose: true, data: { pagevalue: value, allitems: item } });
    dialogue.afterClosed().subscribe(result => {

      if (result.data == "download") {
        this.downloadloop(item);
        // ////console.log("download")
      } else {

      }

    });
  }

  getUserDetail() {
    this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptplandetail(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }

  async searchhistory1() {
    // ////console.log("gaya")
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    // if (tokkenget) {
    //   headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

    // }
    if (tokkenget) {
      this.searchhistory();
      // ////console.log("yes")
    } else {
      // ////console.log("nahi gaya")
    }
  }

  searchhistory() {
    // ////console.log(this.validationforsearch)
    let sendingdata = {
      "search": this.validationforsearch
    }
    this.api.forclientgettoken(`/user/get_past_search`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
       
        // ////console.log(res);
        // ////console.log(res.response.data.total_records);
        
        if (res.response.data.total_records == 0) {
          this.hiderecentsearches = true;
          this.arrayforsearch = []
        } else {
          this.hiderecentsearches = false;
          this.arrayforsearch = res.response.data.pagging_list
        }
      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      // this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }
  async clearhistory1() {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget)
    let headers: any;
    
    if (tokkenget) {
      this.clearhistory1();
    }
  }

  clearhistory() {
    // ////console.log(this.validationforsearch)
    let sendingdata = {
      
    }
    this.api.forclientgettoken(`/user/delete_past_search`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
       
        // ////console.log(res);
        // ////console.log(res.response.data.total_records);
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        this.arrayforsearch = []
        // if (res.response.data.total_records == 0) {
        //   this.hiderecentsearches = true;
        // }
      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      // this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }







}


















