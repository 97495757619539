import { Component, ElementRef, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, APP_ROUTES, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  loginForm = StaticService.getLoginFormAdmin();
  lFormC: any;
  apiHit = false;
  constructor(
    private el: ElementRef,
    public api : ApiServiceService,
    public common: commonservice,
    private spinner: NgxSpinnerService,
    public router: Router, private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    let a = localStorage.getItem("loggedintoken");
    if (a) {
     
      this.router.navigate(['admin/dashboard']);
      
      
    }
  }

  vCheck(fieldName: any, type: INPUT_TYPE_NAME, options: any = {}) {
    options.isRequired = true;
    const cFrm = this.loginForm.controls;
    if (cFrm) {
      return StaticService.formError(cFrm, fieldName, type, options).msg;
    }
  }
  async login() {
    // ////console.log('Click',this.loginForm.value)
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    StaticService.markFormGroupTouched(this.loginForm, invalidElements);
    if (this.loginForm.valid) {
      this.apiHit = true;
      this.common.showLoader();
      // ////console.log('check',APIURL.ADMIN_LOGIN);
      this.api.postApiHit(APIURL.ADMIN_LOGIN,this.loginForm.value).then((res:any)=>{
        this.apiHit = false;
        this.common.hideLoader();
        this.common.showToast(res.response.msg?res.response.msg:res.response.errors[0], res.response.status);
        if(res.response.status){
          this.common.setlocal(VARS.ADMIN_LOCAL_TOKEN_KEY,res.response.data.token);
          // this.common.navigateToUrl(APP_ROUTES.ADMIN_HOME)
          // location.replace("http://localhost:4200/admin");
          // location.replace("https://newloop.herokuapp.com/admin");
          // location.replace("https://loopheadadmin.herokuapp.com/admin");
          location.replace(APIURL.BASE_URLforredirect+"/admin/dashboard");
        } else{

        }
        // ////console.log('res',res);
        
      })
    }
  }
}
