import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgModule, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Clipboard } from '@angular/cdk/clipboard';
import { commonservice } from 'src/app/allServices/commonservice';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { DeleteComponent } from '../delete/delete.component';
import { DeletePackComponent } from '../delete-pack/delete-pack.component';
import { UploadSuccessComponent } from '../upload-success/upload-success.component';
@Component({
  selector: 'app-edit-pack',
  templateUrl: './edit-pack.component.html',
  styleUrls:[ './edit-pack.component.css'],
})
// @NgModule({
//   imports: [
//     CdkDropList,
//     CdkDrag // Add DragDropModule to imports
//   ],
 
// })

export class EditPackComponent implements OnInit {
  pack_name: any;
  visiblity: boolean;
  ImagefileName: any;
  edit: boolean;
  user: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  loops_list: any;
  encryptedagainremoveslashes: string;
  formData = new FormData();
  tokenFromUI: string = "0123456789123456";
  user_id: any;
  encrypted: string;
  show: boolean;
  pic: any;
  usage:any;
  apihitforstream: boolean;
  above1secondlessthan5: boolean;
  above15second: boolean;
  durationofaudio: any;
  id: NodeJS.Timeout;
  currentItem :any
  drag_index:any
  constructor(public matDialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService,private clipboard: Clipboard, public common: commonservice,private http: HttpClient,
  public api: ApiServiceService,private router: Router
  ) { }

  ngOnInit(): void {
    
    var sendingdata = {
      pack_id : this.data.data
    }
    this.api.forclientgettokenpublic(`/user/pack_detail`, sendingdata).then(async (res: any) => {
      this.pack_name = res.response.data.pack_name
      this.visiblity = res.response.data.pack_visibility==1?true:false
      this.usage = res.response.data.usage_rights===null?'':res.response.data.usage_rights
      this.common.getDataInCookie(VARS.Login_Client_data).then(async (res: any) => {
        
        let resa = JSON.parse(res)
      if (resa) {
      let send = {
        profile_user_id : resa.user_type_id
      }
      // 
      if(! this.usage ){
        this.api.forclientgettokenpublic(`/user/get_user_details`, send).then(async (res: any) => {
          if(res.response.status){
            this.usage = res.response.data[0].usage_rights
          }
        })
      }
    }
  })
    // if(res.response.data.pack_cover_img){
        this.ImagefileName = res.response.data.pack_cover_img
      // }else{
      // this.ImagefileName = this.profile_image_pathofcomments;
    // }
    this.user = res.response.data
    this.loops_list = res.response.data.loop_detials
    // ////console.log(this.loops_list[0].artwork_path);
    
    this.edit=true 
  })
}
onDragStart(event:any,index:any) {
  this.currentItem = event.loop_id
  this.drag_index=index
}
onDrop(event:any){

  
  const dropIndex = this.calculateDropIndex(event);
  //////console.log('draggedIndex',this.drag_index);
  if (!isNaN(this.drag_index) && !isNaN(dropIndex)) {
  //////console.log('draggedIndex',dropIndex);
  this.moveItem(this.drag_index, dropIndex);
  }
}
calculateDropIndex(event: DragEvent): number {
  //////console.log(event.currentTarget);
  let r :any
  r= event.currentTarget
  const rect = r?.getBoundingClientRect();
  //////console.log(rect);
  if (!rect) return -1;
  const offsetY = event.clientY - rect.top;
  const height = rect.height;
  const percentage = offsetY / height;
  return Math.floor(this.loops_list.length * percentage);
}

moveItem(previousIndex: number, currentIndex: number) {
  if (currentIndex >= 0 && currentIndex < this.loops_list.length) {
      const item = this.loops_list.splice(previousIndex, 1)[0];
      this.loops_list.splice(currentIndex, 0, item);

  }
}
onDragOver(event:any){
  event.preventDefault()
  //////console.log(this.loops_list);
  
}
selectFileImage(event: any) {
  // this.imageProgress = true;
  // this.disable_input_as_itsloading1 = true;
  const file = event.target.files[0];
  this.ImagefileName=file.name
  this.user_id = localStorage.getItem("client_data");
  const user = JSON.parse(this.user_id).user_type_id;
  // //////console.log("hey_ram", user);

  let tokkenget: any = localStorage.getItem("clientintoken");
  const formData: FormData = new FormData();
  formData.append("loop_artwork", file);
  this.pic = file
  // formData.append("loop_id", this.loop_id);

  // let headers: any = {
  //   Authorization: `Bearer ${tokkenget}`,
  //   "My-Custom-Header": "foobar",
  // };

  // this.http
  //   .post(`${APIURL.BASE_URL}/user/upload_loop_draft`, formData, {
  //     headers: headers,
  //   })
  //   .pipe()
  //   .subscribe((event: any) => {
  //     this.imageProgress = true;
  //     this.disable_input_as_itsloading1 = false;
  //     //////console.log("event:", event);
  //     this.loop_id = event.response.data.loop_id;
  //   });
}
playAudio(item: any,index: any) {
   
  // this.showAudioPlayer = true;
  // //////console.log(item,index);
  
  // this.player = index
  this.loops_list.map((r: any) => {
    r.hidepausebutton = false;
  })
  this.loops_list.map((r: any) => {
    if (r.audio) r.audio.pause()
  })


  // this.tableData[index].audio.addEventListener('timeupdate', () => {
  //   this.sliderValue = this.tableData[index].audio.currentTime;
  // });

  if (!item.audio) {
    if (item.loop_type == 1) {
      if (item.tagged_loop_path !== null) {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      } else {
        item.file = CONSTNAME.fILE_PATH + item.loop_path;
        this.durationofaudio = item.loop_duration;
      }
    } else {
      item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
      this.durationofaudio = item.tagged_loop_duration;
    }
    // if (!item.duration) {
    //   let sound: any;
    //   sound = await new Promise((resolve, reject) => {
    //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
    //   });
    // }
    item.duration = this.durationofaudio;
    item.audio = new Audio(item.file);
    item.audio.play();
    item.audio.addEventListener('timeupdate', () => {
      // this.sliderValue = item.audio.currentTime;
    });
    item.audio.addEventListener('ended', () => {
      // this.next();
    });
    if (item.duration >= 15) {
      this.above15second = true;
      this.apihitforstream = true

    }
    else if (item.duration < 15 && item.duration >= 5) {

      this.apihitforstream = true
      this.above1secondlessthan5 = true;
    } else if (item.duration > 1 && item.duration <= 5) {

      this.apihitforstream = true
      this.above1secondlessthan5 = true;
    }
  } else {
    item.audio.addEventListener('timeupdate', () => {
      // this.sliderValue = item.audio.currentTime;
    });
    item.audio.addEventListener('ended', () => {
      // this.next();
    });
    item.audio.play();
  }
  




  item.hidepausebutton = true;
  let r: any;
  this.id = setInterval(() => {
    r = item.audio.currentTime;
    if (r === item.duration || r > item.duration) {

      setTimeout(() => {

      }, 1000);
    }

    let dura: any = item.duration;
    if (item.duration >= 15) {
      if (r > 15) {
        if (this.apihitforstream == true) {
          this.apihitforstreaming(item, r, dura);
          clearInterval(this.id);
          this.apihitforstream = false;

        }

      }
    } else if (item.duration < 15 && item.duration >= 5) {

      if (r > 5) {

        if (this.apihitforstream == true) {
          this.apihitforstreaming(item, r, dura);
          clearInterval(this.id);
          this.apihitforstream = false;

        }
      }


    } else if (item.duration > 1 && item.duration <= 5) {
      if (r >= 1) {
        if (this.apihitforstream == true) {
          this.apihitforstreaming(item, r, dura);
          clearInterval(this.id);
          this.apihitforstream = false;

        }

      }
    }
  }, 200);

}
stopAudio(item: any) {
  // //////console.log(item);
  if (item) {
    if (item.audio) {
      item.audio.pause()
      // this.showAudioPlayer = false;s
    }
    clearInterval(this.id);

    this.loops_list.map((r: any) => {
      r.hidepausebutton = false;
    })

  } else {
    this.loops_list.map((r: any) => {
      if (r.audio) {
        r.audio.pause()
      }
      r.hidepausebutton = false;
    })

  }


}
cancel(){
  this.matDialog.closeAll()
}
async apihitforstreaming(item: any, r: any, dura: any) {

  let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

  let headers: any;
  if (tokkenget) {
    headers = { 'Authorization': `Bearer ` + tokkenget, 'My-Custom-Header': 'foobar', };

  }

  let sendingdata = {
    "audio_duration": dura,
    "loop_id": item.loop_id,
    "play_duration": r
  }
  this.http.post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers }).subscribe(async (res: any) => {
    if (res.response.status == 1) {
      // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
    }

    else {
      let errorthroughapi = res.response.errors;
      let error = [];
      // this.cancel();
      this.common.hideLoader();
      for (var i = 0; i < errorthroughapi.length; i++) {
        this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
      }
    }


  }, async e => {

    this.common.hideLoader();
    this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
  });
}
setimage(data: any) {
  // //////console.log(data);
  
  if (data?.pack_cover_img == null) {
    if (data?.profile_image_path == null) {
      return this.profile_image_pathofcomments;

    } else {
      return  data?.profile_image_path;
    }

  } else {

    return CONSTNAME.fILE_PATH + data?.pack_cover_img;
  }
}

redirecttouser(id: any) {
  this.matDialog.closeAll()
  let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  let encrypted = CryptoJS.AES.encrypt(JSON.stringify(id), _key, {
    keySize: 16,
    iv: _iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  this.encrypted = encrypted.toString();
  this.encrypted = encodeURIComponent(this.encrypted);

  this.router.navigate([`user/sounds/@${this.id}`])

}
redirecttolooppage(value: any) {
  this.matDialog.closeAll()
  let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  let encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), _key, {
    keySize: 16,
    iv: _iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  this.encrypted = encrypted.toString();
  this.encrypted = encodeURIComponent(this.encrypted);

  this.router.navigate(["detail-loop"], {
    queryParams: { loop_id: this.encrypted },
  });
}
setimage1(data: any) {
  // //////console.log(data);
  
  if (data.artwork_path == null) {
    if (data.profile_image_path == null) {
      return this.profile_image_pathofcomments;

    } else {
      return  data.profile_image_path;
    }

  } else {

    return CONSTNAME.fILE_PATH + data.artwork_path;
  }
}

remove(item:any,index:any){
  // //////console.log(item,index);

  this.loops_list.splice(index,1)
  // item.checked = false
  this.user.number_of_loops -= 1 
  if(this.user.number_of_loops===0){
    let sendingdata={
      pack_id:this.data.data,
      loops : this.loops_list,
      number_of_loops : this.user.number_of_loops,
      edit:true
    }
    this.api.forclientgettokenpublic(`/user/delete_pack`, sendingdata).then(async (res: any) => {
      this.cancel()
    })
    }else{
  this.api.forclientgettokenpublic(`/user/remove_loop`, {loop_id:item.loop_id,pack_id:this.data.data,loops:this.user.number_of_loops}).then(async (res: any) => {})
  }
} 
delete(){

  let dialogue = this.matDialog.open(DeletePackComponent, { panelClass: 'delete-pack',data:{data:this.data.data}, disableClose: true });
  dialogue.afterClosed().subscribe(result => {
    // //////console.log('The dialog was closed');
  });
}
next1(){
  this.show = true

}
next() {
  this.common.showLoader()
  // //////console.log();
  
  this.formData.append("pack_id",this.data.data)
    if(this.pic)
    this.formData.append("loopkit_artwork",this.pic)
    if(this.usage)
    this.formData.append("usage",this.usage)
    this.formData.append('name',this.pack_name)
    if(this.visiblity){
    this.formData.append('visiblity','1')
  }else{
    this.formData.append('visiblity','0')

  }
    let tokkenget: any = localStorage.getItem("clientintoken");
    let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
    this.http
      .post(`${APIURL.BASE_URL}/user/edit_pack`, this.formData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
       this.show = true
        this.common.hideLoader()
        // this.matDialog.closeAll()

      })

  
  // }
  
  // else{
  //   //////console.log(this.pack_name);
  //   if(this.pack_name===undefined){
  //     this.common.hideLoader()

  //   this.nameerror = "Enter Pack name"
  // }
  // if(this.pic===undefined){
  //   this.picerror = "Select an image"
  // this.common.hideLoader()
    
  // }
  // ////console.log("heyyyyyyy",this.pic,this.pack_name,this.visiblity);
  // let user_id = localStorage.getItem("client_data");
  // const user = JSON.parse(this.user_id).user_type_id;
  // this.formData.append("loopkit_artwork",this.pic)
  // this.formData.append('name',this.pack_name)
  // if(this.visiblity){
  //   this.formData.append('visiblity','1')
  // }else{
  //   this.formData.append('visiblity','0')

  // }    this.formData.append('user_id',user)
  // let tokkenget: any = localStorage.getItem("clientintoken");
  // let headers: any = { Authorization: `Bearer ${tokkenget}`, "My-Custom-Header": "foobar" };
  // this.http
  //   .post(`${APIURL.BASE_URL}/user/create_pack`, this.formData, {
  //     headers: headers,
  //   })
  //   .pipe()
  //   .subscribe((event: any) => {

  //     ////console.log("event:", event.response.data.pack_id);
  //     this.pack_id = event.response.data.pack_id;
  // this.common.hideLoader()
      
  //     let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //     let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //     let encrypted = CryptoJS.AES.encrypt(
  //       JSON.stringify(this.pack_id), _key, {
  //       keySize: 16,
  //       iv: _iv,
  //       mode: CryptoJS.mode.ECB,
  //       padding: CryptoJS.pad.Pkcs7
  //     });
  //     this.encrypted = encrypted.toString();
  //     this.encrypted = encodeURIComponent(this.encrypted);
  //     ////console.log('pack_id:', this.encrypted );
      
  //       this.router.navigate(['add-loops/'], { queryParams: { pack_id: this.encrypted }});
  //   });
 
  // }

}
finish(){
  // this.common.showLoader()s
  let sendingdata={
    pack_id:this.data.data,
    loops : this.loops_list,
    number_of_loops : this.user.number_of_loops,
    edit:true
  }
  if(this.user.number_of_loops==0){
    this.api.forclientgettokenpublic(`/user/delete_pack`, sendingdata).then(async (res: any) => {
      this.toastr.success("Pack Updated Successfully. ", "", { positionClass: 'toast-bottom-right' });
    this.matDialog.closeAll();
    // let dialogue = this.matDialog.open(UploadSuccessComponent, { panelClass: 'upload-loop',data:{data:'pack'}, disableClose: true });
  // dialogue.afterClosed().subscribe(result => {
  //   // ////console.log('The dialog was closed');
  // });
    })

  }else{
  this.user_id = localStorage.getItem('client_data');
  const user = JSON.parse(this.user_id).user_type_id;
  let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  let encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(user), _key, {
    keySize: 16,
    iv: _iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  this.encrypted = encrypted.toString();
  this.encrypted = encodeURIComponent(this.encrypted);
  this.api.forclientgettokenpublic(`/user/update_pack`, sendingdata).then(async (res: any) => {
    if(res.response.status){
      this.toastr.success("Pack Updated Successfully. ", "", { positionClass: 'toast-bottom-right' });
      // let dialogue = this.matDialog.open(UploadSuccessComponent, { panelClass: 'upload-loop',data:{data:'pack'}, disableClose: true });
      // dialogue.afterClosed().subscribe(result => {
      //   // ////console.log('The dialog was closed');
      // });
    this.matDialog.closeAll();

    }
  })
}

}
toggle(){
  this.show=!this.show
}
toggleVis(){
 this.visiblity = !this.visiblity
}
}
