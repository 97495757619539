import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bannedlisting',
  templateUrl: './bannedlisting.component.html',
  styleUrls: ['./bannedlisting.component.css']
})
export class BannedlistingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
