<div class="container-1">
    <div class="row">
        <div class="loop-main-div">
            <div class="cross-icon" (click)="cancel()"><img src="../../../../assets/img/Edit loop.svg"></div>
            <div class="right-content-div">
                <div class="heading-div">
                    <h3>Edit Pack<img src="../../../../assets/img/logo.png" class="logo-img"><img
                            src="../../../../assets/img/logo-secondary.png" class="logo-img1"></h3>
                    <p>Change the details on a pack you’ve published</p>
                </div>
                <div class="tab">
                    <div class="tabs-btns">
                        <div class="tab-div " [ngClass]="show?'':'active'" (click)="toggle()" >Details</div>
                        <div class="tab-div" [ngClass]="show?'active':''" (click)="next1()" >Loops</div>
                    </div>

                    <div class="content-div details-tab-content" *ngIf="!show">
                        <div class="col-sm-12 name-artwork-div">
                            <div class="input-box col-sm-6 pl-0">
                                <label for="name">Pack Name</label>
                                <input type="text" class="form-control" id="surname" name="surname"
                                    [(ngModel)]="pack_name" [ngModelOptions]="{ standalone: true }"
                                    placeholder="Rainbow">
                            </div>
                            <div class="img-change-div col-sm-4">
                                <img [src]="setimage(user)" class="choose-img">
                                <label class="form-control file-input-div">Change Artwork
                                    <input type="file" class="d-none" accept=".jpeg, .jpg, .png"
                                        (change)="selectFileImage($event)" />
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-12 usage-rights-div pl-0">
                            <label for="rights">Usage Rights</label>
                            <textarea class="form-control" rows="3" [(ngModel)]="usage"
                                [ngModelOptions]="{ standalone: true }"></textarea>
                        </div>
                        <div class="col-sm-12 toggle-div pl-0">
                            <label _ngcontent-xyg-c237="" for="checkbox" class="switch">
                                <input _ngcontent-xyg-c237="" type="checkbox" id="checkbox" [(ngModel)]="visiblity"
                                    [ngModelOptions]="{ standalone: true }" />
                                <div _ngcontent-xyg-c237="" class="slider round">

                                </div>
                            </label>
                            <p _ngcontent-xyg-c237="" class="toggleText">Make this pack private</p>
                        </div>
                        <div class="col-sm-12 pl-0" (click)="delete()">
                            <button class="btn delete-loop-btn"><img
                                    src="../../../../assets/img/trash-01.png" />Delete This Pack</button>
                        </div>
                        <div class="col-sm-12 pl-0">
                            <button class="btn update-btn" (click)="next()">Update</button>
                        </div>
                    </div>

                    <div class="content-div loop-tab-content" *ngIf="show">
                        <div class="loop-div" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
                            <div class="list-box" *ngFor="let items of loops_list,let i = index" draggable="true" (dragstart)="onDragStart(items,i)">
                                <img src="../../../../assets/img/Drag-Dots.png" class="dots">

                                <div class="info">
                                    <div class="play-div">
                                        <img [src]="setimage1(items)" class="img"/>
                                      <span ngcontent-yrg-c82="" class="play-btn hover">
                                        <span class="play-btn hover play_button" id="play_button">
                                            <i aria-hidden="true" *ngIf="!items.hidepausebutton" (click)="playAudio(items,i)" class="fa fa-play"></i>
                                            <i (click)="stopAudio(items)" *ngIf="items.hidepausebutton" class="fa fa-pause"
                                                aria-hidden="true"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <div class="list-info">
                                        <h5 (click)="redirecttolooppage(items.loop_id)">{{items.title}}</h5>
                                        <!-- <p (click)="redirecttouser(items.user_id)">@{{items.username}}</p> -->
                                    </div>
                                </div>
                                <div class="minus-btn-div" (click)="remove(items,i)">
                                <img src="../../../../assets/img/minus-circle.png" class="minus-button">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 pl-0">
                            <button class="btn update-btn" (click)="finish()">Update</button>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    </div>
</div>