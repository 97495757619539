import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { commonservice } from 'src/app/allServices/commonservice';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmDeleteComponent } from '../MODAL/confirm-delete/confirm-delete.component';
import { environment } from 'src/app/sevicesall/alldata';
import * as CryptoJS from 'crypto-js';
import { Title } from "@angular/platform-browser"
import { HostListener } from '@angular/core';
import { FollwersComponent } from '../MODAL/follwers/follwers.component';
import { FollowingComponent } from '../MODAL/following/following.component';
import { Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { UpdateTabService } from 'src/app/allServices/update-tab.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  // @Output() Idpass = new EventEmitter();
  // @HostListener('window:popstate', ['$event'])

  tableforinfo: any = [];
  showinstagramicon = true;
  twittericon = true;
  youtubeicon = true;
  twitchicon = true;
  tiktokicon = true;
  beatstaricon = true;
  showeditprofilebutton: boolean;
  showfollowbutton: boolean;
  showdropdownbutton: boolean;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  urlforheader = "assets/img/artwork.jpg";
  url = "assets/img/artwork.jpg";
  showFlag: boolean = false;
  showFlag2: boolean = false;
  currentIndex: any;
  showsoundfound = true;



  userMenuforlike = environment.Likedlooploopkit;
  loop = environment.Likedlooploopkit[0].active;


  userMenuforSoundmenu = environment.SOundprofile;
  sound = environment.SOundprofile[0].active;

  value: any;
  encryptedagainremoveslashes: string;
  showdropdownofwithdraw: boolean;
  selectedImageIndex: number = -1;
  selectedImageIndex2: number = -1;
  nameofuser: any;
  useridsend: any;
  encryptedforsendinginfollow: string;
  admintokenpresent = false;
  showdeleted: boolean = false;
  profile_image_pathofcomments: any = 'assets/img/artwork.jpg';
  tab: any = false
  previousUrl: string = '';
  username: any;
  private navigationSubscription: Subscription;
  isMobile: any;
  showMenu: any;

  constructor(public common: commonservice, private http: HttpClient, private UpdateTabService: UpdateTabService, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService, private matDialog: MatDialog, private breakpointObserver: BreakpointObserver) {
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Navigation is about to start, handle cleanup logic
        this.handleCleanup();
      }
    });
  }
  private handleCleanup() {
    setTimeout(() => {
      this.ngOnInit()
    }, 2000);
  }

  ngOnDestroy() {

    // let user = localStorage.getItem('currentuser')
    // // this.route.params.subscribe(params => {
    //   // Extract the 'username' parameter from the route
    //   var username
    // if (this.router.url.indexOf('user/packs') > -1) {

    //   }else{
    //     if(this.router.url.indexOf('user/sounds') >= -1){
    //     username = this.router.url.slice(14,14+50)
    //     if(username != user){
    //       setTimeout(() => {
    //           location.reload()
    //         }, 5);}
    //   }
    // }
    // 
  }

  ngOnInit(): void {
    this.tableforinfo = [];
    let b = localStorage.getItem("clientintoken");
    if (b) {
      this.showMenu = true
    } else {
      this.showMenu = false
    }
    // console.log(this.showMenu)
    window.scroll(0, 0)
    // ////console.log('kkk');
    this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
    let user = localStorage.getItem('currentuser')
    // this.route.params.subscribe(params => {
    // Extract the 'username' parameter from the route



    // You can now use the 'username' variable in your component
    // this.encryptedagainremoveslashes = decodeURIComponent(this.value);



    // this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
    //   if (!res) {
    //   }
    //   let resa = JSON.parse(res)
    //   if (resa) {
    //     this.useridsend = resa.user_type_id
    //     // this.username = 
    //     // this.redirecttolooppage(this.value)
    //   };
    // })

    // if (isFirstLoad) {
    // ////console.log(this.router.url.indexOf('user/packs') > -1);
    this.route.paramMap.subscribe(params => {
      this.value = params.get('username');
      // Fetch user data or perform actions based on `username`
    });

    // if (this.router.url.indexOf('user/packs') > -1) {
    //   this.showsoundfound = false;

    // } else {
    //   if(this.router.url.indexOf('user/sounds') > -1)
    // {
    this.soundfun()
    // const username = this.router.url.slice(14,14+50)
    //   if(user!==username){
    // location.reload()
    // }

    // localStorage.setItem('currentuser',username)
    //       this.value = username
    // }
    // }
    // ////console.log(    this.value);
    //   const id = localStorage.getItem('user_id')
    //   if (this.encryptedagainremoveslashes!==id) {
    //     location.reload();
    //   }
    //   // Update previousUserId with the current user_id
    //   localStorage.setItem('user_id',this.encryptedagainremoveslashes)

    //   // Set isFirstLoad to false after the code has been executed
    //   // isFirstLoad = false;
    // }

    // });
    this.getallinfoofupperdiv();
  }
  redirecttourl() {
    this.router.navigate([`${this.tableforinfo.username}`])
  }
  ngAfterViewInit() {
    // ////console.log('hii');

  }
  redirecttolooppage(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encryptedforsendinginfollow = encodeURIComponent(this.encrypted);
    this.calldata()

  }
  async getallinfoofupperdiv() {
    var sendingdata = {
      "username": this.value
    }
    //  ////console.log(sendingdata);

    this.api.forclientgettokenpublic(`/user/get_user_id`, sendingdata).then(async (res: any) => {
      // ////console.log(res);

      this.decrypted = res.response.data.user_type_id
      this.redirecttolooppage(this.decrypted)
    })
    //  



  }

  calldata() {
    let sendingdata = {
      "profile_user_id": this.decrypted
    }
    this.api.forclientgettokenpublic(`/user/get_user_details`, sendingdata).then(async (res: any) => {
      let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
      if (!tokkenget) {
        let tokkenget = await this.common.getlocal(VARS.ADMIN_LOCAL_TOKEN_KEY);
        if (tokkenget) {
          this.admintokenpresent = true;
        } else {
          this.admintokenpresent = false;
        }
      } else {
        this.admintokenpresent = false;
      }
      // ////console.log(res.response);

      if (res.response.status == 1) {
        this.common.hideLoader();
        // this.Idpass.emit(this.decrypted)
        this.tableforinfo = res.response.data[0];
        this.tableforinfo.bio_guidelines = this.tableforinfo.bio_guidelines.replace(/\n/g, '<br>');

        this.foragainfunctions(res);
        if (res.response.data[0].profile_type == 0 && res.response.data[0].show_edit_profile_button == 0) {

          this.common.hideLoader();
        }
      } else if (res.response.status == 2) {
        this.showdeleted = true
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
        this.common.hideLoader();
      }
      else if (res.response.status == 4) {
        this.common.hideLoader();
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  redirecttoeditProfile() {
    this.router.navigate(['edit']);
  }

  validateURL(link: any) {
    if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
      return true
    }
    else {
      return false
    }
  }
  redirectto(item: any) {

    let link: any = item.instagram_link
    if (this.validateURL(link)) {
      window.open(`${link}`, `_blank`)
      // ////console.log("contain")
    } else {
      window.open("https://" + `${link}`, `_blank`)
      // ////console.log("not contain")
    }
  }
  redirecttotwitter(item: any) {

    let link = item.twitter_link
    if (this.validateURL(link)) {
      window.open(`${link}`, `_blank`)
      // ////console.log("contain")
    } else {
      window.open("https://" + `${link}`, `_blank`)
      // ////console.log("not contain")
    }


  }
  redirecttoyoutube(item: any) {

    let link = item.youtube_link
    if (this.validateURL(link)) {
      window.open(`${link}`, `_blank`)
      // ////console.log("contain")
    } else {
      window.open("https://" + `${link}`, `_blank`)
      // ////console.log("not contain")
    }

  }
  redirecttotwitch(item: any) {

    let link = item.twitch_link
    if (this.validateURL(link)) {
      window.open(`${link}`, `_blank`)
      // ////console.log("contain")
    } else {
      window.open("https://" + `${link}`, `_blank`)
      // ////console.log("not contain")
    }

  }
  redirecttotiktok(item: any) {

    let link = item.tiktok_link
    if (this.validateURL(link)) {
      window.open(`${link}`, `_blank`)
      // ////console.log("contain")
    } else {
      window.open("https://" + `${link}`, `_blank`)
      // ////console.log("not contain")
    }

  }
  redirecttobeatstar(item: any) {

    let link = item.beatstars_link
    if (this.validateURL(link)) {
      window.open(`${link}`, `_blank`)
      // ////console.log("contain")
    } else {
      window.open("https://" + `${link}`, `_blank`)
      // ////console.log("not contain")
    }

  }
  redirecttoinstagram(item: any) {

    let link = item.instagram_link
    if (this.validateURL(link)) {
      window.open(`${link}`, `_blank`)
      // ////console.log("contain")
    } else {
      window.open("https://" + `${link}`, `_blank`)
      // ////console.log("not contain")
    }

  }

























  clicked: any
  async Followapi() {
    // this.common.showLoader();
    this.clicked = true
    let sendingData = {
      "profile_user_id": this.decrypted,
      "encrypted_user_type_id": this.encryptedforsendinginfollow
    }




    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      this.clicked = true
      // this.common.showLoader();
      this.api.postApiHit(`/user/follow_unfollow_user`, sendingData).then((res: any) => {
        if (res.response.status == 1) {

          this.getallinfoofupperdiv();


          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();

          // ////console.log("gaya");
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }


        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.common.hideLoader();
      this.opendialogueforverificationfollow();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }
  }


  unfollow(item: any) {
    this.nameofuser = item.username
    // ////console.log(this.nameofuser)
    this.opendialoguefor();


  }
  withdraw() {
    this.opendialogueforwithdraw();
  }

  async unfoll() {
    // this.common.showLoader();
    let sendingData = {
      "profile_user_id": this.decrypted,
      "encrypted_user_type_id": this.encryptedforsendinginfollow
    }

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      // this.common.showLoader();
      this.api.postApiHit(`/user/follow_unfollow_user`, sendingData).then((res: any) => {
        if (res.response.status == 1) {


          this.getallinfoofupperdiv();


          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();

          // ////console.log("gaya");
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }


        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.common.hideLoader();
      this.opendialogueforverification();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }
  }




  imageObject: Array<object> = [];
  imageObject2: Array<object> = [];

  foragainfunctions(res: any) {


    // ////console.log(res)

    if (res.response.data[0].header_image_path == null) {
      this.urlforheader = "assets/img/default.jpg";
      let img = {
        image: this.urlforheader
      }
      this.imageObject[0] = img

    } else {
      this.urlforheader = CONSTNAME.fILE_PATH + res.response.data[0].header_image_path;
      this.imageObject = [];
      let img = {
        image: this.urlforheader
      }

      this.imageObject.push(img)
      // ////console.log( this.imageObject,"this one")
    }

    if (res.response.data[0].profile_image_path == null) {
      // ////console.log(res.profile_image_path,"null")
      this.url = 'assets/img/artwork.jpg';
      let img = {
        image: this.url
      }
      this.imageObject2[0] = img

    } else {
      // ////console.log("ha image")
      this.url = CONSTNAME.fILE_PATH + res.response.data[0].profile_image_path;
      // this.imageObject = [];
      let img = {
        image: this.url
      }
      this.imageObject2.push(img)
      this.imageObject2[0] = img
    }





    if (res.profile_type == 1) {
      this.showeditprofilebutton = true;
    } else {
      if (res.is_follower == 0) {
        this.showfollowbutton = true;
      } else {
        this.showdropdownbutton = true;
      }
    }

    if (res.is_follower == 1) {

    }
    if (res.is_follower == 0) {
      if (res.has_user_requested_before == 1) {
        // ////console.log("gaya");
        this.showdropdownofwithdraw = true;
      }
    }




  }

  showLightboxheader(index: any) {
    this.selectedImageIndex = index;
    // ////console.log(this.selectedImageIndex,"yes",this.imageObject)
    this.showFlag = true;
  }
  showLightboxheader1(index: any) {
    this.selectedImageIndex2 = index;
    // ////console.log(this.selectedImageIndex2,"yes",this.imageObject2)
    this.showFlag2 = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
  closeEventHandler2() {
    this.showFlag2 = false;
    this.currentIndex = -1;
  }

  setheaderimage(item: any) {
    if (item.header_image_path == null) {
      return CONSTNAME.artworkpathimage;
    } else {
      return CONSTNAME.fILE_PATH + item.header_image_path;
    }

  }

  setprofileimage(item: any) {
    if (item.profile_image_path == null) {
      return CONSTNAME.artworkpathimage;
    } else {
      return CONSTNAME.fILE_PATH + item.profile_image_path;
    }
  }

  redirecttouserpage() {
    this.router.navigate(['/profile/my-account']);
  }

  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "detailpage" } });
    dialogue.afterClosed().subscribe(result => {

      // return new Promise(resolve => {
      //   if (num) {

      //     resolve((Math.round(num * 100) / 100).toFixed(2))
      //   }
      // })
      // ////console.log('The dialog was closed');
      // ////console.log(result);

      if (result.data == true) {
        this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
        this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
        // this.router.navigate(['/users/uploadloopstatus']);
      } else {

      }

    });
  }


  routotofollowing(item: any) {
    // this.followers = environment.followerspage[0].active = false
    // this.following = environment.followerspage[1].active = true

    // ////console.log(['user/' + item.username], { queryParams: { user_id: this.value } });
    this.router.navigate(['followings/'], { queryParams: { user_id: this.value } })

    // this.loop = environment.Likedlooploopkit[0].active = false;
    // this.sound = environment.SOundprofile[0].active = false;

  }

  routotofollowers() {
    // this.followers = environment.followerspage[0].active = true
    // this.following = environment.followerspage[1].active = false

    // this.loop = environment.Likedlooploopkit[0].active = false;
    // this.sound = environment.SOundprofile[0].active = false;
    this.router.navigate(['followers/'], { queryParams: { user_id: this.value } })
  }


  looplikeon() {
    // this.router.navigate([`user/packs/@${this.value}`])
    this.UpdateTabService.setUpdateloopTabServiceProgress(false);
    this.UpdateTabService.setUpdatepackTabServiceProgress(true);
    this.loop = environment.Likedlooploopkit[0].active = true;
    this.sound = environment.SOundprofile[0].active = false;
    this.tab = true
    this.showsoundfound = false;
  }


  setimage(data: any) {
    // if (data.artwork_path == null) {
    if (data.profile_image_path == null) {
      return this.profile_image_pathofcomments;

    } else {
      if (data.profile_image_path.includes("/profile_photos/")) {
        return CONSTNAME.fILE_PATH + data.profile_image_path;

      } else
        return data.profile_image_path;
    }

    // } else {

    //   return CONSTNAME.fILE_PATH + data.artwork_path;
    // }
  }

  soundfun() {
    // ////console.log("running")
    // this.router.navigate([`user/sounds/@${this.value}`])
    this.UpdateTabService.setUpdateloopTabServiceProgress(true);
    this.UpdateTabService.setUpdatepackTabServiceProgress(false);
    this.tab = true
    this.showsoundfound = true;
  }


  // forcolor(item: any) {
  //   return 'active';

  // }


  decryptUsingAES256() {
    // ////console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    // ////console.log(this.decrypted)
    // ////console.log(this.decrypted);
    // ////console.log(this.decrypted)
    this.decrypted = this.decrypted.replace(/[""]/gi, '');

    // ////console.log(this.decrypted)
    this.getallinfoofupperdiv();
    // ////console.log(this.tokenFromUI);

  }


  opendialoguefor(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "unfollow", name: this.nameofuser } });
    dialogue.afterClosed().subscribe(result => {

      // return new Promise(resolve => {
      //   if (num) {

      //     resolve((Math.round(num * 100) / 100).toFixed(2))
      //   }
      // })
      // ////console.log('The dialog was closed');
      // ////console.log(result);

      if (result.data == true) {
        this.unfoll();
      } else {

      }

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  opendialogueforwithdraw(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "withdraw" } });
    dialogue.afterClosed().subscribe(result => {

      // return new Promise(resolve => {
      //   if (num) {

      //     resolve((Math.round(num * 100) / 100).toFixed(2))
      //   }
      // })
      // ////console.log('The dialog was closed');
      // ////console.log(result);

      if (result.data == true) {
        this.unfoll();
      } else {

      }

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }

  opendialogueforverificationfollow(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: "follow" } });
    dialogue.afterClosed().subscribe(result => {

      // return new Promise(resolve => {
      //   if (num) {

      //     resolve((Math.round(num * 100) / 100).toFixed(2))
      //   }
      // })
      // ////console.log('The dialog was closed');
      // ////console.log(result);

      if (result.data == true) {
        this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
        this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
        // this.router.navigate(['/users/uploadloopstatus']);
      } else {

      }

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }

  openfollower(): void {
    let dialogue = this.matDialog.open(FollwersComponent, { panelClass: 'followers', disableClose: true, data: { pageValue: "follow", user_id: this.decrypted } });
    dialogue.afterClosed().subscribe(result => {

    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
      // document.documentElement.style.padding = '0px';
    });
  }


  openfollowing(): void {
    let dialogue = this.matDialog.open(FollowingComponent, { panelClass: 'followings', disableClose: true, data: { pageValue: "follow", user_id: this.decrypted } });
    dialogue.afterClosed().subscribe(result => {

    })
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }


}