import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { APIURL } from 'src/app/allServices/constant.service';
import { Adminvariables } from '../../globaladminvariable';
import { commonservice } from 'src/app/allServices/commonservice';

@Component({
  selector: 'app-header-inner',
  templateUrl: './header-inner.component.html'
})
export class HeaderInnerComponent implements OnInit {
  ownername = ""
  ownerName: any;
  constructor(public router: Router, private http: HttpClient, public common: commonservice) {
    // this.getapicallforupdate();
    this.ownerName = Adminvariables.ownername;
    if (window.location.href.indexOf('admin') > -1) {
      this.common.event$.subscribe(ev => {
        if (this.common.eventResMatch('_ADMINUPDATE_profile', ev, false)) {
          if (localStorage.getItem("adminprofiledetail")) {
            this.call();
          } else {
            this.getapicallforupdate();
          }
        }
      });
    }
  }

  ngOnInit(): void {

    if (window.location.href.indexOf('admin') > -1) {
      if (localStorage.getItem("adminprofiledetail")) {
        this.call();
      } else {
        this.getapicallforupdate();
      }
    } else {
      // ////console.log("no")
    }
    // this.getapicallforupdate();
    // this.getapicallforupdate();

    // this.call();
  }
  call() {
    let local: any = localStorage.getItem("adminprofiledetail");
    let data = JSON.parse(local);
    // ////console.log(data.first_name +" "+ data.last_name);
    // this.getapicallforupdate();
    this.ownerName = data.first_name + " " + data.last_name;
    // ////console.log(this.ownerName,"owner name");
  }


  getapicallforupdate() {
    let tokkenget = localStorage.getItem("loggedintoken");

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.http.get(APIURL.BASE_URL + `/admin/get_admin_profile_detail`, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        let first_name = res.response.data.first_name;
        let second_name = res.response.data.last_name;
        this.ownername = first_name + " " + second_name;



      } else {



      }
    }, async e => {
      // ////console.log('Error', e);
    });
  }





  onlogout() {
    localStorage.removeItem("loggedintoken");
    // localStorage.removeItem("loggedintoken");
    localStorage.removeItem('adminprofiledetail');
    //  window.location.href = "/login";
    // location.replace("http://localhost:4200/admin/login")
    // location.replace("https://newloop.herokuapp.com/admin/login")    
    // location.replace("https://loopheadadmin.herokuapp.com/admin/login")    
    location.replace(APIURL.BASE_URLforredirect + "/admin/login")

    // this.router.navigate(['/admin/login']);
  }


}
