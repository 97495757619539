<!-- <h1 class="text-center">Welcome to Coupon Code Page. Coming soon !!!</h1> -->
<h3 class="dynamictext">Coupon Codes</h3>
<div class="page-actions">
  <div class="row">
    <div class="col-md-4 col-sm-5">
      <form class="search-group">
        <input type="search" [(ngModel)]="validationforsearch" class="form-control rounded form-control-sm"
          placeholder="Search" aria-label="Search" name="searchsubscription" id="searchsubscription"
          aria-describedby="search-addon" />
        <button class="btn btn-theme"  style="margin: 0px;" type="submit" (click)="searchClick()"><i class="fa fa-search"></i></button>
      </form>
    </div>
    <div class="col-md-8 col-sm-7">
      <div class="btn-group-right">
        <button routerLink="/admin/list-coupon-codes/detail-coupon-code/new"  class="btn btn-theme "> <i
            class="fa fa-plus" aria-hidden="true"></i> Add
          Coupon Code</button>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive">
  <table class="table table-striped  table-bordered" *ngIf="!noresult">
    <thead>
      <tr>
        <th class="text-center marginfa" *ngFor="let items of tablevaluearray ">{{items.name}} <i (click)="sortOn(items)"
            *ngIf="items.showicon" id="{{items.tablehead}}" class={{items.class}} style="cursor: pointer;"
            aria-hidden="true"></i></th>
        <!-- <th class="text-center">Coupon Name <i class="hover " aria-hidden="true"></i></th>
        <th class="text-center">Coupon Start Date <i class=" hover " aria-hidden="true"></i></th>
        <th class="text-center">Coupon End Date <i class="hover " aria-hidden="true"></i></th>
        <th class="text-center">Coupon Created Date <i class="hover " aria-hidden="true"></i></th>
        <th class="text-center">Coupon Status <i class="hover " aria-hidden="true"></i></th>
        <th class="text-center">Action</th> -->
      </tr>

    </thead>
    <tbody>


      <tr *ngFor="let item of tableData">
        <td class="text-center">{{item.coupon_code}}</td>
        <td class="text-center"> {{item.valid_from| date: 'dd-MM-yyyy'}}</td>
        <!-- <td class="text-center"  >{{item?.loops_to_upload}}</td>
          <td class="text-center" >{{item.can_sell_loops?'Yes':'No'}}</td>
          <td class="text-center">{{item.loopkits_to_upload}}</td>
          <td class="text-center">{{item.can_sell_loopkits?'Yes':'No'}}</td> -->
        <td class="text-center">{{item.valid_to| date: 'dd-MM-yyyy'}}</td>
        <td class="text-center">{{item.created_on| date: 'dd-MM-yyyy h:mm a'}} </td>
        <td class="text-center">
          <mat-slide-toggle [(ngModel)]="item.is_enabled" (ngModelChange)="buttonapi(item.coupon_code_id)">
          </mat-slide-toggle>
        </td>
        <td><a class="text-center hover" [routerLink]="['/admin/list-coupon-codes/detail-coupon-code/']"
            [queryParams]="{coupon_id: encryptUsingAES256(item.coupon_code_id), referer: MyEncodeURI(router.url)}"><i
              class="fa fa-pencil-square-o text-center" aria-hidden="true"></i></a></td>
      </tr>
      <!-- [routerLink]="['/admin/detail-coupon-code/'+item.coupon_code_id]" -->


    </tbody>

  </table>
  <div *ngIf="noresult" class="noresult">No result to display</div>


</div>
<div class="text-center">
  <ul class="paginationlist">
    <li class="text-info" *ngIf="!noresult">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
    <!-- <li class="text-info" *ngIf="!noresult">Showing {{record}} records of {{total}}</li> -->
    <!-- <li class="text-info"> {{total}}</li> -->
    <ul class="nextulpagination">

      <!-- <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item === pageno}" *ngFor="let item of pagercount" id="liststyle{{item}}"
        (click)="paginationget(item)">
        {{item}}

      </li> -->
      <li *ngIf="!noresult">
        <jw-pagination class="hover" [items]="items" (changePage)="onChangePage($event)" [pageSize]=1 [maxPages]="3" [initialPage]=pageno>
        </jw-pagination>
      </li>
      <!-- <ul class="pagination pagination-end">
        <li class="paginationliyellowcolor bluecolor" *ngFor="let item of pagercount | paginate: { itemsPerPage: 1, currentPage: pageno, totalItems: totallength }" id="liststyle{{item}}"
        (click)="paginationget(item)">
        {{item}}
        </li>
     </ul> -->
      <!-- <li *ngIf="!noresult">
        <pagination-controls  (pageChange)="pageno = $event"></pagination-controls>
      </li> -->
    </ul>

  </ul>


</div>