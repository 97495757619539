<app-header></app-header>

<div class="account">
  <div class="container">
    <div class="row mb-3">
      <div class="col">
        <button class="back-button" (click)="goback()"><i class="fas fa-arrow-left"></i>Back To Profile</button>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col heading">
        <h4>Edit Profile</h4>
        <h3>Make changes to your public Loophead profile</h3>
      </div>
    </div>

    <form [formGroup]="signupformforloophead" (ngSubmit)="onSubmit()" class="mt-2 account-form">

      <div class="row m-t">
        <div class="col-md-3">
          <label for="username" class="label">Username</label>
        </div>
        <div class="col-md-9 form-s">
          <input type="text" class="form-control col-md-12" id="username" placeholder="Enter your username" name="username" formControlName="username" />
          <small
            class="form-text text-danger"
            *ngIf="signupformforloophead.get('username')?.touched && signupformforloophead.get('username')?.hasError('required')">
            Username is required</small
          >
          <small class="form-text text-danger" *ngIf="signupformforloophead.get('username')?.hasError('pattern')"
            >No white space is allowed</small
          >
          <small class="form-text text-danger">{{ usernameerror }}</small>
        </div>
      </div>
      <div class="row m-t">
        <div class="col-md-3">
          <label for="Bio" class="label bio-label">Bio<span class="ml-1 text-danger"></span></label>
        </div>
        <div class="col-md-9 form-s">
          <textarea
            (keyup)="keyupfunc()"
            class="form-control col-md-6"
            id="descriptiontext"
            cols="4"
            rows="4"
            formControlName="bio_guidelines"
            (keyup)="valuefortitle()"></textarea>
          <small class="form-text text-danger" *ngIf="signupformforloophead.get('bio_guidelines')?.hasError('maxlength')"
            >Max 160 characters can be entered</small
          >
          <small class="text-muted" id="textarea_feedback"></small>
          <div>
            <small class="text-danger">{{ errorshowfortitle }}</small>
          </div>
        </div>
      </div>
      <div class="row m-t">
        <div class="col-md-3"><label for="profile" class="label">Profile Photo</label><span class="ml-1 text-danger"></span></div>
        <div class="col-md-9">
          <div class="account-profile cursor">
            <img [src]="url" alt="" class="prfile-img" (click)="showLightbox(0)" />
            <ng-image-fullscreen-view
              [images]="imageObject"
              [imageIndex]="selectedImageIndex"
              [show]="showFlag"
              (close)="closeEventHandler()"></ng-image-fullscreen-view>
            <span *ngIf="imagenullforleft" class="edit-pImg image-upload hover cursor"
              ><label for="file-inputforleft"
                ><i
                  mat-raised-button
                  #red="matTooltip"
                  matTooltip="Update Profile Image"
                  matTooltipHideDelay="100"
                  class="fa fa-edit hover cursor edit-button"
                  ><input
                    id="file-inputforleft"
                    type="file"
                    class="cursor"
                    (change)="selectFile($event)"
                    accept=".jpg,.jpeg,.png" /></i></label>
                    <h5>Change Photo</h5>
                  </span>
                    
            <span *ngIf="!imagenullforleft" class="dropdowninprofile edit-pImg">
              <a (click)="editleft()" style="margin-left: 10px;">Change Photo</a>
            </span>
            <span style="display: 'none';cursor: pointer;">
              <input id="file-inputshiddenforleft" type="file" (change)="selectFile($event)" accept=".jpg,.jpeg,.png" />
            </span>
         
            <div>
              <small id="imageerror" class="text-danger confirmperror"></small>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-t">
        <div class="col-md-3 socials">
          <label for="Bio" class="label bio-label">Social Media<span class="ml-1 text-danger"></span></label>
        </div>
        <div class="col-md-9">
          <div class="social-inputs">
            <div class="icon-forms">
              <i class="fab fa-youtube input-icon" style="color: red"></i>
              <input
                type="text"
                class="form-control col-md-6"
                id="youtube"
                name="youtube"
                placeholder="youtube.com/"
                formControlName="youtube" />
              <small class="form-text text-danger" *ngIf="signupformforloophead.get('youtube')?.hasError('pattern')"
                >Please Enter Valid URL</small
              >
            </div>
            <div class="icon-forms mt-4">
              <img src="assets/img/icons/beatstars.png" style="height: 42px; padding: 0px" class="input-icon" />
              <input
                type="text"
                class="form-control col-md-6"
                id="beatstars"
                placeholder="beatstars.com/"
                name="beatstars"
                formControlName="beatstars" />
              <small class="form-text text-danger" *ngIf="signupformforloophead.get('beatstars')?.hasError('pattern')"
                >Please Enter Valid URL</small
              >
            </div>
            <div class="icon-forms mt-4">
              <i class="fab fa-instagram input-icon" style="color: #444445"></i>
              <input
                type="text"
                class="form-control col-md-6"
                id="instagram"
                name="instagram"
                placeholder="instagram.com/"
                formControlName="instagram" />
              <small class="form-text text-danger" *ngIf="signupformforloophead.get('instagram')?.hasError('pattern')"
                >Please Enter Valid URL</small
              >
            </div>

            <div class="icon-forms mt-4">
              <i class="fab fa-twitter input-icon" style="color: #1da1f2"></i>
              <input
                type="text"
                class="form-control col-md-6"
                id="twitter"
                placeholder=" x.com/"
                name="twich"
                formControlName="twitter" />
              <small class="form-text text-danger" *ngIf="signupformforloophead.get('twitter')?.hasError('pattern')"
                >Please Enter Valid URL</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="form-group col-md-12 save-btn">
        <button class="btn btn-submit text-end" [disabled]="buttonShow" (click)="onSubmit()">Save Changes</button>
      </div>
    </form>
  </div>
  <!-- Container Wrapper Ends -->
</div>
<app-footer></app-footer>
<!-- <button (click)="tests()">test</button> -->
