import { Component, OnInit, ElementRef, ViewChild, APP_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, VARS } from 'src/app/allServices/constant.service';
import { Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
// import { APIURL, VARS } from '../allServices/constant.service';
// import { ApiServiceService } from '../allServices/api-service.service';
// import { commonservice } from '../allServices/commonservice';
var signupcomponent: any;
// declare var paypal;
declare let paypal: any;

@Component({
  selector: 'app-plan3signup',
  templateUrl: './plan3signup.component.html',
  styleUrls: ['./plan3signup.component.css']
})
export class Plan3signupComponent implements OnInit {
  @ViewChild("paypal", { static: true }) paypalElement: ElementRef;
  // @ViewChild('paypal') paypalElement: ElementRef;

  planprice: any;
  planpriceforanother: any
  Premimumplandetails: any;
  paypalid: any;
  name: any;
  Premimum: any;
  planId: any;
  btndis: any = false;
  localplanid: any;
  localplanname: any;
  localplanpriceshow: any;
  paypaldatabillingToken: any;
  paypaldataorderID: any;
  paypaldatasubscriptionID: any;
  paypaldatafacilitatorAccessToken: any;
  apicall: any = false;
  validationforsearch: any = '';
  showerror: boolean = false;
  showremoveoption: boolean = false;
  coupondiscountprice: any = "00.00";
  couponcodeapplied: boolean = false;
  couponcodeid: any;
  freeforlifetime: boolean = false;
  sendingData: any;
  sendingdatainpaypal: any;
  couponpricesendinapi: any;
  // this.constanthttpaddresforscript
  handler: any = null;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, public api: ApiServiceService, public common: commonservice, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) {




    this.Premimumplandetails = (localStorage.getItem('Premimumplandetails'));
    this.Premimumplandetails = JSON.parse(this.Premimumplandetails);
    this.paypalid = this.Premimumplandetails.live_plan_id;
    this.localplanid = this.Premimumplandetails.plan_id;
    this.localplanname = this.Premimumplandetails.plan_name;
    this.localplanpriceshow = this.Premimumplandetails.plan_price;



    // ////console.log(this.Premimumplandetails);
    if (this.Premimumplandetails.plan_name == "Monthly") {
      this.name = "1 Month";
      this.planprice = this.Premimumplandetails.plan_price;
      this.planpriceforanother = this.Premimumplandetails.plan_price;
      this.Premimum = "Premium";

    } else if (this.Premimumplandetails.plan_name == "Yearly") {
      this.name = "1 Year";
      this.Premimum = "Premium";
      this.planprice = this.Premimumplandetails.plan_price;
      this.planpriceforanother = this.Premimumplandetails.plan_price;
    } else if (this.Premimumplandetails.plan_name == "Free") {
      this.name = "Free";
      this.Premimum = "Free";
      this.planprice = this.Premimumplandetails.plan_price;
      this.planpriceforanother = this.Premimumplandetails.plan_price;
    }


  }

  ngOnInit(): void {
    this.loadStripe();
    // ////console.log(APIURL.httpaddresforscript)
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.text = 'data-sdk-integration-source="button-factory"';
    script.src = `https://www.paypal.com/sdk/js?client-id=${APIURL.httpaddresforscript}&vault=true&intent=subscription`;
    // Aa7ku0mgsDzWuOnnW-mtprNcixJXXmDI4a7eFctw4Bt4M-JDAdJJAb0vWs7qdHw3WyaLPQLXOMCUgyfK
    // AQEZ69Yz7aOkPOZk7mjPZ5l0lfsRHhuqneCOmkgr7csb8SyfDbDthmES4rPd6TWRm-r4ZRc84UYkS5JD

    this._renderer2.appendChild(this._document.body, script);
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      let resa = JSON.parse(res)
      if (!res) {
        this.getUserDetail();
      }

      if (resa) {
        if (resa.steps_completed == 1) {}
          //console.log('')
        else this.api.getUrlToRedirect(resa.is_on_boarding_complete, resa.steps_completed)

      }
    })
    // this.getapiforallinfo();
  }

  searchClick() {
    if (this.validationforsearch !== "") {
      this.apicallforcouponcode();
      this.showerror = false
    } else {
      this.showerror = true
    }


  }


  apicallforcouponcode() {

    this.common.showLoader();
    let sending = {
      "plan_id": this.localplanid,
      "coupon_code": this.validationforsearch,


    }
    this.api.postApiHit(`/user/apply_coupon_code`, sending, VARS.ClIENT_TYPE).then((res: any) => {

      if (res.response.status == 1) {
        this.btndis = false;
        this.common.hideLoader();
        this.showremoveoption = true
        this.couponcodeapplied = true;
        // ////console.log(res.response.data)
        this.couponpricesendinapi = res.response.data.coupon_code_amount
        this.coupondiscountprice = (Math.round(res.response.data.coupon_code_amount * 100) / 100).toFixed(2)
        this.planprice = (Math.round(res.response.data.new_plan_price * 100) / 100).toFixed(2)
        this.couponcodeid = res.response.data.coupon_code_id
        if (this.planprice == 0) {
          this.couponcodeapplied = false;
          this.freeforlifetime = true;
          // ////console.log("Free life time")
        }


      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.reader = new FormData();
        this.common.hideLoader();

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in API", "", { positionClass: 'toast-bottom-right' });

    });
    // ////console.log("random", this.validationforsearch, this.localplanid)
  }



  removecouponcode() {

    this.common.showLoader();
    let sending = {}
    this.api.postApiHit(`/user/remove_coupon_code`, sending, VARS.ClIENT_TYPE).then((res: any) => {

      if (res.response.status == 1) {
        this.validationforsearch = ""
        this.btndis = false;
        this.common.hideLoader();
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
        this.showremoveoption = false
        this.coupondiscountprice = "00.00";
        this.planprice = this.planpriceforanother
        this.couponcodeapplied = false;
        this.freeforlifetime = false;


      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.reader = new FormData();
        this.common.hideLoader();

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in API", "", { positionClass: 'toast-bottom-right' });

    });
    // ////console.log("random",this.validationforsearch,this.planId)
  }






  click1() {
    // ////console.log("click")
    signupcomponent = this;
    this.btndis = true;









    // ////console.log("paypalwithoutdiscount")
    let sendingdata2: any = {
      'plan_id': this.paypalid

    }




    paypal.Buttons({
      style: {
        shape: 'pill',
        color: 'gold',
        layout: 'horizontal',
        label: 'paypal'
      },
      createSubscription: function (data: any, actions: any) {
        return actions.subscription.create(sendingdata2);
      },

      onApprove: function (data: any, actions: any) {


        signupcomponent.paypaldatabillingToken = data.billingToken;
        signupcomponent.paypaldataorderID = data.orderID;
        signupcomponent.paypaldatasubscriptionID = data.subscriptionID;
        signupcomponent.paypaldatafacilitatorAccessToken = data.facilitatorAccessToken;

        signupcomponent.datapostapi();







      }


    }).render('#paypal-button-container-' + this.paypalid);









  }


  click2() {
    // ////console.log("click1")
    signupcomponent = this;
    this.btndis = true;


    // ////console.log("Afterdiscount", signupcomponent.planprice, signupcomponent.localplanname, signupcomponent.coupondiscountprice)
    let sendingdata: any = {
      'plan_id': this.paypalid,
      "plan": {
        "billing_cycles": [
          {
            "sequence": 1,
            "total_cycles": 0,
            "pricing_scheme": {
              "fixed_price": {
                "value": signupcomponent.planprice,
                "currency_code": "USD"
              }
            }
          }
        ]
      }

    }








    paypal.Buttons({
      style: {
        shape: 'pill',
        color: 'gold',
        layout: 'horizontal',
        label: 'paypal'
      },
      createSubscription: function (data: any, actions: any) {
        return actions.subscription.create(sendingdata);
      },

      onApprove: function (data: any, actions: any) {


        signupcomponent.paypaldatabillingToken = data.billingToken;
        signupcomponent.paypaldataorderID = data.orderID;
        signupcomponent.paypaldatasubscriptionID = data.subscriptionID;
        signupcomponent.paypaldatafacilitatorAccessToken = data.facilitatorAccessToken;

        signupcomponent.datapostapi();







      }


    }).render('#paypal-button-container-' + this.paypalid);









  }




  datapostapi() {
    // ////console.log("api hit");
    let tokkenget = localStorage.getItem(VARS.CLIENT_LOCAL_TOKEN_KEY);
    // ////console.log(tokkenget);

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    // let sendingData = {
    //   "plan_id": this.localplanid,
    //   "visible_plan_name": this.localplanname,
    //   "visible_plan_price": this.localplanpriceshow,
    //   "billingToken": this.paypaldatabillingToken,
    //   "orderID": this.paypaldataorderID,
    //   "subscriptionID": this.paypaldatasubscriptionID,
    //   "facilitatorAccessToken": this.paypaldatafacilitatorAccessToken


    // }




    if (this.freeforlifetime) {
      // ////console.log("freeeliffeeetimee",this.localplanid)
      this.sendingData = {
        "plan_id": this.localplanid,
        "visible_plan_name": this.localplanname,
        "visible_plan_price": this.localplanpriceshow,
        "is_coupon_applied": 1,
        'coupon_code_id': this.couponcodeid,
        'coupon_code': this.validationforsearch,
        'coupon_code_amount': Number(this.couponpricesendinapi)


      }
    } else {
      // ////console.log("no coupon code")
      this.sendingData = {
        "plan_id": signupcomponent.localplanid,
        "visible_plan_name": signupcomponent.localplanname,
        "visible_plan_price": signupcomponent.localplanpriceshow,
        "billingToken": signupcomponent.paypaldatabillingToken,
        "orderID": signupcomponent.paypaldataorderID,
        "subscriptionID": signupcomponent.paypaldatasubscriptionID,
        "facilitatorAccessToken": signupcomponent.paypaldatafacilitatorAccessToken


      }
    }

    if (this.couponcodeapplied) {
      // ////console.log("no coupon code + applied")
      this.sendingData['is_coupon_applied'] = 1,
        this.sendingData['coupon_code_id'] = this.couponcodeid,
        this.sendingData['coupon_code'] = this.validationforsearch,
        this.sendingData['coupon_code_amount'] = Number(this.couponpricesendinapi)

    }






    // ////console.log(sendingData);
    this.common.showLoader();
    this.http.post(APIURL.BASE_URL + '/user/save_user_subscription', this.sendingData, { headers }).subscribe(async (res: any) => {

      if (res.response.status == 1) {
        // ////console.log(res);
        // ////console.log("sucess")
        // this.displayname = "none";
        // let successmess = res.response.msg;
        this.common.hideLoader();
        this.getUserDetail()
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });

        // signupcomponent.router.navigate(['/paymentreceipt'], { queryParams: { plantype: "Premimum" } });
        // signupcomponent.router.navigate(['/emailverify']);



      } else {
        // this.displayname = "none";
        // ////console.log("fail");
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        // this.displayname = "none";
        // this.submitbutton.submitresponse = "Save changes";
        // this.submitbutton.iswaitingoption = false;
      }
    }, async e => {
      this.common.hideLoader();
      // this.displayname = "none";
      // ////console.log('Error', e);
      this.toastr.error("Error please try again later (API error)", "", { positionClass: 'toast-bottom-right' });
    });
  }
  getUserDetail() {
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptplandetail(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }







  getapiforallinfo() {
    let tokkenget = localStorage.getItem("clientintoken");
    let sendingData = {

    }



    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    // ////console.log(headers);
    this.http.post(APIURL.BASE_URL + `/user/get_logged_in_user_details`, sendingData, { headers }).subscribe(async (res: any) => {

      if (res.response.status == 1) {
        // ////console.log(res.response.data);
        // ////console.log(res.response.data.user_details.is_on_boarding_complete);
        // ////console.log(res.response.data.user_details.steps_completed);
        this.api.geturltoredirectexceptplandetail(res.response.data.is_on_boarding_complete, res.response.data.steps_completed);


      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      // ////console.log('Error', e);
    });


  }

  // click() {
  //   
  // }



  click(amount: any) {
    // ////console.log("open stripe")
    // ////console.log(amount)
    var handler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_51HxRkiCumzEESdU2Z1FzfCVAJyiVHyHifo0GeCMAyzHPFme6v6ahYeYbQPpD9BvXbAacO2yFQ8ETlKjo4pkHSHSh00qKzqUVK9',
      locale: 'auto',
      token: function (token: any) {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        // ////console.log(token)
        alert('Token Created!!');
      }
    });

    handler.open({
      name: 'Demo Site',
      description: '2 widgets',
      amount: amount * 100
    });

  }

  loadStripe() {

    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_51HxRkiCumzEESdU2Z1FzfCVAJyiVHyHifo0GeCMAyzHPFme6v6ahYeYbQPpD9BvXbAacO2yFQ8ETlKjo4pkHSHSh00qKzqUVK9',
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            // ////console.log(token)
            alert('Payment Success!!');
          }
        });
      }

      window.document.body.appendChild(s);
    }
  }












}






