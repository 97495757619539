import { Component, HostListener, OnInit } from '@angular/core';
import { LayoutService } from 'angular-admin-lte';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
// import { filter, map, mergeMap } from 'rxjs/operators'
import { environment } from './sevicesall/alldata';
import { commonservice } from './allServices/commonservice';
import { VARS } from './allServices/constant.service';
import { Meta } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  customLayout:any
  isSignIn:any;
  routeData:any;
  envFiles = environment.menubar;
  userMenu = environment.account_menu;
  hideFooter = false;
  hideHeader = false;
  isMobile: boolean;
  constructor(
    private layoutService: LayoutService,public common: commonservice,
   private router: Router,private route: ActivatedRoute,private metaService: Meta,
  ) {
    this.metaService.addTag({ name: 'description', 
                   content: 'Loophead is an open marketplace for music producers to share their loops for collabs' });
    // ////console.log("sound 123")
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    //   map(() => this.route),
    //   map(route => {
    //     while (route.firstChild) route = route.firstChild
    //     return route
    //   }),
    //   filter(route => route.outlet === 'primary'),
    //   mergeMap(route => route.data)
    // ).subscribe(data =>{
    //   // ////console.log(data);
    //   this.routeData = data;
    //   this.envFiles.seconMenu.map(sm=>{
    //     sm.hide = false;
    //   });
    //   if(this.routeData.secondMenu){
    //     this.envFiles.seconMenu.map(sm=>{
    //       this.routeData['secondMenu'].map((smq:any)=>{
    //         // ////console.log(smq)
    //         if(sm.val == smq.val) sm.hide =true;
  
    //       })
    //     });
    //   }
    //   if (this.routeData.followerspage) {
    //     environment.followerspage.map(fun => {
    //       this.routeData.followerspage.map((again:any) => {
    //         if (again.val==fun.val) {
    //           fun.active = again.active 
    //         }
    //       })
    //       // fun.active = false;
    //     })

    //  }
    //   if (this.routeData.Likedlooploopkit) {
    //     environment.Likedlooploopkit.map(fun => {
    //       this.routeData.Likedlooploopkit.map((again:any) => {
    //         if (again.val==fun.val) {
    //           fun.active = again.active 
    //         }
    //       })
    //       // fun.active = false;
    //     })

    //  }
      
    //     // if()
    //     this.userMenu.map(um=>{
    //       um.active = false;
    //     });
    //     this.userMenu.map((um:any)=>{
    //       if(this.routeData.activeTab == um.val){
    //         um.active = true;
    //       }
    //     });
    //     // ////console.log('this.userMenu',this.userMenu)
    //     this.envFiles.regType = data.regType?data.regType:false;
    //   this.envFiles.menuItems.map(m => {
    //     m.hide = true;
    //   })
    //   if (this.routeData && this.routeData.menuOption) {
    //     this.envFiles.menuItems.map((menu: any) => {
    //       this.routeData.menuOption.map((opt: any) => {
    //         // ////console.log(opt.hide);
    //         if (opt.val == menu.val) {
    //           menu.hide = opt.hide;
    //         }
    //       })
    //     })
        
    //   }
    //   this.envFiles.showMenu = this.routeData.hideMenu;
    //   this.hideFooter = this.routeData.hideFooter;
    //   this.hideHeader = this.routeData.hideHeader;
    //   // ////console.log('this.envFiles.showMenu', this.envFiles.showMenu);
    //   if(data.type) {
    //     setTimeout(() => {
    //     this.type404();
          
    //     }, 100);
    //   }
    // }
     
    // )
      
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // Check the screen size and reload the page if needed
    if (window.innerWidth > 500) {
      this.reloadPage();
    }
  }

  reloadPage() {
    // window.location.reload();
  }
  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {
 
        var rt = this.getChild(this.route)
 
        rt.data.subscribe((data: { descrption: any; robots: any; ogUrl: any; ogTitle: any; ogDescription: any; ogImage: any; }) => {
          // ////console.log(data);
 
          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }
 
          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }
 
          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }
 
          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }
 
          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
 
          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }
 
 
        })
 
      })
 
  
    // this.meta.addTag({ name: 'description', 
    //                content: 'Loophead is an open marketplace for music producers to share their loops for collabs' });
    this.isMobile = window.innerWidth < 600; // Set initial value based on window width

    window.addEventListener('resize', () => {
      // Update isMobile when window is resized
      this.isMobile = window.innerWidth < 500;
    });
    // ////console.log("got")
    this.layoutService.isCustomLayout.subscribe(value => {
      this.customLayout = value;
    });
    // this.router.events.subscribe((e) => {
    //   if (e instanceof NavigationEnd) {
    //     // Function you want to call here
    //     let left_sidebar:any = document.querySelectorAll('.sidebar-menu > li.custom-active');
    //     if (left_sidebar.length > 0) {
    //       for (let dd = 0; dd < left_sidebar.length; dd++){
    //         left_sidebar[dd].classList.remove("active", 'menu-open', 'custom-active');
    //       }
    //     }
    //   }
    // });
   
  }
  getChild(activatedRoute: ActivatedRoute):any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }
  menu = environment.menubar;
  openEmail() {
    window.location.href = "mailto:theloophead@gmail.com";
}
  type404(){
    // this.menu.menuItems.map(i => {
    //     i.hide = true;
  
    //   });
      this.menu.seconMenu.map(i => {
        i.hide = true;
  
      });
      this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY).then(data => {
        // ////console.log('data=>', data);
        if (data) {
          // ////console.log('logout');
          this.menu.menuItems.map(i => {
            i.hide = false
          })
          this.menu.menuItems.map(i=>{
              if (i.val == 'rg' ) {
                i.hide = true;
              
              }
            if (i.val == 'ad') i.hide = true;
            if (i.val == 'promo') i.hide = true;
          })
         
          this.menu.seconMenu.map(s=>{
            s.hide = false;
          })
        } else {
          // ////console.log("else part")
          // this.menu.regType =true;
          this.menu.menuItems.map(i => {
            if (i.val == 'rg') i.hide = false;
            if (i.val == 'ad') i.hide = false;
            if (i.val == 'promo') i.hide = false;
            if (i.val == 'upload') i.hide = false;
            if (i.val == 'topcharts') i.hide = false;
            if (i.val == 'myfeed') {
             
              i.hide = false;
              // ////console.log('inside feed',i)
            }
          })
  
        }
  
      })
  }
}
