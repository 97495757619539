<!-- <h4 class="mb-2">My Uploads</h4> -->

<div class="uploads-wrapper">
   <!-- <h1>sss</h1> -->


   <div *ngFor="let items of tableData" class="uploaded-item">
      <span class="loop-badge ">{{items.loop_type ===1?"Loop":"Loop Kit"}}
         {{looptype}}</span><span class="item-name hover "><a class="link" (click)="detailpageofloop(items.loop_id)">
            {{items.title}}</a>, </span><span class="item-terms">
         {{items.terms_name}},
      </span><span class="upload-date">{{items.created_on| date: 'dd-MM-yyyy
         ' }}</span> 
         <!-- <span class="download-archive hover {{userMenu[2].active?'active':''}}"
         (click)="redirecttopagearchive(items.loop_id)"><i class="fa fa-history"></i></span> -->

      <!-- <span class="downloads-count"><i class="fa fa-download"></i> {{items.number_of_downloads}},</span>  <span *ngIf="items.delete_flag == 1"
         class="fa fa-arrow-circle-down upload-date"> {{items.delete_flag?'deleted':'notdeleted'}}</span> -->
      <!-- <span *ngIf="items.selling_preference === 1?call(items.terms):call()"></span> -->
   </div>
   <div *ngIf="noresult" class="noresul text-center">No records to display </div>

   <div *ngIf="norecordfound" class="text-center">No records to display </div>

   <div class="text-center" *ngIf="!noresult">
      <ul class="paginationlist">
         <!-- <li class="text-info" *ngIf="!norecordfound">Showing {{record}} records of {{total}}</li> -->
         <li class="text-info" *ngIf="!norecordfound">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
         <!-- <li class="text-info"> {{total}}</li> -->
         <ul class="nextulpagination" *ngIf="!hidepagination">

            <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item == pageno}"
               *ngFor="let item of pagercount" id="liststyle{{item}}" (click)="paginationget(item)">
               {{item}}

            </li>
            <!-- <li><jw-pagination class="hover" [items]="items" (changePage)="onChangePage($event)"
    
               [pageSize]=1
               [maxPages]="7"
               
               ></jw-pagination></li> -->
         </ul>

      </ul>


   </div>