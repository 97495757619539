<div class="modal-header">
    <button type="button" class="close" (click)="cancel()"><span aria-hidden="true">×</span></button>
    <h4 class="mt-0 mb-0">Report Track</h4>

</div>
<div class="modal-body">

    <div class="report-options">
        <form [formGroup]="reportform">
            <div class="form-group">
                <div class="radio-custom" *ngFor="let item of arraytableforreportname">
                    <input type="radio" id={{item.report_point_id}} formControlName="checkboxreport" 
                        value={{item.report_point_id}}>
                    <label for={{item.report_point_id}}>{{item.report_point}}</label>
                </div>
            </div>
            <div>
                <small class="text-danger" [innerHTML]="vCheck('checkboxreport', '_INPUT')"></small>
            </div>
            <div class="form-group text-right">
                <button type="button" class="btn btn-theme" (click)="onSubmit()">Submit</button>
            </div>
        </form>
    </div>







    <!-- <div class="report-options">
        <div class="form-group">
            <div class="radio-custom">
                <input type="radio" id="haras" name="gender" value="1">
                <label for="haras">Harassment & Cyberbullying</label>
            </div>
        </div>
        <div class="form-group">
            <div class="radio-custom">
                <input type="radio" id="violence" name="gender" value="2">
                <label for="violence">Violent or repulsive content</label>
            </div>
        </div>
        <div class="form-group">
            <div class="radio-custom">
                <input type="radio" id="hate" name="gender" value="3">
                <label for="hate">Hate speech or violence</label>
            </div>
        </div>
        <div class="form-group">
            <div class="radio-custom">
                <input type="radio" id="infringes" name="gender" value="4">
                <label for="infringes">Infringes my copyright or privacy</label>
            </div>
        </div>
        <div class="form-group">
            <div class="radio-custom">
                <input type="radio" id="unwanted" name="gender" value="5">
                <label for="unwanted">Unwanted commercial content or span</label>
            </div>
        </div>
        <div class="form-group">
            <div class="radio-custom">
                <input type="radio" id="other" name="gender" value="6">
                <label for="other">Other</label>
           </div>
       </div>
    </div> -->
</div>