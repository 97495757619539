<div style="width: 100%;min-height: 100vh;" >
  <div class="desktop-screen">
      <div >
      <div _ngcontent-htr-c51="" class="">
        <div ngcontent-htr-c51="" class="row srch-rw">
          <div class="col-md-6">
            <div class="searchbox">
              <i class="srch-icon fa fa-search" aria-hidden="true"></i>
              <input
                type="text"
                class="form-control"
                placeholder="Search Loops"
                (keyup.enter)="searchloop()"
                [(ngModel)]="search"
                style="" />
              <button class="btn" (click)="searchloop()">Search</button>
            </div>
          </div>
          <div class="col-md-6 create-btn">
            <button class="btn create" (click)="handleCreate()" *ngIf="check == 1">Create Pack</button>
            <!-- (click)="handleCreate()" -->
          </div>
        </div>
        <div class="table-responsive">
          <table *ngIf="norecordfound" class="table">
            <thead>
              <th>Loop</th>
              <th></th>
              <th class="mobi-hide-2"></th>
              <th class="t-align mobi-hide-2">BPM</th>
              <th class="t-align mobi-hide-2">Key</th>
              <th class="mobi-hide-2 tag-div">Tags</th>
              <th class="mobi-shw-2">Artist</th>
              <th class="dnload-align">Actions</th>
            </thead>
            <tbody>
              <tr *ngFor="let items of tableData; let i = index" ngcontent-yrg-c82="" id="player-row-{{i}}" >
                <td class="loop-artwork">
                  <img [src]="setimage(items)" />
                  <!-- <span ngcontent-yrg-c82="" class="play-btn hover"> -->
                    <span class="play-btn hover play_button" id="play_button"  *ngIf="waveformLoaded[i] && !items.hidepausebutton" (click)="check == 1||items.visiblity!==1?playAudio(items, i,$event):''"   >

                      <i aria-hidden="true" class="fa fa-play"></i></span>
                  <span class="play-btn hover play_button" id="play_button"  *ngIf="waveformLoaded[i] && items.hidepausebutton"  (click)="check == 1||items.visiblity!==1?stopAudio(items,i):''" >
                      <i class="fa fa-pause"
                          aria-hidden="true"></i></span>
                </td>
                <td class="loop-details">
                  <!-- <div ngcontent-yrg-c82=""> -->
                    <div class="lock">
                      <h4 ngcontent-yrg-c82="" class="titles" [ngClass]="fontSize(items)" (click)="check == 1||items.visiblity!==1?redirecttolooppage(items):''">{{ items.title }}</h4>
                      <img *ngIf="items.visiblity===1" src="../../../../assets/img/lock-01.svg"/>
                    </div>
                  <a (click)="check == 1||items.visiblity!==1?redirecttodownloadspage(items):''" style="cursor: pointer;" *ngIf="check == 1&&items.is_already_downloaded===1"> {{ items.is_already_downloaded}} download</a>
                  <a (click)="check == 1||items.visiblity!==1?redirecttodownloadspage(items):''" style="cursor: pointer;" *ngIf="check == 1&&items.is_already_downloaded!==1"> {{ items.is_already_downloaded}} downloads</a>
                  <a style="cursor: pointer;font-weight: 500;" *ngIf="check == 0"> @{{ items.username}}</a>
                  <!-- </div> -->
                </td>
                <td class="col-md-4 wave mobi-hide-2">
                  <div _ngcontent-yrg-c82="" class="">
                    <div _ngcontent-yrg-c82="" class="media-equlizer">
                      <app-waveform #waveformRef [arr]="srcforsound(items)" [loop_id]="items.loop_id" [items]="i"[passedValue]="items" 
                      [slide]="sliderValue"  (sliderValueChange)="onSliderValueChanged($event)"  (waveformLoaded)="onWaveformLoaded(i)"  (musicEnded)="next(i)"></app-waveform>
                    </div>
                  </div>
                </td>
                <td class="t-align mobi-hide-2">
                  {{ items.bpm }}
                </td>
                <td class="t-align mobi-hide-2">
                  {{ items.sub_key }} {{items.main_key===1?"Major":items.main_key!==null?"Minor":''}}
                </td>
                <td class="mobi-hide-2 tag-div">
                  <div class="tags mobi-hide-2" *ngFor="let i of items.tags_array" (click)="redirectto(i)">
                    {{ i }}
                  </div>
                </td>
                <td class="mobi-shw-2">
                  <div class="artist-td">@{{ items.username}}</div>
                </td>
                <td *ngIf="check == 1">
                  <div  class="action-li">
                    <div style="cursor: pointer;" (click)="openEdit(items)"><img src="../../../../assets/img/edit.svg" /></div>
                    <div style="cursor: pointer;" (click)="openshare(items)"><img src="../../../../assets/img/share.svg" /></div>
                  </div>
                </td>
                <td *ngIf="check == 0" style="margin-top: 42px;" class="action">
                  <div class="action-div">
                        <div  class="stem">{{items.stems?"Contains Stems":""}}</div>
                        <div class="download dnload-align" (click)="downloadFile(items)"><img src="../../../../assets/img/download.svg" />
                        </div></div>
                  <!-- <div class="download dnload-align color" *ngIf="disable"><img src="../../../../assets/img/download.svg" /></div> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

   
      <button class="load-btn btn center mb-4" *ngIf="!hidepagination" (click)="paginationgetmore()">Load More Results</button>
      </div>

      <div class="text-center" *ngIf="nocomments">
        <ul class="paginationlist">
        </ul>
      </div>

      <div *ngIf="!norecordfound" class="text-center" style="padding: 10px; padding-top: 20px">
        No Results Found
      </div>

     

  </div>
  <div class="mobile-screen">
    <div>
      <div _ngcontent-htr-c51="" class="table-div">
        <div ngcontent-htr-c51="" class="row srch-rw">

            <div class="searchbox">
              <i class="srch-icon fa fa-search" aria-hidden="true" (click)="searchloop()"></i>
              <input
                type="text"
                class="form-control"
                placeholder="Search Loops"
                (keyup.enter)="searchloop()"
                [(ngModel)]="search" style="" />
            </div>
        </div>
        <div class="mobil-tble">
          <table *ngIf="norecordfound" class="table">
            <tbody>
              <tr *ngFor="let items of tableData; let i = index" ngcontent-yrg-c82="" id="player-row-{{i}}" >
                <td class="loop-artwork">
                  <img [src]="setimage(items)" />
                  <!-- <span ngcontent-yrg-c82="" class="play-btn hover"> -->
                    <span class="play-btn hover play_button" id="play_button"  *ngIf="waveformLoaded[i] && !items.hidepausebutton" (click)="check == 1||items.visiblity!==1?playAudio(items, i,$event):''"   >
    
                      <i aria-hidden="true" class="fa fa-play"></i></span>
                  <span class="play-btn hover play_button" id="play_button"  *ngIf="waveformLoaded[i] && items.hidepausebutton"  (click)="check == 1||items.visiblity!==1?stopAudio(items,i):''" >
                      <i class="fa fa-pause"
                          aria-hidden="true"></i></span>
                </td>
                <td class="loop-details">
                  <!-- <div ngcontent-yrg-c82=""> -->
                    <div class="lock">
                      <h4 ngcontent-yrg-c82="" class="titles" [ngClass]="fontSize(items)" (click)="check == 1||items.visiblity!==1?redirecttolooppage(items):''">{{ items.title }}</h4>
                      <img *ngIf="items.visiblity===1" src="../../../../assets/img/lock-01.svg"/>
                    </div>
                    <a style="cursor: pointer;font-weight: 500;" *ngIf="check == 0"> @{{items.username}}</a>
                  <!-- </div> -->
                </td>
                
                <td *ngIf="check == 1">
                  <div  class="action-li">
                    <div style="cursor: pointer;" (click)="openEdit(items)"><img src="../../../../assets/img/edit.svg" /></div>
                    <div style="cursor: pointer;" (click)="openshare(items)"><img src="../../../../assets/img/share.svg" /></div>
                  </div>
                </td>
                <td *ngIf="check == 0" class="action">
                  <div class="action-div">
                        <!-- <div  class="stem">{{items.stems?"Contains Stems":""}}</div> -->
                        <div class="download dnload-align" (click)="downloadFile(items)"><img src="../../../../assets/img/download.svg" />
                        </div></div>
                  <!-- <div class="download dnload-align color" *ngIf="disable"><img src="../../../../assets/img/download.svg" /></div> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    
      <div *ngIf="!norecordfound" class="empty" >
            No Loop Found
          </div>
      <button class="load-btn btn center mb-4" *ngIf="!hidepagination" (click)="paginationgetmore()">Load More Results</button>
    </div>
  </div>
  <td class="music-player" *ngIf="showAudioPlayer">
    <div class="aWrap" data-src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3">
      <div class="col-sm-4 player-img">
      <div class="img-info-div">
        <img class="img" [src]="setimage(tableData[player])" />
        <div class="name">
          <h5>{{ tableData[player].title }}</h5>
          <span class="username-box">@{{ tableData[player].username }}</span>
        </div>
      </div>
      </div>

      <div class="col-md-5 col-sm-6 media-player">
        <div class="btns d-flex media-player-btns">
          <div (click)="backward()"><img src="../../../assets/img/fast-backward.svg" /></div>
        
        <div class="buttons" *ngIf="!tableData[player].hidepausebutton" (click)="playAudio(tableData[player], player,$event)">
          <button class="aPlay">
            <span class="aPlayIco"><i class="fa fa-play" style="color: white"></i></span>
          </button>
          <!-- <button class="aPlay" *ngIf="!play" ><span class="aPlayIco"><i class="fa fa-pause" style="color: white;"></i></span></button> -->
          <!-- </span><i class="fa-solid fa-forward"></i></span> -->
        </div>

        <div class="buttons" *ngIf="tableData[player].hidepausebutton" (click)="stopAudio(tableData[player],player)">
          <!-- <button class="aPlay" ><span class="aPlayIco"><i class="fa fa-play" style="color: white;"></i></span></button> -->
          <button class="aPlay">
            <span class="aPlayIco">
              <i class="fa fa-pause" style="color: white"></i>
            </span>
          </button>
          <!-- </span><i class="fa-solid fa-forward"></i></span> -->
        </div>
    
        <div>
          <div (click)="forward()"><img src="../../../assets/img/fast-forward.svg" /></div>
        </div>
    </div>
      </div>
      <!-- <div class="range" style="width:100%;"> -->
      <!-- <span class="under-ranger"></span>  -->
      <input #slider class="range" style="width: 100%;" id="pi_input" type="range" [min]="0" [max]="timeupdate()" [step]="steps" [(ngModel)]="sliderValue"   [ngModelOptions]="{standalone: true}" (input)="onInput($event)" />
      <!-- </div> -->
    <div class="col-3 volume-btns">
      <div class="volume-div d-flex">
      <div *ngIf="!mute" class="volume-container" (click)="Mute()">
        <span class="aVolIco"><i class="fa fa-volume-up icon"></i></span>
      </div>
      <div *ngIf="mute" class="volume-container" (click)="Unmute()">
        <span class="aVolIco" >
          <i class="fa fa-volume-off" style="color: black"></i>
      </span>     </div>
      <div class="range-volume">
        <!-- <span class="under-ranger" style="height: 5px;"></span> -->
        <input
          class="aVolume"
          id="volume"
          type="range"
          min="0"
          max="1"
          step="0.01"
          [(ngModel)]="volumeValue"
          (input)="onVolumeInput($event)"
          style="height: 5px" />
        <!-- </div> -->
        <!-- </div> -->
      </div>
      </div>
    </div>
    </div>
  </td>
</div>



  
  
 