<div class="h100">
    <div class="container">
        <div class="text-center">
            <h1>Home Page</h1>
            <p>Home page is Under Construction</p>
            <p>You can check following things:<br></p>
            <a [routerLink]="['/register']"class="btn btn-info">Register</a>
            <a [routerLink]="['/admin']" class="btn btn-warning mL-16">Admin Panel</a>
            <a [routerLink]="['/login']" class="btn btn-success mL-16">User Login</a>
        </div>
    </div>
</div>