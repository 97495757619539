import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { ConfirmCancelComponent } from '../../MODAL/confirm-cancel/confirm-cancel.component';

@Component({
  selector: 'app-subscriptionstatus',
  templateUrl: './subscriptionstatus.component.html',
  styleUrls: ['./subscriptionstatus.component.css']
})
export class SubscriptionstatusComponent implements OnInit {
  showFlag: boolean = false;
  currentIndex: any;
  selectedImageIndex: number = -1;
  imagevalidation: any = true;
  subscription_type: any;
  premiumshow: any = false;
  url = "assets/img/artwork.jpg";
  imagepath: any;
  free: any = false;
  loops_to_upload_allowed: any;
  already_uploaded_loops: any;
  premiumpriceshow: any;
  premiumpriceshowyearly: any;
  reader = new FormData();
  imageshow: any;
  datafromlocal: any;
  subscription_expiry: any;
  hidecancelbutton: any = false;
  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, public api: ApiServiceService, public common: commonservice, public matDialog: MatDialog) { }

  ngOnInit(): void {
    this.common.showLoader();
    this.getapiforallinfo();

    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
        // this.call();
      }
      this.datafromlocal = JSON.parse(res)
      let resa = JSON.parse(res)
      if (resa) this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
      
     
      // this.call();
      
    })

  }

  call() {
    // ////console.log(this.datafromlocal);
    this.subscription_expiry = this.datafromlocal.subscription_expiry;
    if ( this.datafromlocal.subscription_status == 2) {
      this.hidecancelbutton = true;
    }

    if (this.datafromlocal.subscription_type == "Premium") {
      this.premiumshow = true;
      this.getapicallforplans();
    } else {
      this.free = true;
      this.getapiforpremimumplan();
    }

    this.subscription_type = this.datafromlocal.subscription_type;
    if (this.datafromlocal.profile_image_path !== null) {
      this.url = CONSTNAME.fILE_PATH + this.datafromlocal.profile_image_path;
      
    }
  }

  

  getapiforallinfo() {
    let tokkenget = localStorage.getItem("clientintoken");
    let sendingData = {

    }

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    // ////console.log(headers);
    this.http.post(APIURL.BASE_URL + `/user/get_logged_in_user_details`, sendingData, { headers }).subscribe(async (res: any) => {

      if (res.response.status == 1) {
        this.common.hideLoader();
        
        this.subscription_expiry = res.response.data.subscription_expiry;
        

        if (res.response.data.subscription_status == 2) {
          this.hidecancelbutton = true;
        }
       
        this.subscription_type = res.response.data.subscription_type;
        if (res.response.data.profile_image_path !== null) {
          this.url = CONSTNAME.fILE_PATH + res.response.data.profile_image_path;
          
        }
        if (this.subscription_type == "Premium") {
          this.premiumshow = true;
          this.getapicallforplans();
        } else {
          this.free = true;
          this.getapiforpremimumplan();
        }
      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      // ////console.log('Error', e);
    });


  }

  getapicallforplans() {
    this.common.showLoader();
    // this.http.get(APIURL.BASE_URL + '/user/get_signup_subscription_plans').subscribe(async (res: any) => {
      this.api.getApiForClient('/user/get_signup_subscription_plans', VARS.ClIENT_TYPE).then((res: any) => {
      // ////console.log(res);
      this.common.hideLoader();
      if (res.response.status == 1) {
        this.loops_to_upload_allowed = res.response.data[0].loops_to_upload;
        this.already_uploaded_loops = res.response.data[0].loopkits_to_upload;
        this.common.hideLoader();
        // ////console.log(this.subscription_expiry);
        // ////console.log(res);
        if (this.premiumshow == true) {
          this.premiumpriceshow = res.response.data[1].plan_price;
          this.premiumpriceshowyearly = this.premiumpriceshow * 12;
        } else {
          this.premiumpriceshow = res.response.data[1].plan_price;
          this.premiumpriceshowyearly = this.premiumpriceshow * 12;
        }
      
      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  }


  getapiforpremimumplan() {
    const headers: any = "";
    this.api.getApiForClient( '/user/get_all_premium_plans', VARS.ClIENT_TYPE).then((res: any) => {
    // this.http.get(APIURL.BASE_URL + '/user/get_all_premium_plans', { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
         if (this.premiumshow == true) {
          this.premiumpriceshow = res.response.data[1].plan_price;
          this.premiumpriceshowyearly =  res.response.data[0].plan_price;
        } else {
          this.premiumpriceshow = res.response.data[1].plan_price;
          this.premiumpriceshowyearly =  res.response.data[0].plan_price;
         }
         this.common.hideLoader();

      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });

  }









  routing() {
    // this.router.navigate(['user/my-account/profile/premiumplan1'])
  }



  selectFile(event: any) {
    let id: any = document.getElementById("imageerror");
    if (event.target.files[0] == undefined) {
      this.url = "assets/img/artwork.jpg";
      this.imagevalidation = true;
      id.innerHTML = "";
    } else {
      if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/jpg') {

        if (event.target.files[0].size > 10000000) {
          this.imagevalidation = false;

          id.innerHTML = "Image size cannot be max 10 mb";

          var read = new FileReader()
          read.readAsDataURL(event.target.files[0])
          read.onload = (event: any) => {
            this.url = event.target.result;
          }
        } else {
          if (event.target.files) {

            this.imagevalidation = true;
            id.innerHTML = "";
            this.reader.append('profile_image_path', event.target.files[0]);
            // this.imageshow = event.target.files[0];
            var read = new FileReader()
            read.readAsDataURL(event.target.files[0])
            read.onload = (event: any) => {
              this.url = event.target.result;
            }
            this.apihitforimage();
          }
        }
      } else {
        this.imagevalidation = false;
        id.innerHTML = "Image file should be in jpeg,png,jpg";

      }
    }
  }

  apihitforimage() {

    this.api.postApiHit('/user/upload_profile_photo', this.reader, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        let keys = res.response.data.loop_id;
        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.toastr.error("", "", { positionClass: 'toast-bottom-right' });
    });

  }

  cancelapirun() {
    this.opendialogueforverification();
    // this.common.showLoader();

    // this.api.postApiHit('/user/cancel_subscription', {}, VARS.ClIENT_TYPE).then((res: any) => {

    //   this.common.hideLoader();
    //   if (res.response.status == 1) {
    //     this.getapiforallinfo();
    //     this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' })
    //   } else {
    //     this.common.hideLoader();
    //     let errorthroughapi = res.response.errors;
    //     let error = [];
    //     for (var i = 0; i < errorthroughapi.length; i++) {

    //       this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
    //     }
    //   }
    // }, async e => {
    //   this.common.hideLoader();
    //   this.toastr.error("Error in Api.", "", { positionClass: 'toast-bottom-right' });
    // })
  }


  imageObject: Array<object> = [{
    image: this.url


  }];



  showLightbox(index: any) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }


  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmCancelComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: this.subscription_expiry } });
    dialogue.afterClosed().subscribe(result => {

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }


  getUserDetail() {
    this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {},VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if(res.response.status){
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          this.datafromlocal = JSON.parse(res);
          // ////console.log(this.datafromlocal);
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }
}

