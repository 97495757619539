<h3 class="dynamictext">{{name}} Subscription Plan</h3>

<p class="alert alert-warning mb-2">{{detail}}</p>
<p class="alert alert-warning mb-2">Make sure Plan Name and Plan Price is same as what you entered in paypal.
</p>

<span class="loader d-none" id="divLoading" [ngStyle]="{'display': displayname}">
    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
    <span class="sr-only">Loading...</span>
</span>


<form action="" class="my-5">

    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="subscriptionplanname">Plan Name</label><span class="ml-1 text-danger">*</span>
            <input type="email" [disabled]="disabledvalue" [(ngModel)]="validationforplanname.plannamecheck"
                class="form-control" id="subscriptionplanname" name="subscriptionplanname"
                placeholder="Enter Plan Name">
            <small class="form-text "
                [ngClass]=" !validationforplanname.isplanvalid ? 'text-danger' : 'text-success'">{{plannameerror}}</small>
        </div>
        <!-- *ngIf="this.validationforplanprice.planpricecheck === 0?checkzero() :''" -->
        <div class="form-group col-md-6">
            <label for="subscriptionplanprice">Plan Price ($)</label><span class="ml-1 text-danger">*</span>
            <input type="number" [disabled]="disabledvalue || forzero" [(ngModel)]="validationforplanprice.planpricecheck" min="0"
                class="form-control" id="subscriptionplanprice" placeholder="Enter Plan Price "
                name="subscriptionplanprice">
            <small class="form-text "
                [ngClass]=" !validationforplanprice.isplanpricevalid? 'text-danger' : 'text-success'">{{planpriceerror}}</small>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="subscriptionRecurrenceInterval">Recurrence Interval </label><span
                class="mx-4 text-danger">*</span>

            <!-- <input type="number" [disabled]="disabledvalue ||(validationforplanprice.planpricecheck === 0)" -->
            <input type="number" [disabled]=true
                [(ngModel)]="validationforRecurrenceinterval.recurrenceintervalcheck" class="form-control"
                id="subscriptionRecurrenceInterval" name="subscriptionRecurrenceInterval"
                placeholder="Enter Recurrence Interval ">
            <small class="form-text "
                [ngClass]=" !validationforRecurrenceinterval.recurrenceintervalvalid ? 'text-danger' : 'text-success'">{{recuurenceintervalerror}}</small>
        </div>
        <div class="form-group col-md-6">
            <label for="subscriptionRecurrenceIntervalUnit">Recurrence Interval Unit</label><span
                class="ml-1 text-danger">*</span>
            <div>
                <!-- [disabled]="disabledvalue ||( validationforplanprice.planpricecheck === 0) " -->
                <select class="form-control"
                    
                    [disabled]="true"
                    [(ngModel)]=" validationforRecurrenceintervalunit.recurrenceintervalunitcheck"
                    name="subscriptionRecurrenceIntervalUnit" id="subscriptionRecurrenceIntervalUnit">
                    <option value=""> Please Select</option>
                    <option value="1">Days</option>
                    <option value="2">Weeks</option>
                    <option value="3">Months</option>
                    <option value="4">Years</option>
                </select>
                <small class="form-text text-danger">{{ recuurenceintervaluniterror}}</small>
            </div>


        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="subscriptionUploadloops">Upload Loops </label><span class="ml-1 text-danger">*</span>
            <input type="number" [disabled]="true" [(ngModel)]="validationforUploadloops.Uploadloopscheck"
                class="form-control" id="subscriptionUploadloops" name="subscriptionUploadloops"
                placeholder="Enter Upload Loops">
            <small class="form-text "
                [ngClass]=" !validationforUploadloops.Uploadloopsvalid ? 'text-danger' : 'text-success'">{{uploadloopserror}}</small>
        </div>
        <div class="form-group col-md-6">
            <label for="subscriptionuploadloopkit">Upload Loopkits</label><span class="ml-1 text-danger">*</span>
            <input type="number" [disabled]="true"
                [(ngModel)]="validationforUploadloopkits.Uploadloopkitscheck" class="form-control"
                id="subscriptionuploadloopkit" name="subscriptionuploadloopkits" placeholder="Enter Upload Loopkit">
            <small class="form-text "
                [ngClass]=" !validationforUploadloopkits.Uploadloopkitsvalid ? 'text-danger' : 'text-success'">{{uploadloopkitserror}}</small>
        </div>

    </div>
    <!-- <div class="form-row">
        
      
    </div> -->

    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="subscriptionCanSellLoop">Can Sell Loops</label><span class="ml-1 text-danger">*</span>
            <div>

                <input type="radio" class="paddingradio" [checked]="validationforcansellloops.cansellloopscheck"
                    [disabled]="true" [(ngModel)]=" validationforcansellloops.cansellloopscheck" id="Yesok"
                    name="radioforsellloops" value="1">
                <label for="Yesok">Yes</label>
                <input class="px-3"  disabled="true" class="paddingradio" [checked]="validationforcansellloops.cansellloopscheck"
                [(ngModel)]=" validationforcansellloops.cansellloopscheck" type="radio" id="Nowrong"
                    name="radioforsellloops" value="0">
                <label for="Nowrong">No</label>
                <div><small class="form-text "
                        [ngClass]=" !validationforstatus.statusvalid ? 'text-danger' : 'text-success'">{{cansellloopserror}}</small>
                </div>
            </div>

        </div>

        <div class="form-group col-md-6">
            <label for="subscriptionCanSellLoopkit">Can Sell Loopkits</label><span class="ml-1 text-danger">*</span>
            <div>

                <input type="radio" class="paddingradio" [checked]='validationforCansellloopkits.Cansellloopkitscheck'
                    [disabled]="true" [(ngModel)]=" validationforCansellloopkits.Cansellloopkitscheck" id="Yes"
                    name="radioforsellloopkits" value="1">
                <label for="Yes">Yes</label>
                <input class="px-3" class="paddingradio" [checked]='validationforCansellloopkits.Cansellloopkitscheck'
                    type="radio" disabled="true"
                    [(ngModel)]="validationforCansellloopkits.Cansellloopkitscheck" id="No" name="radioforsellloopkits"
                    value="0">
                <label for="No">No</label>
                <div><small class="form-text "
                        [ngClass]=" !validationforCansellloopkits.Cansellloopkitsvalid ? 'text-danger' : 'text-success'">{{cansellloopkitserror}}</small>
                </div>
            </div>


        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-md-6 ">
            <label for="subscriptionStatus">Status </label><span class="ml-1 text-danger">*</span>
            <div>

                <input type="radio" class="paddingradio" [disabled]="disabledvalue"
                    [(ngModel)]="validationforstatus.statuscheck" id="enabled" name="radioforstatus" value=1
                    [checked]='validationforstatus.statuscheck'>
                <label for="enabled">Enabled</label>
                <input class="mx-5 paddingradio" [disabled]="disabledvalue"
                    [(ngModel)]="validationforstatus.statuscheck" type="radio" id="disabled" name="radioforstatus"
                    value=0 [checked]='validationforstatus.statuscheck'>
                <label for="disabled">Disabled</label>

            </div>
            <div><small class="form-text "
                    [ngClass]=" !validationforstatus.statusvalid ? 'text-danger' : 'text-success'">{{statuserror}}</small>
            </div>


        </div>

    </div>
    
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="subscriptionsandboxplanid">Sandbox Plan Id</label>
            <input type="text" name="subscriptionsandboxplanid" [(ngModel)]="sandboxplan" 
                id="subscriptionsandboxplanid" class="form-control" disabled="disabled">
        </div>
        <div class="form-group col-md-6">
            <label for="subscriptionsandboxliveplanid">Live Plan Id</label>
            <input type="text" name="subscriptionsandboxliveplanid"  [(ngModel)]="liveplanid"
                id="subscriptionsandboxliveplanid" class="form-control" disabled="disabled">
        </div>
    </div>
    
    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="subscriptioncreatedon">Created Date</label>
            <input type="datetime" name="subscriptioncreatedon" [(ngModel)]="validationforcreateddate.datecheck"
                id="subscriptioncreatedon" class="form-control" disabled="disabled">
        </div>
        <div class="form-group col-md-6">
            <label for="subscriptionModifiedOn">Modified Date</label>
            <input type="datetime" name="subscriptionModifiedOn" [(ngModel)]="validationformodifieddate.datecheckd"
                id="subscriptionModifiedOn" class="form-control" disabled="disabled">
        </div>
    </div>




    <!-- [ngClass]="submitbutton.iswaitingoption ? 'disabled' : ''" -->
    <div class="form-group  text-right mx-0 ">
        <button type="submit" [disabled]="disabledvalue" class="btn btn-theme text-end"
            (click)="onsavesubscription()">Save</button>
    </div>
</form>