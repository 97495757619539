import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.css']
})
export class PendingComponent implements OnInit {

  constructor(
    private matDialog: MatDialog

  ) { }

  ngOnInit(): void {
  }
  cancel(){
    this.matDialog.closeAll()
  }
}
