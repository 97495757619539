import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { VARS } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  year: any;
  showtokkenget = false;
  youtube: any;
  twiiter: any;
  instagramlin: any;

  constructor(private router: Router, public common: commonservice,public api: ApiServiceService) { }

  ngOnInit(): void {
    this.year = new Date();
    this.year = this.year.getFullYear();
    
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (res) {
        // ////console.log("gaya")
        this.showtokkenget = true
      }
    })
    this.common.getDataInCookie("global_setting").then((res: any) => {
      if (!res) {
        // ////console.log("will hit")
      this.gethomepagevalue();
      } else {
        let response:any = JSON.parse(res)
        // ////console.log();
        this.instagramlin = response[1].meta_value;
        this.twiiter =response[2].meta_value;
        this.youtube = response[3].meta_value;
        // ////console.log("will not hit")
        // ////console.log(typeof res);
        // ////console.log("getting it")
      }
    })
  }

  open(){
    this.router.navigate(['/contactus']);

  }

  routetocontactpage() {
    this.router.navigate(['/contactus']);
  }
  routetotermpage() {
    // ////console.log("ye")
    this.router.navigate(['/termsandcondition']);
  }
  routetoprivacypage() {
    this.router.navigate(['/PrivacyPolicy']);
  }
  routetocookiepage() {
    this.router.navigate(['/CookiesPolicy']);
  }
  routetocustomer() {
    this.router.navigate(['/CustomerSupport']);
  }

  routetoabout() {
    this.router.navigate(['/about']);
  }

  routetohome() {
    this.router.navigate(['']);
  }

  routetotopchart() {
    // this.router.navigate(['']);
  }

  routetomyfeeds() {
    this.router.navigate(['/my-feed']);
  }


  async gethomepagevalue() {
    // this.common.showLoader();

    let sendingdata = {

    }

    this.api.forclientgettokenpublic(`/user/get_global_settings`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
      //  ////console.log(res)
       this.instagramlin = res.response.data[1]?.meta_value;
       this.twiiter = res.response.data[2]?.meta_value;
        this.youtube = res.response.data[3]?.meta_value;
      

      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
        
        }
      }
    }, async e => {
      this.common.hideLoader();

     
    });
  }

  instagramlink() {
    let link: any = this.instagramlin
    // ////console.log(link)
    if (this.validateURL(link)) {
      window.open(`${link}`, `_blank`)

    } else {
      window.open("https://" + `${link}`, `_blank`)
     
    }
    // window.open("https://" + `www.instagram.com/theloophead`, `_blank`)
  }
  redirecttohome(){
    this.router.navigate(['/explore']);

  }
 twitterlink() {
    let link: any = this.twiiter
    // ////console.log(link)
    if (this.validateURL(link)) {
      window.open(`${link}`, `_blank`)
     
    } else {
      window.open("https://" + `${link}`, `_blank`)
      
    }
    // window.open("https://" + `https://www.youtube.com/watch?v=-E_O2PwZpCc`, `_blank`)
  }

   youtubelink() {
    let link: any = this.youtube
    // ////console.log(link)
    if (this.validateURL(link)) {
      window.open(`${link}`, `_blank`)
      
    } else {
      window.open("https://" + `${link}`, `_blank`)
    
    }
    // window.open("https://" + `https://twitter.com/login/again`, `_blank`)
  }


  validateURL(link: any) {
    if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
      return true
    }
    else {
      return false
    }
  }

}
