
<app-header  *ngIf="!isMobile"></app-header>
<div class="header"  *ngIf="isMobile">
  <div class="">
    <div class="row1">
      <div class="navbar-translate logged-out col-sm-9 col-7">
        <div class="header-logo">
          <img src="./assets/img/logo1.png" href="index.html" height="60" class="header-logo-img">
          <a class="navbar-brand" href="index.html" rel="tooltip" data-placement="bottom">
            Loophead
          </a>
        </div>
      </div>
      <div _ngcontent-mwb-c143="" class="col-sm-3 col-5 btn-div"><button _ngcontent-mwb-c143="" class="header-btn mr-2" (click)="redirecttologin()">Login</button>
        <button _ngcontent-mwb-c143="" class="header-btn" (click)="redirecttoregister()">Register
        </button></div>
    </div>
  </div>
</div>
<div class="page-header">
    <div class="page-header-image" style="background-image:url(../../../../assets/img/login.jpg)"></div>
    <div class="container  my-auto">
      <div class="row">
        <div class="col-lg-6 col-md-9 col-11 mx-auto">
          <div class="card z-index-0" style="border-radius: 0; padding: 25px">
            <div class="card-body recovery-card">
              <form role="form" class="text-start" [formGroup]="forgotpasswordloophead">
                <a (click) = "redirecttologin()">
                  <div style="width: 100%; height: 100%; gap: 5px; display: inline-flex; padding-bottom: 5px;">
                    <div style=" position: relative;cursor:pointer;">
                      <i class="fas fa-arrow-left" style="font-size: 14px; color: black;"></i>
                    </div>
                    <div style="color: black; font-size: 16px; font-family: Inter; font-weight: 600; word-wrap: break-word;    margin: 7px 0px 0px 4px;cursor:pointer;">Back to Login</div>
                  </div>
                </a>
                <div style="color: #444445; font-size: 28px; font-family: Inter; font-weight: 700; word-wrap: break-word">Password Recovery</div>
                <div class="recovery-psswrd">Please provide us with an email to send you a recovery password</div>
                <div class="input-group  recovery-input input-group-outline mt-4 mb-5">
                  <!-- <label class="form-label">Email</label> -->
                  <input type="text" class="form-control mail" placeholder="Email" formControlName="email" (keyup.enter)="onSubmit()">
        <small class="text-danger error" [innerHTML]="vCheck('email', '_EMAIL')"></small>
        <small class="text-danger error" *ngIf="forgoterror">There is no account associated with this email</small>
                  
                </div>
                <a class="btn btn-neutral" type="button" class="btn" (click)="onSubmit()"  style="border-radius: 10px; padding: 12px 50px; background-color: black">
                <p style="font-weight: 700; font-size: medium; color: #FFFFFF; margin: 0; text-transform: capitalize;">Send</p>
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>