import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { StaticService } from 'src/app/sevicesall/static.service';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
// import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { ArrayType } from '@angular/compiler';
import { MatChipInputEvent } from '@angular/material/chips';
import * as CryptoJS from 'crypto-js';
import { HostListener } from "@angular/core";

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmLeaveComponent } from '../../MODAL/confirm-leave/confirm-leave.component';
import { commonservice } from 'src/app/allServices/commonservice';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { LabelType, Options } from 'ng5-slider';
import { FilterComponent } from '../../MODAL/filter/filter.component';
import { SharedService } from 'src/app/allServices/data-sharing.service';
import { WaveformComponent } from '../../waveform/waveform.component';
import { ZipService } from 'src/app/allServices/zip.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-maintagsearchforadvance',
  templateUrl: './maintagsearchforadvance.component.html',
  styleUrls: ['./maintagsearchforadvance.component.css']
})
export class MaintagsearchforadvanceComponent implements OnInit {
  // api: any;
  @ViewChild(WaveformComponent) childComponent!: WaveformComponent;
  @Input() items: any;
  tableDataofinstrumentcategory: any;
  instrumentcategory: any;
  tableDataofinstruments: any;
  tableDataofgenre: any;
  tableDataoftags: any;
  tokenFromUI: string = "0123456789123456";
  arr: any = [];
  instrument_name: any[] = [];
  advancesearch = StaticService.advancesearch();
  genrecategory: any;
  tableDataofsubgenre: any;
  arrforsubgenre: any = [];
  changevalueofsubgenre = false;
  arrforinstrument: any[] = [];
  changevalue = false;
  steps: any = 0.01
  // changevalueofsubgenre = false;
  usersearch = false;
  loopsearch = true;
  loopkitsearch = false;
  tableData: any[] = [];
  check: any;
  active: boolean = false;
  minbpmvalue: number;
  maxbpmvalue: number;
  bpmrange = true;
  errorforbpm = false;
  errorshowbpm: string;
  hiderangeactive = true;
  hideexactbpm: boolean = false;
  pageno = 1;
  paginationempty = false;
  value: any;
  rowsize = 10;
  valueforgenre: string | null;
  totalData: any;
  totalRecords: any;
  openModal: boolean
  validationforsearch: any;
  value1: string | null;
  value2: string | null;
  value3: string | null;
  value4: string | null;
  value5: string | null;
  value6: string | null;
  player: any = 0;
  hidepagination: boolean = true;
  load: any = false
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  disable: boolean;
  sliderValue: any;
  volumeValue: any;
  mute: boolean;
  showAudioPlayer: boolean;
  durationofaudio: any;
  above15second: boolean;
  apihitforstream: boolean;
  above5secondlessthan15: boolean;
  above1secondlessthan5: boolean;
  id: NodeJS.Timeout;
  disabled: boolean;
  idfromwebsite: any;
  encrypted: string;
  isMobile: boolean;
  private navigationSubscription: Subscription;
  showdownload: boolean = true;
  showMenu: boolean;
  constructor(private formbuilder: FormBuilder, private matDialog: MatDialog, private sharedService: SharedService, public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService,
    private zipService: ZipService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Navigation is about to start, handle cleanup logic
        this.handleCleanup();
      } else if (event instanceof NavigationEnd) {
        // Navigation has ended, perform any necessary actions
        // (e.g., trigger the click event)
        this.handleNavigationEnd();
      }
    });
  }
  waveformLoaded: boolean[] = [];

  onWaveformLoaded(index: number) {
    this.waveformLoaded[index] = true;
  }
  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    //////console.warn("hii", event, this.player);

    if (event.key === "ArrowUp") {
      event.preventDefault();
      if (this.player >= 0) {
        this.previous();
      }
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      // if(this.player<=this.tableData.length){
      this.next(this.player);
      // }else{
      //   this.player = 0
      //   this.next()
    }
    else if (event.key === " ") {
      const searchInput: HTMLInputElement = this.elementRef.nativeElement.querySelector('.form-control');
      const isFocused = (searchInput === document.activeElement);
      if (!isFocused) {
        event.preventDefault();
        if (this.tableData[this.player]?.hidepausebutton) {
          this.stopAudio(this.tableData[this.player], this.player);
        } else {
          this.playAudio(this.tableData[this.player], this.player, event);
          // }
        }
      }
    }

  }
  private handleCleanup() {
    // Perform cleanup logic before navigation
    // (e.g., pausing audio or video playback)
    const element = this.el.nativeElement.querySelector("#player-row-" + this.player + " .play_button.pause");
    if (element) {
      element.click();
    }

    // ////////console.log('Performing cleanup before navigation');
  }

  private handleNavigationEnd() {
    // Navigation has ended, perform necessary actions
    // (e.g., trigger the click event)
    // ////////console.log('Navigation has ended, triggering click event');

    const element = this.el.nativeElement.querySelector("#player-row-" + this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
  }
  redirectto(item: any) {
    let result = item.trimStart();

    this.router.navigate(["advancesearch/"], {
      queryParams: { key_word: result },
    });
    setTimeout(() => {
      location.reload()

    }, 1000);
    ////////////console.log(item);
  }
  srcforsound(items: any) {
    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        items.showbutton = true
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      } else {
        this.durationofaudio = items.loop_duration;
        items.showbutton = true
        return CONSTNAME.fILE_PATH + items.loop_path;
      }
    } else {
      this.durationofaudio = items.tagged_loop_duration;
      items.showbutton = true
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
    }
  }
  onSliderValueChanged(value: number) {
    this.sliderValue = value; // Update sliderValue in the parent component

  }

  redirecttouser(id: any) {
    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let encrypted = CryptoJS.AES.encrypt(JSON.stringify(id), _key, {
    //   keySize: 16,
    //   iv: _iv,
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7,
    // });
    // this.encrypted = encrypted.toString();
    // this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate([`${id}`])

  }
  getFileExtension(filename: string): string {
    // Extract the extension by splitting the filename at the last dot
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1].toLowerCase();
    } else {
      // If there's no dot in the filename, consider it as having no extension
      return '';
    }
  }

  setimage(data: any) {
    if (data.artwork_path == null) {
      if (data.profile_image_path == null) {

        return this.profile_image_pathofcomments;
      } else {
        if (data.profile_image_path.includes("/profile_photos/")) {
          return CONSTNAME.fILE_PATH + data.profile_image_path;

        } else
          return data.profile_image_path;
      }
    } else {
      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }

  downloadFile(filePath: any) {
    // Create a hidden anchor element
    this.disabled = true
    // filePath.showdownload = false
    const a = document.createElement("a");
    // console.log("jid", filePath);
    const fileExtension = this.getFileExtension(CONSTNAME.fILE_PATH + filePath.loop_path ||
      CONSTNAME.fILE_PATH + filePath.tagged_loop_path);
    document.body.appendChild(a);
    a.style.display = "none";
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      //console.log("res",res);
      if (res === null) {
        //       fetch(
        //         CONSTNAME.fILE_PATH + filePath.loop_path ||
        //           CONSTNAME.fILE_PATH + filePath.tagged_loop_path
        //       )
        //         .then((response) => response.blob())
        //         .then((blob) => {
        //           // Create a blob URL for the file
        //           const blobUrl = window.URL.createObjectURL(blob);

        //           // Set the anchor's href and download attributes
        //           a.href = blobUrl;

        //           a.download =
        //             filePath.title +
        //             "_" +
        //             filePath.bpm +
        //             "_" +
        //             filePath.sub_key +
        //             "_@" +
        //             filePath.username +
        //             `.${fileExtension}`; // Set the desired filename
        //           this.common.hideLoader();

        //           // Simulate a click on the anchor to trigger the download
        //           a.click();
        //           this.disabled = false

        //           // Clean up by revoking the blob URL
        //           window.URL.revokeObjectURL(blobUrl);
        // })
        //         .catch((response) => {
        //           this.toastr.error(response.response.errors, "", {
        //             positionClass: "toast-bottom-right",
        //           });
        //           this.disabled = false
        //         })
        this.toastr.error("Please Login First", "", {
          positionClass: "toast-bottom-right",
        });
        setTimeout(() => {
          this.router.navigate(["/login"])
        }, 1000);
            } else {

        let resa = JSON.parse(res);
        this.idfromwebsite = resa.user_type_id;
        if (filePath.user_id !== this.idfromwebsite) {
          this.api
            .postApiHit(`/user/save_free_loop`, {
              loop_id: filePath.loop_id,
              user_type_id: this.idfromwebsite,
            })
            .then((res: any) => {
              if (res.response.status) {
                fetch(
                  CONSTNAME.fILE_PATH + filePath.loop_path ||
                  CONSTNAME.fILE_PATH + filePath.tagged_loop_path
                )
                  .then((response) => response.blob())
                  .then((blob) => {
                    // Create a blob URL for the file
                    const blobUrl = window.URL.createObjectURL(blob);

                    // Set the anchor's href and download attributes
                    a.href = blobUrl;

                    a.download =
                      filePath.title +
                      "_" +
                      filePath.bpm +
                      "_" +
                      filePath.sub_key +
                      "_@" +
                      filePath.username +
                      `.${fileExtension}`; // Set the desired filename
                    this.common.hideLoader();

                    // Simulate a click on the anchor to trigger the download
                    filePath.showdownload = true
                    a.click();

                    // Clean up by revoking the blob URL
                    window.URL.revokeObjectURL(blobUrl);
                  })
                  .catch((response) => {
                    this.toastr.error(response.response.errors, "", {
                      positionClass: "toast-bottom-right",
                    });
                  });

                // Remove the anchor element from the DOM
                document.body.removeChild(a);
              } else {
                this.toastr.error(res.response.errors, "", {
                  positionClass: "toast-bottom-right",
                });
                filePath.showdownload = true
              }
            });
        } else {
          fetch(
            CONSTNAME.fILE_PATH + filePath.loop_path ||
            CONSTNAME.fILE_PATH + filePath.tagged_loop_path
          )
            .then((response) => response.blob())
            .then((blob) => {
              // Create a blob URL for the file
              const blobUrl = window.URL.createObjectURL(blob);

              // Set the anchor's href and download attributes
              a.href = blobUrl;

              a.download =
                filePath.title +
                "_" +
                filePath.bpm +
                "_" +
                filePath.sub_key +
                "_@" +
                filePath.username +
                `.${fileExtension}`; // Set the desired filename
              this.common.hideLoader();

              // Simulate a click on the anchor to trigger the download
              a.click();

              // Clean up by revoking the blob URL
              window.URL.revokeObjectURL(blobUrl);
            })
            .catch((response) => {
              this.toastr.error(response.response.errors, "", {
                positionClass: "toast-bottom-right",
              });
            });

          // Remove the anchor element from the DOM
          document.body.removeChild(a);
          this.disabled = false

          if (filePath.stems) {
            this.zipService.downloadFile(CONSTNAME.fILE_PATH + filePath.stems).subscribe((data) => {
              const blob = new Blob([data], { type: 'application/zip' });
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = `${filePath.title}.zip`;
              link.click();
            })
            // Create a blob for the file data
          }
          filePath.showdownload = true
        }

      }

    });

  }
  /* Start of Player Functions */
  next(i: any) {
    if (this.tableData.length - 1 == i) {
      this.disable = false;
      this.tableData[i].hidepausebutton = false;
      this.tableData[i].audio.currentTime = 0;
      this.sliderValue = 0;
      //  this.player = this.player + 1
      // this.playAudio(this.tableData[0], 0, event);
    } else {
      this.disable = false;
      this.tableData[i].audio.currentTime = 0;
      this.sliderValue = 0;
      this.stopAudio(this.tableData[i], i);
      i = i + 1;
      var seek_btn = document.querySelectorAll(
        "#player-row-" + i + " .play_button.vol"
      )[0];
      seek_btn.setAttribute("data-seek-vol", String(this.volumeValue));
      (seek_btn as HTMLElement).click();
      setTimeout(() => {
        this.playAudio(this.tableData[i], i, event);

      }, 1000);
      if (this.mute === true) {
        var seek_btn = document.querySelectorAll(
          "#player-row-" + i + " .play_button.mute"
        )[0];
        // seek_btn.setAttribute("data-seek-time", String());
        (seek_btn as HTMLElement).click();
      }
    }
  }
  previous() {
    ////////////console.log(this.tableData.length - 1, this.player);

    if (this.player == 0) {
      this.disable = true;
    } else {
      this.disable = false;
      this.sliderValue = 0;
      this.tableData[this.player].audio.currentTime = 0;
      this.stopAudio(this.tableData[this.player], this.player);
      this.player = this.player - 1;
      this.playAudio(this.tableData[this.player], this.player, event);
    }
  }
  onInput(event: any): void {
    // ////////console.log('event',event.target.value);
    //     // this.stopAudio(this.tableData[this.player],this.player)
    var seek_time = parseFloat(event.target.value).toFixed(2);
    //     this.tableData[this.player].audio.currentTime = seek_time;
    //     ////////console.log("sli",this.tableData[this.player].audio.currentTime);
    //     // this.playAudio(this.tableData[this.player],this.player,event)

    this.sliderValue = seek_time
    // ////////console.log("sli",this.sliderValue);

    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.seek"
    )[0];
    seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }


  onVolumeInput(event: any): void {
    // //////////console.log(event.target.value);
    var seek_time = parseFloat(event.target.value).toFixed(2);
    this.tableData[this.player].audio.volume = parseFloat(event.target.value);
    this.volumeValue = this.tableData[this.player].audio.volume
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.vol"
    )[0];
    seek_btn.setAttribute("data-seek-vol", String(seek_time));
    (seek_btn as HTMLElement).click();
    if (this.volumeValue > 0) {
      this.Unmute()
    } else if (this.volumeValue == 0) {
      this.Mute()
    }
  }
  Mute() {
    this.volumeValue = 0
    this.mute = true;
    this.tableData[this.player].audio.muted = true;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.mute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }
  Unmute() {
    this.volumeValue = this.tableData[this.player].audio.volume
    this.mute = false;
    this.tableData[this.player].audio.muted = false;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.unmute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }
  timeupdate() {
    const du = this.tableData[this.player].duration || this.tableData[this.player].loop_duration || this.tableData[this.player].tagged_loop_duration
    return du * 1000
  }
  redirecttolooppage(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate(["detail-loop"], {
      queryParams: { loop_id: this.encrypted },
    });
  }
  forward() {
    // this.steps = this.steps + 0.10;
    this.tableData[this.player].audio.currentTime += 2;
    this.sliderValue = this.tableData[this.player].audio.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.forward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
    ////////////console.log(this.steps);
  }
  backward() {
    // this.steps = this.steps - 0.01;
    this.tableData[this.player].audio.currentTime -= 2;
    this.sliderValue = this.tableData[this.player].audio.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.backward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }

  playAudio(item: any, index: any, event: any) {
    // ////////console.log(item,index);
    const divsWithClass = document.querySelectorAll('div.list');

    divsWithClass.forEach(div => {
      div.classList.add('ply');
    })
    this.showAudioPlayer = true;
    this.player = index;
    var play_time: any = 0.00;
    this.tableData.map((r: any) => {
      r.hidepausebutton = false;
    });
    this.tableData.map((r: any, i: any) => {
      if (r.audio) {
        r.audio.pause();
        const element = document.querySelectorAll(
          "#player-row-" + i + " .play_button.pause"
        )[0];

        if (element && "click" in element) {
          (element as HTMLElement).click();
        }
      }
    });
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.play"
    )[0];

    // ////////console.log(element);

    play_time = document
      .querySelectorAll("#player-row-" + index + " .play_button.seek")[0]
      ?.getAttribute("data-seek-time");
    ////console.log(play_time);

    play_time = parseFloat(play_time).toFixed(2);
    // this.tableData[index].audio.addEventListener('timeupdate', () => {
    //   this.sliderValue = this.tableData[index].audio.currentTime;
    // });

    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      // this.childComponent.onPlayButtonClick(event)
      // ////////////console.log(this.waveform);
      // item.waveform = new NgWaveformComponent(item.file);
      // this.waveform = item.waveform
      // ////////////console.log(this.waveform);
      // this.waveform.play();
      // var target = event.target || event.srcElement || event.currentTarget;
      // var currently_playing_btn:any = document.querySelectorAll(".is-playing");
      // if (currently_playing_btn.length > 0) {
      //   if (target != currently_playing_btn[0]) {
      //     currently_playing_btn[0].nextSibling.click();
      //   }
      // }
      // item.audio.currentTime = play_time;

      // item.audio.addEventListener('loadedmetadata', () => {
      // if(!this.mute){
      //   item.audio.muted = false
      //     }
      // item.audio.volume = this.volumeValue

      //  const i =  item.audio.play();
      //  ////////console.log(i);

      // if(!item.audio.paused){
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
      // }
      // });
      // item.audio.play();

      this.common.hideLoader();
      // item.audio.playbackRate = 1.3
      // ////////console.log(item.audio.currentTime);
      //   item.audio.addEventListener("timeupdate", (test:any, test2:any) => {
      //     // //////////console.log("fff",test, test2);
      // ////////console.log("timeupdate",item.audio.currentTime);

      //   this.sliderValue = item.audio.currentTime;
      //     // this?.currentwaveform?.setRegionStart(this.sliderValue);
      //   //////////////console.log(item.audio.currentTime);
      //   //////////////console.log(this.sliderValue);
      //     var seek_btn = document.querySelectorAll(
      //       "#player-row-" + index + " .play_button.seek"
      //     )[0];
      //     // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
      //     // (seek_btn as HTMLElement).click();
      //     // this.currentwaveform.setRegionStart(this.sliderValue);
      //   });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        this.next(index);
      });
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true;
      } else if (item.duration < 15 && item.duration >= 5) {
        this.apihitforstream = true;
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {
        this.apihitforstream = true;
        this.above1secondlessthan5 = true;
      }
    } else {
      if (!this.mute) {
        item.audio.muted = false
      }
      // item.audio.volume = this.volumeValue
      // item.audio.play();
      // item.audio.currentTime = play_time;
      // ////////console.log("currentTime",item.audio.currentTime);
      // item.audio.addEventListener("timeupdate", () => {
      //   this.sliderValue = item.audio.currentTime;
      //   // var seek_btn = document.querySelectorAll(
      //   //   "#player-row-" + index + " .play_button.seek"
      //   // )[0];
      //   // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
      //   // (seek_btn as HTMLElement).click();
      //   ////////////console.log( this.currentwaveform);
      //   // this?.currentwaveform?.setRegionStart(item.audio.currentTime);

      //   // this.currentwaveform.setRegionStart(this.sliderValue);
      // });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        // this?.currentwaveform?.setRegionStart(item.duration);
        this.next(index);
      });
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
      this.common.hideLoader();
    }

    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {
        setTimeout(() => { }, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration < 15 && item.duration >= 5) {
        if (r > 5) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      }
    }, 200);
  }

  stopAudio(item: any, index: any) {
    // ////////console.log(item);
    if (item) {
      if (item.audio) {
        item.audio.pause();
        // this.showAudioPlayer = false;s
      }
      // clearInterval(this.id);

      this.tableData.map((r: any) => {
        r.hidepausebutton = false;
      });
    } else {
      this.tableData.map((r: any) => {
        if (r.audio) {
          r.audio.pause();
        }
        r.hidepausebutton = false;
      });
    }
    // ////////console.log(this.childComponent);
    // item.waveform.pause();
    this.childComponent.onPauseButtonClick(event)

    // document.querySelectorAll("#player-row-"+index+ " .play_button.pause")[0].click();
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.pause"
    )[0];
    // ////////console.log(element);

    if (element && "click" in element) {
      (element as HTMLElement).click();
    }
  }
  /* End of Player Functions */

  async apihitforstreaming(item: any, r: any, dura: any) {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = {
        Authorization: `Bearer ` + tokkenget,
        "My-Custom-Header": "foobar",
      };
    }

    let sendingdata = {
      audio_duration: dura,
      loop_id: item.loop_id,
      play_duration: r,
    };
    this.http
      .post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers })
      .subscribe(
        async (res: any) => {
          if (res.response.status == 1) {
            // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          } else {
            let errorthroughapi = res.response.errors;
            let error = [];
            // this.cancel();
            this.common.hideLoader();
            for (var i = 0; i < errorthroughapi.length; i++) {
              this.toastr.error(res.response.errors[i], "", {
                positionClass: "toast-bottom-right",
              });
            }
          }
        },
        async () => {
          this.common.hideLoader();
          this.toastr.error("Error in Api", "", {
            positionClass: "toast-bottom-right",
          });
        }
      );
  }

  fontSize(item: any) {
    if (item.title.length <= 50 && item.title.length >= 30) {
      return 'long'
    } else if (item.title.length <= 30 && item.title.length >= 10) {
      return 'medium'
    } else {
      return 'small'
    }
  }
  redirecttologin() {
    this.router.navigate(['/login']);
  }
  redirecttoregister() {
    this.router.navigate(['/register']);
  }
  ngOnDestroy() {
    const element = this.el.nativeElement.querySelector("#player-row-" + this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
    //////console.log('lll');

  }
  ngOnInit(): void {
    let b = localStorage.getItem("clientintoken");
    if (b) {
      this.showMenu = true
    }else{
      this.showMenu = false
    }
    this.breakpointObserver.observe(['(max-width: 576px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
    this.value = this.route.snapshot.queryParamMap.get('key_word');
    this.value1 = this.route.snapshot.queryParamMap.get('exact_bpm');
    this.value2 = this.route.snapshot.queryParamMap.get('sub_key');
    this.value3 = this.route.snapshot.queryParamMap.get('main_key');
    this.value4 = this.route.snapshot.queryParamMap.get('min_bpm');
    this.value5 = this.route.snapshot.queryParamMap.get('max_bpm');
    this.value6 = this.route.snapshot.queryParamMap.get('bpm_range');
    this.valueforgenre = this.route.snapshot.queryParamMap.get('genre_id');
    if (this.valueforgenre !== null) {
      this.advancesearch.patchValue({
        genre: this.valueforgenre
      })
      this.validationforsearch = this.value

      //  ////////console.log(this.valueforgenre)
    }
    // this.advancesearch.value.keyword = this.value
    // ////////console.log(this.sharedService.sharedData);

    // this.check = this.sharedService.sharedData;

    this.advancesearch.patchValue({
      keyword: this.value,
      exact_bpm: this.value1,
      sub_key: this.value2,
      main_key: this.value3,
      min_bpm: this.value4,
      max_bpm: this.value5,
      bpm: this.value6
    })

    // this.getapicallforalldata()
    this.loopactive();
    // this.tableData = [
    //   {
    //     name:2,class:4
    //   },
    //   {
    //     name:2,class:4
    //   }
    // ]

  }
  open() {
    // ////////console.log('hii',this.openModal);

    this.openModal = !this.openModal
  }

  valuepassgenre() {
    this.genrecategory = this.advancesearch.value.genre;
    // ////////console.log(this.genrecategory);

    if (this.advancesearch.value.genre === "") {
      // this.changevalueofsubgenre = false;
    } else {
      this.genrecategoryapi(this.genrecategory);
      // this.changevalueofsubgenre = true;
    }
  }
  valuepass() {
    this.instrumentcategory = this.advancesearch.value.instrumentcategory;
    // //////console.log(this.instrumentcategory);

    if (this.advancesearch.value.instrumentcategory === "") {
      this.changevalue = false;
    } else {
      this.instrumentssubgenre(this.instrumentcategory);
      this.changevalue = true;
    }
  }

  getapicallforalldata() {



    const sendingdata = "";
    this.api.forclientgettokenpublic(`/user/get_genre_instru_tags`, sendingdata).then((res: any) => {

      // this.api.postApiHit(`/user/get_genre_instru_tags`, sendingdata, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.tableDataofinstrumentcategory = res.response.data.instrument_categories;
        this.tableDataofgenre = res.response.data.genres;

        // this.allFruits = res.response.data.tags;
        // //////console.log(res.response.data.banned_words[1].banned_word);
        for (i = 0; i < res.response.data.genres.length; i++) {
          this.arr.push(res.response.data.genres[i].genre_name);
        }
        // for (i = 0; i < res.response.data.banned_words.length; i++) {
        //   this.banned_word.push(res.response.data.banned_words[i].banned_word);
        // }
        // //////console.log(res);
        for (i = 0; i < res.response.data.instrument_categories.length; i++) {
          // //////console.log(res);
          this.instrument_name.push(res.response.data.instrument_categories[i].instrument_name);
        }

      } else {
        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })

        }
      }
    }, async e => {
      this.toastr.error("Failed to get details of instrument", "", { positionClass: 'toast-bottom-right' });

    });
  }

  genrecategoryapi(value: any) {
    let sendingData = {
      "genre_id": value
    }
    this.api.forclientgettokenpublic(`/user/get_sub_genres`, sendingData).then((res: any) => {
      // this.api.postApiHit('/user/get_sub_genres', sendingData, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.tableDataofsubgenre = res.response.data;
        this.changevalueofsubgenre = false;
        for (i = 0; i < res.response.data.length; i++) {
          this.arrforsubgenre.push(res.response.data[i].genre_name);
        }
        // this.checktag();
        // //////console.log(res.response.data);






      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.changevalueofsubgenre = false;
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.changevalueofsubgenre = false;
      this.toastr.error("Failed to get subgenres ", "", { positionClass: 'toast-bottom-right' });
    });
  }

  instrumentssubgenre(value: any) {
    let sendingData = {
      "instrument_id": value
    }

    this.api.forclientgettokenpublic(`/user/get_instruments`, sendingData).then((res: any) => {

      // this.api.postApiHit('/user/get_instruments', sendingData, VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.tableDataofinstruments = res.response.data;
        // //////console.log(res.response.data);
        this.changevalue = false;

        for (i = 0; i < res.response.data.length; i++) {
          this.arrforinstrument.push(res.response.data[i].instrument_name);
        }
        // this.checktag();





      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.changevalue = false;
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.changevalue = false;
      this.toastr.error("Failed to get instrument ", "", { positionClass: 'toast-bottom-right' });
    });
  }

  testhtml() {
    if (this.changevalue == true) {
      return "Loading...Please wait"
    } else {
      return "Select";
    }

  }

  testhtmlforsubgenre() {
    if (this.changevalueofsubgenre == true) {
      return "Loading...Please wait"
    } else {
      return "Select";
    }
  }

  // arrayforradio = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
  // arrayforradio1 = ['Ab', 'Bb', 'Cb', 'Db', 'Eb', 'Fb', 'Gb'];
  // arrayforradio2 = ['A#', 'B#', 'C#', 'D#', 'E#', 'F#', 'G#'];
  arrayforradio = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'A♭', 'B♭', 'C♭', 'D♭', 'E♭', 'F♭', 'G♭', 'A#', 'B#', 'C#', 'D#', 'E#', 'F#', 'G#'];
  arrayformainkey = [{ key: '0', name: "Major" }, { key: '1', name: "Minor" }];
  // arrayforradio = [{ name: 'A', selected: false }, { name: 'B', selected: false }, { name: 'C', selected: false }, { name: 'D', selected: false }, { name: 'E', selected: false }, { name: 'F', selected: false }, { name: 'G', selected: false }];
  // arrayforradio1 = [{ name: 'Ab', selected: false }, { name: 'Bb', selected: false }, { name: 'Cb', selected: false }, { name: 'Db', selected: false }, { name: 'Eb', selected: false }, { name: 'Fb', selected: false }, { name: 'Gb', selected: false }];
  // arrayforradio2 = [{ name: 'A#', selected: false }, { name: 'B#', selected: false }, { name: 'C#', selected: false }, { name: 'D#', selected: false }, { name: 'E#', selected: false }, { name: 'F#', selected: false }, { name: 'G#', selected: false }];

  looplikeon() {
    // this.loop = environment.Likedlooploopkit[0].active = true;
    // this.sound = environment.SOundprofile[0].active = false;

    // this.showsoundfound = false;
  }
  loopactive() {
    this.rowsize = 10
    this.active = false;
    this.usersearch = false;
    this.loopsearch = true;
    this.loopkitsearch = false;
    this.apply('fromother');
  }
  loopkitactive() {
    this.rowsize = 10
    this.active = false;
    this.usersearch = false;
    this.loopsearch = false;
    this.loopkitsearch = true;
    this.apply('fromother');
  }
  useractive() {
    this.rowsize = 10
    this.active = false;
    this.usersearch = true;
    this.loopsearch = false;
    this.loopkitsearch = false;
    this.apply('fromother');
  }

  valueforBPM() {
    let valueforBpm: any = this.advancesearch.value.exact_bpm;

    if (valueforBpm > 200) {
      this.errorforbpm = true;
      this.errorshowbpm = "BPM  cannot be more than 200"
    } else if (valueforBpm < 0) {
      this.errorforbpm = true;
      this.errorshowbpm = "BPM  cannot be less than 1"
    } else if (valueforBpm % 1 != 0) {
      this.errorforbpm = true;
      this.errorshowbpm = "BPM should be in number only"
    } else if (isNaN(valueforBpm)) {
      this.errorforbpm = true;
      this.errorshowbpm = "BPM should be in number only"
    } else {
      this.errorforbpm = false;
      this.errorshowbpm = "";
    }
  }

  parentFun() {
    // //////console.log("it works")
  }

  keydummy1: any;
  keydummy2: any;
  valueofmain_key: any = ""
  keydumy(value: any) {
    // //////console.log("click")

    if (value == 1) {
      this.valueofmain_key = ""
      // //////console.log(this.valueofmain_key)
      if (this.keydummy1) {
        this.valueofmain_key = 1
        // //////console.log(this.valueofmain_key)
        // //////console.log(this.keydummy1)
        this.keydummy2 = false
      }
    } else if (value == 2) {
      this.valueofmain_key = ""
      // //////console.log(this.valueofmain_key)
      if (this.keydummy2) {
        this.valueofmain_key = 2
        // //////console.log(this.valueofmain_key)
        // //////console.log(this.keydummy2)
        this.keydummy1 = false
      }
    }
  }
  valueofsub_key = "";

  secondradio(item: any, type: any) {
    // //////console.log(item,type)
    this.arrayforradio.map((it: any) => {
      it['selected'] = false;
    });
    this.arrayforradio.map((it: any) => {
      it['selected'] = false;
    })
    this.arrayforradio.map((it: any) => {
      it['selected'] = false;
    });
    // //////console.log(this.arrayforradio)
    // //////console.log(this.arrayforradio1)
    // //////console.log(this.arrayforradio2)
    if (type) {
      item.selected = false;
    } else {
      item.selected = true;
    }
    if (item.selected) {
      // //////console.log(item.name)
      this.valueofsub_key = item.name
    } else {
      this.valueofsub_key = ""
      // //////console.log("not selected")
    }

    // for (let i = 0; i < res.response.data.pagging_list.length; i++) {



    // }




  }

  Reset() {

    this.advancesearch.reset()
    this.advancesearch.value.sort_on = "relevance";

    // //////console.log(this.advancesearch.value.key);
    // //////console.log(this.advancesearch.value.key);
  }
  private elementRef: ElementRef

  apply(value: any) {
    this.tableData.map((item: any, index: any) => {
      const element = document.querySelectorAll(
        "#player-row-" + index + " .play_button.pause"
      )[0];
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
    })
    this.value = this.route.snapshot.queryParamMap.get('key_word');
    this.value1 = this.route.snapshot.queryParamMap.get('exact_bpm');
    this.value2 = this.route.snapshot.queryParamMap.get('sub_key');
    this.value3 = this.route.snapshot.queryParamMap.get('main_key');
    this.value4 = this.route.snapshot.queryParamMap.get('min_bpm');
    this.value5 = this.route.snapshot.queryParamMap.get('max_bpm');
    this.value6 = this.route.snapshot.queryParamMap.get('bpm_range');
    this.valueforgenre = this.route.snapshot.queryParamMap.get('genre_id');
    if (this.valueforgenre !== null) {
      this.advancesearch.patchValue({
        genre: this.valueforgenre
      })
      this.validationforsearch = this.value
    }
    if (this.value1) {
      this.advancesearch.patchValue({
        exact_bpm: this.value1,
      })
    }
    if (this.value2) {
      this.advancesearch.patchValue({
        sub_key: this.value2,
      })
    }
    if (this.value3) {
      this.advancesearch.patchValue({
        main_key: this.value3,
      })
    }
    if (this.value4) {
      this.advancesearch.patchValue({
        min_bpm: this.value4,
      })
    }
    if (this.value5) {
      this.advancesearch.patchValue({
        max_bpm: this.value5,
      })
    }
    if (this.value6) {
      this.advancesearch.patchValue({
        bpm: this.value6
      })
    }
    if (this.errorforbpm == false) {
      let sendingdata: any =
      {
        "page_no": this.pageno,
        "sort_on": this.advancesearch.value.sortof,
        "row_size": this.rowsize,
        "sort_type": "DESC",
        "key_word": this.advancesearch.value.keyword,

      }
      if (this.usersearch) {
        sendingdata["users_search"] = 1
      } else if (this.loopsearch) {
        sendingdata["loop_search"] = 1
      } else if (this.loopkitsearch) {
        sendingdata["loopkits_search"] = 1
      }
      if (this.advancesearch.value.instrumentcategory !== "") {
        sendingdata["instrument_category"] = this.advancesearch.value.instrumentcategory
      }
      if (this.advancesearch.value.instruments !== "") {
        sendingdata["instrument"] = this.advancesearch.value.instruments
      }
      if (this.advancesearch.value.genre !== "") {
        sendingdata["genre"] = this.advancesearch.value.genre
      }
      if (this.advancesearch.value.subgenre !== "") {
        sendingdata["sub_genre"] = this.advancesearch.value.subgenre
      }
      if (this.advancesearch.value.main_key !== "") {
        sendingdata["main_key"] = this.advancesearch.value.main_key
      }
      if (this.advancesearch.value.sub_key !== "") {
        sendingdata["sub_key"] = this.advancesearch.value.sub_key
      }
      if (this.advancesearch.value.sub_key !== "") {
        sendingdata["min_bpm"] = this.advancesearch.value.min_bpm
      }
      if (this.advancesearch.value.sub_key !== "") {
        sendingdata["max_bpm"] = this.advancesearch.value.max_bpm
      }
      if (this.advancesearch.value.sub_key !== "") {
        sendingdata["bpm_range"] = this.advancesearch.value.bpm
      }
      if (this.advancesearch.value.exact_bpm !== "") {
        sendingdata["exact_bpm"] = this.advancesearch.value.exact_bpm
      }
      if (this.advancesearch.value.sellingpreference !== "") {
        sendingdata["selling_preference"] = this.advancesearch.value.sellingpreference
      }
      if (this.advancesearch.value.sortof === "") {
        sendingdata["sort_on"] = "relevance"
      }
      if (this.advancesearch.value.keyword !== "") {
        sendingdata["key_word"] = this.advancesearch.value.keyword
      }

      this.validationforsearch = this.advancesearch.value.keyword
      if (value == 'fromother') {
      } else {
        if (this.bpmrange == true && this.hideexactbpm == false) {
        } else if (this.bpmrange !== true && this.hideexactbpm !== false) {
        } else {

        }
      }
      this.active = true
      this.api.forclientgettokenpublic(`/user/advance_search`, sendingdata).then((res: any) => {
        if (res.response.status == 1) {
          this.common.hideLoader();
          res.response.data.pagging_list.map((item: any, index: any) => {
            if (item.tags) {
              res.response.data.pagging_list[index].tags_array = item.tags.split('\n');
            }
            item.showdownload = true
            item.showbutton = false
          });
          if (res.response.data.pagging_list.length === 0)
            this.active = false
          else
            this.active = true;
          this.check = ""
          this.totalRecords = res.response.data.total_records;
          this.tableData = res.response.data.pagging_list
          this.check = res
          if (res.response.data.pagging_list.length === 0)
            this.active = false
          else
            this.active = true;
          if (this.rowsize > this.totalRecords) {
            this.hidepagination = true
          } else {
            this.hidepagination = false
          }
        } else {
          this.common.hideLoader();
          let errorthroughapi = res.response.errors;
          let error = [];
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    }
  }
  minValue: number = 1;
  maxValue: number = 200;
  options: Options = {
    floor: 0,
    ceil: 200,
  };

  Rangeactive() {
    this.hideexactbpm = false;
    this.hiderangeactive = true;
    this.bpmrange = true
  }

  exactbpmctive() {
    this.bpmrange = false
    this.hiderangeactive = false;
    this.hideexactbpm = true;
  }

  paginationget() {
    this.active = false;
    // ////////console.log("great");
    this.paginationempty = true;
    // this.pageno = pageinfo;
    this.pageno += 1
    this.rowsize += this.rowsize
    // ////////console.log(this.tableData);
    this.load = true

    this.tableData.map((item: any, index: any) => {
      item.hidepausebutton = false
      const element = document.querySelectorAll(
        "#player-row-" + index + " .play_button.pause"
      )[0];
      // ////////console.log('lll',element);

      if (element && "click" in element) {
        (element as HTMLElement).click();
      }

      // ////////console.log(item);
      // if(item.audio){
      // item.audio.pause()
      // }
    })
    this.active = true
    let sendingdata: any =
    {
      "page_no": this.pageno,
      "sort_on": this.advancesearch.value.sortof,
      "row_size": 10,
      "sort_type": "DESC",
      "key_word": this.advancesearch.value.keyword,

    }
    if (this.usersearch) {
      sendingdata["users_search"] = 1
    } else if (this.loopsearch) {
      sendingdata["loop_search"] = 1
    } else if (this.loopkitsearch) {
      sendingdata["loopkits_search"] = 1
    }
    if (this.advancesearch.value.instrumentcategory !== "") {
      sendingdata["instrument_category"] = this.advancesearch.value.instrumentcategory
    }
    if (this.advancesearch.value.instruments !== "") {
      sendingdata["instrument"] = this.advancesearch.value.instruments
    }
    if (this.advancesearch.value.genre !== "") {
      sendingdata["genre"] = this.advancesearch.value.genre
    }
    if (this.advancesearch.value.subgenre !== "") {
      sendingdata["sub_genre"] = this.advancesearch.value.subgenre
    }
    if (this.advancesearch.value.main_key !== "") {
      sendingdata["main_key"] = this.advancesearch.value.main_key
    }
    if (this.advancesearch.value.sub_key !== "") {
      sendingdata["sub_key"] = this.advancesearch.value.sub_key
    }
    if (this.advancesearch.value.sub_key !== "") {
      sendingdata["min_bpm"] = this.advancesearch.value.min_bpm
    }
    if (this.advancesearch.value.sub_key !== "") {
      sendingdata["max_bpm"] = this.advancesearch.value.max_bpm
    }
    if (this.advancesearch.value.sub_key !== "") {
      sendingdata["bpm_range"] = this.advancesearch.value.bpm
    }
    if (this.advancesearch.value.exact_bpm !== "") {
      sendingdata["exact_bpm"] = this.advancesearch.value.exact_bpm
    }
    if (this.advancesearch.value.sellingpreference !== "") {
      sendingdata["selling_preference"] = this.advancesearch.value.sellingpreference
    }
    if (this.advancesearch.value.sortof === "") {
      sendingdata["sort_on"] = "relevance"
    }
    if (this.advancesearch.value.keyword !== "") {
      sendingdata["key_word"] = this.advancesearch.value.keyword
    }

    this.api.forclientgettokenpublic(`/user/advance_search`, sendingdata).then((res: any) => {



      if (res.response.status == 1) {
        this.common.hideLoader();
        // //////console.log(res)

        res.response.data.pagging_list.map((item: any, index: any) => {
          if (item.tags) {
            res.response.data.pagging_list[index].tags_array = item.tags.split('\n');
          }
        });
        for (let i = 0; i < res.response.data.pagging_list.length; i++) {
          // this.check.response.data.pagging_list.push(res.response.data.pagging_list[i])
          this.tableData.push(res.response.data.pagging_list[i])
          // this.check.response.data.pagging_list.push(res.response.data.pagging_list[i])
          // //////console.log( res.response.data.pagging_list);
        }
        this.check = ""
        this.totalRecords = res.response.data.total_records;

        // if(value==="main"){

        // ////////console.log(this.check);
        res.response.data.pagging_list = this.tableData
        // }
        // ////console.log(res);

        this.check = res
        if (this.tableData.length !== 0)
          this.active = true;



        if (this.rowsize > this.totalRecords) {
          this.hidepagination = true
        } else {
          this.hidepagination = false
        }




      } else {
        this.common.hideLoader();


        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  }

  openDialog(): void {
    let dialogue = this.matDialog.open(FilterComponent, { panelClass: 'filter-modal', data: { arrayforradio: this.arrayforradio, arrayformainkey: this.arrayformainkey }, autoFocus: false });
    dialogue.afterClosed().subscribe(result => {
      // Handle the data received from the dialog
      // ////////console.log('Data received from dialog:', result);
      if (result) {
        this.active = true
        this.apply('filtered')
      }
    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
}


