import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  // @Input() items: any;
  userData: any ;
  res: any;
  email: any;
  AuthForm : FormGroup

  constructor(private fb: FormBuilder,private matDialog: MatDialog, public dialogRef: MatDialogRef<AuthComponent>) { }

  ngOnInit(): void {
    this.userData = localStorage.getItem("client_data");
    this.res = JSON.parse(this.userData)
    this.email = this.res.email
    // ////console.log(this.res);
    
    this.AuthForm = this.fb.group({
      email: this.res.authentication===1?true:false,  // Example initial value for email checkbox
      phone: this.res.authentication===2?true:false,  // Example initial value for phone checkbox
      phone_number : this.res.phone
    });
  }
  cancel(){
    this.matDialog.closeAll()
  }
  submit(){
    const formValues = this.AuthForm.value;
    // ////console.log(formValues);

    // You can access individual values like this:
    const emailValue = formValues.email;
    const phoneValue = formValues.phone;
    if(formValues.phone){
      // this.AuthForm = this.fb.group({
      //   phone_number: ["", [Validators.required]],
      // })

    
      
    }
    // ////console.log(emailValue,phoneValue);
    this.dialogRef.close(this.AuthForm.value);
    

  }

}
