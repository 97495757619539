import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL } from 'src/app/allServices/constant.service';
import { CONSTNAME } from 'src/app/allServices/constant.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DeleteaccountComponent } from '../deleteaccount/deleteaccount.component';
import { DeleteComponent } from '../delete/delete.component';
import { UploadSuccessComponent } from '../upload-success/upload-success.component';
@Component({
  selector: 'app-edit-loop',
  templateUrl: './edit-loop.component.html',
  styleUrls: ['./edit-loop.component.css']
})
export class EditLoopComponent implements OnInit {
  api: any;
  pack_name: any;
  zone: any;
  keys:any = ['A','B','C','D','E','F','G','A♭','B♭','C♭','D♭','E♭','F♭','G♭','A#','B#','C#','D#','E#','F#','G#']
  visiblity: any =0;
  ImagefileName: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  edit: boolean;
  tableData: any;
  decrypted: any;
  loop_name: any;
  usage: any;
  key: any;
  bpm: any;
  image: any;
  zip: any;
  set_usage: boolean;
  encryptedagainremoveslashes: string;
  tokenFromUI: string = "0123456789123456";
  user_id: any;
  encrypted: string;
  bpmerror: string;
  MAX_ARTWORK_SIZE_MB: number;

  constructor(public matDialog: MatDialog,
    private dialogRef: MatDialogRef<DeleteComponent>
    ,@Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService,private clipboard: Clipboard, public common: commonservice,private http: HttpClient,private router: Router) { }

  ngOnInit(): void {
    // ////console.log(this.data);
    this.common.showLoader()
   
    if(this.data){
      let tokkenget: any = localStorage.getItem("clientintoken");
      let headers: any = {
        Authorization: `Bearer ${tokkenget}`,
        "My-Custom-Header": "foobar",
      };
      this.http
        .post(
          `${APIURL.BASE_URL}/user/get_upload_detail`,
          { loop_id:  this.data.data },
          {
            headers: headers,
          }
        )
        .pipe()
        .subscribe((event: any) => {
      this.common.hideLoader()
this.user_id = localStorage.getItem("client_data");
    const user = JSON.parse(this.user_id).usage_rights;      // ////console.log("event:", event.response.data.loop_details[0].title);
          let data = event.response.data.loop_details[0];
          // ////console.log(">>>>", data.main_key, data.bpm, data.tags);
          this.tableData = event.response.data.loop_details[0];
          this.loop_name = data.title;
          this.usage = data.usage_rights?data.usage_rights:user;
          this.key = data.sub_key;
          this.bpm = data.bpm;
          this.image = data.artwork_path
          this.visiblity = data.visiblity===1?true:false
          this.set_usage = data.set_usage===1?true:false
          // ////console.log(this.visiblity);
          
          // this.tags = data.tags;/
          
        });
    }
  }
  async toggle(){
    this.visiblity=!this.visiblity
    // ////console.log(this.visiblity);
    
  }
  submit() {
    // this.user_id = localStorage.getItem("client_data");
    // let tokkenget: any = localStorage.getItem("clientintoken");
    // const user = JSON.parse(this.user_id).user_type_id;
    // const formData = new FormData();
    // ////console.log(this.visiblity);
    if(this.bpm !== null){
    if(this.bpm !== ""){
    if(this.bpm<10 || this.bpm>300){
    this.bpmerror = "Enter BPM between 10 - 300"
    }else{
      this.bpmerror = ""
      let tokkenget: any = localStorage.getItem("clientintoken");
      let sendData = {
        "title": this.loop_name,
        // "bpm": this.bpm,
        "sub_key": this.key,
        // "loop_id": this.getloopidfrommedia,
        "loop_id": this.data.data,
        "usage_right":this.usage,
        "set_usage":this.set_usage?1:0,
        "visiblity":this.visiblity?1:0
      }
      if(this.bpm!==""){
        Object.assign(sendData,{bpm:this.bpm})
      }
      // Append each file to the FormData object
      // this.droppedFiles.forEach(({ file }, index) => {
      //   formData.append(`file${index + 1}`, file);
      // });
      let headers: any = {
        Authorization: `Bearer ${tokkenget}`,
        "My-Custom-Header": "foobar",
      };
  
      this.http
        .post(`${APIURL.BASE_URL}/user/upload_loop_update`, sendData, {
          headers: headers,
        })
        .pipe()
        .subscribe((event: any) => {
          // ////console.log("event:", event); 
          if(event.response.status){
            this.common.hideLoader()
            // let dialogue = this.matDialog.open(UploadSuccessComponent, { panelClass: 'upload-loop',data:{data:'loop'}});
            // dialogue.afterClosed().subscribe(result => {
            //   // ////console.log('The dialog was closed');
            //   location.reload()
            // });
          }
          else{
            this.toastr.error(event.response.errors,"", { positionClass: 'toast-bottom-right' })
          }
        });
    }}else{
      this.bpmerror = ""
      let tokkenget: any = localStorage.getItem("clientintoken");
      let sendData = {
        "title": this.loop_name,
        // "bpm": this.bpm,
        "sub_key": this.key,
        // "loop_id": this.getloopidfrommedia,
        "loop_id": this.data.data,
        "usage_right":this.usage,
        "set_usage":this.set_usage?1:0,
        "visiblity":this.visiblity?1:0
      }
      if(this.bpm!==""){
        Object.assign(sendData,{bpm:this.bpm})
      }
      // Append each file to the FormData object
      // this.droppedFiles.forEach(({ file }, index) => {
      //   formData.append(`file${index + 1}`, file);
      // });
      let headers: any = {
        Authorization: `Bearer ${tokkenget}`,
        "My-Custom-Header": "foobar",
      };
  
      this.http
        .post(`${APIURL.BASE_URL}/user/upload_loop_test`, sendData, {
          headers: headers,
        })
        .pipe()
        .subscribe((event: any) => {
          // ////console.log("event:", event); 
          if(event.response.status){
            this.common.hideLoader()
            // let dialogue = this.matDialog.open(UploadSuccessComponent, { panelClass: 'upload-loop',data:{data:'loop'}});
            // dialogue.afterClosed().subscribe(result => {
            //   // ////console.log('The dialog was closed');
            //   location.reload()
            // });
    this.matDialog.closeAll();

          }
          else{
            this.toastr.error(event.response.errors,"", { positionClass: 'toast-bottom-right' })
          }
        });
    }
    this.matDialog.closeAll();
  }else{
        this.common.showLoader()
    let tokkenget: any = localStorage.getItem("clientintoken");
    let sendData = {
      "title": this.loop_name,
      // "bpm": this.bpm,
      "sub_key": this.key,
      // "loop_id": this.getloopidfrommedia,
      "loop_id": this.data.data,
      "usage_right":this.usage,
      "set_usage":this.set_usage?1:0,
      "visiblity":this.visiblity?1:0
    }
    if(this.bpm!==""){
      Object.assign(sendData,{bpm:this.bpm})
    }
    // Append each file to the FormData object
    // this.droppedFiles.forEach(({ file }, index) => {
    //   formData.append(`file${index + 1}`, file);
    // });
    let headers: any = {
      Authorization: `Bearer ${tokkenget}`,
      "My-Custom-Header": "foobar",
    };

    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_test`, sendData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
        // ////console.log("event:", event); 
        if(event.response.status){
          this.common.hideLoader()
          this.matDialog.closeAll();

          // let dialogue = this.matDialog.open(UploadSuccessComponent, { panelClass: 'upload-loop',data:{data:'loop'}});
          // dialogue.afterClosed().subscribe(result => {
          //   // ////console.log('The dialog was closed');
          //   // location.reload()
          // });
        }
        else{
          this.toastr.error(event.response.errors,"", { positionClass: 'toast-bottom-right' })
        }
      });
    }
  }
  cancel(){
    this.matDialog.closeAll()
  }
  delete(){
    let dialogue = this.matDialog.open(DeleteComponent, { panelClass: 'delete-loop',data:{loop_id:this.data.data}});
    dialogue.afterClosed().subscribe(result => {
      // ////console.log('The dialog was closed');
      this.user_id = localStorage.getItem("client_data");
      const user = JSON.parse(this.user_id).user_type_id;
      // this.router.navigate(['user/sounds'],{queryParams:{user_id:user}})
      if(result)
      this.dialogRef.close({result:result});

    });
   
  }
  setimage(data: any) {
    if (data?.artwork_path == null) {
      if (data?.profile_image_path == null) {
        return this.profile_image_pathofcomments;

      } else {
        return  data.profile_image_path;
      }

    } else {

      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }
  isValidArtworkSize(file: { size: number; }) {
    return file.size <= this.MAX_ARTWORK_SIZE_MB * 1024 * 1024;
  }
  selectFileImage(event: any) {
    // this.imageProgress = true;
    this.common.showLoader()
    const file = event.target.files[0];
    if (!this.isValidArtworkSize(file)) {
      this.toastr.error("Artwork file size exceeds the maximum limit of 5MB. Please upload a smaller file.", "", { positionClass: 'toast-bottom-right' });
    this.common.hideLoader()
    return;
    }
    this.ImagefileName=file.name
    this.user_id = localStorage.getItem("client_data");
    const user = JSON.parse(this.user_id).user_type_id;
    // ////console.log("hey_ram", user);

    let tokkenget: any = localStorage.getItem("clientintoken");
    const formData: FormData = new FormData();
    formData.append("loop_artwork", file);
    formData.append("loop_id", this.data.data);

    let headers: any = {
      Authorization: `Bearer ${tokkenget}`,
      "My-Custom-Header": "foobar",
    };

    this.http
      .post(`${APIURL.BASE_URL}/user/upload_loop_draft`, formData, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
        // this.imageProgress = false;
        // this.disable_input_as_itsloading1 = true;

    // ////console.log("event:", event);
    let tokkenget: any = localStorage.getItem("clientintoken");
    let headers: any = {
      Authorization: `Bearer ${tokkenget}`,
      "My-Custom-Header": "foobar",
    };
    this.http
      .post(
        `${APIURL.BASE_URL}/user/get_upload_detail`,
        { loop_id:  this.data.data },
        {
          headers: headers,
        }
      )
      .pipe()
      .subscribe((event: any) => {
        this.tableData = event.response.data.loop_details[0];
        this.common.hideLoader()
        // let dialogue = this.matDialog.open(DeleteComponent, { panelClass: 'delete-loop',data:{loop_id:this.data.data}, disableClose: true });
        // dialogue.afterClosed().subscribe(result => {
        //   // ////console.log('The dialog was closed');
        // });
      })
      });
  }
}
