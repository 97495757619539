<app-header></app-header>
<div class="page-header" style="background-image:url(../../../../assets/img/login.jpg);background-size: cover;margin:0px;">
    <main>
    <div class="container">
        <div class="verifyemail">
            <div class="card-eVerification">
                <div class="card-success" *ngIf="apivalid && apiResponse">
                    <h1 class="text-center">Login verification</h1>
                    <img class="inline" src="assets/img/emal-success.png" alt="">
                    <h3 class="inline headingleftmargin">{{statusmessage}}</h3>
                </div>
                <div class="card-fail" *ngIf="!apivalid && apiResponse" style="margin-top: 90px;">
                    <h1 class="text-center">Login verification</h1>
                    <img class="inline" src="assets/img/incorrect.png" alt="">
                    <h3 class="inline headingleftmargin">{{statusmessage}}</h3>
                </div>
            </div>
        </div>
    </div>
</main>
</div>
