import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { APIURL, VARS } from 'src/app/allServices/constant.service';
import { commonservice } from 'src/app/allServices/commonservice';
// import { APIURL } from '../allServices/constant.service';
// import { ApiServiceService } from '../allServices/api-service.service';

@Component({
  selector: 'app-plan2signup',
  templateUrl: './plan2signup.component.html',
  styleUrls: ['./plan2signup.component.css']
})
export class Plan2signupComponent implements OnInit {
  radiocheckforloophead: FormGroup;
  monthlyprice: any = "...";
  yearlyprice: any = "...";
  datasend: any;
  objectformonth: any;
  objectforyear: any;
  yearrlydivide: any;
  discount: any;

  constructor(private formbuilder: FormBuilder, private toastr: ToastrService, private router: Router, private http: HttpClient,public api: ApiServiceService,public common: commonservice) {
    this.forgetpriceapi();

    this.radiocheckforloophead = formbuilder.group({

      radio: ["year", [Validators.required]],

    });
  }



  ngOnInit(): void {
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
      }
      let resa = JSON.parse(res)
      if(resa) this.api.geturltoredirectexceptplandetail(resa.is_on_boarding_complete, resa.steps_completed);
    })
    // this.getapiforallinfo();
  }

  forgetpriceapi() {
    const headers: any = "";
    this.common.showLoader();
    // this.http.get(APIURL.BASE_URL+'/user/get_all_premium_plans', { headers }).subscribe(async (res: any) => {
      this.api.getApiForClient( '/user/get_all_premium_plans', VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        // ////console.log(res.response.data[0]);
        this.monthlyprice = res.response.data[1].plan_price;
        this.yearlyprice = res.response.data[0].plan_price;
        this.yearrlydivide = res.response.data[0].plan_price / 12;
        this.yearrlydivide = this.yearrlydivide.toFixed(2);

        this.discount = Math.ceil(((this.monthlyprice - this.yearrlydivide) / this.yearrlydivide) * 100);
        // this.datasend = res.response.data[0].plan_name;
        // localStorage.setItem("loggedintoken", res.response.data.token);
        this.objectforyear = {
          can_sell_loopkits: res.response.data[0]. can_sell_loopkits,
          can_sell_loops: res.response.data[0]. can_sell_loops,
          created_on: res.response.data[0]. created_on,
          delete_flag: res.response.data[0]. delete_flag,
          is_enabled: res.response.data[0].  is_enabled,
          loopkits_to_upload: res.response.data[0]. loopkits_to_upload,
          loops_to_upload: res.response.data[0]. loops_to_upload,
          modified_on: res.response.data[0].  modified_on,
          plan_id: res.response.data[0]. plan_id,
          plan_name: res.response.data[0].  plan_name,
          plan_price: res.response.data[0]. plan_price,
          recurrence_interval: res.response.data[0]. recurrence_interval,
          recurrence_interval_unit: res.response.data[0].recurrence_interval_unit,
          live_plan_id: res.response.data[0].live_plan_id,
          sandbox_plan_id:  res.response.data[0]. sandbox_plan_id

        }

        this.objectformonth = {
          can_sell_loopkits: res.response.data[1]. can_sell_loopkits,
          can_sell_loops: res.response.data[1]. can_sell_loops,
          created_on: res.response.data[1]. created_on,
          delete_flag: res.response.data[1]. delete_flag,
          is_enabled: res.response.data[1].  is_enabled,
          loopkits_to_upload: res.response.data[1]. loopkits_to_upload,
          loops_to_upload: res.response.data[1]. loops_to_upload,
          modified_on: res.response.data[1].modified_on,
          plan_id: res.response.data[1]. plan_id,
          plan_name: res.response.data[1].  plan_name,
          plan_price: res.response.data[1]. plan_price,
          recurrence_interval: res.response.data[1]. recurrence_interval,
          recurrence_interval_unit: res.response.data[1].recurrence_interval_unit,
          live_plan_id: res.response.data[1].live_plan_id,
          sandbox_plan_id:  res.response.data[1]. sandbox_plan_id

        }
      } else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          // let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      // ////console.log('Error', e);
      this.common.hideLoader();
      this.toastr.error("Error in Api", "Error", { positionClass: 'toast-bottom-right' });

    });
  }


  onclick() {
    this.router.navigate(['plandetails']);
  }

  onSubmit() {
    // ////console.log(this.radiocheckforloophead.value.radio);
    if (this.radiocheckforloophead.valid == true) {
      if (this.radiocheckforloophead.value.radio == "month") {
        localStorage.setItem("Premimumplandetails", JSON.stringify(this.objectformonth));
      } else {
        localStorage.setItem("Premimumplandetails", JSON.stringify(this.objectforyear));
      }
      // if (this.radiocheckforloophead.value.radio == "day") {
      //   ////console.log("ye")
      // }

      // alert("right");
      this.router.navigate(['/payment'], { queryParams: { plantype: this.radiocheckforloophead.value.radio } })

      // alert("Api will hit now");

      // this.apiforsubmitform();

    }
  }


  getapiforallinfo() {
    let tokkenget = localStorage.getItem("clientintoken");
    let sendingData = {

    }

    

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    // ////console.log(headers);
    this.http.post(APIURL.BASE_URL + `/user/get_logged_in_user_details`, sendingData, { headers }).subscribe(async (res: any) => {
      
      if (res.response.status == 1) {
        // ////console.log(res.response.data);
        // ////console.log(res.response.data.user_details.is_on_boarding_complete);
        // ////console.log(res.response.data.user_details.steps_completed);
        this.api.geturltoredirectexceptplandetail(res.response.data.is_on_boarding_complete, res.response.data.steps_completed);


      }else if (res.response.status == 3) {
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        // ////console.log("in going")
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          // this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        this.common.clearAllLocalAndSession();
        // localStorage.removeItem("clientintoken");
        this.router.navigate(['/login']);
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) { 
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      // ////console.log('Error', e);
    });

  }
  getUserDetail() {
    this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {},VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if(res.response.status){
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res:any)=>{
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptplandetail(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }

}
