import { Component, Inject, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CONSTNAME } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-follwers',
  templateUrl: './follwers.component.html',
  styleUrls: ['./follwers.component.css']
})
export class FollwersComponent implements OnInit {
  pageno: any;
  sorton: any;
  rowsize: any;
  sorttype: any;
  norecordfound: boolean;
  hidepagination: boolean;
  paginationempty: boolean;
  pagercount: never[];
  array: any;
  encrypted: string;
  tableData: any;
  record: any;
  pagenolist: any;
  total: any;
  pagenocount: any;
  image = CONSTNAME.fILE_PATH;
  hidesearchbox: boolean;
  toastr: any;
  tokenFromUI: string = "0123456789123456";
  search: any;
  decrypted: string;
  encryptedagainremoveslashes: string;
  value: any;
  data: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";

  constructor(private router: Router,public common: commonservice, public api: ApiServiceService,private route: ActivatedRoute, public matDialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data1: any) { }

  ngOnInit(): void {
    this.value = this.data1.user_id;
    // this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // this.decrypted = CryptoJS.AES.decrypt(
    //   this.encryptedagainremoveslashes, _key, {
    //   keySize: 16,
    //   iv: _iv,
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7
    // }).toString(CryptoJS.enc.Utf8);
    // // ////console.log(this.decrypted);
    // this.decrypted = this.decrypted.replace(/[""]/gi, '');
    // ////console.log(this.tokenFromUI);

   this.performsearch()
  
  }
  performsearch(){
 
    let sendingdata = {
      "page_no":this.pageno,
      "sort_on":this.sorton,
      "row_size":this.rowsize,
      "sort_type":this.sorttype,
      "get_followers":1,
      "profile_user_id": this.value,
      "search" : this.search
      
    }

    this.api.forclientgettokenpublic(`/user/get_followers_following`, sendingdata).then((res: any) => {

      // ////console.log(res);


      if (res.response.status == 1) {
        this.common.hideLoader();
        // ////console.log(res);

        if (res.response.data.total_records == 0) {
          this.norecordfound = false;
        } else {
          this.norecordfound = true;
        }

        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
        } else {
          this.hidepagination = false;
        }
        if (this.paginationempty == true) {
          this.pagercount = [];
        }

        // this.tableData = res.response.data.pagging_list;

        for (let i = 0; i < res.response.data.pagging_list.length; i++) {
          this.array = res.response.data.pagging_list[i];

          this.common.hideLoader();


        

          let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
          let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
          let encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(this.array.user_id), _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          });
          this.encrypted = encrypted.toString();
          this.encrypted = encodeURIComponent(this.encrypted);
        


          this.array['enc_link'] = this.encrypted;
          // this.tableData.push(this.array)






        }






        this.data = res.response.data.pagging_list
        // this.record = this.tableData.length;
        // ////console.log(this.data);
        
        this.pagenolist = res.response.data.total_pages + 1;
        this.record = res.response.data.pagging_list.length;
        this.total = res.response.data.total_records;
        this.record = this.pageno - 1 + "1";
        if (this.record == "01") {
          this.record = 1;
        }
        this.pagenocount = 10*(this.pageno - 1) + res.response.data.pagging_list.length


        // this.tableData.map((data: any) => {
        //   if ( data.profile_image_path == null) {
        //     this.imageofcomment = this.profile_image_pathofcomments;
        //     // ////console.log("gaya");
        //   } else {
        //     // ////console.log("nahi gaya");
        //     this.imageofcomment = CONSTNAME.fILE_PATH + data.profile_image_path;
        //   }
        // })

        for (let i = 1; i < this.pagenolist; i++) {
          // this.pagercount.push(i);

        }
        // ////console.log(res.data.total_records)
        this.total = res.response.data.total_records;
        // let ida: any = document.getElementById("liststyle"+1);
        // ////console.log(ida);
        // ida.style.backgroundColor = "orange";

      } else if (res.response.status == 4) {
        // ////console.log("status is 4");
        this.common.hideLoader();
        this.hidesearchbox = true;
        // this.noresult = false

      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async (e: any) => {
      this.common.hideLoader();

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  }
  cancel(){
    this.matDialog.closeAll()
  }
  setimage(data: any) {
    // if (data.artwork_path == null) {
      if (data.profile_image_path == null) {
        return this.profile_image_pathofcomments;
  
      } else {
        if (data.profile_image_path.includes("/profile_photos/")) {
          return  CONSTNAME.fILE_PATH + data.profile_image_path;
          }else
        return data.profile_image_path;
      }
  
    // } else {
  
    //   return CONSTNAME.fILE_PATH + data.artwork_path;
    // }
  }
  redirecttouser(item: any){
    this.matDialog.closeAll()
    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let encrypted = CryptoJS.AES.encrypt(
    //   JSON.stringify(item), _key, {
    //   keySize: 16,
    //   iv: _iv,
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7
    // });
    // this.encrypted = encrypted.toString();
    // this.encrypted = encodeURIComponent(this.encrypted);
    this.router.navigate([`${item}`])

  }
}
