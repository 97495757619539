// zip-upload.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZipuploadService {
  private zipUploadInProgressSubject = new BehaviorSubject<boolean>(false);
  zipUploadInProgress$ = this.zipUploadInProgressSubject.asObservable();

  setZipUploadInProgress(inProgress: boolean) {
    this.zipUploadInProgressSubject.next(inProgress);
  }
}
