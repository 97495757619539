import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// import { CustomvalidationService } from '../+register/customvalidation.service';
// import { ApiServiceService } from '../allServices/api-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
// import { ForgotpasswordmodelComponent } from '../forgotpasswordmodel/forgotpasswordmodel.component';
// import { StaticService } from '../sevicesall/static.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, APP_ROUTES, INPUT_TYPE_NAME, VARS } from 'src/app/allServices/constant.service';
import { StaticService } from 'src/app/sevicesall/static.service';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { ForgotpasswordmodelComponent } from '../../forgotpasswordmodel/forgotpasswordmodel.component';
import { EmailchangeverificationComponent } from '../../MODAL/emailchangeverification/emailchangeverification.component';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
// import { EmailchangeverificationComponent } from '../user/modal/emailchangeverification/emailchangeverification.component';
// import { ForgotpasswordmodelComponent } from '../CLIENT_PAGES/forgotpasswordmodel/forgotpasswordmodel.component';


@Component({
  selector: 'app-login-loophead',
  templateUrl: './login-loophead.component.html',
  styleUrls: ['./login-loophead.component.css']
})
export class LoginLoopheadComponent implements OnInit {
  loginForm = StaticService.getLoginForm();
  lFormC: any;
  isLogin = true;
  displayname = "none"
  loginformforloophead: FormGroup;
  temp_email: any;
  apihit = false;
  loginerror: any;
  isMobile: boolean;


  constructor(private matDialog: MatDialog,
    private el: ElementRef,
    private formbuilder: FormBuilder, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute,
    public common: commonservice,
    public api: ApiServiceService,private breakpointObserver: BreakpointObserver
  ) {

    const password = '^(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$';
    this.loginformforloophead = formbuilder.group({

      // username_email: ["", [Validators.required]],
      password: ["", [Validators.minLength(6), Validators.required, Validators.pattern(password)]]
      //  rememberLoginpage:["",[]]

    });
  }

  redirecttologin() {
    this.router.navigate(['/login']);
  }
  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 576px)']).subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
    let local = localStorage.getItem("clientintoken");
    if (local) {

      this.router.navigate(['/explore']);


    }
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        
        if (localStorage.getItem(VARS.CLIENT_LOCAL_TOKEN_KEY)) {
          this.common.showLoader();
          this.getUserDetail();
        }
        
      }
      let resa = JSON.parse(res)
      if (resa) this.api.getUrlToRedirect(resa.is_on_boarding_complete, resa.steps_completed);
     
    })
  }

  redirecttosignup(){
    this.router.navigate(['/register']);

  }
  redirecttoforgot(){
    this.router.navigate(['/forgot-password']);

  }





  // /////// New
  vCheck(fieldName: any, type: INPUT_TYPE_NAME, options: any = {}) {
    options.isRequired = true;
    const cFrm = this.loginForm.controls;
    // ////console.log(this.loginForm.value);
    // ////console.log(cFrm);
    
    if (cFrm) {
      return StaticService.formError(cFrm, fieldName, type, options).msg;
    }
  }
  async login() {
    // ////console.log('Click', this.loginForm.value)
    const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
    StaticService.markFormGroupTouched(this.loginForm, invalidElements);
    if (this.loginForm.valid &&  !this.apihit) {
      this.apihit = true;
      this.common.showLoader();
      // ////console.log('check',APIURL.ADMIN_LOGIN);
      
      this.api.postApiHit(APIURL.LOGIN_CLIENT, this.loginForm.value).then((res: any) => {

        // this.apiHit = false;

        this.common.showToast(res.response.msg ? res.response.msg : res.response.errors[0], res.response.status);
        if (res.response.status == 1) {

          this.common.setlocal(VARS.AUTH_WITH, JSON.stringify(res.response.data.user_details));
          // ////console.log();
          this.common.hideLoader();
          
          // if(res.response.data.user_details.auth==0){
            // ////console.log('hkj');
          this.common.setlocal(VARS.CLIENT_LOCAL_TOKEN_KEY, res.response.data.token);
          this.getUserDetail();
          // }
          // }else{
          // this.common.setlocal(VARS.CLIENT_LOCAL_TOKEN_temp, res.response.data.token);
          // ////console.log('iohkj');
          //   this.router.navigate(['/authenticate'])
          // }
          this.common.eventPublish('_Update_profile');
          // this.api.getUrlToRedirect(resa.is_on_boarding_complete, resa.steps_completed);
       
          // this.api.getUrlToRedirect(res.response.data.user_details.is_on_boarding_complete, res.response.data.user_details.steps_completed);


          // if (res.response.data.user_details.steps_completed == 1) {
          //   this.router.navigate(['/plandetails']);
          // }
          // if (res.response.data.user_details.steps_completed == 2) {
          //   ////console.log("verify email");
                      // this.router.navigate(['/explore']);
          // }
          // this.common.navigateToUrl(APP_ROUTES.ADMIN_HOME)
        } else if (res.response.status == 0) {
          this.common.hideLoader();
          this.apihit = false;
          this.loginerror = "Your username or password is incorrect";
        } else if (res.response.status == 2) {
          this.common.hideLoader();
          this.apihit = false;
          this.temp_email = res.response.data.temp_email;
          this.opendialogueforverification();
        } else {
          this.apihit = false;
          this.common.hideLoader();
        }
        // ////console.log('res',res);

      })
    }
  }

  getUserDetail() {
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res)
          this.router.navigate(['/explore']);

        })
      }
    })
  }

  opendialogueforverification(): void {
    let dialogue = this.matDialog.open( EmailchangeverificationComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: this.temp_email } });
    dialogue.afterClosed().subscribe(result => {

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
}
