import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
@Component({
  selector: 'app-emailchangeverification',
  templateUrl: './emailchangeverification.component.html',
  styleUrls: ['./emailchangeverification.component.css']
})
export class EmailchangeverificationComponent implements OnInit {
  fromPage:any;
  
  constructor( public matDialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any,public api: ApiServiceService) {   }

  ngOnInit(): void {
   this.fromPage=this.data.pageValue 
  }

  

  cancel(){
    this.matDialog.closeAll()
  }


  verifyemail() {
    this.api.apihitforresendverification(this.fromPage);
    // ////console.log("click hua");
  }
}
