import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, VARS } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-myuploadsmyaccount',
  templateUrl: './myuploadsmyaccount.component.html',
  styleUrls: ['./myuploadsmyaccount.component.css']
})
export class MyuploadsmyaccountComponent implements OnInit {
  tableData: any;
  pagercount: any = [];
  pagenolist: any;
  pageno: any = 1;
  paginationempty = false;
  totallength: any;
  noresultshow: any;
  termsofpreference: any
  looptype: any;
  constructor(public api: ApiServiceService,private toastr: ToastrService,public common: commonservice) { }

  ngOnInit(): void {
    this.getallinfoofloops();

    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
      }
      let resa = JSON.parse(res)
      if(resa) this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
    })
  }


  getallinfoofloops() {
    
    this.common.showLoader();
    this.api.getApiForClient(`/user/get_all_uploads?page_no=${this.pageno}&row_size=10&sort_on=created_on&sort_type=DESC`, VARS.ClIENT_TYPE).then((res: any) => {
     
      // this.afterload = true;
      if (res.response.status == 1) {
        if (this.paginationempty == true) {
          this.pagercount = [];
        }
        this.pagenolist = res.response.data.total_pages + 1
        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }
        this.totallength = this.pagercount;
        
       
        this.tableData = res.response.data.pagging_list;

        // this.sellingpreference = res.response.data.loop_details[0].selling_preference;
        // this.termsofpreference = res.response.data.loop_details[0].terms;

        // if (this.sellingpreference == 1) {
        //   if (this.termsofpreference == 1) {
        //     this.termsofpreference = "Royalty Free";
        //   } else if (this.termsofpreference == 2) {
        //     this.termsofpreference = "50/50 Master + Publishing Split";
        //   }
        // } else if (this.sellingpreference == 2) {
        //   if (this.termsofpreference == 1) {
        //     this.termsofpreference = "Royalty Free";
        //   } else if (this.termsofpreference == 2) {
        //     this.termsofpreference = "50/50 Master + Publishing Split";
        //   } else {
        //     this.termsofpreference = "Exclusive";
        //   }
          
        // }
        // ////console.log(this.tableData);

        this.common.hideLoader();
        //  ////console.log("rec");
        

    

      } else if (res.response.status == 0) {
        // ////console.log(res.response.data.total_records);
        this.common.hideLoader();
        this.noresultshow = true;
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];
        
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })

        }
      }
    }, async e => {
      this.common.hideLoader();
    });
  }

  paginationget(pageinfo: any) {
    this.paginationempty = true;

    this.pageno = pageinfo;
  
    
      this.getallinfoofloops();
    


  }

  callfree(item:any) {
    // ////console.log(item);
    if (item == 1) {
      this.termsofpreference = "Royalty Free";
      
    } else {
      this.termsofpreference = "50/50 Master + Publishing Split"
      
    }
  }
  callpremium(item:any) {
    // ////console.log(item);
    if (item == 1) {
      this.termsofpreference = "Royalty Free";
      
    }  else if(item == 2)  {
      this.termsofpreference = "50/50 Master + Publishing Split"
      
    } else {
          this.termsofpreference = "Exclusive";
        }
  }
  loopinfo(item:any) {
    if (item == 1) {
      this.looptype = "Loop";
      
    }  else if(item == 2)  {
      this.looptype = "Loop Kit"
      
    } 
  }


  getUserDetail() {
    this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {},VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if(res.response.status){
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res:any)=>{
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }

  



}
