


import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import { InvoiceComponent } from 'src/app/CLIENT_PAGES/MODAL/invoice/invoice.component';
import { MatDialog } from '@angular/material/dialog';
import { AdmininvoiceComponent } from 'src/app/CLIENT_PAGES/MODAL/admininvoice/admininvoice.component';

@Component({
  selector: 'app-allinvoices',
  templateUrl: './allinvoices.component.html',
  styleUrls: ['./allinvoices.component.css']
})
export class AllinvoicesComponent implements OnInit {
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = '';
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = false;
  norecordfound = false;
  sorttype = "";
  sorton = "";
  pagenocount: any;
  arrayfortable: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  encryptedagainremoveslashes: string;
  value: any;
  totalpagesinaccount: any;
  items: any = [];
  pageOfItems: Array<any>
  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, public matDialog: MatDialog) { }

  ngOnInit(): void {
    // this.pageno = this.route.snapshot.queryParamMap.get('pageno')
    // this.encrypted = this.route.snapshot.params['id'];
    this.value = this.route.snapshot.queryParamMap.get('loop_id');
    // ////console.log(this.encrypted)
    this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    this.decryptUsingAES256();
  }
  decryptUsingAES256() {
    // ////console.log(this.encryptedagainremoveslashes);
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    this.decrypted = this.decrypted.replace(/[""]/gi, '');
    this.getapicallforcouponcode(this.decrypted);
  }

  encryptUsingAES256a() {
    this.router.navigate([`/admin/loops/detail-uploads/${this.value}`]);
  }


  getapicallforcouponcode(dec: any) {
    this.common.showLoader();
    let sendingdata = {
      "page_no": 1,
      "sort_on": "created_on",
      "row_size": this.rowsize,
      "sort_type": "DESC",
      "loop_id": dec,
      // "search": "loopu"
    }


    // this.router.navigate(['/admin/loops/list-uploads'], { queryParams: { pageno: this.pageno } });
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

    this.api.postApiHit(`/admin/download_archive`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {




      if (res.response.status == 1) {
        this.common.hideLoader();
        // ////console.log(res)
        this.tableData = res.response.data.pagging_list

        if (res.response.data.total_records == 0) {
          this.norecordfound = true;
        } else {
          this.norecordfound = false;
        }

        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
        } else {
          this.hidepagination = false;
        }
        if (this.paginationempty == true) {
          this.pagercount = [];
        }

        this.tableData = res.response.data.pagging_list;
        this.record = this.tableData.length;
        // ////console.log(this.record);
        this.pagenolist = res.response.data.total_pages + 1;
        this.record = this.pageno - 1 + "1";
        // ////console.log(this.record,this.pageno);
        // ////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
        this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length

        this.tableData = res.response.data.pagging_list;
        // this.record = this.tableData.length;
        this.pagenolist = res.response.data.total_pages + 1;
        if (this.record == "01") {
          this.record = 1;
        }

        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }
        // ////console.log(res.data.total_records)
        this.total = res.response.data.total_records;
        if (this.totalpagesinaccount !== res.response.data.total_pages) {
          this.totalpagesinaccount = res.response.data.total_pages
          this.items = Array(this.totalpagesinaccount).fill(0).map((x, i) => (i + 1))
          }
        // let ida: any = document.getElementById("liststyle"+1);
        // ////console.log(this.record);
        // ida.style.backgroundColor = "orange";

      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();

      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

    });
  }





  onsearch() {

    // ////console.log("searching");
    this.searchApi();
  }
  searchClick() {

    this.pageno = 1;
    this.searchApi();
  }
  searchApi() {
    this.common.showLoader();
    let search = this.validationforsearch
    let sendingdata = {
      "page_no": 1,
      "sort_on": "created_on",
      "row_size": this.rowsize,
      "sort_type": "DESC",
      "loop_id": this.decrypted,
      "search": search
    }



    // this.router.navigate(['/admin/loops/list-uploads'], { queryParams: { search: this.validationforsearch, pageno: this.pageno } });
   
    let tokkenget = localStorage.getItem("loggedintoken");
    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };


    this.api.postApiHit(`/admin/download_archive`, sendingdata, VARS.ADMIN_TYPE).then((res: any) => {

      if (res.response.status == 1) {
        this.common.hideLoader();
        if (res.response.data.total_pages == 1) {
          this.hidepagination = true;
        } else {
          this.hidepagination = false;
        }



        if (res.response.data.total_records == 0) {
          this.pagercount = [];


          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.noresult = true;
          // ////console.log("aa");
          // this.toastr.success("No record found", res.response.errors, { positionClass: 'toast-bottom-right' });
          this.total = res.response.data.total_records;

        } else {
          this.common.hideLoader();

          this.noresult = false;
          this.pagercount = [];
          this.tableData = res.response.data.pagging_list;
          this.record = this.tableData.length;
          this.pagenolist = res.response.data.total_pages + 1;
          this.record = this.pageno - 1 + "1";
          if (this.record == "01") {
            this.record = 1;
          }
          // ////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
          this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length
          for (let i = 1; i < this.pagenolist; i++) {
            this.pagercount.push(i);
          }
          this.total = res.response.data.total_records;

        }
        if (this.totalpagesinaccount !== res.response.data.total_pages) {
          this.totalpagesinaccount = res.response.data.total_pages
          this.items = Array(this.totalpagesinaccount).fill(0).map((x, i) => (i + 1))
          }
      } else {
        this.common.hideLoader();

        let errorthroughapi = res.response.errors;
        let error = [];

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.common.hideLoader();
      this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  tablevaluearray = [
    { name: 'Title', tablehead: 'title', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Loop type', tablehead: 'loop_type', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created By', tablehead: 'first_name', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },
    { name: 'Created On', tablehead: 'created_on', sort: false, showicon: true, sortdesc: false, class: 'fa fa-sort' },

    { name: 'Action', tablehead: 'Action', sort: false, showicon: false, sortdesc: false, class: 'fa fa-sort' }

  ];
  sortOn(value: any) {
    this.getapicallforsort(value.tablehead);
    this.tablevaluearray.map(item => {
      item.sort = false;
      item.sortdesc = false;
      item.class = 'fa fa-sort';
    })
    value.sort = true;
    value.class = "fa fa-sort-asc";
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "title")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "loop_type")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "first_name")) {
      value.class = 'fa fa-sort-desc';
    }
    if ((this.route.snapshot.queryParamMap.get('sort') == "ASC") && (value.tablehead == "created_on")) {
      value.class = 'fa fa-sort-desc';
    }


  }

  getapicallforsort(got: any) {
    let sort_on = got;
    let arrange = "DESC";
    if (this.route.snapshot.queryParamMap.get('sort') == "ASC") {
      arrange = "DESC";
      this.sortsign = "sort-desc";
      this.sorton = sort_on;
      this.sorttype = arrange;
    } else {
      arrange = "ASC";
      this.sortsign = "sort-asc";
      this.sorton = sort_on;
      this.sorttype = arrange;
    }
    this.searchwordget = this.route.snapshot.queryParamMap.get('search')
    if (this.searchwordget) {
      this.common.showLoader();
      this.router.navigate(['/admin/loops/list-uploads'], { queryParams: { search: this.validationforsearch, pageno: this.pageno, sort: arrange } });
      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };

      // this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search_input=${this.searchwordget}`, { headers }).subscribe(async (res: any) => {
      this.api.postApiHit(`/admin/get_all_uploads?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}&search=${this.searchwordget}`, {}, VARS.ADMIN_TYPE).then((res: any) => {

        if (res.response.status == 1) {
          this.common.hideLoader();
          this.tableData = res.response.data.pagging_list;
        } else {
          this.toastr.error("Error", res.response.error, { positionClass: 'toast-bottom-right' });
          this.common.hideLoader();
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.getpageno = this.route.snapshot.queryParamMap.get('pageno');
      if (this.getpageno) {
        this.pageno = this.getpageno;
      } else {
        this.pageno = 1;
      }
      this.router.navigate(['/admin/loops/list-uploads'], { queryParams: { sort: arrange, pageno: this.pageno } });

      let tokkenget = localStorage.getItem("loggedintoken");
      const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
      // this.http.get(APIURL.BASE_URL + `/admin/get_all_coupon_codes?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}`, { headers }).subscribe(async (res: any) => {
      this.api.postApiHit(`/admin/get_all_uploads?sort_on=${sort_on}&sort_type=${arrange}&row_size=${this.rowsize}&page_no=${this.pageno}`, {}, VARS.ADMIN_TYPE).then((res: any) => {
        if (res.response.status == 1) {
          this.common.hideLoader();

          this.tableData = res.response.data.pagging_list;

        } else {
          this.toastr.error("", res.response.error, { positionClass: 'toast-bottom-right' });
          this.common.hideLoader();
        }
      }, async e => {
        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

      });

    }

  }


  paginationget(pageinfo: any) {
    this.paginationempty = true;
    this.pageno = pageinfo;

    if (this.route.snapshot.queryParamMap.get('search')) {
      this.searchApi()
    } else {
      this.getapicallforcouponcode(this.decrypted);
    }


  }

  // routerLink=""
  toreportedloops() {
    // ////console.log("yes");
    this.router.navigate(['/admin/loops/reported-loops']);
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(value);
    // ////console.log(this.encrypted);

    // ////console.log(`/admin/loops/detail-uploads/${this.encrypted}`);
    this.router.navigate([`/admin/loops/detail-uploads/${this.encrypted}`]);
  }

  redirecttoinvoices() {
    this.router.navigate(['/admin/loops/allinvoices']);
  }

  // [routerLink]="['/admin/loops/detail-uploads/'+item.loop_id]"

  addcolor(item: any) {
    if (item == this.pageno) {
      return 'activated';
    } else {
      return ''
    }
  }

  srcforimage(item: any) {
    if (item.profile_image_path == null) {
      // ////console.log("i am in null");
      return this.profile_image_pathofcomments;
    } else {
      // ////console.log("i am not in null");
      return CONSTNAME.fILE_PATH + item.profile_image_path;
    }
  }

  redirecttoprofilepage(item: any) {
    // ////console.log("gaya")
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(item), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    window.open(`${APIURL.BASE_URLforredirect}/user?user_id=${this.encrypted}`, `_blank`)


  }

  redirecttoinvoice(items: any) {
    // ////console.log("yo")
    this.opendialogueforverificatio(this.decrypted, items)
  }

  opendialogueforverificatio(item: any, items: any): void {
    let dialogue = this.matDialog.open(AdmininvoiceComponent, { panelClass: 'forgot-password', disableClose: true, data: { pagename: item, paymentid: items } });
    dialogue.afterClosed().subscribe(result => {





    });



  }
  onChangePage(pageOfItems: Array<any>) {
    
    if ((this.pageno !== pageOfItems[0]) && !(pageOfItems[0] == undefined)) {
      // ////console.log("inside")
      this.pageno = pageOfItems[0];
      if (this.route.snapshot.queryParamMap.get('search')) {
        this.searchApi()
      } else {
        this.getapicallforcouponcode(this.decrypted);
      }
    }




  }
}


