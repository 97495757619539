<ng-container *ngIf="customLayout; else noCustomLayout">
  <ngx-spinner
        color="#fff"
        type="timer"
        text="loading"
        size="default"
        >
          <p style="color: white" > Loading... </p>
      </ngx-spinner>
  <!-- <app-header *ngIf="!hideHeader"></app-header> -->
  <router-outlet ></router-outlet>
  <div class="box-mob"  *ngIf="isMobile">
    
    <!-- <app-header></app-header> -->
    <!-- <div class="mobile">
      <img src="../assets/img/Electronic musician setup.png"/>
    </div>
    <h1 class="mob-head">In The Lab...</h1>
    <p class="text">Loophead mobile is currently not available. While we are working, please visit our website on your desktop computer!</p>
    <p class="reg">Warmest Regards,
      </p>
      <h1 class="mob-auth">The Loophead Team</h1>
      <button class="bt" (click)="openEmail()">Contact</button> -->
  </div>
</ng-container>
<ng-template #noCustomLayout>


  <mk-layout-wrapper>
    <mk-layout-header logoLink="/admin">
      
      <mk-layout-header-logo><b>Loophead </b> Admin </mk-layout-header-logo>
      <mk-layout-header-logo-mini><b>L</b>h</mk-layout-header-logo-mini>
      <app-header-inner></app-header-inner>
    </mk-layout-header>



    <mk-layout-sidebar-left>
      <app-sidebar-left-inner></app-sidebar-left-inner>
    </mk-layout-sidebar-left>

    <!-- <mk-layout-sidebar-right>
      <app-sidebar-right-inner></app-sidebar-right-inner>
    </mk-layout-sidebar-right> -->



    <mk-layout-content>
      <ngx-spinner
        color="#fff"
        type="timer"
        text="loading"
        size="small"
        >
          <p style="color: white" > Loading... </p>
      </ngx-spinner>
      <div mk-layout-content-before-header>
        <div *mkLoadingPage="{checkPendingHttp: true, checkPendingRoute: true}">
          <mk-material-bar></mk-material-bar>
        </div>
      </div>
      <router-outlet></router-outlet>
    </mk-layout-content>



    <mk-layout-footer>
      <mk-layout-footer-left>
        <div>
          <!-- <strong> <a href="">Loophead Milestones</a>.</strong> -->
        </div>
        
      </mk-layout-footer-left>
      <mk-layout-footer-right>
        <!-- <b>Version</b> -->
      </mk-layout-footer-right>
    </mk-layout-footer>
  </mk-layout-wrapper>
</ng-template>
