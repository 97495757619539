export const adminLteConf = {
  skin: 'blue',
  // isSidebarLeftCollapsed: false,
  // isSidebarLeftExpandOnOver: false,
  // isSidebarLeftMouseOver: false,
  // isSidebarLeftMini: true,
  // sidebarRightSkin: 'dark',
  // isSidebarRightCollapsed: true,
  // isSidebarRightOverContent: true,
  // layout: 'normal',pullRights: [{text: 'New', classes: 'label pull-right bg-green'}]
  sidebarLeftMenu: [
    {label: 'MAIN NAVIGATION',route: 'admin', separator: true},
    { label: 'Dashboard', route: 'admin/dashboard', iconClasses: 'fa fa-cog' },
    { label: 'Admin Profile', route: 'admin', iconClasses: 'fa fa-unlock' },
    {
      label: 'Subscription Plans', route: 'admin/subscriptiontiers', iconClasses: 'fa fa-credit-card'
    },
    {
      label: 'Coupon Codes', route: 'admin/list-coupon-codes', iconClasses: 'fa fa-clipboard'
    },
    //  children: [
    //     { label: 'Add ', route: '/admin/list-coupon-codes/detail-coupon-code/new' }]
    // },
    { label: 'Loops / Loop Kits', route: 'admin/loops/list-uploads', iconClasses: 'fa fa-arrows-v' },
    {label: 'Users', route: 'admin/user', iconClasses: 'fa fa-users'},
    { label: 'Banned words', route: 'admin/bannedwords', iconClasses: 'fa fa-cog' },
    { label: 'Global Settings', route: 'admin/globalsetting', iconClasses: 'fa fa-cog' }
    // { label: 'rough', route: 'admin/roughwork', iconClasses: 'fa fa-cog' },

    // {label: 'Layout', iconClasses: 'fa fa-th-list', children: [
    //     {label: 'Configuration', route: 'layout/configuration'},
    //     {label: 'Custom', route: 'layout/custom'},
    //     {label: 'Header', route: 'layout/header'},
    //     {label: 'Sidebar Left', route: 'layout/sidebar-left'},
    //     {label: 'Sidebar Right', route: 'layout/sidebar-right'},
    //     {label: 'Content', route: 'layout/content'}
    //   ]},
    // // {label: 'COMPONENTS', separator: true},
    
    
    // {label: 'Boxs', iconClasses: 'fa fa-files-o', children: [
    //     {label: 'Default Box', route: 'boxs/box'},
    //     {label: 'Info Box', route: 'boxs/info-box'},
    //     {label: 'Small Box', route: 'boxs/small-box'}
    //   ]},
    
    // {label: 'Form', iconClasses: 'fa fa-files-o', children: [
    //     {label: 'Input Text', route: 'form/input-text'}
    // ]},
    
    
  ]




  // sidebarLeftMenu: [
  //   {label: 'MAIN NAVIGATION', separator: true},
  //   {label: 'Get Started', route: '/', iconClasses: 'fa fa-road', pullRights: [{text: 'New', classes: 'label pull-right bg-green'}]},
  //   {label: 'Layout', iconClasses: 'fa fa-th-list', children: [
  //       {label: 'Configuration', route: 'layout/configuration'},
  //       {label: 'Custom', route: 'layout/custom'},
  //       {label: 'Header', route: 'layout/header'},
  //       {label: 'Sidebar Left', route: 'layout/sidebar-left'},
  //       {label: 'Sidebar Right', route: 'layout/sidebar-right'},
  //       {label: 'Content', route: 'layout/content'}
  //     ]},
  //   {label: 'COMPONENTS', separator: true},
  //   {label: 'Accordion', route: 'accordion', iconClasses: 'fa fa-tasks'},
  //   {label: 'Alert', route: 'alert', iconClasses: 'fa fa-exclamation-triangle'},
  //   {label: 'Boxs', iconClasses: 'fa fa-files-o', children: [
  //       {label: 'Default Box', route: 'boxs/box'},
  //       {label: 'Info Box', route: 'boxs/info-box'},
  //       {label: 'Small Box', route: 'boxs/small-box'}
  //     ]},
  //   {label: 'Dropdown', route: 'dropdown', iconClasses: 'fa fa-arrows-v'},
  //   {label: 'Form', iconClasses: 'fa fa-files-o', children: [
  //       {label: 'Input Text', route: 'form/input-text'}
  //   ]},
  //   { label: 'Tabs', route: 'tabs', iconClasses: 'fa fa-th' },
    
  // ]
};
