import { Component, ElementRef, HostListener, Inject, Input, OnInit, Renderer2, ViewChild, NgZone } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { commonservice } from 'src/app/allServices/commonservice';
import { CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { environment } from 'src/app/sevicesall/alldata';
import { ConfirmLeaveComponent } from '../../MODAL/confirm-leave/confirm-leave.component';
import { ForgotpasswordmodelComponent } from '../../forgotpasswordmodel/forgotpasswordmodel.component';
import { UploadClickComponent } from '../../MODAL/upload-click/upload-click.component';
// import { ForgotpasswordmodelComponent } from 'src/app/CLIENT_PAGES/forgotpasswordmodel/forgotpasswordmodel.component';
// // import { ForgotpasswordmodelComponent } from 'src/app/forgotpasswordmodel/forgotpasswordmodel.component';
// import { environment } from '../../sevicesall/alldata';
import * as CryptoJS from 'crypto-js';
import { state } from '@angular/animations';
import { Observable } from 'rxjs';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { ZipuploadService } from 'src/app/allServices/zipupload.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
// @Directive({
//   selector: '[appScrollColor]'
// })
export class HeaderComponent implements OnInit {
  @ViewChild('menu') menu: ElementRef;
  action: string;
  local_data: any;
  search:any;
  @Input() public signup: any;
  a = environment.menubar;
  image = CONSTNAME.fILE_PATH;
  imageView : any = 'assets/img/artwork.jpg';
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  usernamr: any;
  userid: any;
  currentRoute: string;
  isWhiteBackground: boolean = true; // Change this based on your logic
    isNavbarTransparent = true;
    showMenu : boolean 
    showMenulogin : boolean
    rowsize: any;
    pageno: any;
    totalnotification: any = 0;
    arrayfortable: any;
    lengthofnotificationdiv: any;
    total_records: any;
    morethatzero: boolean;
    profile_image_pathofcomments: any = 'assets/img/artwork.jpg';
  
  // el: any;

  //   @HostListener('window:scroll', ['$event'])
  //   onScroll(event: any): void {
  //     // Check the scroll position, e.g., at 100 pixels from the top
  //     const scrollPosition = window.scrollY;

  //   if (scrollPosition > 150) { // Adjust the scroll position where the color should change
  //     this.renderer.addClass(this.el.nativeElement, 'navbar-colored');
  //     this.renderer.removeClass(this.el.nativeElement, 'navbar-transparent');
  //   } else {
  //     this.renderer.addClass(this.el.nativeElement, 'navbar-transparent');
  //     this.renderer.removeClass(this.el.nativeElement, 'navbar-colored');
  //   }
  // }
  zipUploadInProgress: boolean = false;
  isDropdownOpen: boolean = false;
  hideLoginButton: boolean;
  showCustomClass: boolean;
  shownotification: boolean;
  searchDrop: boolean;
  isDrawerOpen: boolean;
  searchbar: any;
  isMenuOpen: any=false;
  clickListener: any;
  showSearch: boolean =true;
  isCollapsed:true
  adddropdownclass: boolean =true;
  // Your existing code

  // Define a method to toggle the dropdown menu
  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  // @HostListener("window:keydown", ["$event"])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (event.key === " ") {
  //     event.preventDefault();
  //     const searchInput: HTMLInputElement = this.elementRef.nativeElement.querySelector('.form-control');
  //     if (searchInput) {
  //       searchInput.focus();
  //     }
  //   }
  // }
  @HostListener('body:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = event.target as HTMLElement;
    // Check if the target or its parent is the button
    const isButtonOrChild = target.matches('.ps-dropdown, .ps-dropdown img') ||
                            target.closest('.ps-dropdown') !== null ;
    const isButtonOrChild1 = target.matches('.ps-dropdown1, .ps-dropdown1 img') ||
                            target.closest('.ps-dropdown1') !== null;
// //console.log('click',isButtonOrChild1,isButtonOrChild,target.closest('.ps-dropdown-menu'));
let flag = 1
    if (isButtonOrChild) {
      // Run the code inside the Angular zone
      const dropdownMenus = document.querySelectorAll('.ps-dropdown-menu.show');
      dropdownMenus.forEach((menu: Element) => {
        // //console.log(menu);
        flag = 0;   
        menu.classList.remove('show');
      })
      if(flag==1){
      this.zone.run(() => {
        const thisId = target.closest('.ps-dropdown')?.id;
        const dropdownMenu = document.querySelector(`.ps-dropdown-menu[aria-labelledby="${thisId}"]`);
        dropdownMenu?.classList.add('show');
      });
    }

      const dropdownMenus1 = document.querySelectorAll('.ps-dropdown-menu1.show');
      dropdownMenus1.forEach((menu: Element) => {
        // Check if the element is an HTMLElement before accessing classList
        if (menu instanceof HTMLElement) {
          // Check if the click is on a button with class '.play_button.seek'
          const isPlayButtonSeek = target.matches('.play_button.seek') || target.closest('.play_button.seek') !== null;

          if (!isPlayButtonSeek) {
            // Run the code inside the Angular zone
            this.zone.run(() => {
              menu.classList.remove('show');
            });
          }
        }
      });
    }else if(isButtonOrChild1){
      const dropdownMenus1 = document.querySelectorAll('.ps-dropdown-menu1.show');
let flag = 1
dropdownMenus1.forEach((menu: Element) => {
  flag = 0;   

        menu.classList.remove('show');
      })
      if(flag==1){
        
      this.zone.run(() => {
        const thisId = target.closest('.ps-dropdown1')?.id;
        const dropdownMenu = document.querySelector(`.ps-dropdown-menu1[aria-labelledby="${thisId}"]`);
        dropdownMenu?.classList.add('show');
      });
    }
    const dropdownMenus = document.querySelectorAll('.ps-dropdown-menu.show');
      dropdownMenus.forEach((menu: Element) => {
        // Check if the element is an HTMLElement before accessing classList
        if (menu instanceof HTMLElement) {
          // Check if the click is on a button with class '.play_button.seek'
          const isPlayButtonSeek = target.matches('.play_button.seek') || target.closest('.play_button.seek') !== null;

          if (!isPlayButtonSeek) {
            // Run the code inside the Angular zone
            this.zone.run(() => {
              menu.classList.remove('show');
            });
          }
        }
      });
    } else {

      // Handle body click to close dropdown
      const dropdownMenus = document.querySelectorAll('.ps-dropdown-menu.show');
      const dropdownMenus1 = document.querySelectorAll('.ps-dropdown-menu1.show');
      dropdownMenus1.forEach((menu: Element) => {
        // Check if the element is an HTMLElement before accessing classList
        if (menu instanceof HTMLElement) {
          // Check if the click is on a button with class '.play_button.seek'
          const isPlayButtonSeek = target.matches('.play_button.seek') || target.closest('.play_button.seek') !== null;

          if (!isPlayButtonSeek) {
            // Run the code inside the Angular zone
            this.zone.run(() => {
              menu.classList.remove('show');
            });
          }
        }
      });
      dropdownMenus.forEach((menu: Element) => {
        // Check if the element is an HTMLElement before accessing classList
        if (menu instanceof HTMLElement) {
          // Check if the click is on a button with class '.play_button.seek'
          const isPlayButtonSeek = target.matches('.play_button.seek') || target.closest('.play_button.seek') !== null;

          if (!isPlayButtonSeek) {
            // Run the code inside the Angular zone
            this.zone.run(() => {
              menu.classList.remove('show');
            });
          }
        }
      });
    }
  }
  // onDocumentClick1(event: Event): void {
  //   const target = event.target as HTMLElement;

  //   // Check if the target or its parent is the button
  //   const isButtonOrChild = target.matches('.ps-dropdown1, .ps-dropdown1 img') ||
  //                           target.closest('.ps-dropdown1') !== null;
  //   //console.log('cl');
    
  //   if (isButtonOrChild) {
  //     // Run the code inside the Angular zone
  //     this.zone.run(() => {
  //       const thisId = target.closest('.ps-dropdown1')?.id;
  //       const dropdownMenu = document.querySelector(`.ps-dropdown-menu1[aria-labelledby="${thisId}"]`);
  //       dropdownMenu?.classList.add('show');
  //     });
  //   } else {
  //     // Handle body click to close dropdown
  //     const dropdownMenus = document.querySelectorAll('.ps-dropdown-menu1.show');
  //     dropdownMenus.forEach((menu: Element) => {
  //       // Check if the element is an HTMLElement before accessing classList
  //       if (menu instanceof HTMLElement) {
  //         // Check if the click is on a button with class '.play_button.seek'
  //         const isPlayButtonSeek = target.matches('.play_button.seek') || target.closest('.play_button.seek') !== null;

  //         if (!isPlayButtonSeek) {
  //           // Run the code inside the Angular zone
  //           this.zone.run(() => {
  //             menu.classList.remove('show');
  //           });
  //         }
  //       }
  //     });
  //   }
  // }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    const scrollPosition = window.scrollY; // Get the current scroll position

    // Adjust this threshold as needed
    const scrollThreshold = 100; // Adjust the threshold as needed

    // Check if the scroll position has crossed the threshold
    if (scrollPosition > scrollThreshold) {
      // Add a CSS class to your div element
      this.showCustomClass = true
    } else {
      // Remove the CSS class if the scroll position is less than the threshold
      this.showCustomClass = false
    }
  }
  submit(){
    // //console.log(this.search);
    this.searchDrop = true
    let sendingdata={
        key_word : this.search
      }
      if (!this.zipUploadInProgress) {

    this.router.navigate(['advancesearch/'], { queryParams: sendingdata });
  }else{
    this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
  }
  }
  constructor(private router: Router,public api: ApiServiceService, private zipUploadService: ZipuploadService, private toastr: ToastrService,
    public common: commonservice, private matDialog: MatDialog, private route: ActivatedRoute,private el: ElementRef, private renderer: Renderer2, private zone: NgZone,private elementRef: ElementRef) {
      this.zipUploadService.zipUploadInProgress$.subscribe(inProgress => {
        // console.log(inProgress);
        
        this.zipUploadInProgress = inProgress;
      });
    this.common.event$.subscribe(ev => {
      if (this.common.eventResMatch('_Update_profile', ev, false)) {
        // //console.log("according to me", "login click");
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res);
          // //console.log(resa.username);
          if (resa) {
            // //console.log(resa);
            this.usernamr = resa.username
            this.userid = resa.user_type_id
          }
          
          if (resa && resa.profile_image_path) {
            this.imageView =  resa.profile_image_path
          } else {
            this.imageView = 'assets/img/artwork.jpg'
          }
        })
        this.getImage();
      }
      if (this.common.eventResMatch('_Log_out', ev, false)) {
        this.getImage();
        
      }
      
    });
    this.route.url.subscribe((segments: any) => {
      this.currentRoute = segments.join('/');
    });
  this.checkClientToken();
    
  }
  clickedOutside(): void {
    this.isDropdownOpen = false;
    this.toggleMenu()
  }
  toggleMenu(): void {
    // //console.log(this.isMenuOpen);
    
    this.isMenuOpen = !this.isMenuOpen;

    if (this.isMenuOpen) {
      // Add click event listener to the document
      this.clickListener = this.renderer.listen('document', 'click', (event: Event) => this.handleDocumentClick(event));
    } else {
      // Remove click event listener when the menu is closed
      this.removeClickListener();
    }
    setTimeout(() => {
      this.ngOnInit()
    }, 100);
  }

  private removeClickListener(): void {
    if (this.clickListener) {
      this.clickListener(); // Call the function returned by listen to remove the listener
      this.clickListener = undefined; // Reset the clickListener
    }
  }

  // Close the menu when clicking outside the dropdown
  @HostListener('document:click', ['$event'])
  private handleDocumentClick(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isMenuOpen = false;
      this.removeClickListener();
    }
  }

  

  ngOnInit(): void {
    // //console.log("this",this.isMenuOpen);
    // canActivate(
      // this.route: ActivatedRouteSnapshot,
      // state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if(this.search){
          // //console.log("this");
          
        }
    this.callfornotification()
    let b = localStorage.getItem("clientintoken");
        if (!b) {
         
    //       // this.router.navigate(['/login']);
          
          this.showMenu = true;
          this.showMenulogin = false
        } else {
          
          this.showMenulogin = true
          this.showMenu=false;
        }
        
    // }
  

    // //console.log("according to me");
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      let resa = JSON.parse(res);
      // //console.log(resa.username);
      if (resa) {
        // //console.log(resa);
        this.usernamr = resa.username
        this.userid = resa.user_type_id
      }

      if (resa && resa.profile_image_path) {
        this.imageView = resa.profile_image_path
    // //console.log("Imagepath",this.imageView);
      } else {
        this.imageView = 'assets/img/artwork.jpg'
      }
    })
    // //console.log("Imagepath",this.imageView);
    
    // this.getImage();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url;
        if (currentRoute === '/login' || currentRoute === '/register') {
          
          this.hideLoginButton = true;
        } else {
          // //console.log('hi');
          this.hideLoginButton = false;
        }
      }
    });
    // this.imageView = '../../../../assets/img/avatar.png'
    // //console.log(this.a )

    // $(document).ready(function(){
    //   $('.ps-dropdown').on('click', function (event) {
    //     var this_id = $(this).attr("id");
    //     $('.ps-dropdown-menu[aria-labelledby="'+this_id+'"').toggleClass('show');
    //   });
    //   $('body').on('click', function (e) {
    //     if (!$('.ps-dropdown-menu').is(e.target) 
    //         && $('.ps-dropdown-menu').has(e.target).length === 0 
    //         && $('.show').has(e.target).length === 0
    //     ) {
    //         $('.ps-dropdown-menu').removeClass('show');
    //     }
    //   });
    // });
  }
  // onClickMenu(e: any) {
  //   // if (this.isDrawerOpen == "") {
  //     //console.log(this.isDrawerOpen);
      
  //     this.isDrawerOpen =  !this.isDrawerOpen;
    
  //   // if (this.isDrawerOpen === true) {
  //   //   this.isDrawerOpen = false;
  //   // }
  //   //console.log(this.isDrawerOpen? "Drawer open" : "Drawer closed");
  // }
  displaySearch(){
    this.showSearch = !this.showSearch
  }

  async callfornotification() {
    // this.common.showLoader();
    let sendingdata = {
      "sort_on": "created_on",
      "sort_type": "DESC",
      "page_no": this.pageno,
      "row_size": this.rowsize
    }

    this.api.forclientgettokenpublic(`/user/get_notifications`, sendingdata).then((res: any) => {
      if (res.response.status == 1) {
        // this.common.hideLoader();
        this.totalnotification = res.response.data.number_of_unread_notifications
        if (res.response.data.number_of_unread_notifications > 9) {
          this.totalnotification = "9 +"
        }
        if (res.response.data.number_of_unread_notifications == 0) {
          this.totalnotification = ""
        }
        // this.arrayfortable.push(res.response.data.pagging_list) 
        // //console.log(this.arrayfortable)
        this.arrayfortable = res.response.data.pagging_list.slice(0,3)
        // //console.log( res.response.data.pagging_list)
        this.lengthofnotificationdiv = this.arrayfortable.length
        this.total_records = res.response.data.total_records


        // //console.log(this.arrayfortable)
        if (res.response.data.total_records == 0) {
          this.morethatzero = false
        } else {
          this.morethatzero = true
        }

        // if (res.response.data.number_of_unread_notifications > 0) {
        //   setTimeout(() => {
        //   this.callforasreadnoti();
        // }, 3000);
        // } else {
        //   // //console.log("no")
        // }

        
      }
      else {
        // this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        // this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          // this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }
  setimage(data: any) {
    // if (data.artwork_path == null) {
      if (data.notifier_profile_image_path == null) {
        return this.profile_image_pathofcomments;
  
      } else {
        if (data.notifier_profile_image_path.includes("/profile_photos/")) {
          return  CONSTNAME.fILE_PATH + data.notifier_profile_image_path;
  
          }else
        return data.notifier_profile_image_path;
      }
  
    // } else {
  
    //   return CONSTNAME.fILE_PATH + data.artwork_path;
    // }
  }
  remove(){
    const sendingdata = {
      
    }
    this.api.forclientgettokenpublic(`/user/mark_all_notification_as_read`, sendingdata).then((res: any) => {})
    this.callfornotification()
  }
  redirecttoallnotification(){
    if (!this.zipUploadInProgress) {
      this.router.navigate(['/notifications']);
    this.isMenuOpen = !this.isMenuOpen;
    const isButtonOrChild = document.querySelectorAll('.navbar-collapse.show');
    isButtonOrChild.forEach((menu: Element) => {
      // //console.log(menu);
      menu.classList.remove('show');
    })
  }else{
    this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
  }
  }
  redirecttousernoti(id: any){
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate([`${id.notification_text_1.slice(1)}`]);
   
  }
  redirecttouser(id: any){
    this.router.navigate([`${id.notification_text_1.slice(1)}`]);
  }
  isActive(route: string): boolean {
    return this.router.isActive(route, true);
  }
  checkClientToken() {
    let b = localStorage.getItem("clientintoken");
    if (!b) {
      // Token doesn't exist in localStorage
      this.showMenu = true;
      this.showMenulogin = false
      // You can navigate to login page here if needed
      // this.router.navigate(['/login']);
    } else {
      // Token exists in localStorage
      this.showMenulogin = true
      this.showMenu = false;
    }
    
  }

  getImage() {
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      let resa = JSON.parse(res);
      if (resa && resa.profile_image_path) {
        this.imageView =  resa.profile_image_path
      } else {
        this.imageView = 'assets/img/artwork.jpg'
      }
    })
    
  }
  onclick(i: any) {

    if (i == 'rg') this.router.navigate(['/register']);
    else if (i == 'ad') this.router.navigate(['/login']);
    else if (i == 'logout') {
     
      this.getImage();
      this.common.clearAllLocalAndSession();

      this.router.navigate(['/login']);
    }
    else if (i == 'upload') {

      this.openDialog()

    }
    else if (i == 'user') {

      // this.router.navigate(['/profile']);
    }
    else if (i == 'myfeed') {
      this.router.navigate(['/my-feed']);
    } else if (i == 'promo') {
      this.router.navigate(['/promo-page']);
    }
  

    // if (this.router.url !== "/uploadloopkit"  ) {


    // } else {

    //   var r = confirm("Are you sure you want to leave? Your upload will be deleted");
    //   if (r == true) {

    //     if (i == 'rg') this.router.navigate(['/register']);
    //     else if (i == 'ad') this.router.navigate(['/login']);
    //     else if (i == 'logout') {
    //       this.common.clearAllLocalAndSession();

    //       this.router.navigate(['/login']);
    //     }
    //     else if (i == 'upload') {

    //       this.openDialog()

    //     }
    //     else if (i == 'user') {

    //       this.router.navigate(['/profile']);
    //     }
    //   } else {

    //   }

    // }




  }
  redirecttoLoop(id: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    this.router.navigate(["/detail-loop"], { queryParams: { loop_id: this.encrypted } });
  }
  redirecttopack(items:any) {
    
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.id), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    this.encrypted = encrypted.toString();
    this.encrypted =encodeURIComponent(this.encrypted);
    // //console.log(value);
    // //console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(['detail-pack'], { queryParams: {pack_id: this.encrypted } });
  }
  showsearch(){
    this.searchbar = !this.searchbar
  }
  redirecttoexplore() {
    if (!this.zipUploadInProgress) {
      this.router.navigate(['/explore']);
    // Check if the target or its parent is the button
    const isButtonOrChild = document.querySelectorAll('.navbar-collapse.show');
    isButtonOrChild.forEach((menu: Element) => {
      // //console.log(menu);
      menu.classList.remove('show');
    })
  }else{
    this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
  }
}
  redirecttomyfeed() {
    // console.log(this.zipUploadInProgress);
    
    if (!this.zipUploadInProgress) {
    this.router.navigate(['/my-feed']);
   
    // Check if the target or its parent is the button
    const isButtonOrChild = document.querySelectorAll('.navbar-collapse.show');
    isButtonOrChild.forEach((menu: Element) => {
      // //console.log(menu);
      menu.classList.remove('show');
    })
  }else{
    this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
  }
      }
  redirecttoupload(){
    if (!this.zipUploadInProgress) {
      this.router.navigate(['/upload-loop']);
  }else{
    this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
  }
  }
  redirecttonotification() {
    // this.router.navigate(['/notifications']);
    this.shownotification = !this.shownotification
    this.adddropdownclass = true
    // Check if the target or its parent is the button
    const isButtonOrChild = document.querySelectorAll('.navbar-collapse.show');
    isButtonOrChild.forEach((menu: Element) => {
      // //console.log(menu);
      menu.classList.remove('show');
    })
  }
  redirecttologin() {
    this.router.navigate(['/login']);
  }
  redirecttoregister() {
    this.router.navigate(['/register']);
  }
  redirecttologout() {
    if (!this.zipUploadInProgress) {

    // this.router.navigate(['/profile']);
    this.common.clearAllLocalAndSession();
    this.toggleDropdown()
    this.showMenu = false
    this.showMenulogin=true
    // Check if the target or its parent is the button
    const isButtonOrChild = document.querySelectorAll('.navbar-collapse.show');
    isButtonOrChild.forEach((menu: Element) => {
      // //console.log(menu);
      menu.classList.remove('show');
    })
    this.router.navigate(['/login'],{ replaceUrl: true });
  }else{
    this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
  }
  }
  redirecttodownload(){
    if (!this.zipUploadInProgress) {

    this.router.navigate(['/downloadhistory']);
    const isButtonOrChild = document.querySelectorAll('.navbar-collapse.show');
    isButtonOrChild.forEach((menu: Element) => {
      // //console.log(menu);
      menu.classList.remove('show');
    })
  }else{
    this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
  }
  }
  redirecttohome(){
    if (!this.zipUploadInProgress) {

    this.router.navigate(['/']);
  }else{
    this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
  }
  }
  redirecttodashboard() {
    // //console.log("i am in");
    if (!this.zipUploadInProgress) {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.userid), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //console.log("this",this.encrypted);
    
    this.toggleDropdown()
    // //console.log(`/@${this.usernamr}`);
    
    // this.router.navigate([`@${this.usernamr}`]);
    // this.router.navigate([`@:username`]);
    // this.router.navigate([`${this.tableforinfo.username}`])
    // this.router.navigate([`${this.usernamr}`]);
    this.router.navigate([`${this.usernamr}`]);

  }else{
    this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
  }
  }
  redirecttosettings() {
    // //console.log("i am in");
    if (!this.zipUploadInProgress) {

    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(this.userid), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    this.toggleDropdown()

    this.router.navigate(['profilesettings']);
    // Check if the target or its parent is the button
    const isButtonOrChild = document.querySelectorAll('.navbar-collapse.show');
    isButtonOrChild.forEach((menu: Element) => {
      // //console.log(menu);
      menu.classList.remove('show');
    })
  }else{
    this.toastr.error("Upload is in progress", "", { positionClass: 'toast-bottom-right' });
  }
  }
  openDialog(): void {
    let dialogue = this.matDialog.open(UploadClickComponent, { panelClass: 'forgot-password', disableClose: true });
    dialogue.afterClosed().subscribe(result => {

    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }
  tohome() {
    this.toggleDropdown()
    this.router.navigate(['/']);
    // if (this.router.url !== "/uploadloopkit" ) {
    //   this.router.navigate(['/']);
    // } else {
    //   // this.opendialogueforverification();
    //   var r = confirm("Are you sure you want to leave? Your upload will be deleted");
    //   // async let(){
    //   if (r == true) {
    //     this.router.navigate(['/']);
    //     // //console.log("gaya");
    //   } else {
    //     // //console.log("gaya nahi");
    //   }
    //   // }


    // }
  }
 
  setupImage() {
    // this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
    //   let resa = JSON.parse(res);
    //   if(resa && resa.profile_image_path){
    //     return this.image + resa.profile_image_path
    //   } else{
    //     return '../../../../assets/img/avatar.png'
    //   }
    // })
    return '../../../../assets/img/avatar.png'
  }

  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmLeaveComponent, { panelClass: 'forgot-password', disableClose: true, data: { pageValue: this.router.url } });
    dialogue.afterClosed().subscribe(result => {

      // return new Promise(resolve => {
      //   if (num) {

      //     resolve((Math.round(num * 100) / 100).toFixed(2))
      //   }
      // })
      // //console.log('The dialog was closed');
      // //console.log(result.data);
      this.local_data = result.data
    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }




}
function canActivate(route: any, ActivatedRouteSnapshot: any, state: any, RouterStateSnapshot: any) {
  throw new Error('Function not implemented.');
}

