import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { environment } from "./../../../sevicesall/alldata";
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";

import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApiServiceService } from "./../../../allServices/api-service.service";
import { commonservice } from "./../../../allServices/commonservice";
import {
  APIURL,
  CONSTNAME,
  VARS,
} from "./../../../allServices/constant.service";
import * as CryptoJS from "crypto-js";
import * as moment from "moment";
import { ConfirmDeleteComponent } from "./../../../CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component";
import { MatDialog } from "@angular/material/dialog";
import { NgWaveformComponent } from "ng-waveform";
import { WaveformComponent } from "../../waveform/waveform.component";
// import * as WaveSurfer from "wavesurfer.js";
import { Subscription } from 'rxjs';
import { ZipService } from "src/app/allServices/zip.service";
@Component({
  selector: "app-loopsearch",
  templateUrl: "./loopsearch.component.html",
  styleUrls: ["./loopsearch.component.css"],
})
export class LoopsearchComponent implements OnInit {
  @Input() items: any;
  @Input() load: any;
  @Output() messageEvent = new EventEmitter<string>();
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenolist: any;
  tableData: any[] = [];
  pageno: any = 1;
  rowsize: any = 10;
  pagercount: any = [];
  validationforsearch: any = "";
  paginationempty = false;
  noresult = false;
  sortsign: any = "sort";
  searchwordget: any;
  getpageno: any;
  total: any;
  record: any;
  hidepagination = false;
  volumeValue: number = 1;
  norecordfound: any;
  sorttype = "";
  currentwaveform:any;
  sorton = "";
  imageofcomment: any;
  profile_image_pathofcomments = "assets/img/artwork.jpg";
  value: any;
  encryptedagainremoveslashes: string;
  tablefortagsdisplay1: any;
  tablefortagsdisplay2: any;
  tablefortagsdisplay3: any;
  time: string;
  addred: boolean;
  addgreen: boolean;
  durationofaudio: any;
  above15second = false;
  apihitforstream = true;
  above5secondlessthan15 = false;
  above1secondlessthan5 = false;
  id: any;
  nocomments: boolean = false;
  src: any;
  pagenocount: any;
  tags: any = [];
  showAudioPlayer: boolean;
  player: any = 0;
  pageNumber: number;
  urlpass: string;
  valuefromurl: string | null;
  urlstatusmessage: string;
  loopidurl: string | null;
  loopidfromurl: string;
  checktoken: boolean;
  idfromwebsite: any;
  callparentFun: any;
  disable: boolean = false;
  completerender: boolean = false;
  sliderValue: any = 0.0;
  mute: boolean;
  steps: any = 0.0001;
  color:"#444445"
  dom: any = {};
  dimension: any = {};
  rangeWidth: number = 0;
  amount: number = 0;
  range: number = 0;
  textSuperPosition: number = 0;
  disabled: boolean;
  private navigationSubscription: Subscription;
  constructor(
    private zipService: ZipService,
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    public common: commonservice,
    public api: ApiServiceService,
    private matDialog: MatDialog,
    private elementRef: ElementRef
    
  ) {
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Navigation is about to start, handle cleanup logic
        this.handleCleanup();
      } else if (event instanceof NavigationEnd) {
        // Navigation has ended, perform any necessary actions
        // (e.g., trigger the click event)
        this.handleNavigationEnd();
      }
    });
  }
   @ViewChild('slider') slider: any;
   @ViewChild(WaveformComponent) childComponent!: WaveformComponent;
  slideToValue(num: number) {
    const interval = () => {
      const cur = this.slider.nativeElement.value;
      
      if (cur < num) {
        // ////////console.log(`Value is ${cur}. Incrementing!`);
        this.slider.nativeElement.value = parseInt(cur) + 1;
      } else if (cur > num) {
        // ////////console.log(`Value is ${cur}. Decrementing!`);
        this.slider.nativeElement.value = parseInt(cur) - 1;
      } else {
        // ////////console.log(`Arrived at ${num}!`);
        clearInterval(slowly);
      }
    };

    const slowly = setInterval(interval, 10);
  }
  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    //////console.warn("hii", event, this.player);

    if (event.key === "ArrowUp") {
      event.preventDefault();
      if (this.player >= 0) {
        this.previous();
      }
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      // if(this.player<=this.tableData.length){
      this.next(this.player);
      // }else{
      //   this.player = 0
      //   this.next()
      }
    else if (event.key === " ") {
      const searchInput: HTMLInputElement = this.elementRef.nativeElement.querySelector('.form-control');
      const isFocused = (searchInput === document.activeElement);
            if(!isFocused){
              event.preventDefault();
              if (this.tableData[this.player]?.hidepausebutton) {
                this.stopAudio(this.tableData[this.player], this.player);
              } else {
                this.playAudio(this.tableData[this.player], this.player, event);
                // }
              }
          }
    }

  }
  private handleCleanup() {
    // Perform cleanup logic before navigation
    // (e.g., pausing audio or video playback)
    const element = this.elementRef.nativeElement.querySelector("#player-row-" +  this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
   
    // ////////console.log('Performing cleanup before navigation');
  }

  private handleNavigationEnd() {
    // Navigation has ended, perform necessary actions
    // (e.g., trigger the click event)
    // ////////console.log('Navigation has ended, triggering click event');

    const element = this.elementRef.nativeElement.querySelector("#player-row-" +  this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
  }

  fontSize(item:any){
    if (item.title.length <= 50 && item.title.length >= 30) {
      return 'long'
    } else if (item.title.length <= 30 && item.title.length >= 10) {
      return 'medium'
    } else {
      return 'small'
    }
  }

  ngOnInit(): void {
    // ////////console.log('hi',this.items.response.data.pagging_list);
   
    
   
    // this.items.response.data.pagging_list.map((item: any,index:any)=>{
      
    // })
    this.urlpass = APIURL.BASE_URLforredirect + this.router.url;
    this.value = this.route.snapshot.queryParamMap.get("user_id");
    this.encryptedagainremoveslashes = decodeURIComponent(this.value);
    this.decryptUsingAES256();
    // ////////////console.log(this.items);
    this.run(this.items);
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.checktoken = false;
        // this.getUserDetail();
      } else {
        // ////////////console.log("in")
        this.checktoken = true;
        let resa = JSON.parse(res);
        this.idfromwebsite = resa.user_type_id;
        // //////////console.log(this.idfromwebsite)
      }
  
    });
// //////console.log(this.tableData);

    // this.wavesurfer = WaveSurfer.create({
    //   container: this.waveformElement.nativeElement,
    //   waveColor: 'violet',
    //   progressColor: 'purple'
    // });
    // this.wavesurfer.load(file);
    
  }
  onSliderValueChanged(value: number) {
    this.sliderValue = value; // Update sliderValue in the parent component
   
  }
  
 

  getColor() {
    // Adjust these values based on your desired color range
    const threshold = 0;

    // Set the color based on the threshold
    return this.sliderValue > threshold ? 'black' : '#ccc';
  }
  getColoredWidth() {
    const percentageComplete = (this.tableData[this.player].audio.currentTime  / this.tableData[this.player].duration || this.tableData[this.player].loop_duration || this.tableData[this.player].tagged_loop_duration) *  100;
    //////////console.log('percentageComplete',percentageComplete);
    
    return `${percentageComplete}%`;
  }
  // apihitforpaymentapi() {
  //   throw new Error("Method not implemented.");
  // }
 timeupdate(){
  const du =  this.tableData[this.player].duration || this.tableData[this.player].loop_duration || this.tableData[this.player].tagged_loop_duration
  return du * 1000
 }
  onclick(item: any) {
    // //////////console.log("great")
    this.callparentFun.emit(item);
  }
  // parentFun() {

  // }

  ngOnDestroy() {
    const element = this.elementRef.nativeElement.querySelector("#player-row-" +  this.player + " .play_button.pause");
    if (element) {
      element.click();
    }
    //////console.log('lll');
   
  }

  run(res: any) {
    this.tableData.map((data,ind)=>{
      data.hide = false
      
    
    })
    if (res.response.data.total_records == 0) {
      this.norecordfound = true;
    } else {
      this.norecordfound = false;
      // this.callforallinfo(res);
    }

    if (res.response.data.total_pages == 1) {
      this.hidepagination = true;
      // //////////console.log("hide");
    } else {
      this.hidepagination = false;
      // //////////console.log("not hide");
    }
    if (this.paginationempty == true) {
      this.pagercount = [];
    }
    this.total = res.response.data.total_records;

    // this.tableData = res.response.data.pagging_list;
    for (let i = 0; i < res.response.data.pagging_list.length; i++) {
      let temp_item = res.response.data.pagging_list[i];
      let a = temp_item.tags;
      if(a){
      let strtag: any = a.split("\n ");
      temp_item["tag1"] = strtag[0];
      temp_item["tag2"] = strtag[1];
      temp_item["tag3"] = strtag[2];
}
      this.common.Encryptedfunction(temp_item.user_id).then((data) => {
        if (data) {
          temp_item["enc_link"] = data;
        }
      });
      this.common.Encryptedfunction(temp_item.loop_id).then((data2) => {
        if (data2) {
          temp_item["loop_idencrypt"] = data2;
          // this.arrayforllopkit.push(temp_item);
        }
      });
      this.tableData.push(temp_item);
    }

    this.record = res.response.data.total_records;
    ////////console.log(this.record);
    
    if (this.record == 0) {
      this.nocomments = false;
    } else {
      this.nocomments = true;
    }
    this.pagenolist = res.response.data.total_pages + 1;

    this.record = this.pageno - 1 + "1";
    // //////////console.log(10*(this.pageno - 1) + res.response.data.pagging_list.length);
    this.pagenocount =
      10 * (this.pageno - 1) + res.response.data.pagging_list.length;
    
    // this.record = this.tableData.length;
    this.pagenolist = res.response.data.total_pages + 1;
    this.tableData = res.response.data.pagging_list
    if (this.record == "01") {
      this.record = 1;
    }
    for (let i = 1; i < this.pagenolist; i++) {
      this.pagercount.push(i);
    }
    this.total = res.response.data.total_records;
    this.tableData.map((data,ind)=>{
      const tar = this.elementRef.nativeElement.querySelector("#player-row-" +  ind + " .wave");
      //////console.log(tar);
      if(tar)
      data.hide = true
      
    })
  }

  srcforsound(items: any) {
    if (items.loop_type == 1) {
      if (items.tagged_loop_path !== null) {
        this.durationofaudio = items.tagged_loop_duration;
        return CONSTNAME.fILE_PATH + items.tagged_loop_path;
      } else {
        this.durationofaudio = items.loop_duration;
        return CONSTNAME.fILE_PATH + items.loop_path;
      }
    } else {
      this.durationofaudio = items.tagged_loop_duration;
      return CONSTNAME.fILE_PATH + items.tagged_loop_path;
    }
  }

  redirecttoloopdetailpage(items: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(items.loop_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //////////console.log(value);
    // //////////console.log(this.encrypted);
    // this.router.navigate(['/detail-loop'], { queryParams: { loop_id: value } });

    this.router.navigate(["detail-loop"], {
      queryParams: { loop_id: this.encrypted },
    });
  }

  detailpageofloop(value: any) {
    this.encryptUsingAES256(value);
  }

  paginationget() {
    // //////////console.log(item)
    // //////////console.log("red")
    this.messageEvent.emit("item");
  }

  // routerLink=""
  toreportedloops() {
    this.router.navigate(["/admin/loops/reported-loops"]);
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate(["detail-loop"], {
      queryParams: { loop_id: this.encrypted },
    });
  }

  // [routerLink]="['/admin/loops/detail-uploads/'+item.loop_id]"

  addcolor(item: any) {
    if (item == this.pageno) {
      return "activated";
    } else {
      return "";
    }
  }

  setimage(data: any) {
    if (data.artwork_path == null) {
      if (data.profile_image_path == null) {
       
        return this.profile_image_pathofcomments;
      } else {
        if (data.profile_image_path.includes("/profile_photos/")) {
          return  CONSTNAME.fILE_PATH +  data.profile_image_path;
  
          }else
        return  data.profile_image_path;
      }
    } else {
      return CONSTNAME.fILE_PATH + data.artwork_path;
    }
  }

  async heartcolorred(value: any, detail: any, item: any) {
    let sendingData = {
      loop_id: value,
    };
    // if (item.is_liked == 1) {
    //   item.is_liked = 0;
    // } else if (item.is_liked == 0) {
    //   item.is_liked = 1;
    // }

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      this.common.showLoader();
      this.api.postApiHit(`/user/like_dislike_upload`, sendingData).then(
        (res: any) => {
          if (res.response.status == 1) {
            // let v: any = document.getElementById("red");
            // v.classList.add("red");
            // this.getapicallforcouponcode();
            this.common.hideLoader();

            // if (item.user_id !== t) {

            // }
            if (item.is_liked == 1) {
              item.is_liked = 0;

              return (item.number_of_likes = item.number_of_likes - 1);
            } else if (item.is_liked == 0) {
              item.is_liked = 1;

              return (item.number_of_likes = item.number_of_likes + 1);
            }

            this.toastr.success(res.response.msg, "", {
              positionClass: "toast-bottom-right",
            });
            // this.getallinfoofcomments();
          } else if (res.response.status == 2) {
            let errorthroughapi = res.response.errors;
            let error = [];

            this.common.hideLoader();
            for (var i = 0; i < errorthroughapi.length; i++) {
              this.toastr.error(res.response.errors[i], "", {
                positionClass: "toast-bottom-right",
              });
            }
          } else {
            let errorthroughapi = res.response.errors;
            let error = [];

            this.common.hideLoader();
            for (var i = 0; i < errorthroughapi.length; i++) {
              this.toastr.error(res.response.errors[i], "", {
                positionClass: "toast-bottom-right",
              });
            }
          }
        },
        async (e: any) => {
          this.common.hideLoader();
          this.toastr.error("Error in Api", "", {
            positionClass: "toast-bottom-right",
          });
        }
      );
    } else {
      this.opendialogueforverification();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }
  }

  decryptUsingAES256() {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    this.decrypted = CryptoJS.AES.decrypt(
      this.encryptedagainremoveslashes,
      _key,
      {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString(CryptoJS.enc.Utf8);
    // //////////console.log(this.decrypted);
    // this.decrypted = this.decrypted.replace(/[""]/gi, '');
    // //////////console.log(this.decrypted);
  }

  opendialogueforverification(): void {
    let dialogue = this.matDialog.open(ConfirmDeleteComponent, {
      panelClass: "forgot-password",
      disableClose: true,
      data: { pageValue: "detailpage" },
    });
    dialogue.afterClosed().subscribe((result: { data: boolean; }) => {
      if (result.data == true) {
        this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
        this.toastr.success(
          "You must make an account to interact with uploaded media",
          "",
          { positionClass: "toast-bottom-right" }
        );
        // this.router.navigate(['/users/uploadloopstatus']);
      } else {
      }
    });
    dialogue.afterOpened().subscribe(() => {
      document.documentElement.classList.remove('cdk-global-scrollblock');
      document.documentElement.style.top = '0px';
    });
  }

  addredcolor(item: any) {
    // ////////////console.log("in");
    if (item.is_liked == 1) {
      return "red";
    }
  }
  addgreencolor(item: any) {
    if (item.is_reposted == 1) {
      return "green";
    }
  }

  repostnumber(item: any) {
    return item.number_of_reposts;
  }
  likesnumber(item: any) {
    return item.number_of_likes;
  }

  async repost(value: any, item: any) {
    let sendingData = {
      loop_id: value,
    };

    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      this.common.showLoader();
      this.api.postApiHit(`/user/repost_upload`, sendingData).then(
        (res: any) => {
          if (res.response.status == 1) {
            // this.getapicallforcouponcode();

            this.common.hideLoader();

            this.toastr.success(res.response.msg, "", {
              positionClass: "toast-bottom-right",
            });
            // this.getallinfoofcomments();
            if (item.is_reposted == 1) {
              item.is_reposted = 0;

              return (item.number_of_reposts = item.number_of_reposts - 1);
            } else if (item.is_reposted == 0) {
              item.is_reposted = 1;

              return (item.number_of_reposts = item.number_of_reposts + 1);
            }
          } else if (res.response.status == 2) {
            let errorthroughapi = res.response.errors;
            let error = [];
            // this.cancel();
            this.common.hideLoader();

            for (var i = 0; i < errorthroughapi.length; i++) {
              this.toastr.error(res.response.errors[i], "", {
                positionClass: "toast-bottom-right",
              });
            }
            this.router.navigate(["/users/uploadloopstatus"]);
          } else {
            let errorthroughapi = res.response.errors;
            let error = [];
            // this.cancel();
            this.common.hideLoader();
            for (var i = 0; i < errorthroughapi.length; i++) {
              this.toastr.error(res.response.errors[i], "", {
                positionClass: "toast-bottom-right",
              });
            }
          }
        },
        async (e: any) => {
          this.common.hideLoader();
          this.toastr.error("Error in Api", "", {
            positionClass: "toast-bottom-right",
          });
        }
      );
    } else {
      this.opendialogueforverification();
    }
  }

  async redirecttolooppagecomment(value: any) {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    if (tokkenget) {
      this.redirecttolooppage(value);
    } else {
      this.opendialogueforverification();
    }
  }

  redirecttolooppage(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate(["detail-loop"], {
      queryParams: { loop_id: this.encrypted },
    });
  }

  async apihitforstreaming(item: any, r: any, dura: any) {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      headers = {
        Authorization: `Bearer ` + tokkenget,
        "My-Custom-Header": "foobar",
      };
    }

    let sendingdata = {
      audio_duration: dura,
      loop_id: item.loop_id,
      play_duration: r,
    };
    this.http
      .post(APIURL.BASE_URL + `/user/stream_upload`, sendingdata, { headers })
      .subscribe(
        async (res: any) => {
          if (res.response.status == 1) {
            // this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          } else {
            let errorthroughapi = res.response.errors;
            let error = [];
            // this.cancel();
            this.common.hideLoader();
            for (var i = 0; i < errorthroughapi.length; i++) {
              this.toastr.error(res.response.errors[i], "", {
                positionClass: "toast-bottom-right",
              });
            }
          }
        },
        async () => {
          this.common.hideLoader();
          this.toastr.error("Error in Api", "", {
            positionClass: "toast-bottom-right",
          });
        }
      );
  }

  /* Start of Player Functions */
  next(i:any) {
    if (this.tableData.length - 1 == i) {
      this.disable = false;
      this.tableData[i].hidepausebutton = false;
      this.tableData[i].audio.currentTime = 0;
      this.sliderValue = 0;
      //  this.player = this.player + 1
      // this.playAudio(this.tableData[0], 0, event);
    } else {
      this.disable = false;
      this.tableData[i].audio.currentTime = 0;
      this.sliderValue = 0;
      this.stopAudio(this.tableData[i], i);
      i = i + 1;
      var seek_btn = document.querySelectorAll(
        "#player-row-" + i + " .play_button.vol"
      )[0];
      seek_btn.setAttribute("data-seek-vol", String(this.volumeValue));
      (seek_btn as HTMLElement).click();
      setTimeout(() => {
      this.playAudio(this.tableData[i], i, event);
        
      }, 1000);
      if(this.mute===true){
        var seek_btn = document.querySelectorAll(
          "#player-row-" + i + " .play_button.mute"
        )[0];
        // seek_btn.setAttribute("data-seek-time", String());
        (seek_btn as HTMLElement).click();
      }
    }
  }
  previous() {
    ////////////console.log(this.tableData.length - 1, this.player);

    if (this.player == 0) {
      this.disable = true;
    } else {
      this.disable = false;
      this.sliderValue = 0;
      this.tableData[this.player].audio.currentTime = 0;
      this.stopAudio(this.tableData[this.player], this.player);
      this.player = this.player - 1;
      this.playAudio(this.tableData[this.player], this.player, event);
    }
  }
  onInput(event: any): void {
    // ////////console.log('event',event.target.value);
//     // this.stopAudio(this.tableData[this.player],this.player)
    var seek_time = parseFloat(event.target.value).toFixed(2);
//     this.tableData[this.player].audio.currentTime = seek_time;
//     ////////console.log("sli",this.tableData[this.player].audio.currentTime);
//     // this.playAudio(this.tableData[this.player],this.player,event)
    
this.sliderValue = seek_time
// ////////console.log("sli",this.sliderValue);

    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.seek"
    )[0];
    seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }


  onVolumeInput(event: any): void {
    // //////////console.log(event.target.value);
    var seek_time = parseFloat(event.target.value).toFixed(2);
    this.tableData[this.player].audio.volume = parseFloat(event.target.value);
    this.volumeValue =  this.tableData[this.player].audio.volume
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.vol"
    )[0];
    seek_btn.setAttribute("data-seek-vol", String(seek_time));
    (seek_btn as HTMLElement).click();
    if(this.volumeValue>0){
      this.Unmute()
    }else if(this.volumeValue==0){
      this.Mute()
    }
  }
  Mute() {
    this.volumeValue = 0
    this.mute = true;
    this.tableData[this.player].audio.muted = true;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.mute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }
  Unmute() {
    this.volumeValue = this.tableData[this.player].audio.volume
    this.mute = false;
    this.tableData[this.player].audio.muted = false;
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.unmute"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
  }


  forward() {
    // this.steps = this.steps + 0.10;
    this.tableData[this.player].audio.currentTime += 2;
    this.sliderValue =  this.tableData[this.player].audio.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.forward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String());
    (seek_btn as HTMLElement).click();
    ////////////console.log(this.steps);
  }
  backward() {
    // this.steps = this.steps - 0.01;
    this.tableData[this.player].audio.currentTime -= 2;
    this.sliderValue =  this.tableData[this.player].audio.currentTime
    var seek_btn = document.querySelectorAll(
      "#player-row-" + this.player + " .play_button.backward"
    )[0];
    // seek_btn.setAttribute("data-seek-time", String(seek_time));
    (seek_btn as HTMLElement).click();
  }

  playAudio(item: any, index: any, event: any) {
    // ////////console.log(item,index);
    const divsWithClass = document.querySelectorAll('div.list');

    divsWithClass.forEach(div=>{
      div.classList.add('ply');
    })
    // this.common.showLoader();
   
    this.showAudioPlayer = true;
  
    // list.forEach((menu: Element) => {
    //   // //////////////console.log(menu);
    //   menu.classList.remove('ply');
    // })

    // this.waveform = item
    this.player = index;
    var play_time: any =0.00;
    // document.querySelectorAll("#player-row-"+index+ " .play_button.play")[0].click();
   

    this.tableData.map((r: any) => {
      r.hidepausebutton = false;
    });
    this.tableData.map((r: any,i:any) => {
      if (r.audio) {
        ////////////console.log('hii');
        
        r.audio.pause();
        const element = document.querySelectorAll(
          "#player-row-" + i + " .play_button.pause"
        )[0];
    
        if (element && "click" in element) {
          (element as HTMLElement).click();
        }
      }
    });
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.play"
    )[0];

    // ////////console.log(element);
    
    play_time = document
      .querySelectorAll("#player-row-" + index + " .play_button.seek")[0]
      ?.getAttribute("data-seek-time");
    ////////////console.log(play_time);

    play_time = parseFloat(play_time).toFixed(2);
    // this.tableData[index].audio.addEventListener('timeupdate', () => {
    //   this.sliderValue = this.tableData[index].audio.currentTime;
    // });

    if (!item.audio) {
      if (item.loop_type == 1) {
        if (item.tagged_loop_path !== null) {
          item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
          this.durationofaudio = item.tagged_loop_duration;
        } else {
          item.file = CONSTNAME.fILE_PATH + item.loop_path;
          this.durationofaudio = item.loop_duration;
        }
      } else {
        item.file = CONSTNAME.fILE_PATH + item.tagged_loop_path;
        this.durationofaudio = item.tagged_loop_duration;
      }
      // if (!item.duration) {
      //   let sound: any;
      //   sound = await new Promise((resolve, reject) => {
      //     new p5.SoundFile(item.file, (s: any) => resolve(s), (err: any) => reject(err));
      //   });
      // }
      item.duration = this.durationofaudio;
      item.audio = new Audio(item.file);
      // this.childComponent.onPlayButtonClick(event)
      // ////////////console.log(this.waveform);
      // item.waveform = new NgWaveformComponent(item.file);
      // this.waveform = item.waveform
      // ////////////console.log(this.waveform);
      // this.waveform.play();
      // var target = event.target || event.srcElement || event.currentTarget;
      // var currently_playing_btn:any = document.querySelectorAll(".is-playing");
      // if (currently_playing_btn.length > 0) {
      //   if (target != currently_playing_btn[0]) {
      //     currently_playing_btn[0].nextSibling.click();
      //   }
      // }
      // item.audio.currentTime = play_time;
    
      // item.audio.addEventListener('loadedmetadata', () => {
        if(!this.mute){
          item.audio.muted = false
            }
      item.audio.volume = this.volumeValue
      
    //  const i =  item.audio.play();
    //  ////////console.log(i);
     
      // if(!item.audio.paused){
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
    // }
      // });
      // item.audio.play();

      this.common.hideLoader();
      // item.audio.playbackRate = 1.3
      // ////////console.log(item.audio.currentTime);
    //   item.audio.addEventListener("timeupdate", (test:any, test2:any) => {
    //     // //////////console.log("fff",test, test2);
    // ////////console.log("timeupdate",item.audio.currentTime);
        
    //   this.sliderValue = item.audio.currentTime;
    //     // this?.currentwaveform?.setRegionStart(this.sliderValue);
    //   //////////////console.log(item.audio.currentTime);
    //   //////////////console.log(this.sliderValue);
    //     var seek_btn = document.querySelectorAll(
    //       "#player-row-" + index + " .play_button.seek"
    //     )[0];
    //     // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
    //     // (seek_btn as HTMLElement).click();
    //     // this.currentwaveform.setRegionStart(this.sliderValue);
    //   });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        this.next(index);
      });
      if (item.duration >= 15) {
        this.above15second = true;
        this.apihitforstream = true;
      } else if (item.duration < 15 && item.duration >= 5) {
        this.apihitforstream = true;
        this.above5secondlessthan15 = true;
      } else if (item.duration > 1 && item.duration <= 5) {
        this.apihitforstream = true;
        this.above1secondlessthan5 = true;
      }
    } else {
      if(!this.mute){
        item.audio.muted = false
          }
      item.audio.volume = this.volumeValue
      // item.audio.play();
      // item.audio.currentTime = play_time;
      // ////////console.log("currentTime",item.audio.currentTime);
      // item.audio.addEventListener("timeupdate", () => {
      //   this.sliderValue = item.audio.currentTime;
      //   // var seek_btn = document.querySelectorAll(
      //   //   "#player-row-" + index + " .play_button.seek"
      //   // )[0];
      //   // seek_btn.setAttribute("data-seek-time", String(item.audio.currentTime));
      //   // (seek_btn as HTMLElement).click();
      //   ////////////console.log( this.currentwaveform);
      //   // this?.currentwaveform?.setRegionStart(item.audio.currentTime);
        
      //   // this.currentwaveform.setRegionStart(this.sliderValue);
      // });
      item.audio.addEventListener("ended", () => {
        // item.audio.currentTime = 0.00
        // this?.currentwaveform?.setRegionStart(item.duration);
        this.next(index);
      });
      if (element && "click" in element) {
        (element as HTMLElement).click();
      }
      this.common.hideLoader();
    }

    item.hidepausebutton = true;
    let r: any;
    this.id = setInterval(() => {
      r = item.audio.currentTime;
      if (r === item.duration || r > item.duration) {
        setTimeout(() => {}, 1000);
      }

      let dura: any = item.duration;
      if (item.duration >= 15) {
        if (r > 15) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration < 15 && item.duration >= 5) {
        if (r > 5) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      } else if (item.duration > 1 && item.duration <= 5) {
        if (r >= 1) {
          if (this.apihitforstream == true) {
            this.apihitforstreaming(item, r, dura);
            clearInterval(this.id);
            this.apihitforstream = false;
          }
        }
      }
    }, 200);
  }
  stopAudio(item: any, index: any) {
    // ////////console.log(item);
    if (item) {
      if (item.audio) {
        item.audio.pause();
        // this.showAudioPlayer = false;s
      }
      // clearInterval(this.id);

      this.tableData.map((r: any) => {
        r.hidepausebutton = false;
      });
    } else {
      this.tableData.map((r: any) => {
        if (r.audio) {
          r.audio.pause();
        }
        r.hidepausebutton = false;
      });
    }
    // ////////console.log(this.childComponent);
    // item.waveform.pause();
    this.childComponent.onPauseButtonClick(event)

    // document.querySelectorAll("#player-row-"+index+ " .play_button.pause")[0].click();
    const element = document.querySelectorAll(
      "#player-row-" + index + " .play_button.pause"
    )[0];
    // ////////console.log(element);

    if (element && "click" in element) {
      (element as HTMLElement).click();
    }
  }
  /* End of Player Functions */

 
  timeget(items: any) {
    // //////////////console.log("great ", items)
    let date_future: any = new Date();
    let date_now: any = new Date(items.modified_on);

    let time = date_future - date_now;

    let seconds = moment.duration(time).seconds();
    let minutes = moment.duration(time).minutes();
    let hours = moment.duration(time).hours();
    let days = moment.duration(time).days();

    // //////////////console.log(time,seconds, minutes , hours , days , date_future , date_now)

    if (seconds < 60 && minutes === 0) {
      // //////////////console.log(seconds)
      return "Posted " + seconds + " sec" + " ago";
    } else if (minutes < 60 && hours === 0) {
      return "Posted " + minutes + " min" + " ago";
    } else if (hours == 1 && days === 0) {
      return "Posted " + hours + " hr" + "ago";
    } else if (hours <= 24 && days === 0) {
      return "Posted " + hours + " hrs" + " ago";
    } else if (days === 1) {
      return "Posted " + days + " day" + " ago";
    } else if (days > 1) {
      // //////////////console.log(days)
      return "Posted " + days + " days" + " ago";
    }
  }

  redirecttouser(id: any) {
    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let encrypted = CryptoJS.AES.encrypt(JSON.stringify(id), _key, {
    //   keySize: 16,
    //   iv: _iv,
    //   mode: CryptoJS.mode.ECB,
    //   padding: CryptoJS.pad.Pkcs7,
    // });
    // this.encrypted = encrypted.toString();
    // this.encrypted = encodeURIComponent(this.encrypted);

    this.router.navigate([`user/sounds/@${id}`])

  }

  priceget(item: any) {
    if (item.loop_price == 0) {
    } else {
      return (Math.round(item.loop_price * 100) / 100).toFixed(2);
    }
  }
  onPaused(v: any = null) {
    // //////////////console.log("pause");
  }
  sendingstrtag1: any;
  sendingstrtag2: any;
  sendingstrtag3: any;

  tagsget(items: any) {
    let a = items.tags;
    let strtag: any = a.split("\n ");
    let strtag1: any = strtag[0];
    let strtag2: any = strtag[1];
    let strtag3: any = strtag[2];
    // this.sendingstrtag1 = strtag[0];
    // this.sendingstrtag2 = strtag[1];
    // this.sendingstrtag3 = strtag[2];
    // //////////////console.log(strtag1);
    // //////////////console.log(strtag2);
    // //////////////console.log(strtag3);
    return "#" + strtag1;
  }
  tagsget2(items: any) {
    let a = items.tags;
    let strtag: any = a.split("\n ");

    let strtag2: any = strtag[1];
    return "#" + strtag2;
  }
  tagsget3(items: any) {
    let a = items.tags;
    let strtag: any = a.split("\n ");
    //  //////////////console.log(strtag[2])
    let strtag3: any = strtag[2];
    // //////////////console.log(strtag3);
    return "#" + strtag3;
  }

  redirecttoprofilepage(item: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(item.user_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // //////////////console.log(['user/'], { queryParams: { user_id: item.user_id } });
    this.router.navigate(["user/"], {
      queryParams: { user_id: this.encrypted },
    });
  }

  redirectto1(items: any) {
    let a = items.tags;
    let strtag: any = a.split("\n ");
    this.sendingstrtag1 = strtag[0];
    // //////////////console.log(item)
    this.router.navigate(["search-by-tag/"], {
      queryParams: { search_by: this.sendingstrtag1 },
    });
    //////////////console.log(this.sendingstrtag1);
  }
  redirectto2(items: any) {
    let a = items.tags;
    let strtag: any = a.split("\n ");
    this.sendingstrtag2 = strtag[1];
    // //////////////console.log(item)
    this.router.navigate(["search-by-tag/"], {
      queryParams: { search_by: this.sendingstrtag2 },
    });
    // //////////////console.log(item)
    ////////////console.log(this.sendingstrtag2);
  }
  redirectto3(items: any) {
    let a = items.tags;
    let strtag: any = a.split("\n ");
    this.sendingstrtag3 = strtag[2];
    // ////////////console.log(item)
    this.router.navigate(["search-by-tag/"], {
      queryParams: { search_by: this.sendingstrtag3 },
    });
    // ////////////console.log(item)
    ////////////console.log(this.sendingstrtag3);
  }
  forall(items: any) {
    ////////////console.log("Aa", items);
  }

  redirectto(item: any) {
    let result = item.trimStart();

    this.router.navigate(["advancesearch/"], {
      queryParams: { key_word: result },
    });
    setTimeout(() => {
    location.reload()
      
    }, 1000);
    ////////////console.log(item);
  }
  getFileExtension(filename: string): string {
    // Extract the extension by splitting the filename at the last dot
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1].toLowerCase();
    } else {
      // If there's no dot in the filename, consider it as having no extension
      return '';
    }
  }
  
  downloadFile(filePath: any) {
    // Create a hidden anchor element
    this.disabled = true
    const a = document.createElement("a");
    ////////////console.log("jid");
    const fileExtension = this.getFileExtension(CONSTNAME.fILE_PATH + filePath.loop_path ||
      CONSTNAME.fILE_PATH + filePath.tagged_loop_path);
    document.body.appendChild(a);
    a.style.display = "none";
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      //console.log("res,",res);
      if(res===null){
        fetch(
          CONSTNAME.fILE_PATH + filePath.loop_path ||
            CONSTNAME.fILE_PATH + filePath.tagged_loop_path
        )
          .then((response) => response.blob())
          .then((blob) => {
            // Create a blob URL for the file
            const blobUrl = window.URL.createObjectURL(blob);
            
            // Set the anchor's href and download attributes
            a.href = blobUrl;

            a.download =
              filePath.title +
              "_" +
              filePath.bpm +
              "_" +
              filePath.sub_key +
              "_@" +
              filePath.username +
              `.${fileExtension}`; // Set the desired filename
            this.common.hideLoader();

            // Simulate a click on the anchor to trigger the download
            a.click();
            this.disabled = false

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(blobUrl);
  })
          .catch((response) => {
            this.toastr.error(response.response.errors, "", {
              positionClass: "toast-bottom-right",
            });
            this.disabled = false
          })
      }
      let resa = JSON.parse(res);
      this.idfromwebsite = resa.user_type_id;
      if (filePath.user_id !== this.idfromwebsite) {
        this.api
          .postApiHit(`/user/save_free_loop`, {
            loop_id: filePath.loop_id,
            user_type_id: this.idfromwebsite,
          })
          .then((res: any) => {
            if (res.response.status) {
              fetch(
                CONSTNAME.fILE_PATH + filePath.loop_path ||
                  CONSTNAME.fILE_PATH + filePath.tagged_loop_path
              )
                .then((response) => response.blob())
                .then((blob) => {
                  // Create a blob URL for the file
                  const blobUrl = window.URL.createObjectURL(blob);
                  
                  // Set the anchor's href and download attributes
                  a.href = blobUrl;

                  a.download =
                    filePath.title +
                    "_" +
                    filePath.bpm +
                    "_" +
                    filePath.sub_key +
                    "_@" +
                    filePath.username +
                    `.${fileExtension}`; // Set the desired filename
                  this.common.hideLoader();

                  // Simulate a click on the anchor to trigger the download
                  a.click();

                  // Clean up by revoking the blob URL
                  window.URL.revokeObjectURL(blobUrl);
                })
                .catch((response) => {
                  this.toastr.error(response.response.errors, "", {
                    positionClass: "toast-bottom-right",
                  });
                });

              // Remove the anchor element from the DOM
              document.body.removeChild(a);
            } else {
              this.toastr.error(res.response.errors, "", {
                positionClass: "toast-bottom-right",
              });
            }
          });
      } else {
        fetch(
          CONSTNAME.fILE_PATH + filePath.loop_path ||
            CONSTNAME.fILE_PATH + filePath.tagged_loop_path
        )
          .then((response) => response.blob())
          .then((blob) => {
            // Create a blob URL for the file
            const blobUrl = window.URL.createObjectURL(blob);

            // Set the anchor's href and download attributes
            a.href = blobUrl;

            a.download =
              filePath.title +
              "_" +
              filePath.bpm +
              "_" +
              filePath.sub_key +
              "_@" +
              filePath.username +
              `.${fileExtension}`; // Set the desired filename
            this.common.hideLoader();

            // Simulate a click on the anchor to trigger the download
            a.click();

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(blobUrl);
          })
          .catch((response) => {
            this.toastr.error(response.response.errors, "", {
              positionClass: "toast-bottom-right",
            });
          });

        // Remove the anchor element from the DOM
        document.body.removeChild(a);
    this.disabled = false
  }
    });
    if(filePath.stems){
      this.zipService.downloadFile(CONSTNAME.fILE_PATH  + filePath.stems).subscribe((data) => {
        const blob = new Blob([data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filePath.title}.zip`;
        link.click();
    })
    // Create a blob for the file data
  }
}
}
