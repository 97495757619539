import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, VARS } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-verifyotp',
  templateUrl: './verifyotp.component.html',
  styleUrls: ['./verifyotp.component.css']
})
export class VerifyotpComponent implements OnInit {
email:any
  resa: any;
  constructor(public common: commonservice, public router: Router,public api: ApiServiceService, private toastr: ToastrService,private http: HttpClient) { }

  ngOnInit(): void {
    
this.verifyagain()
  }
  verifyagain(){
    this.common.showLoader()
    this.common.getDataInCookie(VARS.AUTH_WITH).then((res: any) => {
      this.resa = JSON.parse(res)
      let visibleDigits
      if(this.resa.phone){
       visibleDigits = this.resa?.phone.slice(-3);
      }
      const visibleDigits2 = this.resa?.email.slice(0,6)
       const visibleDigits1 = this.resa?.email.slice(-3);
    const maskedPart = 'XXXXXXX';
    
      if(this.resa.auth==1){
          this.email=visibleDigits2+maskedPart+visibleDigits1
      }else if(this.resa.auth==2){
          this.email=maskedPart+visibleDigits
      }
      let headers: any = {
        "My-Custom-Header": "foobar",
      };
      this.http
        .post(`${APIURL.BASE_URL}/user/sendlink`, {user_id:this.resa.user_id}, {
          headers: headers,
        })
        .pipe()
        .subscribe((event: any) => {
    this.common.hideLoader()
    // ////console.log("event:", event);
        });
    })
        
    // this.api.postApiHit("/user/sendlink", {user_id:this.resa.user_id}, VARS.ClIENT_TYPE).then((res: any) => {})

    // })
  }
  check(){
  this.common.showLoader()
  let headers: any = {
      "My-Custom-Header": "foobar",
    };
    this.http
      .post(`${APIURL.BASE_URL}/user/reverify_login_token`, {user_id:this.resa.user_id}, {
        headers: headers,
      })
      .pipe()
      .subscribe((event: any) => {
  // ////console.log("event:", event);
    if(event.response.status){
    this.common.getDataInCookie(VARS.CLIENT_LOCAL_TOKEN_temp).then((res: any) => {
    // ////console.log(res);
    this.common.setlocal(VARS.CLIENT_LOCAL_TOKEN_KEY, res);
  this.toastr.success(event.response.msg,"",{ positionClass: 'toast-bottom-right' })
  this.getUserDetail()
  })
}else{
  this.toastr.error(event.response.error,"",{ positionClass: 'toast-bottom-right' })
  this.common.hideLoader();
}
    
});
   
   
}
getUserDetail() {
  this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
    this.common.hideLoader();
    if (res.response.status) {
      
      this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
      this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
        let resa = JSON.parse(res)
        this.router.navigate(['/explore']);

      })
    }
  })
}

}
