<!-- <div class="loader d-none" id="divLoading" [ngStyle]="{'display': displayname}">
  <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
  <span class="sr-only">Loading...</span>
</div> -->



<main>
  <div class="title-header2 hover">
    <div class=" " id="preview">
      <img class="shadow-none  bg-dark rounded rounded-circle z-depth-2 "  [src]="url"
        id="thumb" />
      <div class="overlay">
        <div class="container-fluid">

          <!-- <h2>My Account</h2> -->
          <span *ngIf="imagenullforleft" class="edit-pImg image-upload hover cursor"><label for="file-inputforleft"><i
                mat-raised-button #red="matTooltip" matTooltip="Update Header Image" matTooltipHideDelay="100"
                class="fa fa-edit hover cursor"><input id="file-inputforleft" type="file" class="cursor"
                  (change)="selectFile($event)" accept=".jpg,.jpeg,.png" /></i></label></span>
          <span *ngIf="!imagenullforleft" class="dropdown edit-pImg">

            <label for="dropcheck"><i class="fa fa-edit hover cursor"></i></label>
            <div class="dropdown-content">
              <a (click)="editleft()">Update</a>
              <!-- <a (click)="remove()">Remove</a> -->
              
              <!-- <a (click)="removeimage()">Remove</a> -->

            </div>
          </span>
        </div>


        <span style="display: 'none';">
          <input id="file-inputshiddenforleft" type="file" (change)="selectFile($event)" accept=".jpg,.jpeg,.png" />
        </span>
        <div>
          <small id="imageerror" class="text-danger confirmperror"></small>
        </div>
      </div>
    </div>
  </div>



  <div class="two-col">
    <div class="col-left">
        <div class="firstincontentform">
          <div class=" " id="previewprofile">
            <img class=" shadow-none  bg-dark rounded rounded-circle z-depth-2 " style="object-fit: cover;"
              [src]="urla" id="thumb" />
            <div class="imagebtn">


              <span *ngIf="!imagenull" class="dropdown edit-pImg">

                <label for="dropcheck"><i class="fa fa-edit hover cursor"></i></label>
                <div class="dropdown-content">
                  <a (click)="edit()">Update</a>
                  <!-- <a (click)="removeimage()">Remove</a> -->
                  
                 


                </div>
              </span>
              <span style="display: 'none';">
                <input id="file-inputshidden" type="file" (change)="selectFileprofile($event)"
                  accept=".jpg,.jpeg,.png" />
              </span>
              <span *ngIf="imagenull" class="edit-pImg image-upload "><label for="file-inputs"><i mat-raised-button
                    #red="matTooltip" matTooltip="Update Profile Image" matTooltipHideDelay="100"
                    class="fa fa-edit cursor hover"><input id="file-inputs" type="file"
                      (change)="selectFileprofile($event)" accept=".jpg,.jpeg,.png" /></i></label></span>
            </div>
          </div>

          <div>
            <small id="imageerrorprofile" class="text-danger confirmperror"></small>
          </div>
        </div>
     
    </div>

    <div class="col-right">
      <form [formGroup]="signupformforloophead" (ngSubmit)="onSubmit()">
        <div class="form-row"  *ngIf="show">
          <div class="form-group col-sm-6">
              <label>Profile visibility</label>
              <label class="switch profileSwitch" for="profileVisibility">
                  <input type="checkbox" id="profileVisibility" formControlName="profilevisible" /> <span
                      class="switchbtn"></span>
              </label>
  
          </div>
      </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label for="full_name">Full name</label><span class="ml-1 text-danger">*</span>
            <input type="text" class="form-control" id="full_name" placeholder="Full name" name="full_name"
              formControlName="full_name">
            <small class="form-text text-danger"
              *ngIf="signupformforloophead.get('full_name')?.touched && signupformforloophead.get('full_name')?.hasError('required')">Full
              name is
              required</small>

          </div>

        </div>


        <div class="form-row">
          <div class="form-group col-sm-6">
            <label for="username">User Name</label><span class="ml-1 text-danger">*</span>
            <input type="text" class="form-control" id="username" placeholder="@" name="username"
              formControlName="username">
            <small class="form-text text-danger"
              *ngIf=" signupformforloophead.get('username')?.touched && signupformforloophead.get('username')?.hasError('required')">
              Username is
              required </small>
            <small class="form-text text-danger" *ngIf="signupformforloophead.get('username')?.hasError('pattern')">No
              white space is allowed</small>
            <small class="form-text text-danger">{{usernameerror}}</small>

          </div>
          <div class="form-group col-sm-6">
            <label for="email">Email</label><span class="ml-1 text-danger">*</span>
            <a *ngIf="showemailtemperrors" class="text-underline small float-right" (click)="verifyemail()">Resend
              Email</a>
            <input type="email" class="form-control" id="email" placeholder="Email" name="email"
              formControlName="emailerr">
            <small class="form-text text-danger"
              *ngIf=" signupformforloophead.get('emailerr')?.touched && signupformforloophead.get('emailerr')?.hasError('required')">Email
              is
              required</small>
            <small class="form-text text-danger"
              *ngIf=" signupformforloophead.get('emailerr')?.touched && signupformforloophead.get('emailerr')?.hasError('email')">Enter
              valid email address</small>
            <small class="text-danger" *ngIf="showemailtemperrors">Email pending for verification -
              {{tempemail}}</small>

          </div>
        </div>


        <div class="form-row" *ngIf="showonlyonedit">
          <div class="form-group col-sm-6">
            <label for="Country">Country</label>
            <div>
              <select class="form-control" name="Country" id="Country" formControlName="country" (change)="valuepass()">
                <option  value="">Select</option>


                <option [value]="items.country_id" *ngFor="let items of tableDataofcountry"> {{items.country_name}}
                </option>
              </select>
              <small class="form-text text-danger"
                *ngIf=" signupformforloophead.get('country')?.touched && signupformforloophead.get('country')?.hasError('required')">Country
                is required</small>



            </div>
          </div>
          <div class="form-group col-sm-6">
            <label for="State">State</label>
            <div>
              <select class="form-control" name="State" id="State" formControlName="state">
                <option selected value="" [innerHtml]="testhtml()"></option>
                <option [value]="items.state_id" *ngFor="let items of tableDataofstate"> {{items.state_name}}
                </option>

              </select>
              <span style="display: flex; justify-content: space-between;">
                <small class="form-text text-danger"
                  *ngIf=" signupformforloophead.get('state')?.touched && signupformforloophead.get('state')?.hasError('required')">State
                  is required </small>
                  <small class="form-text text-danger">{{showcityerror}}</small>
                <!-- <small class="text-right text-info" *ngIf="!forcity">
                  First select country to choose state</small> -->
              </span>


            </div>

          </div>
        </div>



        <div class="form-row" *ngIf="showonlyonedit">
          <div class="form-group col-sm-6">
            <label for="city">City</label>
            <input type="text" name="city" id="city" placeholder="City" class="form-control" formControlName="city">
            <small class="form-text text-danger"
              *ngIf=" signupformforloophead.get('city')?.touched && signupformforloophead.get('city')?.hasError('required')">City
              is required</small>

          </div>
       
        </div>
<!-- 
        <div class="form-row">
          <div class="form-group col-sm-6">
            <small id="atleastonesocialmedialink" class="text-danger confirmperror"></small>
          </div>

        </div> -->



        <div class="form-row">
          <div class="form-group col-sm-6">
            <label class="space" for="instagram">Instagram</label><i class="fa fa-instagram" aria-hidden="true"></i><span> (Optional)</span>
            <input type="text" class="form-control" id="instagram" name="instagram"
              placeholder="instagram.com/YourUsername" formControlName="instagram">
            <small class="form-text text-danger"
              *ngIf="signupformforloophead.get('instagram')?.hasError('pattern')">Please enter valid url</small>
          </div>
          <div class="form-group col-sm-6">
            <label class="space" for="twitter">Twitter </label><i class="fa fa-twitter" aria-hidden="true"></i><span> (Optional)</span>
            <input type="text" class="form-control" id="twitter" placeholder="twitter.com/username" name="twitter"
              formControlName="twitter">
            <small class="form-text text-danger"
              *ngIf="signupformforloophead.get('twitter')?.hasError('pattern')">Please enter valid url</small>
            <!-- <small class="form-text "
                    [ngClass]=" !validationtwitter.istwittervalid? 'text-danger' : 'text-success'">{{twittererror}}</small> -->
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-6">
            <label class="space" for="youtube">YouTube</label><i class="fa fa-youtube-play" aria-hidden="true"></i><span> (Optional)</span>
            <input type="text" class="form-control" id="youtube" name="youtube" placeholder="youtube.com/YourUsername"
              formControlName="youtube">
            <small class="form-text text-danger"
              *ngIf="signupformforloophead.get('youtube')?.hasError('pattern')">Please enter valid url</small>
          </div>
          <div class="form-group col-sm-6">
            <label class="space" for="beatstars">BeatStars</label><span><img class="logoimgbeat"
                src="assets/img/beatstar.png" alt=""></span><span> (Optional)</span>
            <input type="text" class="form-control" id="beatstars" placeholder="beatstars.com" name="beatstars"
              formControlName="beatstars">
            <small class="form-text text-danger"
              *ngIf="signupformforloophead.get('beatstars')?.hasError('pattern')">Please enter valid url</small>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-6">
            <label class="space" for="tiktok">TikTok</label><span><img class="logoimgtik" src="assets/img/tiktok.png"
                alt=""></span><span> (Optional)</span>
            <input type="text" class="form-control" id="tiktok" name="tiktok" placeholder="tiktok.com/@username"
              formControlName="tiktok">
            <small class="form-text text-danger" *ngIf="signupformforloophead.get('tiktok')?.hasError('pattern')">Please
              enter valid url</small>
          </div>
          <div class="form-group col-sm-6">
            <label class="space" for="twich">Twitch</label><i class="fa fa-twitch" aria-hidden="true"></i><span> (Optional)</span>
            <input type="text" class="form-control" id="twich" placeholder="twitch.com/YourUsername" name="twich"
              formControlName="twitch">
            <small class="form-text text-danger" *ngIf="signupformforloophead.get('twitch')?.hasError('pattern')">Please
              enter valid url</small>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group  col-sm-12">
            <label for="Bio">Bio</label><span class="ml-1 text-danger"></span>
            <textarea (keyup)="keyupfunc()" class="form-control" id="descriptiontext" cols="4" rows="4"
              formControlName="bio_guidelines"></textarea>
            <small class="form-text text-danger"
              *ngIf="signupformforloophead.get('bio_guidelines')?.hasError('maxlength')">Max 160 characters can be
              entered</small>
            <small class="text-muted" id="textarea_feedback"></small>
          </div>


        </div>

        <!-- <div class="form-row" *ngIf="!show">
          <div class="form-group col-md-12 ">
            <input type="checkbox" class="form-check-input mr-2" id="tickbox" formControlName="tickbox">
            <label class="tickbox boxcss" class="form-check-label" for="tickbox">Before creating your account, you
              must
              agree with our Terms
              and Policy<b style="text-decoration: underline;"><a> </a></b></label>
            <div>
              <small class="form-text text-danger checkboxtick"
                *ngIf=" signupformforloophead.get('tickbox')?.touched && signupformforloophead.get('tickbox')?.hasError('required')">
                Please check this box to proceed further
              </small>
            </div>

          </div>
        </div> -->

        <div class="form-row" *ngIf="show">
          <div class="form-group col-md-6 textmarg">
            <h4 class="passwordtextinfo"> Password</h4><i class="iinpassword">(Leave blank if you do not want to
              reset
              the Password)</i>
          </div>

        </div>
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label for="password">Password</label><span class="ml-1 text-danger">*</span>
            <input type="password" autocomplete="new-password" name="password" id="password" placeholder="Password"
              class="form-control" formControlName="password">
            <span style="display: flex; justify-content: space-between; flex-direction: column;">
              <small class="form-text text-danger"
                *ngIf=" signupformforloophead.get('password')?.touched && signupformforloophead.get('password')?.hasError('required')">Password
                is
                required</small>
              <small class="form-text text-danger"
                *ngIf=" signupformforloophead.get('password')?.touched && signupformforloophead.get('password')?.hasError('minlength')">Minimum
                6 characters required</small>
              <small class="form-text text-danger"
                *ngIf="  signupformforloophead.get('password')?.hasError('pattern')">Password should include at
                least
                one uppercase letter and one special character</small>
            </span>
          </div>
          <div class="form-group col-sm-6">
            <label for="cpassword">Confirm Password</label><span class="ml-1 text-danger">*</span>
            <input type="password" name="password" id="cpassword" placeholder=" Confirm Password" class="form-control"
              (click)="passwordConfirm()" formControlName="confirmpassword">
            <span style="display: flex; justify-content: space-between; flex-direction: column;">
              <small id="confirmpasserror" class="text-danger confirmperror"></small>
              <small class="form-text text-danger"
                *ngIf=" signupformforloophead.get('confirmpassword')?.touched && signupformforloophead.get('confirmpassword')?.hasError('required')">Confirm
                Password is
                required</small>
              <small class="form-text text-danger"
                *ngIf=" signupformforloophead.get('confirmpassword')?.touched && signupformforloophead.get('confirmpassword')?.hasError('minlength')">Minimum
                6 characters required</small>

              <small class="form-text text-danger"
                *ngIf="  signupformforloophead.get('confirmpassword')?.hasError('pattern')">Password should include
                at
                least one uppercase letter and one special character</small>
            </span>



          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12 text-right">
            <!-- <button class="btn btn-theme text-end " [disabled]="buttonShow" >{{name}}</button> -->
            <button type="button" class="btn btn-theme " (click)="cancelandredirect()">Cancel</button>
            <button type="button" class="btn btn-theme marginleft" (click)="onSubmit()">Submit</button>
          </div>
        </div>

      </form>
    </div>
  </div>
</main>