<div class="page">
    <div class="close" (click) = cancel()>X</div>
    <div class="main-box">
    <div class="heading">Two-Factor Authentication</div>
    <p>Choose a way to sign up your account for 2FA</p>
    <form [formGroup]="AuthForm">
        <div class="input-rw">
        <input class="form-chck" type="checkbox" formControlName="email"/>
        <label><span>Yes.</span>  Use My Email {{email}}</label>
    </div>
    <div class="input-rw">
         <input class="form-chck"  type="checkbox" formControlName="phone"/>
        <label><span>Yes.</span> Use My Phone Number</label>
    </div> 
    <div class="input-rw">
        <input class="input-text" formControlName="phone_number"/>
        <small class="form-text text-danger"
        *ngIf=" AuthForm.get('phone_number')?.touched && AuthForm.get('phone_number')?.hasError('required')">
        Phone number is
        required</small>
    </div>
        <button class="submit" (click)="submit()">Confirm</button>
    </form>
</div>
</div>