<div class="Search-page">
    <app-header  *ngIf="!isMobile||showMenu"></app-header>
    <app-responsiveheader  *ngIf="isMobile&&!showMenu"></app-responsiveheader>
   

<div class="desktop-screen"  *ngIf="!isMobile">
  <div _ngcontent-htr-c51=""  class="container">
      <!-- <div ngcontent-htr-c51="" class="container-fluid "> -->
          <div ngcontent-htr-c51="" class="row mt-4">
              <div class="col-md-6" >
                <form [formGroup]="advancesearch" style="padding: 0px 10px;">
                  <div class="searchbox">
                    <i class="srch-icon fa fa-search" aria-hidden="true"></i>
                      <input type="text" class="form-control" value="{{validationforsearch}}" (keyup.enter)="apply('frommain')"  formControlName="keyword"  style="">
                        <button class="btn" (click)="apply('frommain')">Search</button>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <div class="filter-btn">
                  <a class="filter" (click)="openDialog()"> <img src="../../../../assets/img/icons/filter-lines.svg"/>View Filter</a>
                </div>
              </div>
        </div>
          <!-- <div> -->
          <div class="search-values"> {{totalRecords}} search {{totalRecords==1||totalRecords==0?'result':'results'}} for "{{validationforsearch}}"</div>
        <!-- </div> -->
  </div>
  <div class="container p-0">
      <!-- <div *ngIf="active" class="list" > -->
        <!-- <app-usersearch *ngIf="usersearch" [items]="check"></app-usersearch> -->
        <!-- <app-loopsearch [items]="check" [load]="load"></app-loopsearch> -->
        <div class="desktop-screen" >
          <div class="container">
          <div class="table-responsive table-data" [ngStyle]="showAudioPlayer?{'margin-bottom.px': 115}:null">
              <table *ngIf="active" >
                  <thead>
                  <th style="width: 130px;">Loop + User</th>
                  <th></th>
                  <th class="mobi-hide-2"></th>
                  <th class="t-align mobi-hide-2">BPM</th>
                  <th class="t-align mobi-hide-2">Key</th>
                  <th class="mobi-hide-2">Tags</th>
                  <th class="mobi-shw-2">User</th>
                  <th class="dnload-align dnload-head" >Action</th>
              </thead>
              <tbody>
                  <tr *ngFor="let items of tableData,let i = index" id="player-row-{{i}}" ngcontent-yrg-c82="" >
                      <td class="loop-artwork">
                          <img [src]="setimage(items)"/><span ngcontent-yrg-c82=""
                          class="play-btn hover" >
                          <span class="play-btn hover play_button" id="play_button" *ngIf="waveformLoaded[i] && !items.hidepausebutton" (click)="playAudio(items, i, $event)">
                              <!-- Original icon when items.hide is true -->
                              <i aria-hidden="true" class="fa fa-play"></i>   <!-- Add the appropriate icon or image here -->
                            </span>
                            
                            <!-- <span class="play-btn hover play_button" id="play_button" *ngIf="!items.hidepausebutton && !items.hide" >
                               New icon when items.hide is false -->
                              <!-- Add the refreshed icon or image here 
                              <i aria-hidden="true" class="fa fa-play"></i> 
                            </span> -->
      
                          <span class="play-btn hover play_button" id="play_button"  *ngIf="waveformLoaded[i] && items.hidepausebutton" (click)="stopAudio(items,i)" >
                              <i class="fa fa-pause"
                                  aria-hidden="true"></i></span>
      
                      </span>
                      </td>
                      <td class="loop-details">
                              <h4 ngcontent-yrg-c82=""  style="cursor: pointer;"  (click)="redirecttolooppage(items.loop_id)" [ngClass]="fontSize(items)" >{{items.title}}</h4>
                                      
                                      <a style="cursor: pointer;" (click)="redirecttouser(items.username)" >
                                      @{{items.username}}</a>
              </td>
              <td class="col-md-4 mobi-hide-2">
                  <div _ngcontent-yrg-c82="" class="wave">
                      <div _ngcontent-yrg-c82="" class="media-equlizer">
                         <app-waveform #waveformRef [arr]="srcforsound(items)" [loop_id]="items.loop_id" [items]="i"[passedValue]="items" 
                         [slide]="sliderValue"  (sliderValueChange)="onSliderValueChanged($event)"  (waveformLoaded)="onWaveformLoaded(i)"  (musicEnded)="next(i)"></app-waveform>
      
                      </div>
                  </div>
              </td>
                      <td class="t-align mobi-hide-2">
                          {{items.bpm}}
                      </td>
                      <td class="t-align mobi-hide-2">
                          {{items.sub_key}}  {{items.main_key===0?"Major":items.main_key===1?"Minor":""}}
                      </td>
                      <td class="mobi-hide-2" >
                          <div class="tags" *ngFor="let i of items.tags_array" (click)="redirectto(i)" >
                              {{i}}
      
                          </div>
                      </td>
                      <td class="mobi-shw-2">
                          <div class="artist-name" (click)="redirecttouser(items.username)">  @{{items.username}}</div>
                      </td>
                      <td class="action">
                          <div class="action-div">
                          <div  class="stem">{{items.stems?"Contains Stems":""}}</div>
                         <div class="download dnload-align"(click)="downloadFile(items)"><img src="../../../../assets/img/download.svg"/></div>
                         <!-- <div  *ngIf="!items.showdownload" class="action-li loader" >
                            <mat-spinner style="height: 20px;width: 20px;" [diameter]="20"></mat-spinner>
                        </div> -->
                      </div>
                          <!-- <div class="download1 dnload-align" *ngIf="disabled"><img src="../../../../assets/img/download.svg"/></div> -->
                          <!-- <mat-progress-spinner
                          *ngIf="disabled"
                                class="progress"
                                mode="indeterminate"
                              ></mat-progress-spinner> -->
                      </td>
              </tr>
      
              </tbody>
      
              </table>
      
              <div class="music-player"  *ngIf="showAudioPlayer">
              
              
                  <div class="aWrap" data-src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3">
                      <div>
                          <input #slider class="range" style="width: 100%;" id="pi_input" type="range" [min]="0" [max]="timeupdate()" [step]="steps" [(ngModel)]="sliderValue" (input)="onInput($event)"/>
                          
                          
                      </div>
                      <div class="col-md-4 col-sm-6 player-img">
                          <div class="img-info-div">
                          <img class="img" [src]="setimage(tableData[player])"/>
                          <div class="name">
                          <h5>{{tableData[player].title}} </h5>
                          <span class="username-box" style="cursor: pointer;">@{{tableData[player].username}}</span>
                          </div>
                          </div>
                      </div>
                      <div class="col-md-4 col-sm-6 media-player">
                          <div class="btns d-flex media-player-btns">
                              <div>
                                  <div (click)="backward()"><img src="../../../assets/img/fast-backward.svg"/></div>
                              </div>
                              <div class="buttons" *ngIf="!tableData[player].hidepausebutton"  (click)="playAudio(tableData[player],player,$event)">
                                  <button class="aPlay" ><span class="aPlayIco"><i class="fa fa-play" style="color: white;"></i></span></button>
                              </div>
                              
                              <div class="buttons" *ngIf="tableData[player].hidepausebutton" (click)="stopAudio(tableData[player],player)" >
                                  <button class="aPlay"><span class="aPlayIco"><i class="fa fa-pause" style="color: white;"></i></span></button>
                              </div>
                              <div>
                                  <div (click)="forward()" ><img src="../../../assets/img/fast-forward.svg"/></div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-4 volume-btns">
                          <div class="volume-div d-flex">
                              <div *ngIf="!mute" class="volume-container" (click) ="Mute()">
                              <span class="aVolIco"><i class="fa fa-volume-up icon"></i></span>
                              </div>
                              <div *ngIf="mute" class="volume-container"  (click) ="Unmute()">
                                  <span class="aVolIco" >
                                      <i class="fa fa-volume-off" style="color: black"></i>
                                  </span> 
                                  <!-- <span class="aVolIco" > <img src="../../../../assets/img/volume-xmark-solid.svg"/> </span> -->
                              </div>
                              <div  class="range-volume">
                                  <input class="aVolume" id="volume" type="range" min="0" max="1" step="0.1" [(ngModel)]="volumeValue" (input)="onVolumeInput($event)" style="height: 5px;">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
      
              <div *ngIf="!active" class="text-center" style=" padding: 10px; padding-top: 20px;">
              <img src="../../../../assets/img/hand and electronic musical instrument.png" class="mb-5"/>
              <div class="msg mb-5 mt-5">
                  Search Results Stuck in a  
                  <div class="yellow">
                      Loop
                  </div>
              </div>
              <p>We can’t seem to find what you’re looking for.<br> Use the search bar at the top of the page to conduct another search.</p>
              </div>
            <!-- <button class="btn btn-theme center" *ngIf="!hidepagination" (click)="paginationget()">Load More Results</button> -->
      
          </div>
          </div>
      </div>

      
      <button class="btn center" *ngIf="!hidepagination" (click)="paginationget()">Load More Results</button>
      <!-- <button class="btn center" *ngIf="!hidepagination" (click)="paginationget()">Load More Results</button> -->
      <!-- <app-footer></app-footer> -->
        
        <!-- <app-loopkitsearch *ngIf="loopkitsearch" [items]="check"></app-loopkitsearch> -->
      <!-- </div> -->
  </div>
</div>

<div class="mobile-screen"  *ngIf="isMobile"> 
  <div _ngcontent-htr-c51=""  class="container">
          <div ngcontent-htr-c51="" [ngClass]="!showMenu ? 'row' : 'loggedin-row'"
            >
              <div class="col-md-6">
                <form [formGroup]="advancesearch" class="search_form">
                  <div class="searchbox">
                    <i class="srch-icon fa fa-search" aria-hidden="true"></i>
                      <input type="text" class="form-control" value="{{validationforsearch}}" (keyup.enter)="apply('frommain')"  formControlName="keyword" placeholder="Search Loops">
                        <!-- <button class="btn" (click)="apply('frommain')">Search</button> -->
                  </div>
                  <div class="filter-btn">
                    <a class="filter" (click)="openDialog()"> <img src="../../../../assets/img/icons/filter-lines.svg"/>View Filter</a>
                  </div>
                </form>
              </div>
          </div>
          <!-- <div> -->
          <div class="search-values"> 
            {{totalRecords}} search {{totalRecords==1||totalRecords==0?'result':'results'}} for "{{validationforsearch}}"
          </div>
        <!-- </div> -->
  </div>
  <div class="container p-0">
      <div *ngIf="active" class="list" >
        <!-- <app-usersearch *ngIf="usersearch" [items]="check"></app-usersearch> -->
        <!-- <app-loopsearch [items]="check" [load]="load"></app-loopsearch> -->
            
      <div class="mobile-screen" >
          <div class="container list-contnr">
          <div class="mobil-tble table-data">
              <table *ngIf="active" >
              <tbody>
                  <tr *ngFor="let items of tableData,let i = index" id="player-row-{{i}}" ngcontent-yrg-c82="" >
                      <td class="loop-artwork">
                          <img [src]="setimage(items)"/><span ngcontent-yrg-c82=""
                          class="play-btn hover" >
                          <span class="play-btn hover play_button" id="play_button"  *ngIf="waveformLoaded[i] && !items.hidepausebutton" (click)="playAudio(items, i, $event)">
                              <!-- Original icon when items.hide is true -->
                              <i aria-hidden="true" class="fa fa-play"></i>   <!-- Add the appropriate icon or image here -->
                            </span>
                            
                            <!-- <span class="play-btn hover play_button" id="play_button" *ngIf="!items.hidepausebutton && !items.hide" >
                               New icon when items.hide is false -->
                              <!-- Add the refreshed icon or image here 
                              <i aria-hidden="true" class="fa fa-play"></i> 
                            </span> -->
      
                          <span class="play-btn hover play_button" id="play_button"   *ngIf="waveformLoaded[i] && items.hidepausebutton" (click)="stopAudio(items,i)" >
                              <i class="fa fa-pause"
                                  aria-hidden="true"></i></span>
      
                      </span>
                      </td>
                      <td class="loop-details">
                          <h4 ngcontent-yrg-c82=""  style="cursor: pointer;"  (click)="redirecttolooppage(items.loop_id)" [ngClass]="fontSize(items)" >
                              {{items.title}}
                          </h4>     
                          <a style="cursor: pointer;" (click)="redirecttouser(items.username)" >
                                      @{{items.username}}
                          </a>
                          <div class="bpm-det">
                              <span> Bpm - {{items.bpm}}</span> <span> Key -  {{items.sub_key}}  {{items.main_key===0?"Major":items.main_key===1?"Minor":""}}</span>
                          </div>
                      </td>
                      <td class="col-md-4 wave mobi-hide-2" style="display: none;">
                        <div _ngcontent-yrg-c82="" class="">
                          <div _ngcontent-yrg-c82="" class="media-equlizer">
                            <app-waveform #waveformRef [arr]="srcforsound(items)" [loop_id]="items.loop_id" [items]="i"[passedValue]="items" 
                            [slide]="sliderValue"  (sliderValueChange)="onSliderValueChanged($event)"  (waveformLoaded)="onWaveformLoaded(i)"  (musicEnded)="next(i)"></app-waveform>
                          </div>
                        </div>
                      </td>
                      <td class="dnload-align">
                          <div class="action-i">
                            <div  (click)="downloadFile(items)" *ngIf="items.showdownload" class="action-li">
                              <img src="../../../../assets/img/download.svg"/>
                            </div>
                          
                          </div>
                        </td>   
              </tr>
      
              </tbody>
      
              </table>
      
              <div class="music-player"  *ngIf="showAudioPlayer">
              
              
                  <div class="aWrap" data-src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3">
                      <div>
                          <input #slider class="range" style="width: 100%;" id="pi_input" type="range" [min]="0" [max]="timeupdate()" [step]="steps" [(ngModel)]="sliderValue" (input)="onInput($event)"/>
                          
                          
                      </div>
                      <div class="col-md-4 col-sm-6 player-img">
                          <div class="img-info-div">
                          <img class="img" [src]="setimage(tableData[player])"/>
                          <div class="name">
                          <h5>{{tableData[player].title}} </h5>
                          <span class="username-box" style="cursor: pointer;">@{{tableData[player].username}}</span>
                          </div>
                          </div>
                      </div>
                      <div class="col-md-4 col-sm-6 media-player">
                          <div class="btns d-flex media-player-btns">
                              <div>
                                  <div (click)="backward()"><img src="../../../assets/img/fast-backward.svg"/></div>
                              </div>
                              <div class="buttons" *ngIf="!tableData[player].hidepausebutton"  (click)="playAudio(tableData[player],player,$event)">
                                  <button class="aPlay" ><span class="aPlayIco"><i class="fa fa-play" style="color: white;"></i></span></button>
                              </div>
                              
                              <div class="buttons" *ngIf="tableData[player].hidepausebutton" (click)="stopAudio(tableData[player],player)" >
                                  <button class="aPlay"><span class="aPlayIco"><i class="fa fa-pause" style="color: white;"></i></span></button>
                              </div>
                              <div>
                                  <div (click)="forward()" ><img src="../../../assets/img/fast-forward.svg"/></div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-4 volume-btns">
                          <div class="volume-div d-flex">
                              <div *ngIf="!mute" class="volume-container" (click) ="Mute()">
                              <span class="aVolIco"><i class="fa fa-volume-up icon"></i></span>
                              </div>
                              <div *ngIf="mute" class="volume-container"  (click) ="Unmute()">
                                  <span class="aVolIco" >
                                      <i class="fa fa-volume-off" style="color: black"></i>
                                  </span> 
                                  <!-- <span class="aVolIco" > <img src="../../../../assets/img/volume-xmark-solid.svg"/> </span> -->
                              </div>
                              <div  class="range-volume">
                                  <input class="aVolume" id="volume" type="range" min="0" max="1" step="0.1" [(ngModel)]="volumeValue" (input)="onVolumeInput($event)" style="height: 5px;">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
      
              <div *ngIf="!active" class="text-center" style=" padding: 10px; padding-top: 20px;">
              <img src="../../../../assets/img/hand and electronic musical instrument.png" class="mb-5"/>
              <div class="msg mb-5 mt-5">
                  Search Results Stuck in a  
                  <div class="yellow">
                      Loop
                  </div>
              </div>
              <p>We can’t seem to find what you’re looking for.<br> Use the search bar at the top of the page to conduct another search.</p>
              </div>
            <!-- <button class="btn btn-theme center" *ngIf="!hidepagination" (click)="paginationget()">Load More Results</button> -->
      
          </div>
          </div>
      </div>
      
      <button class="btn center" *ngIf="!hidepagination" (click)="paginationget()">Load More Results</button>
      <!-- <app-footer></app-footer> -->
        
        <!-- <app-loopkitsearch *ngIf="loopkitsearch" [items]="check"></app-loopkitsearch> -->
      </div>
  </div>
</div>

<!-- <app-music-player *ngIf="showAudioPlayer" [items]="check"></app-music-player> -->
