<h3 class="dynamictext">{{name}} Banned word</h3>

<form (ngSubmit)="bannedwordsubmit()" [formGroup]="bannedwordform">

    <div class="form-row">
        <div class="form-group col-md-12">
            <label for="bannedwords">Banned word</label><span class="ml-1 text-danger">*</span>
            <input type="text" id="bannedwords" class="form-control" (keyup.enter)="bannedwordsubmit()"
                for="bannedwords" formControlName="bannedwords" placeholder="Enter word">
            <small class="text-danger" [innerHTML]="vCheck('bannedwords', '_INPUT')"></small>

        </div>




    </div>





    <div class="form-row" *ngIf="!noteditableform">
        <div class="form-group col-md-6">
            <label for="couponcreatedon">Created Date</label>
            <input type="datetime" name="subscriptioncreatedon" formControlName="createddate" id="subscriptioncreatedon"
                class="form-control">
        </div>
        <div class="form-group col-md-6">
            <label for="subscriptionModifiedOn">Modified Date</label>
            <input type="datetime" name="subscriptionModifiedOn" formControlName="modifieddate"
                id="subscriptionModifiedOn" class="form-control">
        </div>
    </div>


    <div class="form-group text-right ">
        <button type="button" class="btn btn-theme " (click)="cancelandredirect()">Cancel</button>
        <button type="button" class="btn btn-theme ml-4" (click)="bannedwordsubmit()"> Save</button>
    </div>





</form>