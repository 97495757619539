<app-header *ngIf="!isMobile||showMenu">
</app-header>
  <app-responsiveheader  *ngIf="isMobile&&!showMenu"></app-responsiveheader>
  <div class="desktop-screen" *ngIf="!isMobile">
  <div class="page-header-image" >
  <div class="container">
      <div class="row loop--details" *ngFor="let item of arraytableforallvalues,let i = index"  id="player-row-{{i}}">
          <div class="col-md-5 col-9 d-flex my-auto">
            <div class="loop-artwork" *ngIf="!completerender" >
              <img class="mobi-hide" [src]="url" alt="" (click)="showLightbox(0)" />
              <ng-image-fullscreen-view
              [images]="imageObject"
              [imageIndex]="selectedImageIndex"
              [show]="showFlag"
              (close)="closeEventHandler()"></ng-image-fullscreen-view>
              <div class="mobi-shw">
                <span ngcontent-yrg-c82=""
                class="play-btn-1 hover">
                <span class="play-btn hover play_button" id="play_button">
                  <i aria-hidden="true" *ngIf="!arraytableforallvalues[0]?.hidepausebutton" (click)="playAudio(arraytableforallvalues[0],0,'main')" class="fa fa-play"></i>
                  <i (click)="stopAudio(arraytableforallvalues[0],0)" *ngIf="arraytableforallvalues[0].hidepausebutton" class="fa fa-pause"
                    aria-hidden="true"></i></span>
          
              </span>
              </div>

            </div>
            <div *ngIf="completerender" class="loop-artwork"><img alt="" [src]="artworkpathimage"></div>
            <div class="loop-info-header">
              <div style="display: flex;">
              <h4 class="title">{{titlename}}
              </h4>
                <img class="lock" *ngIf="arraytableforallvalues[0]?.visiblity===1" src="../../../../assets/img/lock-01.svg"/>
              </div>
              <p style=" margin: 0px; text-align: start;">Loop</p>
              <h4  class="user" >
                <div (click)="redirecttouser(username)" style="cursor: pointer;"><img [src]="setimage1(arraytableforallvalues[0])" width="50px" class="small-img-main"/> @{{username}}</div>
                </h4>
                </div>
          </div>
          <div class="col-md-7 col">
            <div class="inner-box">
            <div class="row btns-rw">
              <div class="share-btn"  (click)="openshare(arraytableforallvalues[0])"> <img src="../../../../assets/img/share 1.svg"/>Share</div>
              <div (click)="openEdit(arraytableforallvalues[0]) "  class="download-btn"  *ngIf="sameshowdownload">Edit Loop</div>
              <div class="download-btn" (click)="downloadFile(arraytableforallvalues[0])"  *ngIf="!sameshowdownload">Download</div>
              </div>
            <div class="rowf">
            <div>
              <span ngcontent-yrg-c82=""
              class="play-btn-1 hover">
              <span class="play-btn hover play_button" id="play_button">
                <i aria-hidden="true" *ngIf="!arraytableforallvalues[0]?.hidepausebutton&&waveformLoaded[i]" (click)="playAudio(arraytableforallvalues[0],0,'')" class="fa fa-play"></i>
                <i (click)="stopAudio(arraytableforallvalues[0],0)" *ngIf="arraytableforallvalues[0].hidepausebutton&&waveformLoaded[i]" class="fa fa-pause"
                  aria-hidden="true"></i></span>
        
            </span>
            </div>
            <div class="media-equlizer" style="width: 500px;">
              <app-waveform #waveformRef [arr]="srcforsound(item)" [loop_id]="item.loop_id"  [items]="i"[passedValue]="item" 
              [slide]="sliderValue"  (sliderValueChange)="onSliderValueChanged($event)"  (waveformLoaded)="onWaveformLoaded(i)"  (musicEnded)="next(i)" ></app-waveform>

  
            </div>
          </div>
            </div>
          </div>
      </div>
  </div>
</div>
  <div class="container mb-5">
    <div class="row ">
      <div class="col">
      <div style="color: var(--Dark-Grey, #444445); font-family: Inter;font-size: 28px;font-style: normal; font-weight: 700; line-height: normal;margin-top: 60px;">Loop Details
    </div>
    </div> 
    </div>
    <div class="row mt-5">
    <div class="col-md-8 col-12">
      <div *ngIf="arraytableforallvalues[0]?.stems!==null" style="font-family: Inter; font-size: 20px;font-style: normal;font-weight: 700;line-height: normal;">CONTAINS STEMS</div>
      <div class="text">
        <div class="col-md-4 col-6 text-line">
          BPM
        </div>
        <div class="col-md-4 col-6 text-line">
          {{ arraytableforallvalues[0]?.bpm?arraytableforallvalues[0]?.bpm:"NA"}}
        </div>
      </div>

      <div class="text">
        <div class="col-md-4 col-6 text-line">
          Key
        </div>
        <div class="col-md-4 col-6 text-line">
          {{arraytableforallvalues[0]?.sub_key?arraytableforallvalues[0]?.sub_key:"NA"}}  {{arraytableforallvalues[0]?.main_key===0?"Major":arraytableforallvalues[0]?.main_key===1?"Minor":"NA"}}
        </div>
      </div>

      <div class="text">
        <div class="col-md-4 col-6 text-line">
          Tags
        </div>
        <div class="col-md-4 col-6 text-line tags-div">
          <span class="badge hover"
          ><a style="color: white;" *ngIf="tablefortagsdisplay1" [routerLink]= "['/advancesearch/']" [queryParams]="{key_word: tablefortagsdisplay1}" >{{tablefortagsdisplay1}}</a> </span><span class="badge hover"
          ><a style="color: white;" *ngIf="tablefortagsdisplay2"  [routerLink]= "['/advancesearch/']" [queryParams]="{key_word: tablefortagsdisplay2}">{{tablefortagsdisplay2}}</a> </span><span class="badge hover"
          ><a style="color: white;" *ngIf="tablefortagsdisplay3"  [routerLink]= "['/advancesearch/']" [queryParams]="{key_word: tablefortagsdisplay3}">{{tablefortagsdisplay3}}</a> </span>
        </div>
      </div>
    

      <div class="text">
        <div class="col-md-4 col-6 text-line">
          Date Added
        </div>
        <div class="col-md-4 col-6 text-line">
          <span class="posted ml-1" [innerHtml]="timeget(arraytableforallvalues[0])"></span>
        </div>
      </div>
    </div>
    <div _ngcontent-fbn-c153="" class="col-md-4 col-12 usage-div">
      <div class="col text-line">
      <h4 class="usage_rights" _ngcontent-fbn-c153="">Usage Rights</h4>
      </div>
      <div class=" col usage-text text-line">
        <div [innerHTML]=arraytableforallvalues[0]?.usage_rights?arraytableforallvalues[0]?.usage_rights:usage style="white-space: pre-wrap;"></div>
        <div _ngcontent-fbn-c153="">
        </div>
      </div>
    </div>
    </div>
  </div>

  <div class="container">
    <div class="row mt-5">
    <div class="col">
      <h4 style="color: var(--Dark-Grey, #444445); font-family: Inter;font-size: 28px;font-style: normal; font-weight: 700; line-height: normal;">More by this creator</h4>
      <div class="table-responsive mt-5">		
        <table *ngIf="!norecordfound" class="table" >
          <thead>
          <th>Loop </th>
          <th></th>
          <th class="mobi-hide-2"></th>
          <th class="t-align bpk mobi-hide-2">BPM</th>
          <th class="t-align bpk mobi-hide-2">Key</th>
          <th class="mobi-hide-2">Tags</th>
          <th class="mobi-shw-2">Artist</th>
          <th class="dnload-align bpk" >Action</th>
        </thead>
        <tbody>
          <tr *ngFor="let items of tableData,let i = index" ngcontent-yrg-c82="" id="player1-row-{{i}}" >
            <td class="loop-artwork">
              <img [src]="setimage(items)"/><span ngcontent-yrg-c82=""
              class="play-btn hover" *ngIf="!items.hidepausebutton&&waveformLoaded[i]" (click)="playAudio1(items,i,$event)">
              <span class="play-btn hover play_button" id="play_button"  >
                <i aria-hidden="true" class="fa fa-play"></i></span>
            </span>
            <span ngcontent-yrg-c82=""
                class="play-btn hover" (click)="stopAudio1(items,i)" *ngIf="items.hidepausebutton&&waveformLoaded[i]">	<span class="play-btn hover play_button" id="play_button"  >
                <i class="fa fa-pause"
                  aria-hidden="true"></i></span>
        
            </span>
            </td>
            <td class="loop-details">
                <h4 ngcontent-yrg-c82=" " (click)="redirecttolooppage(items.loop_id)" style="cursor: pointer;" [ngClass]="fontSize(items)" >{{items.title}}</h4>
      <img *ngIf="items.visiblity===1" src="../../../../assets/img/lock-01.svg"/>
                    
        </td>
        <td class="col-md-4 wave mobi-hide-2">
          <div _ngcontent-yrg-c82="" class="">
            <div _ngcontent-yrg-c82="" class="media-equlizer">
              <app-waveform #waveformRef [arr]="srcforsound(items)" [loop_id]="items.loop_id" [items]="i"[passedValue]="items" 
              [slide]="sliderValue"  (sliderValueChange)="onSliderValueChanged($event)"  (musicEnded)="next(i)"  (waveformLoaded)="onWaveformLoaded(i)"></app-waveform>          </div>
          </div>
        </td>
            <td class="t-align bpk mobi-hide-2">
              {{items?.bpm}}
            </td>
            <td class="t-align bpk mobi-hide-2">
              {{items.sub_key}}  {{items.main_key===0?"Major":items.main_key===1?"Minor":""}}
            </td>
            <td class="mobi-hide-2">
              <div class="tags" *ngFor="let i of items.tags_array" >
                {{i}}
        
              </div>
            </td>
            <td class="mobi-shw-2">
              @{{items.username}}
            </td>
            <td class="bpk action"  *ngIf="!sameshowdownload">
              <div class="action-div">
                      <div  class="stem">{{items.stems?"Contains Stems":""}}</div>
                      <div class="download dnload-align" (click)="downloadFile(items)" ><img src="../../../../assets/img/download.svg"/></div>
                    </div>
              </td>
              
              <td *ngIf="sameshowdownload">
                <div class="action-i">
                  <div class="action-li">
                    <img src="../../../../assets/img/edit.svg" (click)="openEdit(items) "/>
                  </div>
                  <div (click)="openshare(items)" class="action-li">
                    <img src="../../../../assets/img/share.svg" />
                  </div>
                </div>
              </td> 
          </tr>
        </tbody>
        </table>
        <div  *ngIf="norecordfound" > No Loop Found</div>
        </div>   
        
        
    </div>

  </div>
  </div>
  
</div>

<!-- //Reponsive -->

<div  [ngClass]="!showMenu ? 'mobile-screen1' : 'mobile-screen'">
  <div [ngClass]="!showMenu ? 'page-header-image1' : 'page-header-image'">
    <h4>Loop Details</h4> 
  </div>
  <div class="container">
    <div class="mobi-loop-artwork" *ngIf="!completerender" >
      <div class="loop-img">
      <img class="" [src]="url" alt="" (click)="showLightbox(0)" />
      <ng-image-fullscreen-view
      [images]="imageObject"
      [imageIndex]="selectedImageIndex"
      [show]="showFlag"
      (close)="closeEventHandler()"></ng-image-fullscreen-view>
      </div>
      <div class="mobil-btn">
        <span ngcontent-yrg-c82=""
        class="play-btn-1 hover">
        <span class="play-btn hover play_button" id="play_button">
          <i aria-hidden="true" *ngIf="!arraytableforallvalues[0]?.hidepausebutton&&waveformLoaded[0]" (click)="playAudio(arraytableforallvalues[0],0,'main')" class="fa fa-play"></i>
          <i (click)="stopAudio(arraytableforallvalues[0],0)" *ngIf="arraytableforallvalues[0]?.hidepausebutton&&waveformLoaded[0]" class="fa fa-pause"
            aria-hidden="true"></i></span>
            
      </span>
      </div>
      
  </div>
</div>

  <div class="container">
    <div class="row loop--details" *ngFor="let item of arraytableforallvalues,let i = index"  id="player-row-{{i}}">
        <div class="col">
          
          <div *ngIf="completerender" class="loop-artwork"><img alt="" [src]="artworkpathimage"></div>
          <div class="mobil-loop-info-header">
            <div style="">
            <h4 class="loop-nme">{{titlename}}
            </h4>
            <div *ngIf="arraytableforallvalues[0]?.stems!==null" class="stems">Contains Stems</div>
              <img class="lock" *ngIf="arraytableforallvalues[0]?.visiblity===1" src="../../../../assets/img/lock-01.svg"/>
            </div>
            <h4 style="color: rgb(0, 0, 0);" class="user" >
              <div (click)="redirecttouser(username)" class="user-nme">
                 @{{username}}
              </div>
            </h4>
            <div class="bpm-key-detail">
              <div class="text mt-0">
                <div  class="text-line"> BPM -</div>
                <div  class="text-line"> {{ arraytableforallvalues[0]?.bpm?arraytableforallvalues[0]?.bpm:"NA"}}</div>
              </div>
              <div class="text mt-0">
                <div class="text-line"> Key - </div>
              
              <div class="text-line"> {{arraytableforallvalues[0].sub_key?arraytableforallvalues[0].sub_key:"NA"}}  {{arraytableforallvalues[0].main_key===0?"Major":arraytableforallvalues[0].main_key===1?"Minor":""}} </div>
              </div>
            </div>
            <td class="col-md-4 wave mobi-hide-2" style="display: none;">
              <div _ngcontent-yrg-c82="" class="">
                <div _ngcontent-yrg-c82="" class="media-equlizer">
                  <app-waveform #waveformRef [arr]="srcforsound(arraytableforallvalues[0])"  (waveformLoaded)="onWaveformLoaded(i)" [loop_id]="arraytableforallvalues[0].loop_id" [items]="0"[passedValue]="arraytableforallvalues[0]" 
                  [slide]="sliderValue"  (sliderValueChange)="onSliderValueChanged($event)"  (musicEnded)="next(0)"></app-waveform>          </div>
              </div>
            </td>
            <div class="tags-rw">
                <div class=" tags-div">
                  <span class="tags hover" *ngIf="tablefortagsdisplay1"
                  ><a style="color: black;"  [routerLink]= "['/advancesearch/']" [queryParams]="{key_word: tablefortagsdisplay1}" >{{tablefortagsdisplay1}}</a> </span><span class="tags hover" *ngIf="tablefortagsdisplay2" 
                  ><a style="color: black;"  [routerLink]= "['/advancesearch/']" [queryParams]="{key_word: tablefortagsdisplay2}">{{tablefortagsdisplay2}}</a> </span><span class="tags hover" *ngIf="tablefortagsdisplay3"
                  ><a style="color: black;"[routerLink]= "['/advancesearch/']" [queryParams]="{key_word: tablefortagsdisplay3}">{{tablefortagsdisplay3}}</a> </span>
                </div>
            </div>

            <div class="mobil-btns-rw">
              <button class="download-btn"  (click)="downloadFile(arraytableforallvalues[0])"><img src="../../../../assets/img/download-icon .png"></button>
              <button class="" (click)="openshare(arraytableforallvalues[0])"><img src="../../../../assets/img/share-icon (1).png"></button>
            </div>

          </div>
        </div>
    </div>
  </div>
  
  
  
</div>



<div class="music-player"  *ngIf="showAudioPlayer"  >
        
        
  <div class="aWrap" data-src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3">
      <div>
          <input #slider class="range" style="width: 100%;" id="pi_input" type="range" [min]="0" [max]="timeupdate1()" [step]="steps" [(ngModel)]="sliderValue" (input)="onInput($event)"/>
      </div>
      <div class="col-md-4 col-sm-6 player-img">
          <div class="img-info-div">
          <img class="img" [src]="setimage(loop)"/>
          <div class="name">
          <h5 style="
  word-break: break-word;
            ">{{loop.title}} </h5>
          <span class="username-box" style="cursor: pointer;">@{{loop.username}}</span>
          </div>
          </div>
      </div>
      <div class="col-md-4 col-sm-6 media-player">
          <div class="btns d-flex media-player-btns">
              <div>
                  <div (click)="backward()"><img src="../../../assets/img/fast-backward.svg"/></div>
              </div>
              <div class="buttons" *ngIf="!loop.hidepausebutton"  (click)="playAudio(loop,player,$event)">
                  <button class="aPlay" ><span class="aPlayIco"><i class="fa fa-play" style="color: white;"></i></span></button>
              </div>
              
              <div class="buttons" *ngIf="loop.hidepausebutton" (click)="stopAudio(loop,player)" >
                  <button class="aPlay"><span class="aPlayIco"><i class="fa fa-pause" style="color: white;"></i></span></button>
              </div>
              <div>
                  <div (click)="forward()" ><img src="../../../assets/img/fast-forward.svg"/></div>
              </div>
          </div>
      </div>
      <div class="col-4 volume-btns">
          <div class="volume-div d-flex">
              <div *ngIf="!mute" class="volume-container" (click) ="Mute()">
              <span class="aVolIco"><i class="fa fa-volume-up icon"></i></span>
              </div>
              <div *ngIf="mute" class="volume-container"  (click) ="Unmute()">
                  <span class="aVolIco" >
                      <i class="fa fa-volume-off" style="color: black;"></i>
                  </span> 
              </div>
              <div  class="range-volume">
                  <input class="aVolume" id="volume" type="range" min="0" max="1" step="0.01" [(ngModel)]="volumeValue" (input)="onVolumeInput($event)" style="height: 5px;">
              </div>
          </div>
      </div>
  </div>
</div>
<div class="music-player"  *ngIf="showAudioPlayer1"  >
        
        
  <div class="aWrap" data-src="https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3">
      <div>
          <input #slider class="range" style="width: 100%;" id="pi_input" type="range" [min]="0" [max]="timeupdate()" [step]="steps" [(ngModel)]="sliderValue" (input)="onInput1($event)"/>
      </div>
      <div class="col-sm-4 player-img">
          <div class="img-info-div">
          <img class="img" [src]="setimage(loop)"/>
          <div class="name">
          <h5>{{loop.title}} </h5>
          <span class="username-box" style="cursor: pointer;">@{{loop.username}}</span>
          </div>
          </div>
      </div>
      <div class="col-md-5 col-sm-6 media-player">
          <div class="btns d-flex media-player-btns">
              <div>
                  <div (click)="backward1()"><img src="../../../assets/img/fast-backward.svg"/></div>
              </div>
              <div class="buttons" *ngIf="!loop.hidepausebutton"  (click)="playAudio1(loop,player,$event)">
                  <button class="aPlay" ><span class="aPlayIco"><i class="fa fa-play" style="color: white;"></i></span></button>
              </div>
              
              <div class="buttons" *ngIf="loop.hidepausebutton" (click)="stopAudio1(loop,player)" >
                  <button class="aPlay"><span class="aPlayIco"><i class="fa fa-pause" style="color: white;"></i></span></button>
              </div>
              <div>
                  <div (click)="forward1()" ><img src="../../../assets/img/fast-forward.svg"/></div>
              </div>
          </div>
      </div>
      <div class="col-3 volume-btns">
          <div class="volume-div d-flex">
              <div *ngIf="!mute" class="volume-container" (click) ="Mute1()">
              <span class="aVolIco"><i class="fa fa-volume-up icon"></i></span>
              </div>
              <div *ngIf="mute" class="volume-container"  (click) ="Unmute1()">
                  <span class="aVolIco" >
                      <i class="fa fa-volume-off" style="color: black;" ></i>
                  </span> 
              </div>
              <div  class="range-volume">
                  <input class="aVolume" id="volume" type="range" min="0" max="1" step="0.01" [(ngModel)]="volumeValue" (input)="onVolumeInput1($event)" style="height: 5px;">
              </div>
          </div>
      </div>
  </div>
</div>
<app-footer></app-footer>
