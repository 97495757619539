<app-header></app-header>
<div class="main-box card z-index-0">
<div class="col-md-6 col-10 mx-auto">
    <div class="infodiv">
    <h1 class="headingemail">Authenticate Account</h1>
    <p class="h4">An Message with the subject <b>"Verify your Loophead Account"</b> has been sent to : {{email}}</p>
    <p class="h4"><a class="link" (click)="verifyagain()"><b><u>Resend link</u></b></a></p>
    <button class="btn" (click)="check()">Next</button>
</div>
</div>
<!-- <div class="infodiv" *ngIf="!emailget && fullpageload">

    <p class="h4">First login and then come to this page </p>

</div> -->

</div>