<main>
    <div class="firstcontainer container">
        <div class="secondcontainer">
            <ul class="subscriptionul">
                <li class="page1 active"><span>1</span> Subscription Plan</li>
                <li class="page1 active"><span>2</span> Subscription Interval</li>
                <li class="page1"> <span>3</span> Payment</li>
            </ul>
        </div>
        <div class="subscription-wrapper">
            <div class="cols-double">
            <div class="left-col">
                <div class="insidebox1radio choose-plan">
                     <h2 class="font-w-normal">Select the duration of your plan. Longer plans offer better discount!</h2>
                    <Form [formGroup]="radiocheckforloophead">
                        <div class="premium-options">
                            <div class="form-group">
                                <div class="radio-custom">
                                    <input class="radiobtn" type="radio" id="monthly" name="radio" value="month"
                                        formControlName="radio">
                                    <label for="monthly" class="radiobtnlabel"> 1 Month Subscription : ${{monthlyprice}} /month
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <span class="discount-badge">-{{discount}}%</span>
                                <div class="radio-custom">
                                    <input class="radiobtn" type="radio" id="yearly" name="radio" value="year" 
                                        formControlName="radio">
                                   <label for="yearly" class="radiobtnlabel">  1 Year Subscription :${{yearrlydivide}}/month (Pay
                                    annually - ${{yearlyprice}}) </label>
                                </div>
                            </div>
                            <!-- <div class="form-group">
                          
                                <div class="radio-custom">
                                    <input class="radiobtn" type="radio" id="day" name="radio" value="day" 
                                        formControlName="radio">
                                   <label for="day" class="radiobtnlabel">  1 Year Subscription :${{yearrlydivide}}/month (Pay
                                    annually - ${{yearlyprice}}) </label>
                                </div>
                            </div> -->
                            <small class="form-text text-danger"
                            *ngIf="radiocheckforloophead.get('radio')?.hasError('required')">Please
                            select at-least one plan</small>
                        </div>

                        <div class="twobutton">
                            <button class="btn back btn-secondary" (click)="onclick()"   > Back</button>
                            <button class="btn btn-theme" (click)="onSubmit()"> Choose </button>
                        </div>
                    </Form>
                </div>
            </div>
            <!-- assets/img/stripe.jpg -->
            <div class="right-col">
                <div class="box2in1">
                    <h4>Accepted Payment Methods</h4>
                    <img src="assets/img/paypal.png" alt="">
                </div>
                <div class="box2in2">Premium plan: 1 year subscription
                    <h6>Unlimited Access</h6>
                    <ul class="list-check">
                    <li><p>Upload Unlimited Loops</p></li>
                    <li><p>Upload Unlimited Loop Kits </p></li>
                    <li><p>Sell Unlimited Loops and Loopkits</p></li>
                    <li><p>Accept PayPal Payments</p></li>
                    </ul>
                </div>
                <div class="box2in3">
                    <div class="ssl">
                        <div>
                            <img src="assets/img/secure.png" alt="">
                        </div>
                        <div>
                            <h5>SSL SECURE PAYMENT</h5>
                            <p>Your information is protected by 256-bit SSL encryption</p>
                        </div>
                    </div>

                </div>
                </div>
            </div>
        </div>
    </div>
</main>