<app-header *ngIf="!isMobile"></app-header>
<div class="header"  *ngIf="isMobile">
    <div class="container">
      <div class="row">
        <div class="navbar-translate logged-out col-sm-9 col-7">
          <div class="header-logo">
            <img src="./assets/img/logo1.png" href="index.html" height="60" class="header-logo-img">
            <a class="navbar-brand" href="index.html" rel="tooltip" data-placement="bottom">
              Loophead
            </a>
          </div>
        </div>
        <div class="col-sm-3 col-5 btn-div">
          <button class="header-btn" (click)="redirecttologin()">Login</button>
        </div>
      </div>
    </div>
  </div>
<div class="page-header">
  <div class="page-header-image" style="background-image:url(../../../../assets/img/background1.png)"></div>
  <div class="container my-auto" style="padding-top: 10%;">
    <div class="row">
      <div class="col-lg-5 col-md-8 col-12 mx-auto">
        <div class="card z-index-0" style="">
          <div class="card-body background-fff">
            <form role="form" class="text-start" [formGroup]="signupformforloophead" (ngSubmit)="onSubmit()">
              <div style="width: 100%; height: 100%; justify-content: flex-start; align-items: flex-start; gap: 4px; position:relative; display: inline-flex">
                <img style="width: 35px" src="./assets/img/loophead transparent logo_ 1.png" />
                <img style="width: 35px; filter: invert(96%) sepia(53%) saturate(2%) hue-rotate(209deg) brightness(0%) contrast(100%);
                fill:black;" src="./assets/img/Loophead_DarkBlue 1 (1).png" />
              </div>
              <div class="join-head">Sign Up</div>
              <div class="input-group-outline mt-5">
                <label for="username" class="form-label">Username</label>
                <!-- <span class="ml-1 text-danger">*</span> -->
                <input type="text" class="form-control" id="username" placeholder="Username" name="username"
                formControlName="username">
                <small class="form-text text-danger error"
                *ngIf=" signupformforloophead.get('username')?.touched && signupformforloophead.get('username')?.hasError('required')">
                Username is
                required</small>
              <small class="form-text text-danger error" *ngIf="signupformforloophead.get('username')?.hasError('pattern')">No
                white space is allowed</small>
                <small class="form-text text-danger">{{usernameerror}}</small>
  
              </div>
              <!-- <div class="form-group col-sm-6">
                <label for="username">User Name</label><span class="ml-1 text-danger">*</span>
                <input type="text" class="form-control" id="username" placeholder="@" name="username"
                  formControlName="username">
                <small class="form-text text-danger"
                  *ngIf=" signupformforloophead.get('username')?.touched && signupformforloophead.get('username')?.hasError('required')">
                  Username is
                  required</small>
                <small class="form-text text-danger" *ngIf="signupformforloophead.get('username')?.hasError('pattern')">No
                  white space is allowed</small>
                  <small class="form-text text-danger">{{usernameerror}}</small>
    
              </div> -->
              <div class="input-group-outline mt-5">
                <label for="email">Email</label>
                <input type="email" class="form-control" id="email" placeholder="Email" name="email"
                formControlName="emailerr">
                <small class="form-text text-danger error"
                  *ngIf=" signupformforloophead.get('emailerr')?.touched && signupformforloophead.get('emailerr')?.hasError('required')">Email
                  is
                  required</small>
                <small class="form-text text-danger error"
                  *ngIf=" signupformforloophead.get('emailerr')?.touched && signupformforloophead.get('emailerr')?.hasError('email')">Enter
                  valid email address</small>
              </div>
              <div class="input-group-outline mt-5">
                <label for="password">Password</label>
                <input type="password" autocomplete="new-password" name="password" id="password" placeholder="Password"
                class="form-control" formControlName="password" (input)="passwordConfirm()">
              <!-- <span style="display: flex; justify-content: space-between; flex-direction: column;"> -->
                <small class="form-text text-danger error"
                  *ngIf=" signupformforloophead.get('password')?.touched && signupformforloophead.get('password')?.hasError('required')">Password
                  is
                  required</small>
                <small class="form-text text-danger error"
                  *ngIf=" signupformforloophead.get('password')?.touched && signupformforloophead.get('password')?.hasError('minlength')">Minimum
                  6 characters required</small>
                <small class="form-text text-danger error"
                  *ngIf="  signupformforloophead.get('password')?.hasError('pattern')">Password should include at least
                  one uppercase letter and one special character</small>
              <!-- </span> -->
                </div>
              <div class="input-group-outline mt-5">
            <label for="cpassword">Verify Password</label>
            <input type="password" name="password" id="confirmpasserror" placeholder=" Confirm Password" class="form-control"
            (input)="passwordConfirm()" formControlName="confirmpassword">
          <!-- <span style="display: flex; justify-content: space-between; flex-direction: column;"> -->
            <small class="form-text text-danger error"
              *ngIf=" signupformforloophead.get('confirmpassword')?.touched && signupformforloophead.get('confirmpassword')?.hasError('required')">Confirm
              Password is
              required</small>
            <small class="form-text text-danger error"
              *ngIf=" signupformforloophead.get('confirmpassword')?.touched && signupformforloophead.get('confirmpassword')?.hasError('minlength')">Minimum
              6 characters required</small>
             <small class="form-text text-danger error"
             *ngIf="confirmpasserror!=''">Passsword and Confirm
              Password didn't match.</small> 
            <small class="form-text error text-danger error"
              *ngIf="  signupformforloophead.get('confirmpassword')?.hasError('pattern')">Password should include at
              least one uppercase letter and one special character</small>
          <!-- </span> -->
            </div>
              
            <div class="form-check sign-up mt-5">
              <!-- <div> -->
                <input type="checkbox" class="form-check-input" id="tickbox" formControlName="tickbox">
              <!-- </div> -->
              <div  style="text-align: start; line-height: 20px; display: flex;flex-wrap: wrap;width: 100%;column-gap: 5px;">
                <div style="color: #7b809a; font-size: small">I agree to Loophead's </div><div class="terms" style="font-size: small;" (click)="terms()" > Terms </div><div style="color: #7b809a; font-size: small;"> and </div><div class="terms" style="font-size: small;" (click)="Privacy()"> Privacy Policy </div>
              </div>
            </div>
              <div>
                <small class="form-text text-danger checkboxtick error"
                  *ngIf=" signupformforloophead.get('tickbox')?.touched && signupformforloophead.get('tickbox')?.hasError('required')">
                  Please check this box to proceed further
                </small>
              </div>
              <div class="button-submit">
              <a class="btn btn-neutral" (click)="onSubmit()" style="border-radius: 10px; padding: 12px 50px; background-color: black">
                <p style="font-weight: 700; font-size: medium; color: #FFFFFF; margin: 0; text-transform: capitalize;">Continue</p>
              </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>