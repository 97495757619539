import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { commonservice } from 'src/app/allServices/commonservice';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {
  link: any;

  constructor( public matDialog: MatDialog,@Inject(MAT_DIALOG_DATA) public data: any, private toastr: ToastrService,private clipboard: Clipboard, public common: commonservice) { }
  
  ngOnInit(): void {
  if(this.data.data==='pack')
  {
  this.link = `https://loophead.com/detail-pack?pack_id=${this.data.link}`

  }else{  
    this.data.data="loop"
    this.link = `https://loophead.com/detail-loop?loop_id=${this.data.link}`
  }
}
  cancel(){
    this.matDialog.closeAll()
  }
  copyToClipboard(link: string) {
    if (this.clipboard.copy(link)) {
      // Success message (optional)
      this.toastr.success('Link Copied', "", { positionClass: 'toast-bottom-right' })
    } else {
      // Error message (optional)
      this.toastr.error('Failed to copy the link to clipboard', "", { positionClass: 'toast-bottom-right' })
      //console.error('Failed to copy the link to clipboard');
    }
  }
}
