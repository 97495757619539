import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public sharedData: any; // Define a property to store the data

  public setSharedData(data: any): void {
    this.sharedData = data;
  }
}
