import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-responsiveheader',
  templateUrl: './responsiveheader.component.html',
  styleUrls: ['./responsiveheader.component.css']
})
export class ResponsiveheaderComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }
  redirecttologin() {
    this.router.navigate(['/login']);
  }
  redirecttoregister() {
    this.router.navigate(['/register']);
  }
}
