import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import { UploadClickComponent } from 'src/app/CLIENT_PAGES/MODAL/upload-click/upload-click.component';
import * as CryptoJS from 'crypto-js';
import { HttpClient } from '@angular/common/http';
import { InvoiceComponent } from 'src/app/CLIENT_PAGES/MODAL/invoice/invoice.component';
@Component({
  selector: 'app-roughtrialforcheck',
  templateUrl: './roughtrialforcheck.component.html',
  styleUrls: ['./roughtrialforcheck.component.css']
})
export class RoughtrialforcheckComponent implements OnInit {
   tableData: any;
  pagercount: any = [];
  pagenolist: any;
  pageno: any = 1;
  paginationempty = false;
  totallength: any;
  noresultshow: any;
  termsofpreference: any
  looptype: any;
  hidepagination: boolean = true;
  record: any;
  total: any;
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  pagenocount: any;
  arrayfortable: any = []
  durationofaudio: any;
  hidepausebutton: boolean = true;
  constructor(private router: Router, public api: ApiServiceService, private toastr: ToastrService, public common: commonservice, public matDialog: MatDialog, private http: HttpClient) { }
  

 


  ngOnInit(): void {
    this.getallinfoofloops();

    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
      if (!res) {
        this.getUserDetail();
      }
      let resa = JSON.parse(res)
      if (resa) this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
    })
  }


  getallinfoofloops() {
    this.common.showLoader();

    let sendingdata = {
      
      "page_no":1,
      "sort_on":"created_on",
      "row_size":"30",
      "sort_type":"DESC",
      "loop_id": 773
  
  }
    this.api.postApiHit(`/user/download_archive`,sendingdata , VARS.ClIENT_TYPE).then((res: any) => {

     
      this.arrayfortable = res.response.data.pagging_list

      // ////console.log(res)

      if (res.response.status == 1) {
        if (this.paginationempty == true) {
          this.pagercount = [];
        }
        this.pagenolist = res.response.data.total_pages + 1
        for (let i = 1; i < this.pagenolist; i++) {
          this.pagercount.push(i);

        }

        this.record = res.response.data.pagging_list.length;
        this.total = res.response.data.total_records;
        this.record = this.pageno - 1 + "1";
        if (this.record == "01") {
          this.record = 1;
        }
        this.pagenocount = 10 * (this.pageno - 1) + res.response.data.pagging_list.length


        if (res.response.data.total_pages == 1) {
          this.hidepagination = false;

        }

        this.totallength = this.pagercount;


        this.tableData = res.response.data.pagging_list;


        if (res.response.data.total_records == 0) {
          this.noresultshow = true;
        }

        this.common.hideLoader();





      } else if (res.response.status == 0) {

        this.common.hideLoader();

        this.noresultshow = true;
      } else {
        let errorthroughapi = res.response.errors;
        let error = [];



        this.common.hideLoader();

        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })

        }
      }
    }, async e => {

      this.common.hideLoader();

    });
  }

  paginationget(pageinfo: any) {
    this.paginationempty = true;

    this.pageno = pageinfo;
    // this.tableData = false;

    this.getallinfoofloops();



  }

  callfree(item: any) {
    // ////console.log(item);
    if (item == 1) {
      this.termsofpreference = "Royalty Free";

    } else {
      this.termsofpreference = "50/50 Master + Publishing Split"

    }
  }
  callpremium(item: any) {
    // ////console.log(item);
    if (item == 1) {
      this.termsofpreference = "Royalty Free";

    } else if (item == 2) {
      this.termsofpreference = "50/50 Master + Publishing Split"

    } else {
      this.termsofpreference = "Exclusive";
    }
  }
  loopinfo(item: any) {
    if (item == 1) {
      this.looptype = "Loop";

    } else if (item == 2) {
      this.looptype = "Loop Kit"

    }
  }


  getUserDetail() {
    this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res)
          this.api.geturltoredirectexceptemailverifydashboard(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }

  redirecttoloop() {
    this.openDialog();
  }


  detailpageofloop(value: any) {

    this.encryptUsingAES256(value);

  }

  openDialog(): void {
    let dialogue = this.matDialog.open(UploadClickComponent, { panelClass: 'forgot-password', disableClose: true });
    dialogue.afterClosed().subscribe(result => {

    });
  }

  encryptUsingAES256(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);


    this.router.navigate(['detail-loop'], { queryParams: { loop_id: this.encrypted } });


  }

  redirecttoinvoice(item: any, items: any) {
    this.opendialogueforverificatio(item, items)
  }

  opendialogueforverificatio(item: any, items: any): void {
    let dialogue = this.matDialog.open(InvoiceComponent, { panelClass: 'forgot-password', disableClose: true, data: { pagename: item, paymentid: items } });
    dialogue.afterClosed().subscribe(result => {





    });



  }

  srcforimage(item: any) {
    // this.router.navigate(['profile/login']);
    // this.router.navigate[""]
  }





}


