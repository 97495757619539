<div>
    <div>
      <ul class="name">
  
        <li>
          <form class="displayinline">
            <input type="search" [(ngModel)]="validationforsearch" class="form-control rounded form-control-sm " placeholder="Search"
              aria-label="Search" name="searchsubscription" id="searchsubscription" aria-describedby="search-addon" />
            <button class="btn btn-theme" type="submit" (click)=" searchClick()"><i class="fa fa-search"></i></button>
          </form>
        </li>
        <li>
          <button class="btn btn-theme text-right " (click)="encryptUsingAES256a()">Go Back</button>
        </li>
       
        
      </ul>
    </div>
  </div>

<div>
    <!-- <h4 class="mb-4">Download Archives </h4> -->

    <div class="uploads-wrapper" *ngFor="let item of tableData">
        <div class="uploaded-item archive-item "><span class="user-img
          "><img [src]="srcforimage(item)"></span><span class="user-name hover"
                (click)="redirecttoprofilepage(item.user_type_id)">@{{item.username}}</span><span
                class="upload-date">{{item.created_on| date: 'h:mm a ,dd/MM/yyyy
                ' }} </span><span class="download-invoice hover " *ngIf="item.is_paid_loop == 1 && item.payment_id"
                (click)="redirecttoinvoice(item.payment_id)"><i class="fa fa-file"></i></span>
        </div>
    </div>
</div>


<div *ngIf="noresult" class="noresul text-center">No records to display </div>

<div *ngIf="norecordfound" class="text-center">No records to display </div>

<div class="text-center" *ngIf="!noresult">
  <ul class="paginationlist">
    <li class="text-info" *ngIf="!norecordfound">Showing {{record}} to {{pagenocount}} records of {{total}}</li>
    <!-- <li class="text-info"> {{total}}</li> -->
    <ul class="nextulpagination" *ngIf="!hidepagination">

      <!-- <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item == pageno}"
        *ngFor="let item of pagercount" id="liststyle{{item}}" (click)="paginationget(item)">
        {{item}}

      </li> -->
      <li><jw-pagination class="hover" [items]="items" (changePage)="onChangePage($event)"
    
        [pageSize]=1
        [maxPages]="7"
        
        ></jw-pagination></li>
    </ul>

  </ul>


</div>