import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL } from 'src/app/allServices/constant.service';


@Component({
  selector: 'app-globalsetting',
  templateUrl: './globalsetting.component.html',
  styleUrls: ['./globalsetting.component.css'],

})
export class GlobalsettingComponent implements OnInit {

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService,public common: commonservice) {

    this.getapicallforglobalsetting();

  }
  getapicallforglobalsetting() {
    this.common.showLoader();
    let tokkenget = localStorage.getItem("loggedintoken");

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    
    this.http.get(APIURL.BASE_URL+APIURL.GET_GLOBAL_SETTING, { headers }).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        
        
        // let resdata = res.response.data;
        // ////console.log(resdata.length);
        // ////console.log(res);
        // let arr = [];
        // for (var j = 1; j < resdata.length; j++) {

        //   // let jk = error.push(errorthroughapi);
        //   // this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        //   arr.push(res.response.data[j].meta_value);
        //   ////console.log(res.response.data[j].meta_value);
        // }


        // ////console.log(arr);

        this.validateemail.emailcheck = res.response.data[1].meta_value,
          this.validatetopchart.topchartcheck = res.response.data[2].meta_value,
          this.validationinstagram.instagramcheck = res.response.data[3].meta_value,
          this.validationtwitter.twittercheck = res.response.data[4].meta_value,
          this.validationdiscord.discordcheck = res.response.data[5].meta_value,
          this.validaterecommendloop.loopcheck = res.response.data[6].meta_value
          this.validationyoutube.youtubecheck = res.response.data[7].meta_value
          // this.validationcommisoncut.commisioncheck = res.response.data[8].meta_value

      } else {
        this.common.hideLoader();
        this.toastr.error(res.response.msg, "Error", { positionClass: 'toast-bottom-right' });
        // let errorthroughapi = res.response.errors;
        // let error = [];
        // for (var i = 0; i < errorthroughapi.length; i++) {
        //   let jk = error.push(errorthroughapi);
        //   this.toastr.error(res.response.errors[i], "Error", { positionClass: 'toast-bottom-right' })
        // }

      }
    }, async e => {
      this.common.hideLoader();
      // ////console.log('Error', e);
      this.toastr.error("Error in Api", "Error", { positionClass: 'toast-bottom-right' });

    });
  }



  // Post call method 

  apicallforglobalsetting() {
    this.common.showLoader();
    let tokkenget = localStorage.getItem("loggedintoken");

    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    // ////console.log("api calls");
    let sendingData = {

      "MetaKeyToEdit": {
        1: this.validateemail.emailcheck,
        2: this.validatetopchart.topchartcheck,
        3: this.validationinstagram.instagramcheck,
        4: this.validationtwitter.twittercheck,
        5: this.validationdiscord.discordcheck,
        6: this.validaterecommendloop.loopcheck,
        7: this.validationyoutube.youtubecheck,
        8: this.validationcommisoncut.commisioncheck
      }
    }
    // ////console.log(APIURL.BASE_URL + APIURL.POST_GLOBAL_SETTING);
    this.http.post(APIURL.BASE_URL+APIURL.POST_GLOBAL_SETTING, sendingData, { headers }).subscribe(async (res: any) => {
      this.disabledvalue = false;
      if (res.response.status == 1) {
        this.common.hideLoader();
        this.displayname = "none";
        let successmess = res.response.msg;
        this.getapicallforglobalsetting();

        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
        // ////console.log(res.response.msg);
        // let successmessage = [];
        // for (var i = 0; i < successmess.length; i++) {
        //   let pushintoarray = successmessage.push(successmess);

        // }
        this.displayname = "none";
        this.submitbutton.submitresponse = "Save";
        this.submitbutton.iswaitingoption = false;


      } else {
        this.common.hideLoader();
        this.displayname = "none";
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

        this.displayname = "none";
        this.submitbutton.submitresponse = "Save ";
        this.submitbutton.iswaitingoption = false;
      }
    }, async e => {
      this.common.hideLoader();
      this.displayname = "none";
      // ////console.log('Error', e);
      this.toastr.error("Error please try again later", "Error", { positionClass: 'toast-bottom-right' });
    });
  }

  ngOnInit(): void {
  }

  // variable define
  instagramerror = "";
  twittererror = "";
  discorderror = "";
  looperror = "";
  youtubeerror = "";
  emailerror = "";
  topcharterror = "";
  displayname = "none";
  disabledvalue = false;
  commisionvalue =""
  isinstagramvaliadte = false;
  istwittervaliadte = false;
  isdiscordvalidate = false;
  isloopvalidate = false;
  isemailvalidate = false;
  istopchartvalidate = false;
  isyoutubevalidate = false;
  iscommisionvalid = false


  validationinstagram = {
    instagramcheck: "",
    isinstagramvalid: false

  }

  validationtwitter = {
    twittercheck: "",
    istwittervalid: false

  }

  validationdiscord = {
    discordcheck: "",
    isdiscordvalid: false

  }
  validationyoutube = {
    youtubecheck: "",
    youtubevalid: false

  }
  validationcommisoncut = {
    commisioncheck: "",
    commisionvalid: false

  }

  validaterecommendloop = {
    loopcheck: "",
    isloopvalid: false
  }


  validateemail = {
    emailcheck: "",
    isemailvalid: false
  }

  validatetopchart = {
    topchartcheck: "",
    istopchartvalid: false
  }

  submitbutton = {
    submitresponse: "Save ",
    iswaitingoption: false
  }

  // validation and run on click 

  onsave() {

    // foremail

    if (this.validateemail.emailcheck.length !== 0) {
      const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.validateemail.emailcheck.match(email)) {
        this.validateemail.isemailvalid = true
        this.emailerror = "";
        // this.emailerror = " Email is valid";
        this.isemailvalidate = true;
      } else {
        this.validateemail.isemailvalid = false;
        this.emailerror = "Please enter valid email";
        // ////console.log("error");
        this.isemailvalidate = false;
      }
    } else {
      this.validateemail.isemailvalid = false;
      this.emailerror = "Email is required";
      // ////console.log("error");
      this.isemailvalidate = false;
    }

    // for topchart 

    let topchart = this.validatetopchart.topchartcheck;
    // ////console.log(topchart);
    if (topchart != null && topchart.length !== 0) {

      if (isNaN(parseInt(topchart)) || (parseInt(topchart) < 0)) {
        this.validatetopchart.istopchartvalid = false;
        this.topcharterror = "Only positive no is valid";
        this.istopchartvalidate = false;
      } else {
        if (parseInt(topchart) === 0) {
          this.validatetopchart.istopchartvalid = false;
          this.topcharterror = "Cannot set zero or below zero";
          this.istopchartvalidate = false;
        } else {
          this.validatetopchart.istopchartvalid = true
          this.topcharterror = "";
          this.istopchartvalidate = true;
        }

      }
    } else {
      this.validatetopchart.istopchartvalid = false;
      this.topcharterror = "Top chart is required in number";
      this.istopchartvalidate = false;
    }


    // forinstangramurl 

    // const instagramurlformat = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;

    if (this.validationinstagram.instagramcheck.length !== 0) {
      const instagramurlformat = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im;

      if (this.validationinstagram.instagramcheck.match(instagramurlformat)) {
        this.validationinstagram.isinstagramvalid = true;
        this.instagramerror = "";
        this.isinstagramvaliadte = true;
      } else {
        this.validationinstagram.isinstagramvalid = false;
        this.instagramerror = "Please enter valid instagram link";
        this.isinstagramvaliadte = false;
      }
    } else {
      this.validationinstagram.isinstagramvalid = false;
      this.instagramerror = "Instagram url is required";
      this.isinstagramvaliadte = false;
    }

    if (this.validationyoutube.youtubecheck.length !== 0) {
      const youtubeurlformat = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

      if (this.validationyoutube.youtubecheck.match(youtubeurlformat)) {
        this.validationyoutube.youtubevalid = true;
        this.youtubeerror = "";
        this.isyoutubevalidate = true;
      } else {
        this.validationyoutube.youtubevalid = false;
        this.youtubeerror = "Please enter valid youtube link";
        this.isyoutubevalidate = false;
      }
    } else {
      this.validationyoutube.youtubevalid = false;
      this.youtubeerror = "Youtube url is required";
      this.isyoutubevalidate = false;


    }
    if (this.validationcommisoncut.commisioncheck.length !== 0) {
      let valueofcommisioncheck = this.validationcommisoncut.commisioncheck;

      if (isNaN(parseInt(valueofcommisioncheck)) || (parseInt(valueofcommisioncheck) < 0) || (parseInt(valueofcommisioncheck) > 100)) {
        this.validationcommisoncut.commisionvalid = false;
        this.commisionvalue = "Only 0 to 100 no is valid";
        this.iscommisionvalid = false;
      } else {
        this.validationcommisoncut.commisionvalid = true;
        this.commisionvalue = "";
        this.iscommisionvalid = true;
      }
    } else {
      this.validationcommisoncut.commisionvalid = false;
      this.commisionvalue = "Partner Fees Percentage is required";
      this.iscommisionvalid = false;
    }

    // for twitterurl 

    // const twitterurlformat = /https?:\/\/twitter\.com\/(#!\/)?[a-z0-9_]+$/i;
    if (this.validationtwitter.twittercheck.length !== 0) {
      const twitterurlformat = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;

      if (this.validationtwitter.twittercheck.match(twitterurlformat)) {
        this.validationtwitter.istwittervalid = true
        this.twittererror = "";
        this.istwittervaliadte = true;
      } else {
        this.validationtwitter.istwittervalid = false;
        this.twittererror = "Please enter valid twitter link";
        this.istwittervaliadte = false;
      }
    } else {
      this.validationtwitter.istwittervalid = false;
      this.twittererror = "Twitter url is required";
      this.istwittervaliadte = false;
    }


    // fordiscordurl 

    if (this.validationdiscord.discordcheck.length !== 0) {

      const discordurlformat = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

      if (this.validationdiscord.discordcheck.match(discordurlformat)) {

        this.validationdiscord.isdiscordvalid = true
        this.discorderror = "";
        this.isdiscordvalidate = true;
      } else {
        this.validationdiscord.isdiscordvalid = false;
        this.discorderror = "Please enter valid discord link";
        this.isdiscordvalidate = false;

      }

    }
    else {
      this.validationdiscord.isdiscordvalid = false;
      this.discorderror = "Discord url is required";
      this.isdiscordvalidate = false;
    }

    // formaxiumnoofloop 

    let loop = this.validaterecommendloop.loopcheck;
    // ////console.log(loop);
    if (loop != null && loop.length !== 0) {

      if (isNaN(parseInt(loop)) || (parseInt(loop) < 0)) {
        this.validaterecommendloop.isloopvalid = false;
        this.looperror = "Only positive no is valid";
        this.isloopvalidate = false;
      } else {
        if (parseInt(loop) === 0) {
          this.validaterecommendloop.isloopvalid = false;
          this.looperror = "Cannot set zero or below zero";
          this.isloopvalidate = false;
        } else {
          this.validaterecommendloop.isloopvalid = true
          this.looperror = "";
          this.isloopvalidate = true;
        }

      }
    } else {
      this.validaterecommendloop.isloopvalid = false;
      this.looperror = "Listing is required in number";
      this.isloopvalidate = false;
    }

    // checked all are true to hit api 

    if ((this.isemailvalidate == true) && (this.istopchartvalidate == true) && (this.isinstagramvaliadte == true) && (this.istwittervaliadte == true) && (this.isdiscordvalidate == true) && (this.isloopvalidate == true) && (this.isyoutubevalidate == true) ) {
      // this.toastr.success("api will hit", "Success", { positionClass: 'toast-bottom-right' });
      // && (this.iscommisionvalid == true)
      // alert(this.submitbutton.iswaitingoption);
      this.submitbutton.submitresponse = "Loading...";
      this.submitbutton.iswaitingoption = true;
      this.disabledvalue = true;
      // this.displayname = "";
      this.apicallforglobalsetting();

    } else {
      // this.toastr.error("api will not hit", "Error", { positionClass: 'toast-bottom-right' });
    }


  }

}
