import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastrService } from 'ngx-toastr/toastr/toastr.service';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, VARS } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {
  email: any = "";
  emailget: any;
  fullpageload: any = false;

  constructor(public api: ApiServiceService, private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, public common: commonservice,) { }

  ngOnInit(): void {
    this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
// console.log(res);

    //   if (!res) {
    //     this.getUserDetail();
    //   }
    //   let resa = JSON.parse(res)
    //   // ////console.log(resa);
      
    //   if (resa) {
        // this.api.geturltoredirectexceptemailverify(resa.is_on_boarding_complete, resa.steps_completed);
        // ////console.log(resa);
        this.email = JSON.parse(res);
    this.apihitforresendverification();
    // this.emailget = true;
        // this.fullpageload = true;
        // this.getUserDetail();
      // }
    })
    
  }

  getapiforallinfo() {
    let tokkenget = localStorage.getItem("clientintoken");
    let sendingData = {

    }



    const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    // ////console.log(headers);
    this.http.post(APIURL.BASE_URL + `/user/get_logged_in_user_details`, sendingData, { headers }).subscribe(async (res: any) => {
      this.fullpageload = true;
      if (res.response.status == 1) {
        // ////console.log(res);
        this.api.geturltoredirectexceptemailverify(res.response.data.is_on_boarding_complete, res.response.data.steps_completed);
        this.email = res.response.data.temp_email;
        this.emailget = true;



      } else {

        this.emailget = false;
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {

          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }

      }
    }, async e => {
      this.emailget = false;
      // ////console.log('Error', e);


    });

  }

  apihitforresendverification() {

    let sendingData = {
      "email": this.email
    }
    this.common.showLoader();
    this.http.post(APIURL.BASE_URL + `/user/resend_verification_mail`, sendingData).subscribe(async (res: any) => {
      if (res.response.status == 1) {
        // ////console.log(res);

        this.common.hideLoader();


        this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
      } else {
        // ////console.log(res.response.errors);
        // ////console.log(res);
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        for (var i = 0; i < errorthroughapi.length; i++) {
          // let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })

        }

      }
    }, async e => {
      this.common.hideLoader();
      // ////console.log('Error', e);


    });





  }

  checkemail() {
    // this.getUserDetail();
    this.apihitforredirecttodashboard();
  }


 

  apihitforredirecttodashboard() {
    
    // let tokkenget = localStorage.getItem("clientintoken");
    // let sendingData = {

    // }


    // this.common.showLoader();
    // const headers = { 'Authorization': `Bearer ${tokkenget}`, 'My-Custom-Header': 'foobar' };
    // // ////console.log(headers);
    // this.http.post(APIURL.BASE_URL + `/user/complete_registration_steps`, sendingData, { headers }).subscribe(async (res: any) => {

    //   if (res.response.status == 1) {

        
    //     // this.router.navigate(['/dashboard']);
    //     // ////console.log(res);
    //     this.getUserDetail();
    //     this.common.hideLoader();

    //   } else {

        

    //     let errorthroughapi = res.response.errors;
    //     let error = [];
    //     for (var i = 0; i < errorthroughapi.length; i++) {
          
    //       this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
    //       this.common.hideLoader();
    //     }

    //   }
    // }, async e => {
    //   this.common.hideLoader();
    //   ////console.log('Error', e);


    // });



  }



  verifyemail() {
    // ////console.log("red");
    this.apihitforresendverification();
  }

  getUserDetail() {
    this.common.showLoader();
    this.api.postApiHit(APIURL.user_get_logged_in_user_details, {}, VARS.ClIENT_TYPE).then((res: any) => {
      this.common.hideLoader();
      if (res.response.status) {
        this.common.hideLoader();
        this.common.setDataInCookie(VARS.Login_Client_data, res.response.data);
        this.common.getDataInCookie(VARS.Login_Client_data).then((res: any) => {
          let resa = JSON.parse(res)

          this.api.geturltoredirectexceptemailverify(resa.is_on_boarding_complete, resa.steps_completed);
        })
      }
    })
  }






}
