import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CONSTNAME } from 'src/app/allServices/constant.service';

@Component({
  selector: 'app-upload-click',
  templateUrl: './upload-click.component.html',
  styleUrls: ['./upload-click.component.css']
})
export class UploadClickComponent implements OnInit {
  loop: any;
  loopkit: any;
  constructor(private router: Router,private matDialog: MatDialog,) { }

  ngOnInit(): void {
   this.loop =   CONSTNAME.REDIRECT_CLIENT_ACCOUNT_uploadloop
   this.loopkit =  CONSTNAME.REDIRECT_CLIENT_ACCOUNT_uploadloopkit
  }

  redirecttoloopkit() {
    this.router.navigate([this.loopkit]);
    this.matDialog.closeAll()
  }
  redirecttoloop() {
    this.router.navigate([this.loop]);
    this.matDialog.closeAll()
  }
    

  cancel(){
    this.matDialog.closeAll()
  }

}
