<!-- <div id="image-cropper">
  <div class="cropit-preview"></div>
  
  <input type="range" class="cropit-image-zoom-input" />
  
  <input type="file" class="cropit-image-input" />
  <div class="select-image-btn">Select new image</div>
</div> -->
<div (click)="cancel()"class="modal-head">
  <div style="font-size: 24px;
  font-weight: 700;">Profile Picture</div>
      <img src="../../../../assets/img/x-close.svg"/>
      </div>
<div class="img-container">
  <img class="img" #image [src]="imageSource" crossorigin>
</div>
<!-- <img [src]="imageDestination" class="img-preview"> -->
<div style="    text-align: end;
margin-top: 24px;
">
<button class="btn btn-submit text-end" (click)="submit()">Upload</button>
</div>