<!-- <div class="delete">
  <button type="button" (click)="cancel()" class="close"><span aria-hidden="true">×</span></button>
  <h4 class="mt-0 mb-0">Delete Account</h4>
</div> -->
<mat-divider></mat-divider>
<div class="modal-body text-center delete-modal">
  <div _ngcontent-ysd-c142="" class="text-start" style="width: 100%; height: 100%; justify-content:center; align-items: flex-start; gap: 4px; display: inline-flex;">
    <img _ngcontent-ysd-c142="" src="../../../../assets/img/logo.png" style="width: 50px;">
    <img _ngcontent-ysd-c142="" src="../../../../assets/img/logo-secondary.png" style="width: 50px; filter: invert(96%) sepia(53%) saturate(2%) hue-rotate(209deg) brightness(0%) contrast(100%);
    fill:black;">
  </div>
  <h4 class="mt-0 mb-0">Delete User</h4>
<p class="h4 mt-3 mb-3">Are you sure you want to delete your account?</p>
  <div class="delete-btns">
    <button class="btn red-btn" (click)="deleteaccount()">Yes, delete</button> 
    <button class="btn cncl mb-3" (click)="cancel()" >Cancel</button>
  </div>
</div>