import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { StaticService } from 'src/app/sevicesall/static.service';
import { Location } from '@angular/common';
import {  SharedService } from 'src/app/allServices/data-sharing.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  // arrayforradio :any[];
  advancesearch = StaticService.advancesearch();
  // arrayformainkey  :any[];
  selectedKey: string | null = null;
  selectedKey1: string | null = null;
  searchBy: any;
  active: boolean;
  filterForm: FormGroup;
  usersearch: any;
  loopsearch: any;
  loopkitsearch: any;
  check: string;
  tableData: any;
  totalRecords: any;
  isPopupOpen: boolean = true; 
  bpm:any
  min_bpm: any;
  max_bpm: any;
  exact_bpm: any;
  radio1: boolean = false;
  radio2: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private fb: FormBuilder,  public dialogRef: MatDialogRef<FilterComponent>,private sharedService: SharedService,public common: commonservice, private http: HttpClient, private toastr: ToastrService, public router: Router, private route: ActivatedRoute, private el: ElementRef, public api: ApiServiceService, public matDialog: MatDialog,private location: Location) { }

  ngOnInit(): void {
    // ////console.log(this.route.snapshot.queryParamMap.get('sub_key'));
    
    this.selectedKey = null;
    this.selectedKey1 = null;
  
    const subKeyQueryParam = this.route.snapshot.queryParamMap.get('sub_key');
    const mainKeyQueryParam = this.route.snapshot.queryParamMap.get('main_key');
  
    // Check if query parameters are not null or undefined before assigning values
    if (subKeyQueryParam !== null && subKeyQueryParam !== undefined && subKeyQueryParam !== '') {
      this.selectedKey = subKeyQueryParam;
    }
  
    if (mainKeyQueryParam !== null && mainKeyQueryParam !== undefined && mainKeyQueryParam !== '') {
      this.selectedKey1 = mainKeyQueryParam;
    }
    // this.advancesearch.value.min_bpm = this.route.snapshot.queryParamMap.get('min_bpm'),
    // this.advancesearch.value.max_bpm = this.route.snapshot.queryParamMap.get('max_bpm');
    // this.advancesearch.value.exact_bpm =  this.route.snapshot.queryParamMap.get('exact_bpm');
    // this.advancesearch.value.bpm = this.route.snapshot.queryParamMap.get('bpm_range')
    this.filterForm = this.fb.group({
      exact_bpm: this.route.snapshot.queryParamMap.get('exact_bpm'),
      max_bpm:this.route.snapshot.queryParamMap.get('max_bpm'),
      min_bpm: this.route.snapshot.queryParamMap.get('min_bpm'),
      // bpm: this.route.snapshot.queryParamMap.get('bpm_range')
    });
        //  ////console.log(this.selectedKey);
        }
        
         


  onButtonClick(item: string,event:any): void {
    event.preventDefault();
    // Update the selectedKey when a button is clicked
    this.selectedKey = item;
  }
  onButtonClick1(item: string): void {
    // Update the selectedKey when a button is clicked
    this.selectedKey1 = item;
  }
  apply() {
    // ////console.log(this.minValue,this.maxValue)
    

    // ////console.log(this.advancesearch.value);
    // if (this.errorforbpm == false) {
  // this.common.showLoader()
  if(this.data.name==="search-by-tag"){}
  this.route.queryParams.subscribe(params => {
        this.searchBy = params['key_word'];
      });
      this.active = false;
      let sendingdata: any =
      {
        "page_no": 1,
        "sort_on": 1,
        "row_size": "10",
        "sort_type": "DESC",
        "key_word": this.searchBy

      }
      if (this.usersearch) {
        sendingdata["users_search"] = 1
      }else if (this.loopkitsearch) {
        sendingdata["loopkits_search"] = 1
      } else {
        sendingdata["loop_search"] = 1
      } 
      if (this.selectedKey1 !== "") {
        sendingdata["main_key"] = this.selectedKey1
      }
      if (this.selectedKey !== "") {
        sendingdata["sub_key"] = this.selectedKey
      }
      if(this.advancesearch.value.bpm==0){
        sendingdata["bpm_range"] = this.advancesearch.value.bpm
        sendingdata["exact_bpm"] = this.advancesearch.value.exact_bpm
      }
      if(this.advancesearch.value.bpm==1){
        sendingdata["bpm_range"] = this.advancesearch.value.bpm
        sendingdata["max_bpm"] = this.advancesearch.value.max_bpm
        sendingdata["min_bpm"] =this.advancesearch.value.min_bpm
      }
      this.router.navigate(['advancesearch/'], { queryParams: sendingdata });
      
      this.api.forclientgettokenpublic(`/user/advance_search`, sendingdata).then((res: any) => {



        if (res.response.status == 1) {
          this.common.hideLoader();
          // ////console.log(res)
          this.check = ""
          this.totalRecords = res.response.data.total_records;
          this.check = res.response.data.pagging_list
          this.active = true;
          // this.sharedService.setSharedData(res);
          // ////console.log(this.check);
          
          this.dialogRef.close({result:true});
          // Other code
        } else {
          this.common.hideLoader();


          let errorthroughapi = res.response.errors;
          let error = [];
          for (var i = 0; i < errorthroughapi.length; i++) {

            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }

        }
      }, async e => {
        this.common.hideLoader();

        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });

      });

  }

  cancel(){
    this.matDialog.closeAll()
  }


}
