<div class="">
    <div (click)="cancel()"class="modal-head">
        <div class=" col-md-6 heding">
            <img src="../../../../assets/img/x-close.svg"/>
            
            <div class="text-start" style="width: 100%; height: 100%; justify-content: flex-start; align-items: flex-start; gap: 4px; display: inline-flex">
                <div class="text">
                    <div class="heead-img">
                    <p>Downloads</p>
                    <img style="width: 35px" src="../../../../assets/img/logo.png" />
                    <img style="width: 35px;
                        filter: invert(96%) sepia(53%) saturate(2%) hue-rotate(209deg) brightness(0%) contrast(100%);
                        fill:black;" src="../../../../assets/img/logo-secondary.png" />
                </div>
                    <span>Users who have downloaded this</span>
                </div>
                
              </div>
        </div>
        <div class="col-md-6 srch">
            <input type="text" [(ngModel)]="search" value="search" placeholder="Search" (input)="performsearch()"/>
            <!-- <button (click)="performsearch()">Search</button> -->
        </div>
    </div>
    
    
    <div class="list mt-5">
        <ul *ngFor="let item of data" type="none"   > 
            <li  class="list-name" >
                <div (click)="redirecttouser(item.username)">
                    <img class="img" [src]="setimage(item)" width="50px" height="50px"/>
                </div>
                <div class="name-date" >
                    <div class="name" (click)="redirecttouser(item.username)">
                        <p class="username">@{{item.username}}</p>
                    </div>
                    <div class="date">
                        <p>{{getTime(item.created_on)}}</p>
                    </div>
                </div>
            </li>
        </ul>
            
<div *ngIf="total==0">
    <h5 class="empty">No Downloads</h5> 
 </div>
    </div>

    </div>