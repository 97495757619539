




import { environment } from 'src/app/sevicesall/alldata';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from 'src/app/allServices/api-service.service';
import { commonservice } from 'src/app/allServices/commonservice';
import { APIURL, CONSTNAME, VARS } from 'src/app/allServices/constant.service';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
// import { ConfirmDeleteComponent } from 'src/app/CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.
import { NgWaveformModule } from 'ng-waveform';
import { ITimeUpdateEvent, NgWaveformComponent, IRegionPositions } from 'ng-waveform';
import { MatDialog } from '@angular/material/dialog';
// import { ConfirmDeleteComponent } from '../CLIENT_PAGES/MODAL/confirm-delete/confirm-delete.component';
// import { WaveformComponent } from '../commonComponent/waveform/waveform.component';

@Component({
  selector: 'app-roughwork',
  templateUrl: './roughwork.component.html',
  styleUrls: ['./roughwork.component.css']
})
export class RoughworkComponent implements OnInit {
  tokenFromUI: string = "0123456789123456";
  encrypted: any = "";
  decrypted: string;
  request: string;
  responce: string;
  image = CONSTNAME.fILE_PATH;
  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private datePipe: DatePipe, public common: commonservice, public api: ApiServiceService, private matDialog: MatDialog) { }
  posts = ' '.repeat(100).split('').map((s, i) => i + 1)
  async ngOnInit(): Promise<void> {
    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);
    if (tokkenget) {
      
      this.callfornotification();
    }

  }
  arrayfortable: any = [];
  imageprofile: any = "assets/img/artwork.jpg";

  async callfornotification() {
    this.common.showLoader();
    let sendingdata = {
      "sort_on": "created_on",
      "sort_type": "DESC",
      "page_no": 1,
      "row_size": 10
    }

    this.api.postApiHit(`/user/get_notifications`, sendingdata,VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        // ////console.log(res)
        this.arrayfortable = res.response.data.pagging_list
      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  // onScroll() {
  //   this.callfornotification();
  // }

  onScroll() {
    const length = this.posts.length
    setTimeout(() => {
      const p:any = ' '.repeat(100).split('').map((s, i) => i + 1 + length)

      // This approach should be used to avoid creating another memory address to the array
      while(p.length) this.posts.push(p.shift())
    }, 1000)
  }


  imagesrc(items: any) {
    if (items.notifier_profile_image_path === null) {
      return this.imageprofile
    } else {
      return this.image + items.notifier_profile_image_path
    }
  }

  srcartwork(items: any) {
    if (items.artwork_path !== null) {
       return this.image + items.artwork_path
    } else if (items.owner_profile_image_path !== null) {
      return this.image + items.owner_profile_image_path
    } else {
      return this.imageprofile
     
    }
  }

  functionforaccept(items: any) {

    // ////console.log(items)
      let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(items.notifier_user_id), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      this.encrypted = encrypted.toString();
      this.encrypted = encodeURIComponent(this.encrypted);
      // ////console.log(item.user_id);
  
      
  
    

    this.callforaccept(items,this.encrypted);

  }
  callforaccept(items:any,second:any){
    this.common.showLoader();
    let sendingdata = {
      "requester_user_id": items.notifier_user_id,
      "encrypted_requester_user_id": second
    }

    this.api.postApiHit(`/user/accept_follow_request`, sendingdata,VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        // ////console.log(res)
        // this.arrayfortable=res.response.data.pagging_list
      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }
  functionfordecline(items: any) {

    // ////console.log(items.notifier_user_id)
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(items.notifier_user_id), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    // ////console.log(item.user_id);

    

  

    this.callfordestroy(items.notifier_user_id, this.encrypted);
    
  }


  callfordestroy(items:any,second:any){


    this.common.showLoader();
    let sendingdata = {
      "requester_user_id": items,
      "encrypted_requester_user_id": second
    }

    this.api.postApiHit(`/user/decline_follow_request`, sendingdata ,VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        // ////console.log(res)
        // this.arrayfortable=res.response.data.pagging_list
      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }
  follow(){


    this.common.showLoader();
    let sendingdata = {
    
    }

    this.api.postApiHit(`/user/get_follow_requests`,sendingdata,  VARS.ClIENT_TYPE).then((res: any) => {
      if (res.response.status == 1) {
        this.common.hideLoader();
        // ////console.log(res)
        // this.arrayfortable=res.response.data.pagging_list
      }
      else {
        this.common.hideLoader();
        let errorthroughapi = res.response.errors;
        let error = [];
        this.common.hideLoader();
        for (var i = 0; i < errorthroughapi.length; i++) {
          let jk = error.push(errorthroughapi);
          this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
        }
      }
    }, async e => {
      this.common.hideLoader();
      this.common.hideLoader();
      this.toastr.error("Error in api", "", { positionClass: 'toast-bottom-right' });
    });
  }

  timeget(items: any) {
    // ////console.log("great ",items)
    let date_future: any = new Date();
    let date_now: any = new Date(items.actual_created_on);




    let time = date_future - date_now;

    let seconds = moment.duration(time).seconds();
    let minutes = moment.duration(time).minutes();
    let hours = moment.duration(time).hours();
    let days = moment.duration(time).days();


    // ////console.log(time,seconds, minutes , hours , days , date_future , date_now)




    if (seconds < 60 && minutes === 0) {
      // ////console.log(seconds)
      return  seconds + " sec" 
    }
    else if (minutes < 60 && hours === 0) {

      return  minutes + " min" 

    }
    else if (hours == 1 && days === 0) {
      return  hours + " hr" 
    }
    else if (hours <= 24 && days === 0) {
      return  hours + " hrs" 
    } else if (days === 1) {
      return days + " day"

    }
    else if (days >= 1) {
      // ////console.log(days)
      return "Posted " + days + " days" + " ago"
    }
  }

  async Followapi(items:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(items.notifier_user_id), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      this.encrypted = encrypted.toString();
    this.encrypted = encodeURIComponent(this.encrypted);
    this.callfofollow(items,this.encrypted);
    
  }


  async callfofollow(items:any,se:any) {
    this.common.showLoader();
    let sendingData = {
      "profile_user_id": items.notifier_user_id,
      "encrypted_user_type_id": se
    }




    let tokkenget = await this.common.getlocal(VARS.CLIENT_LOCAL_TOKEN_KEY);

    let headers: any;
    if (tokkenget) {
      this.common.showLoader();
      this.api.postApiHit(`/user/follow_unfollow_user`, sendingData).then((res: any) => {
        if (res.response.status == 1) {

          // this.getallinfoofupperdiv();


          this.common.hideLoader();
          this.toastr.success(res.response.msg, "", { positionClass: 'toast-bottom-right' });
          // this.getallinfoofcomments();
        } else if (res.response.status == 2) {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();

          // ////console.log("gaya");
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }


        }
        else {
          let errorthroughapi = res.response.errors;
          let error = [];
          // this.cancel();
          this.common.hideLoader();
          for (var i = 0; i < errorthroughapi.length; i++) {
            this.toastr.error(res.response.errors[i], "", { positionClass: 'toast-bottom-right' })
          }
        }
      }, async e => {

        this.common.hideLoader();
        this.toastr.error("Error in Api", "", { positionClass: 'toast-bottom-right' });
      });
    } else {
      this.common.hideLoader();
      // this.opendialogueforverificationfollow();
      // this.router.navigate([CONSTNAME.REDIRECT_CLIENT_registerCLIENT]);
      // this.toastr.success("You must make an account to interact with uploaded media", "", { positionClass: 'toast-bottom-right' })
    }
  }
}









