<div >
  <h4 class="mb-4">Download Archives  <a href="" class="link-right link"><i class="fa fa-arrow-left"></i> Back to Uploads</a></h4>
<div class="archive-loop-info"><span class="loop-badge">Loop </span> <span class="sub-type">One Man Tribe, 50+50 Split</span> <small>2k downloads</small></div>
<div class="uploads-wrapper"*ngFor="let item of arrayfortable">
    <div class="uploaded-item archive-item"><span class="user-img
        "><img [src]="srcforimage(item)" ></span><span class="user-name">@{{item.title}}</span><span class="upload-date">{{item.download_date_time| date: 'h:mm a dd/MM/yyyy
          ' }} </span><span class="download-invoice" (click)="redirecttoinvoice(item.loop_id,item.payment_id)"><i class="fa fa-file"></i></span>
    </div>
</div>
</div>


<div class="row">
    
  <div class="col-sm-6">
      <p *ngIf="!noresultshow && tableData">Showing {{record}} to {{pagenocount}} records of {{total}}</p>
  </div>
  <div class="col-sm-6">
      <ul class="pagination pagination-end" *ngIf="hidepagination">

          <li class="paginationliyellowcolor bluecolor hover" [ngClass]="{'activated':item === pageno}"
              *ngFor="let item of pagercount" id="liststyle{{item}}" (click)="paginationget(item)">
              {{item}}
          </li>
       


      </ul>
  </div>

</div>
<ul>

</ul>


<div *ngIf="noresultshow">
  <div class="text-center" style="
color: #108a84;
background-color: #DFF2BF;
border-color: #f5c6cb;
padding: 10px;
">No records to display
      <div><a class="link" (click)="redirecttoloop()">Click here Upload now</a> </div>
  </div>
</div>