<app-header  *ngIf="!isMobile" ></app-header>

<div class="header"  *ngIf="isMobile">
    <div class="container">
      <div class="row">
        <div class="navbar-translate logged-out col-sm-9 col-7">
          <div class="header-logo">
            <img src="./assets/img/logo1.png" href="index.html" height="60" class="header-logo-img">
            <a class="navbar-brand" href="index.html" rel="tooltip" data-placement="bottom">
              Loophead
            </a>
          </div>
        </div>
        <div _ngcontent-mwb-c143="" class="col-sm-3 col-5 btn-div"><button _ngcontent-mwb-c143="" class="header-btn mr-2">Login</button>
          <button _ngcontent-mwb-c143="" class="header-btn">Register
          </button></div>
      </div>
    </div>
  </div>

<div class="page-header" style="background-image:url(../../../../assets/img/login.jpg);background-size: cover;">
    <div class="page-header-image" ></div>
    <div class="container cont">
    <div class="col-md-8 col-sm-12 mx-auto">
        <div class="resetPassword">
       
            <!-- <form [formGroup]="resetformforloophead">
                <div class="form-group col-lg-12 ">
                    <label for="password">Password</label><span class="mx-4 text-danger">*</span>
                    <input type="password" (keyup.enter)="Submit()" class="form-control "
                        formControlName="passwordLoginpage" placeholder="Password" name="password" name="password">
                    <span style="display: flex; justify-content: space-between; flex-direction: column;">
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordLoginpage')?.touched && resetformforloophead.get('passwordLoginpage')?.hasError('required')">Password
                            is required</small>
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordLoginpage')?.touched && resetformforloophead.get('passwordLoginpage')?.hasError('minlength')">Minimum
                            6 characters required</small>
                        <small class="form-text text-danger"
                            *ngIf=" resetformforloophead.get('passwordLoginpage')?.hasError('pattern')">Password should
                            include at
                            least
                            one uppercase letter and one special character</small>
                    </span>
                </div>
                <div class="form-group col-lg-12 ">
                    <label for="password">Confirm Password</label><span class="mx-4 text-danger">*</span>
                    <input type="password" (keyup.enter)="Submit()" class="form-control "
                        formControlName="passwordconfirmLoginpage" placeholder="Password" name="password"
                        name="password">
                    <span style="display: flex; justify-content: space-between; flex-direction: column;">
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordconfirmLoginpage')?.touched && resetformforloophead.get('passwordconfirmLoginpage')?.hasError('required')">Confirm
                            Password
                            is required</small>
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordconfirmLoginpage')?.touched && resetformforloophead.get('passwordconfirmLoginpage')?.hasError('minlength')">Minimum
                            6 characters required</small>
                        <small class="form-text text-danger"
                            *ngIf=" resetformforloophead.get('passwordconfirmLoginpage')?.hasError('pattern')">Password
                            should
                            include at
                            least
                            one uppercase letter and one special character</small>
                        <small id="confirmpasserror" class="text-danger confirmperror"></small>
                    </span>

                </div>

                <div class="form-group col-lg-12 col-md-6">
                    <button type="button" class="btn btn-theme" (click)="Submit()">Submit</button>
                </div>
            </form> -->
            <div class="card z-index-0" style="border-radius: 0; padding: 25px">
            
                <div class="card-body">
                    <h1 class=" center">Reset Password</h1>
            <form  [formGroup]="resetformforloophead">
                <div class="form-group">
                    <label style="color: black;" for="password">Password</label><span class="mx-4 text-danger">*</span>
                    <input type="password" (keyup.enter)="Submit()" class="form-control "
                        formControlName="passwordLoginpage" placeholder="Password" name="password" name="password">
                    <span style="display: flex; justify-content: space-between; flex-direction: column;">
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordLoginpage')?.touched && resetformforloophead.get('passwordLoginpage')?.hasError('required')">Password
                            is required</small>
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordLoginpage')?.touched && resetformforloophead.get('passwordLoginpage')?.hasError('minlength')">Minimum
                            6 characters required</small>
                        <small class="form-text text-danger"
                            *ngIf=" resetformforloophead.get('passwordLoginpage')?.hasError('pattern')">Password should
                            include at
                            least
                            one uppercase letter and one special character</small>
                    </span>
                </div>
                <div class="form-group">
                    <label style="color: black;"  for="password">Confirm Password</label><span class="mx-4 text-danger">*</span>
                    <input type="password" (keyup.enter)="Submit()" class="form-control "
                        formControlName="passwordconfirmLoginpage" placeholder="Password" name="password"
                        name="password">
                    <span style="display: flex; justify-content: space-between; flex-direction: column;">
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordconfirmLoginpage')?.touched && resetformforloophead.get('passwordconfirmLoginpage')?.hasError('required')">Confirm
                            Password
                            is required</small>
                        <small class="form-text text-danger"
                            *ngIf="resetformforloophead.get('passwordconfirmLoginpage')?.touched && resetformforloophead.get('passwordconfirmLoginpage')?.hasError('minlength')">Minimum
                            6 characters required</small>
                        <small class="form-text text-danger"
                            *ngIf=" resetformforloophead.get('passwordconfirmLoginpage')?.hasError('pattern')">Password
                            should
                            include at
                            least
                            one uppercase letter and one special character</small>
                        <small id="confirmpasserror" class="text-danger confirmperror"></small>
                    </span>
                </div>
                <div class="form-group">
                    <button type="button"
                    style="border-radius: 10px;
                    padding: 12px 50px;
                    background-color: black;
                    display: flex;
                    width: fit-content;" class="btn btn-theme btn-rounded" (click)="Submit()">Submit</button>
                </div>
            </form>
            </div>
            </div>
        </div>
    </div>
    </div>
</div>
